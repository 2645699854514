import BuyerAddressInfo from './components/BuyerAddressInfo';
import BuyerContactDetails from './components/BuyerContactDetails';
import BuyerDeliveryDetails from './components/BuyerDeliveryDetails';
import BuyerMainInfo from './components/BuyerMainInfo';
import { Country } from '../../../../../../../../models/Country';
import { ReceiverData } from '../../../../../../../../models/ReceiverData';

export interface IBuyerSummary {
    receiverData: ReceiverData;
    countriesList: Country[];
}

export default function BuyerSummary(props: IBuyerSummary) {
    let buyerDeliveryInfoIsNotEmpty: boolean =
        props.receiverData.deliveryAddressName ||
        props.receiverData.deliveryIdRef ||
        props.receiverData.deliveryDate ||
        props.receiverData.deliveryAddressStreet ||
        props.receiverData.deliveryAddressCity ||
        props.receiverData.deliveryAddressZip ||
        props.receiverData.deliveryAddressAddGeoInf ||
        props.receiverData.deliveryAddressLocality ||
        props.receiverData.deliveryAddressCountry
            ? true
            : false;

    return (
        <>
            <BuyerMainInfo receiverData={props.receiverData} />

            <BuyerAddressInfo
                receiverData={props.receiverData}
                countriesList={props.countriesList}
            />

            {buyerDeliveryInfoIsNotEmpty && (
                <BuyerDeliveryDetails
                    receiverData={props.receiverData}
                    countriesList={props.countriesList}
                />
            )}

            {(props.receiverData.buyerContactContactEmail ||
                props.receiverData.buyerContactContactPerson ||
                props.receiverData.buyerContactContactPhoneNo) && (
                <BuyerContactDetails receiverData={props.receiverData} />
            )}
        </>
    );
}
