import { ValidationTools } from '@b4valuenet/ui-react';
import { XRechnung } from '../../../../../models/XRechnung';
import { IXRechnungPageValidator } from '../XRechnungPagesValidator';
import { ISellerOtherPayeeDetailsData } from '../pages/XRechnungSeller/components/SellerOtherPayeeDetails';

export interface IXRechnungSellerValidators {
    data: XRechnung | null;
    isFormSubmitted: boolean;
}

export interface IXRechnungSellerOtherPayeeDetails {
    otherPayeeDetailsData: ISellerOtherPayeeDetailsData | null;
    isFormSubmitted: boolean;
}

export default class XRechnungSellerValidators {
    // General accordion validation
    static DataValid({
        data,
        isFormSubmitted,
    }: IXRechnungSellerValidators): boolean {
        if (!data || !data.senderData) return false;

        return (
            ValidationTools.isRequiredFieldValueValid(
                data.senderData.senderLegalEntityName,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.senderData.senderZip,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.senderData.senderCity,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.senderData.senderCountry,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.senderData.contactContactPerson,
                isFormSubmitted
            ) &&
            ValidationTools.isEmailFieldValueValid(
                data.senderData.contactContactEmail ?? '',
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.senderData.contactContactPhoneNo,
                isFormSubmitted
            ) &&
            ((data.senderData.senderVatId ?? '').length > 0 ||
                ValidationTools.isRequiredFieldValueValid(
                    data.senderData.senderIDRefGroupValue,
                    isFormSubmitted
                )) &&
            (data.senderData.senderMicroenterpreneuer
                ? ValidationTools.isRequiredFieldValueValid(
                      data.senderData.senderTaxNo,
                      isFormSubmitted
                  )
                : ValidationTools.isRequiredFieldValueValid(
                      data.senderData.senderVatId,
                      isFormSubmitted
                  ) ||
                  ValidationTools.isRequiredFieldValueValid(
                      data.senderData.senderTaxNo,
                      isFormSubmitted
                  )) &&
            this.OtherPayeeDetailsValid({
                otherPayeeDetailsData: {
                    payeeAddressName: data.senderData.payeeAddressName || '',
                    payeeIdRef: data.senderData.payeeIdRef || '',
                    payeeLegalEntityIdRef:
                        data.senderData.payeeLegalEntityIdRef || '',
                },
                isFormSubmitted,
            }) &&
            this.SellerTaxRepGroupValid({ data, isFormSubmitted })
        );
    }

    static IsSenderVatIdRequired({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        //BT-32
        const isSenderTaxNoEmpty =
            (data?.senderData?.senderTaxNo?.length ?? 0) === 0;

        //BT-63
        const isSenderTaxRepVatIdEmpty =
            (data?.senderData?.sellerTaxRepVatId?.length ?? 0) === 0;

        //BT-48
        const isBuyerVatIdEmpty =
            (data?.receiverData.receiverVatId?.length ?? 0) === 0;

        //if vat is VatReverseCharge = -2
        if (data?.positions.some((p) => p.vatRate === -2)) {
            return (
                (isSenderTaxNoEmpty ||
                    (isSenderTaxRepVatIdEmpty && isBuyerVatIdEmpty)) &&
                !data?.senderData.senderMicroenterpreneuer
            );

            //if vatRate is Intra-community = -3
        } else if (data?.positions.some((p) => p.vatRate === -3)) {
            return (
                (isSenderTaxNoEmpty ||
                    (isSenderTaxRepVatIdEmpty && isBuyerVatIdEmpty)) &&
                !data?.senderData.senderMicroenterpreneuer
            );
            //if vatRate is out of scope = -5
        } else if (data?.positions.some((p) => p.vatRate === -5)) {
            return false;
        } else {
            return (
                (data?.senderData?.senderTaxNo?.length ?? 0) === 0 &&
                !data?.senderData.senderMicroenterpreneuer
            );
        }
    }

    static IsSenderTaxNumberRequired({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        return (
            data?.senderData.senderMicroenterpreneuer ||
            (data?.senderData?.senderVatId?.length ?? 0) === 0
        );
    }

    static OtherPayeeDetailsValid({
        otherPayeeDetailsData,
        isFormSubmitted,
    }: IXRechnungSellerOtherPayeeDetails): boolean {
        if (!otherPayeeDetailsData) return false;

        return otherPayeeDetailsData.payeeIdRef ||
            otherPayeeDetailsData.payeeLegalEntityIdRef
            ? ValidationTools.isRequiredFieldValueValid(
                  otherPayeeDetailsData.payeeAddressName,
                  isFormSubmitted
              )
            : true;
    }

    static SellerTaxRepGroupValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        if (!this.SellerTaxRepGroupIsNotEmpty(data)) {
            return true;
        }

        return (
            ValidationTools.isRequiredFieldValueValid(
                data?.senderData.sellerTaxRepName,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data?.senderData.sellerTaxRepVatId,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data?.senderData.sellerTaxRepCountry,
                isFormSubmitted
            )
        );
    }

    static SellerTaxRepGroupIsNotEmpty = (data: XRechnung | null): boolean => {
        return data?.senderData.sellerTaxRepName ||
            data?.senderData.sellerTaxRepVatId ||
            data?.senderData.sellerTaxRepStreet ||
            data?.senderData.sellerTaxRepName2 ||
            data?.senderData.sellerTaxRepZip ||
            data?.senderData.sellerTaxRepCity ||
            data?.senderData.sellerTaxRepLocality ||
            data?.senderData.sellerTaxRepCountry
            ? true
            : false;
    };
}
