import './OutboxBanner.scss';

import ReactMarkdown from 'react-markdown';
import { useAppSelector } from '../../../../redux/hooks';

export default function OutboxBanner() {
    const appSettings = useAppSelector((state) => state.app.settings);

    return (
        <aside className='outbox-banner'>
            {appSettings?.providerSettings.providerLogo && (
                <img src={appSettings?.providerSettings.providerLogo} alt='' />
            )}
            <h1>{appSettings?.providerSettings.appTitle}</h1>
            <hr />
            <ReactMarkdown className='subtitle'>
                {appSettings?.providerSettings.subtitle ?? ''}
            </ReactMarkdown>
        </aside>
    );
}
