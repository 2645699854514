import { AnimationTools, AsyncForm, Button, Card } from '@b4valuenet/ui-react';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logout } from '../../../../../redux/actions/auth';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import DeleteAccountDialog from './DeleteAccountDialog';

export default function DeleteAccountCard() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const authenticatedUser = useAppSelector((state) => state.auth.user);

    const [isDeleteDialogActive, setIsDeleteDialogActive] =
        useState<boolean>(false);

    const onSubmit = async () => {
        AnimationTools.activateModal(setIsDeleteDialogActive);
    };

    return (
        <div>
            <Card title={t('ClientAdministration.DeactivateClientHeader.Text')}>
                {authenticatedUser?.isElsterAccount ? (
                    <div className='elster-logo-wrapper'>
                        <img
                            className='elster-logo'
                            src='assets/elster-muk-logo.svg'
                            alt='ELSTER logo'
                        />
                        <p className='description'>
                            {t(
                                'ClientAdministration.ElsterDescriptionForDeletion.Text'
                            )}
                        </p>
                    </div>
                ) : (
                    <AsyncForm
                        onSubmit={() => onSubmit()}
                        className='card-content'
                    >
                        <p>
                            {t(
                                'ClientAdministration.DeactivateClientHint.Text'
                            )}
                        </p>
                        <Button
                            icon='trash'
                            type='submit'
                            text={t('ClientAdministration.DeactivateBtn.Text')}
                            primary={true}
                            danger={true}
                        />
                    </AsyncForm>
                )}
            </Card>
            <DeleteAccountDialog
                active={isDeleteDialogActive}
                onClose={(actionConfirmed: boolean) => {
                    AnimationTools.deactivateModal(setIsDeleteDialogActive);

                    if (actionConfirmed) {
                        setTimeout(() => {
                            dispatch(logout());
                        }, 1000);
                    }
                }}
            />
        </div>
    );
}
