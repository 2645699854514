import { Country } from '../models/Country';
import { GenericResponseWithKeyValuePair } from '../models/GenericResponseWithKeyValuePair';
import { Language } from '../models/Language';
import { RequestTools } from '@b4valuenet/ui-react';

export default class LocaleService {
    static ApiRoot = 'api/';
    static AssetsRoot = 'assets/';

    static getBrowserLanguage = (): string | null => {
        return (
            (navigator.languages && navigator.languages[0]) ||
            navigator.language ||
            null
        );
    };

    static getLanguageList = async (): Promise<Language[] | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'selectableoptions/getlanguagelist'
        );

        let json = await response.data;
        let result = (json as GenericResponseWithKeyValuePair) || null;
        let data = result?.data;

        let languages: Language[] = [];

        data?.forEach((element) => {
            languages.push({
                code: element.key,
                text: element.value,
            });
        });

        return languages;
    };

    static getCountriesList = async (): Promise<Country[] | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'selectableoptions/getcountrylist'
        );

        let json = await response.data;
        let result = (json as GenericResponseWithKeyValuePair) || null;
        let data = result?.data;

        let countries: Country[] = [];

        data?.forEach((element) => {
            countries.push({
                code: element.key,
                name: element.value,
            });
        });

        return countries;
    };

    static getTranslation = async (language: string): Promise<object> => {
        let response = await RequestTools.get(
            this.AssetsRoot + 'i18n/' + language + '.json'
        );

        let json = await response.data;
        return json as object;
    };
}
