import { Card } from '@b4valuenet/ui-react';
import { Country } from '../../../../../../../../../models/Country';
import { SenderData } from '../../../../../../../../../models/SenderData';
import { useTranslation } from 'react-i18next';

export interface ISellerTaxRepresentativeInfo {
    senderData: SenderData;
    countriesList: Country[];
}
export default function SellerTaxRepresentativeInfo(
    props: ISellerTaxRepresentativeInfo
) {
    const { t } = useTranslation();

    let localizedSenderTaxRepCountry = props.countriesList.find(
        (country) => country.code === props.senderData.sellerTaxRepCountry
    )?.name;

    return (
        <Card title={t('XRechnung.SellerTaxRepresentative.Text')}>
            <div className='pseudo-table align-left cw-50'>
                <table>
                    <tbody>
                        <tr>
                            <th>{t('XRechnung.Name.Text')}</th>
                            <td>{props.senderData.sellerTaxRepName}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.VatId.Text')}</th>
                            <td>{props.senderData.sellerTaxRepVatId}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.Street.Text')}</th>
                            <td>{props.senderData.sellerTaxRepStreet}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.SenderSubLocality.Text')}</th>
                            <td> {props.senderData.sellerTaxRepName2}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.ZipCity.Text')}</th>
                            <td>
                                {(props.senderData.sellerTaxRepZip ?? '') +
                                    ' ' +
                                    (props.senderData.sellerTaxRepCity ?? '')}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.Locality.Text')}</th>
                            <td>{props.senderData.sellerTaxRepLocality}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.Country.Text')}</th>
                            <td>{localizedSenderTaxRepCountry}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
