import './XRechnungReferences.scss';

import {
    AnimationTools,
    Button,
    DragAndDropArea,
    DragAndDropAreaTranslation,
    LoadingOverlay,
    RequiredFieldsNotice,
    StringTools,
    Toast,
    findAndFocusModalCallerElement,
} from '@b4valuenet/ui-react';
import React, { useEffect, useState } from 'react';
import {
    IUploadReportDialogData,
    UploadReportDialog,
} from './components/UploadReportDialog';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BigAttachment } from '../../../../../../models/BigAttachment';
import { BigAttachmentUploadRestrictions } from '../../../../../../models/BigAttachmentUploadRestrictions';
import { ExternalReference } from '../../../../../../models/ExternalReference';
import { GenericActionResponse } from '../../../../../../models/GenericActionResponse';
import { useAppSelector } from '../../../../../../redux/hooks';
import BigAttachmentsService from '../../../../../../services/BigAttachmentsService';
import { UILibraryTranslations } from '../../../../../../shared/UILibraryTranslations';
import { IXRechnungSubPage } from '../../XRechnungPage';
import InvoiceBigAttachmentLinkDialog from './components/InvoiceBigAttachmentLinkDialog';
import InvoiceExternalReference from './components/InvoiceExternalReference';

export function XRechnungReferences(props: IXRechnungSubPage) {
    const { t } = useTranslation();
    const bigAttachmentsSizeLimit = 209715200;

    const appSettings = useAppSelector((state) => state.app.settings);

    let params = useParams();
    let id = params.id !== undefined ? +params.id : null;

    let dndAreaTranslation: DragAndDropAreaTranslation =
        UILibraryTranslations.getDragAndDropTranslation(t);

    let messageId: number = props.data?.messageId ?? id ?? 0;

    const [restrictions, setRestrictions] =
        useState<BigAttachmentUploadRestrictions | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [dialogActive, setDialogActive] = useState<boolean>(false);

    const [uploadReport, setUploadReport] = useState<IUploadReportDialogData>({
        size: [],
        type: [],
        upload: [],
        success: [],
    });

    const [uploadReportDialogActive, setUploadReportDialogActive] =
        useState<boolean>(false);

    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

    const [modalCallerElement, setModalCallerElement] =
        useState<HTMLButtonElement | null>(null);

    const [selectedBigAttachments, setSelectedBigAttachments] = useState<
        BigAttachment[]
    >([]);

    useEffect(() => {
        async function fetchRestrictions() {
            if (appSettings && !appSettings.bigAttachmentsEnabled) return;

            try {
                let newRestrictions =
                    await BigAttachmentsService.getUploadRestrictions();

                setRestrictions(newRestrictions);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        }

        fetchRestrictions();
    }, [messageId, appSettings]);

    useEffect(() => {
        if (
            !uploadInProgress &&
            (uploadReport.size.length > 0 ||
                uploadReport.type.length > 0 ||
                uploadReport.upload.length > 0 ||
                uploadReport.success.length > 0)
        ) {
            if (
                uploadReport.size.length > 0 ||
                uploadReport.type.length > 0 ||
                uploadReport.upload.length > 0
            ) {
                AnimationTools.activateModal(setUploadReportDialogActive);
            } else if (uploadReport.success.length > 0) {
                Toast.success(t('XRechnung.UploadSuccessful.Text'));
            }
        }
    }, [uploadInProgress, uploadReport, t]);

    const handleOnFileSelected = (files: File[]) => {
        if (!appSettings?.bigAttachmentsEnabled) return;

        setIsLoading(true);
        setUploadInProgress(true);

        let filesToUpload: Promise<void>[] = [];

        for (const file of files) {
            try {
                if (!restrictions || !appSettings) {
                    Toast.error(t('Common.UploadTooLarge.Text'));
                    setIsLoading(false);
                    return;
                }

                if (file.size > restrictions.maxUploadFileSize) {
                    setUploadReport((prevData) => {
                        return {
                            ...prevData,
                            size: [
                                ...prevData.size,
                                {
                                    filename: file.name,
                                    size:
                                        (file.size / 1024 / 1024).toFixed(2) +
                                        ' MB',
                                    maxUploadLimit:
                                        restrictions.maxUploadFileSize /
                                            1024 /
                                            1024 +
                                        ' MB',
                                },
                            ],
                        };
                    });

                    continue;
                }

                const fileExtension =
                    file.name.split('.').pop()?.toString() || '';

                if (
                    !restrictions.allowedFileExtensions.some(
                        (ext) =>
                            fileExtension.toLocaleLowerCase() ===
                            ext.toLocaleLowerCase()
                    )
                ) {
                    setUploadReport((prevData) => {
                        return {
                            ...prevData,
                            type: [
                                ...prevData.type,
                                {
                                    filename: file.name,
                                    type: fileExtension,
                                    allowedTypes:
                                        restrictions.allowedFileExtensions
                                            .join(', ')
                                            .replace(/\./g, ''),
                                },
                            ],
                        };
                    });

                    continue;
                }

                let totalSize = 0;

                props.data?.references.forEach((reference) => {
                    totalSize += reference.fileSize ?? 0;
                });

                if (totalSize + file.size > bigAttachmentsSizeLimit) {
                    Toast.error(
                        t(
                            'UserFeedback.MaximumAllowedBigAttachmentSizeExceeded.Text',
                            {
                                '0': StringTools.humanSize(
                                    Math.abs(
                                        bigAttachmentsSizeLimit - totalSize
                                    )
                                ),
                                '1': StringTools.humanSize(
                                    bigAttachmentsSizeLimit
                                ),
                            }
                        )
                    );
                    break;
                }
                filesToUpload.push(uploadFile(file));
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        }

        try {
            Promise.all(filesToUpload)
                .then(() => {})
                .catch(() => {
                    AnimationTools.activateModal(setUploadReportDialogActive);
                })
                .finally(() => {
                    setUploadInProgress(false);
                    setIsLoading(false);
                });
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        setIsLoading(false);
    };

    const uploadFile = (file: File): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            if (!restrictions || !appSettings) {
                Toast.error(t('Common.UploadTooLarge.Text'));
                setIsLoading(false);
                return;
            }

            try {
                const result = await BigAttachmentsService.upload(
                    file,
                    new AbortController(),
                    () => {}
                );

                setUploadReport((prevData) => {
                    return {
                        ...prevData,
                        success: [
                            ...prevData.success,
                            {
                                filename: file.name,
                            },
                        ],
                    };
                });

                props.updateData((prevData) => ({
                    ...prevData,
                    references: [
                        ...prevData.references,
                        {
                            name: result?.data?.metaData.link || '',
                            identifier: result?.data?.metaData.fileName || '',
                            description: '',
                            fileSize: result?.data.metaData.fileSize,
                        },
                    ],
                }));

                resolve();
            } catch (error) {
                const axiosError = error as AxiosError;

                if (axiosError.response) {
                    const response = axiosError.response
                        .data as GenericActionResponse;

                    if (parseInt(axiosError.status?.toString() || '') === 409) {
                        setUploadReport((prevData) => {
                            return {
                                ...prevData,
                                upload: [
                                    ...prevData.upload,
                                    {
                                        filename: file.name,
                                        reason:
                                            response.message || axiosError.code,
                                    },
                                ],
                            };
                        });
                    } else if (parseInt(axiosError.status?.toString() || '') === 415) {
                        const fileExtension =
                            file.name.split('.').pop()?.toString() || '';

                        setUploadReport((prevData) => {
                            return {
                                ...prevData,
                                type: [
                                    ...prevData.type,
                                    {
                                        filename: file.name,
                                        type: fileExtension,
                                        allowedTypes:
                                            restrictions.allowedFileExtensions
                                                .join(', ')
                                                .replace(/\./g, ''),
                                    },
                                ],
                            };
                        });
                    } else {
                        setUploadReport((prevData) => {
                            return {
                                ...prevData,
                                upload: [
                                    ...prevData.upload,
                                    {
                                        filename: file.name,
                                        reason:
                                            response.message || axiosError.code,
                                    },
                                ],
                            };
                        });
                    }
                }

                reject();
            }
        });
    };

    return (
        <div className='spaced-fields xrechnung-references'>
            <RequiredFieldsNotice
                description={t(
                    'ValidationMessage.IndicatesARequiredField.Text'
                )}
            />
            {appSettings?.bigAttachmentsEnabled && (
                <>
                    <DragAndDropArea
                        translation={dndAreaTranslation}
                        allowedExtensions={
                            restrictions?.allowedFileExtensions ?? []
                        }
                        onFilesSelected={(files: File[]) =>
                            handleOnFileSelected(files)
                        }
                    />

                    <div className='file-requirements'>
                        <p>
                            <strong>
                                {t('XRechnung.MaxFileSizePerFile.Text')}:
                            </strong>{' '}
                            {StringTools.humanSize(
                                restrictions?.maxUploadFileSize ?? 0
                            )}
                        </p>
                        <p>
                            <strong>
                                {t('XRechnung.AllowedExtensions.Text')}:
                            </strong>{' '}
                            {restrictions?.allowedFileExtensions.join(', ')}
                        </p>
                    </div>

                    <p className='help-text'>
                        {t('XRechnung.BigAttachmentsGridHelpText.Text')}
                    </p>
                </>
            )}

            <div className='spaced-fields'>
                {props.data?.references.map(
                    (reference: ExternalReference, index: number) => {
                        return (
                            <InvoiceExternalReference
                                key={index}
                                isFormSubmitted={props.isFormSubmitted}
                                reference={reference}
                                onChange={(
                                    updatedReference: ExternalReference
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => {
                                            let newReferences = [
                                                ...(prevData.references ?? []),
                                            ];

                                            newReferences[index] =
                                                updatedReference;

                                            return {
                                                ...prevData,
                                                references: newReferences,
                                            };
                                        });
                                    }
                                }}
                                onRemove={async () => {
                                    if (props.data) {
                                        setIsLoading(true);

                                        try {
                                            props.updateData((prevData) => {
                                                let newReferences = [
                                                    ...prevData.references,
                                                ];

                                                newReferences.splice(index, 1);

                                                return {
                                                    ...prevData,
                                                    references: newReferences,
                                                };
                                            });

                                            Toast.success(
                                                t(
                                                    'UserFeedback.SuccessfullyRemoved.Text',
                                                    {
                                                        field: t(
                                                            'XRechnung.AttachmentTypeReference.Text'
                                                        ),
                                                    }
                                                )
                                            );
                                        } catch (error) {
                                            Toast.showErrorWhenAuthorized(
                                                error
                                            );
                                        }

                                        setIsLoading(false);
                                    }
                                }}
                            />
                        );
                    }
                )}
            </div>

            <div className='action-buttons'>
                <Button
                    onClick={() => {
                        if (props.data) {
                            props.updateData((prevData) => {
                                let newReferences = [...prevData.references];

                                newReferences.push({} as any);

                                return {
                                    ...prevData,
                                    references: newReferences,
                                };
                            });
                        }
                    }}
                    className='add-reference'
                    icon='plus-lg'
                    text={t('XRechnung.AddReferenceAttachmentBtn.Text')}
                    primary={true}
                />

                {appSettings?.bigAttachmentsEnabled && (
                    <Button
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            if (e.target instanceof HTMLButtonElement)
                                setModalCallerElement(e.target);
                            AnimationTools.activateModal(setDialogActive);
                        }}
                        className='link-big-attachmnet'
                        icon='link-45deg'
                        text={t('XRechnung.LinkBigAttachmentBtn.Text')}
                        primary={true}
                    />
                )}
            </div>

            <InvoiceBigAttachmentLinkDialog
                active={dialogActive}
                selectedReferences={props.data?.references ?? []}
                onClose={(selectedAttachments: BigAttachment[]) => {
                    AnimationTools.deactivateModal(setDialogActive, () => {
                        findAndFocusModalCallerElement(
                            modalCallerElement,
                            () => {
                                setModalCallerElement(null);
                            }
                        );
                    });

                    if (selectedAttachments.length > 0) {
                        if (props.data) {
                            props.updateData((prevData) => {
                                let newReferences = [...prevData.references];

                                let newBigAttachments = [
                                    ...selectedBigAttachments,
                                ];

                                selectedAttachments.forEach((attachment) => {
                                    newReferences.push({
                                        name: attachment.metaData.link,
                                        identifier:
                                            attachment.metaData.fileName,
                                        description: '',
                                        fileSize: attachment.metaData.fileSize,
                                    });

                                    newBigAttachments.push(attachment);
                                });

                                return {
                                    ...prevData,
                                    references: newReferences,
                                };
                            });
                        }
                    }
                }}
            />

            <UploadReportDialog
                report={uploadReport}
                onClose={() => {
                    AnimationTools.deactivateModal(
                        setUploadReportDialogActive,
                        () => {
                            setUploadReport((prevData) => {
                                return {
                                    size: [],
                                    type: [],
                                    upload: [],
                                    success: [],
                                };
                            });
                        }
                    );

                    setIsLoading(false);
                }}
                active={uploadReportDialogActive}
            />

            <LoadingOverlay
                active={isLoading || uploadInProgress}
                loadingMessage={t('Common.LoadingMessage.Text')}
                isGlobal={true}
            />
        </div>
    );
}
