import {
    BootstrapIcon,
    Button,
    Column,
    Dialog,
    ErrorOverlay,
    FileTools,
    FilteringOdataFunction,
    FilteringType,
    GridTable,
    IActiveFilter,
    IHeadingTitle,
    Pagination,
    RequestTools,
    Row,
    SortState,
    StringTools,
    TableTools,
    Toast,
    useDebounce,
} from '@b4valuenet/ui-react';
import { useCallback, useEffect, useState } from 'react';

import { BigAttachment } from '../../../../../../../models/BigAttachment';
import BigAttachmentsService from '../../../../../../../services/BigAttachmentsService';
import { ExternalReference } from '../../../../../../../models/ExternalReference';
import { UILibraryTranslations } from '../../../../../../../shared/UILibraryTranslations';
import { useAppSelector } from '../../../../../../../redux/hooks';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IInvoiceBigAttachmentLinkDialog {
    active: boolean;
    selectedReferences: ExternalReference[];
    onClose: (selectedIds: BigAttachment[]) => void;
}

export default function InvoiceBigAttachmentLinkDialog(
    props: IInvoiceBigAttachmentLinkDialog
) {
    const { t } = useTranslation();

    const itemsProPage = 25;
    const bigAttachmentsSizeLimit = 209715200;

    let tableHeadingTitles: IHeadingTitle[] = [
        {
            title: t('Messages.BigAttachmentsFileName.Text'),
            propertyName: 'metaData/fileName',
            sortable: true,
            filtering: FilteringType.String,
            filteringOdataFunction: FilteringOdataFunction.Contains,
        },
        {
            title: t('Messages.BigAttachmentsFileFormat.Text'),
            propertyName: 'metaData/mimeType',
            sortable: true,
            filtering: FilteringType.StringList,
            filteringOdataFunction: FilteringOdataFunction.Equal,
            filteringOptionsList: [
                {
                    key: '',
                    value: t('Common.NoSelection.Text'),
                },
                {
                    key: 'image/jpeg',
                    value: 'JPG/JPEG',
                },
                {
                    key: 'image/png',
                    value:
                        FileTools.getFileExtension('png')?.toUpperCase() || '',
                },
                {
                    key: 'application/pdf',
                    value:
                        FileTools.getFileExtension('pdf')?.toUpperCase() || '',
                },
                {
                    key: 'text/csv',
                    value:
                        FileTools.getFileExtension('csv')?.toUpperCase() || '',
                },
                {
                    key: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    value:
                        FileTools.getFileExtension('xlsx')?.toUpperCase() || '',
                },
                {
                    key: 'application/vnd.oasis.opendocument.spreadsheet',
                    value:
                        FileTools.getFileExtension('ods')?.toUpperCase() || '',
                },
                {
                    key: 'application/xml',
                    value:
                        FileTools.getFileExtension('xml')?.toUpperCase() || '',
                },
            ],
        },
        {
            title: t('Messages.BigAttachmentsCreated.Text'),
            propertyName: 'metaData/created',
            sortable: true,
            filtering: FilteringType.Date,
            filteringOdataFunction: FilteringOdataFunction.DateDefault,
        },
        {
            title: t('Messages.BigAttachmentsExpires.Text'),
            propertyName: 'metaData/expires',
            sortable: true,
            filtering: FilteringType.Date,
            filteringOdataFunction: FilteringOdataFunction.DateDefault,
        },
    ];

    const [searchParams, setSearchParams] = useSearchParams();
    let queryFiltering = TableTools.parseQueryFiltering(searchParams);

    const dashboardRoute = useAppSelector((state) => state.app.dashboardRoute);

    const [data, setData] = useState<BigAttachment[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingError, setLoadingError] = useState<string | null>(null);

    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

    const [pageCount, setPageCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [activeFiltering, setActiveFiltering] = useState<IActiveFilter[]>(
        TableTools.queryFilteringToGridTableFormat(
            queryFiltering,
            tableHeadingTitles
        )
    );
    const [filteringString, setFilteringString] = useState<string>(
        TableTools.buildOdataFilterString(activeFiltering)
    );
    const [activeSort, setActiveSort] = useState<SortState>(
        TableTools.sortStateFromString('none')
    );
    const [activeSortProperty, setActiveSortProperty] = useState<string | null>(
        null
    );
    const [totalCount, setTotalCount] = useState<number>(0);

    const [selectedIds, setSelectedIds] = useState<(number | string)[]>();
    const [debouncedSelectedIds, setDebouncedSelectedIds] =
        useState<(number | string)[]>();

    const [selectedAttachments, setSelectedAttachments] = useState<
        BigAttachment[]
    >([]);

    const [bigAttachmentsSize, setBigAttachmentsSize] = useState<{
        totalSize: number;
        error: boolean;
    }>({ totalSize: 0, error: false });

    useEffect(() => {
        setSelectedIds([]);
        setSelectedAttachments([]);
    }, [props.active]);

    useEffect(() => {
        let newSelectedAttachments = [...selectedAttachments];

        if (selectedIds?.length === 0) {
            newSelectedAttachments = [];
            setSelectedAttachments(newSelectedAttachments);
            return;
        }

        selectedIds?.forEach((id) => {
            let alreadyExists: boolean = selectedAttachments.find(
                (attachment) => attachment.id === id
            )
                ? true
                : false;

            if (!alreadyExists) {
                let findedAttachment = data?.find(
                    (attachment) => attachment.id === id
                );

                if (findedAttachment) {
                    newSelectedAttachments.push(findedAttachment);
                }
            }
        });

        newSelectedAttachments.forEach((attachment, index) => {
            if (!selectedIds?.includes(attachment.id)) {
                newSelectedAttachments.splice(index, 1);
            }
        });

        setSelectedAttachments(newSelectedAttachments);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIds]);

    useEffect(() => {
        if ((filteringString?.length ?? 0) === 0) refresh(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteringString]);

    useEffect(() => {
        let totalSize = 0;

        props.selectedReferences.forEach((reference) => {
            totalSize += reference.fileSize ?? 0;
        });

        selectedAttachments.forEach((attachment) => {
            totalSize += attachment.metaData.fileSize;
        });

        //TODO: Get this somewhere from config
        if (totalSize > bigAttachmentsSizeLimit) {
            setBigAttachmentsSize({ totalSize: totalSize, error: true });
        } else {
            setBigAttachmentsSize({ totalSize: totalSize, error: false });
        }
    }, [props.selectedReferences, selectedAttachments]);

    useEffect(() => {
        setActiveFiltering(
            TableTools.queryFilteringToGridTableFormat(
                queryFiltering,
                tableHeadingTitles
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refresh = useCallback(
        (showToast: boolean = true) => {
            setRefreshTrigger(!refreshTrigger);

            if (showToast) Toast.info(t('Common.ListUpdateSuccess.Text'));
        },
        [refreshTrigger, t]
    );

    useDebounce(() => {
        setDebouncedSelectedIds(selectedIds);
    }, [selectedIds]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                let content = await BigAttachmentsService.getBigAttachments(
                    currentPage,
                    itemsProPage,
                    activeSort,
                    activeSortProperty,
                    filteringString
                );

                setTotalCount(content?.['@odata.count'] ?? 0);
                setData(content?.value ?? null);
                setLoadingError(null);

                let allItemsCount =
                    content !== null ? content['@odata.count'] : 0;
                let newPageCount = Math.ceil(allItemsCount / itemsProPage);

                setPageCount(newPageCount);

                setIsLoading(false);
            } catch (error) {
                if (error instanceof Error) {
                    setLoadingError(RequestTools.getApiErrorMessage(error));
                }

                setIsLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentPage,
        itemsProPage,
        activeSort,
        activeSortProperty,
        refreshTrigger,
    ]);

    return (
        <Dialog
            title={t('Messages.BigAttachmentsSelect.Text')}
            closeButtonTitle={t('Common.Close.Text')}
            active={props.active}
            onClose={() => props.onClose([])}
            className='big-attachment-link-dialog'
        >
            <div className='attachments-table-wrapper'>
                <GridTable
                    tableData={data}
                    disableCtrlA={true}
                    enableSelection={true}
                    selectedItems={debouncedSelectedIds}
                    onSelectItems={(items: (number | string)[]) => {
                        if (items.length !== debouncedSelectedIds?.length)
                            setSelectedIds(items);
                    }}
                    isLoading={isLoading}
                    loadingText={t('Common.LoadingMessage.Text')}
                    loadingError={loadingError}
                    onEmptyMessage={t('Messages.NoAttachmentsAvailable.Text')}
                    tableHeadingTitles={tableHeadingTitles}
                    enableFiltering={true}
                    onFilter={(filtering, newFilteringString) => {
                        TableTools.updateQueryParamsOnFilter(
                            filtering,
                            searchParams,
                            setSearchParams
                        );

                        //TODO: causes double loading
                        setCurrentPage(1);
                        setSelectedIds([]);
                        setSelectedAttachments([]);
                        setDebouncedSelectedIds([]);
                        setFilteringString(newFilteringString);
                    }}
                    activeFiltering={activeFiltering ?? []}
                    filteringTranslation={{
                        toggleButtonTitle: t(
                            'Common.Filtering_ToggleButtonTitle.Text'
                        ),
                        resetButtonTitle: t(
                            'Common.Filtering_ResetButtonTitle.Text'
                        ),
                        calendarTranslation:
                            UILibraryTranslations.getCalendarTranslation(t),
                    }}
                    filteringActionButtons={
                        <>
                            <Button
                                onClick={() => refresh(false)}
                                primary={true}
                                icon={'search'}
                                disabled={(filteringString?.length ?? 0) === 0}
                                text={t('Setup.SearchBtn.Text')}
                            />
                        </>
                    }
                    activeSort={activeSort}
                    activeSortProperty={activeSortProperty}
                    onSort={(
                        newActiveSort: SortState,
                        newActiveSortProperty: string | null
                    ) => {
                        setActiveSort(newActiveSort);
                        setActiveSortProperty(newActiveSortProperty);
                    }}
                    showCount={true}
                    totalCount={totalCount}
                    countPerPage={itemsProPage}
                    currentPage={currentPage}
                    countTranslation={{
                        of: t('Common.PaginatorOf.Text'),
                        items: t('XRechnung.Attachment.Text'),
                    }}
                    rowRendering={(element: BigAttachment, index: number) => {
                        return (
                            <Row id={element.id} key={index}>
                                <Column>{element.metaData.fileName}</Column>
                                <Column size='small'>
                                    {
                                        <div className='file-format'>
                                            <BootstrapIcon
                                                className='file-type'
                                                icon={FileTools.getIconByMime(
                                                    element.metaData.mimeType
                                                )}
                                            />
                                            {FileTools.getFileExtension(
                                                element.metaData.fileName
                                            )?.toUpperCase()}
                                        </div>
                                    }
                                </Column>
                                <Column>
                                    {TableTools.toLocaleDateString(
                                        element.metaData.created
                                    )}
                                </Column>
                                <Column>
                                    {TableTools.toLocaleDateString(
                                        element.metaData.expires
                                    )}
                                </Column>
                            </Row>
                        );
                    }}
                    //TODO: actions buttons and title required - should be optional
                    actionButtons={<></>}
                    title={''}
                />
                <ErrorOverlay
                    active={loadingError !== null}
                    errorText={loadingError ?? ''}
                />
            </div>

            {pageCount > 1 && (
                <Pagination
                    routePath={dashboardRoute}
                    maxItemsCount={5}
                    currentPage={currentPage}
                    pageCount={pageCount}
                    stateMode={true}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    labels={{
                        goToStartPage: t(
                            'Common.PaginationGoToStartPageLabel.Text'
                        ),
                        goToPage: t('Common.PaginationGoToPageLabel.Text'),
                        goToEndPage: t(
                            'Common.PaginationGoToEndPageLabel.Text'
                        ),
                    }}
                />
            )}

            {bigAttachmentsSize.error && (
                <div aria-live='polite'>
                    {
                        <p className='error'>
                            {t(
                                'UserFeedback.MaximumAllowedBigAttachmentSizeExceeded.Text',
                                {
                                    '0': StringTools.humanSize(
                                        Math.abs(
                                            bigAttachmentsSizeLimit -
                                                bigAttachmentsSize.totalSize
                                        )
                                    ),
                                    '1': StringTools.humanSize(
                                        bigAttachmentsSizeLimit
                                    ),
                                }
                            )}
                        </p>
                    }
                </div>
            )}

            <div className='action-buttons'>
                <Button
                    icon='x-lg'
                    danger={true}
                    text={t('Messages.ProtocolCloseBtn.Text')}
                    onClick={() => {
                        props.onClose([]);
                    }}
                />

                <Button
                    icon='arrow-clockwise'
                    text={t('Messages.RefreshBtn.Text')}
                    onClick={() => {
                        refresh();
                    }}
                />

                <Button
                    disabled={bigAttachmentsSize.error}
                    icon='plus-lg'
                    primary={true}
                    text={
                        t('Messages.AddBigAttachmentLinks.Text') +
                        ' (' +
                        selectedAttachments.length +
                        ')'
                    }
                    onClick={() => {
                        if (bigAttachmentsSize.error) {
                            Toast.error(
                                t(
                                    'UserFeedback.MaximumAllowedBigAttachmentSizeExceeded.Text',
                                    {
                                        '0': StringTools.humanSize(
                                            Math.abs(
                                                bigAttachmentsSizeLimit -
                                                    bigAttachmentsSize.totalSize
                                            )
                                        ),
                                        '1': StringTools.humanSize(
                                            bigAttachmentsSizeLimit
                                        ),
                                    }
                                )
                            );
                        } else {
                            props.onClose(selectedAttachments);
                        }
                    }}
                />
            </div>
        </Dialog>
    );
}
