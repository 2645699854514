import { AccordionItem } from '@b4valuenet/ui-react';
import { KeyValuePair } from '../../../../../../../../../models/KeyValuePair';
import { PositionLine } from '../../../../../../../../../models/PositionLine';
import RebateGroupsSummary from './RebateGroupsSummary';
import SurchargeGroupsSummary from './SurchargeGroupsSummary';
import { useTranslation } from 'react-i18next';

interface IPositionLineSummary {
    line: PositionLine;
    unitOptions: KeyValuePair[];
    taxCategories: KeyValuePair[];
    index: number;
    totalCount: number;
}

export default function PositionLineSummary(props: IPositionLineSummary) {
    const { t } = useTranslation();

    let localizedUnit =
        props.unitOptions.find((unit) => unit.key === props.line.unit)?.value ??
        '';

    let localizedTaxCategory =
        props.taxCategories.find(
            (taxCategory) => parseFloat(taxCategory.key) === props.line.vatRate
        )?.value ?? '';

    const positionCostCenterNotEmpty =
        props.line.costCenter || props.line.poPos || props.line.articleNoBuyer;

    return (
        <AccordionItem
            expanded={false}
            title={
                <>
                    <strong>
                        {t('XRechnung.PositionNo.Text') + ':'}
                        &nbsp;
                    </strong>
                    {props.line.positionNo ?? ''}
                </>
            }
            body={
                <>
                    <div
                        className='pseudo-table align-left cw-25'
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <table className='w-50'>
                            <tbody>
                                <tr>
                                    <th>{t('XRechnung.PositionNo.Text')}</th>
                                    <td>{props.line.positionNo}</td>
                                </tr>
                                <tr>
                                    <th>
                                        {t('XRechnung.ArticleDescription.Text')}
                                    </th>
                                    <td>{props.line.articleDescription}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table className='w-50'>
                            <tbody>
                                <tr>
                                    <th>
                                        {t('XRechnung.ArticleNoSupplier.Text')}
                                    </th>
                                    <td>{props.line.articleNoSupplier}</td>
                                </tr>
                                <tr>
                                    <th>
                                        {t(
                                            'XRechnung.ArticleDescription2.Text'
                                        )}
                                    </th>
                                    <td>{props.line.articleDescription2}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div
                        className='pseudo-table align-left cw-25'
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <table className='w-50'>
                            <tbody>
                                <tr>
                                    <th>
                                        {t('XRechnung.PositionQuantity.Text')}
                                    </th>
                                    <td>{props.line.quantity}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className='w-50'>
                            <tbody>
                                <tr>
                                    <th>{t('XRechnung.PositionUnit.Text')}</th>
                                    <td>{localizedUnit}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div
                        className='pseudo-table align-left cw-16'
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <table className='w-33'>
                            <tbody>
                                <tr>
                                    <th>{t('XRechnung.PositionPrice.Text')}</th>
                                    <td>{props.line.price}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className='w-33'>
                            <tbody>
                                <tr>
                                    <th>
                                        {t(
                                            'XRechnung.PositionTaxCategory.Text'
                                        )}
                                    </th>
                                    <td>{localizedTaxCategory}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className='w-33'>
                            <tbody>
                                <tr>
                                    <th>{t('XRechnung.PositionValue.Text')}</th>
                                    <td>{props.line.value}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    {positionCostCenterNotEmpty && (
                        <>
                            <div
                                className='pseudo-table align-left cw-16'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <table className='w-33'>
                                    <tbody>
                                        <tr>
                                            <th>
                                                {t(
                                                    'XRechnung.PositionCostCenter.Text'
                                                )}
                                            </th>
                                            <td>{props.line.costCenter}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='w-33'>
                                    <tbody>
                                        <tr>
                                            <th>
                                                {t(
                                                    'XRechnung.PositionPoPos.Text'
                                                )}
                                            </th>
                                            <td>{props.line.poPos}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='w-33'>
                                    <tbody>
                                        <tr>
                                            <th>
                                                {t(
                                                    'XRechnung.ArticleNoBuyer.Text'
                                                )}
                                            </th>
                                            <td>{props.line.articleNoBuyer}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr />
                        </>
                    )}

                    {props.line.rebateGroups.length > 0 && (
                        <>
                            <RebateGroupsSummary
                                rebateGroups={props.line.rebateGroups}
                            />
                            <hr />
                        </>
                    )}
                    {props.line.rebateGroups.length > 0 && (
                        <>
                            <SurchargeGroupsSummary
                                surchargeGroups={props.line.surchargeGroups}
                            />
                            <hr />
                        </>
                    )}
                    {props.line.subLines.length > 0 &&
                        props.line.subLines.map((subLine, i) => {
                            return (
                                <PositionLineSummary
                                    line={subLine}
                                    unitOptions={props.unitOptions}
                                    taxCategories={props.taxCategories}
                                    index={i}
                                    totalCount={props.line.subLines.length}
                                />
                            );
                        })}
                </>
            }
        />
    );
}
