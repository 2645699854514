import './EasyLanguagePage.scss';

import {
    AccessibleNavLink,
    BootstrapIcon,
    Button,
    Card,
    ReadableScreenReaderItem,
} from '@b4valuenet/ui-react';

import { useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';

export interface IEasyLanguagePage {
    title?: string;
    i18title?: string;
}

export default function EasyLanguagePage(props: IEasyLanguagePage) {
    const { t } = useTranslation();

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    return (
        <div className='sub-page easy-language-page'>
            <h1>
                {props.title ??
                    (props.i18title !== undefined
                        ? t(props.i18title)
                        : '[no-title]')}
            </h1>
            <hr />
            {!isLoggedIn && (
                <div className='navigation-to-auth'>
                    <Button
                        linkTo={'/'}
                        isLink={true}
                        icon='chevron-left'
                        text={t('PasswordForgotten.CancelButton.Text')}
                        primary={true}
                    />
                </div>
            )}
            <h2>Inhalt von der Internet-Seite</h2>

            <section>
                <Card
                    aria-labelledby='easy-language-card-title-1'
                    className='flex-row align-items-center'
                >
                    <div className='img-wrapper'>
                        <BootstrapIcon
                            className='card-icon'
                            icon='bounding-box'
                        />
                    </div>
                    <div>
                        <p>
                            <h3 id='easy-language-card-title-1'>
                                Die OZG-konforme Rechnungs-Eingangs-Plattform.
                                Kurz OZG-RE
                            </h3>
                        </p>

                        <p>OZG ist die Abkürzung für Online-Zugangs-Gesetz.</p>

                        <p>
                            Das Online-Zugangs-Gesetz soll die Zusammen-Arbeit
                            verbessern:{' '}
                        </p>
                        <ul>
                            <li>zwischen Bürgern und öffentlichen Ämtern</li>
                            <li>zwischen Firmen und öffentlichen Ämtern</li>
                        </ul>
                        <p>
                            Die Zusammen-Arbeit über das Internet soll einfach
                            und sicher sein.
                        </p>
                        <p>Rechnungs-Eingangs-Plattform bedeutet:</p>
                        <p>
                            Auf dieser Seite können Sie Rechnungen versenden und
                            verwalten. Diese Seite ist vom Bundes-Ministerium
                            des Inneren und für Heimat.
                        </p>
                    </div>
                </Card>

                <Card
                    aria-labelledby='easy-language-card-title-2'
                    className='flex-row align-items-center'
                >
                    <div className='img-wrapper'>
                        <img
                            src={'assets/guide/login-form.png'}
                            alt='Login Website'
                            className='card-img'
                        />
                    </div>
                    <div>
                        <h3 id='easy-language-card-title-2'>
                            So kommen Sie auf die Start-Seite
                        </h3>
                        <p>
                            Klicken Sie auf diesen Link:&nbsp;
                            <a
                                href='https://xrechnung-bdr.de'
                                target='_blank'
                                rel='noreferrer'
                            >
                                xrechnung-bdr.de
                            </a>
                            . Dann öffnet sich ein neues Fenster. In dem Fenster
                            können Sie sich anmelden.
                        </p>
                        <p>
                            Sie geben dort ihre Mail-Adresse und ein Passwort
                            ein.
                        </p>
                        <p>Dann kommen Sie auf die Seite von OZG-RE</p>
                    </div>
                </Card>

                <Card
                    aria-labelledby='easy-language-card-title-3'
                    className='flex-row align-items-center'
                >
                    <div className='img-wrapper flex-column'>
                        <img
                            style={{ marginBottom: '1rem', width: '7rem' }}
                            src={'assets/guide/menu-closed.png'}
                            alt='Menü geschlossen Abbildung'
                            className='card-img'
                        />
                        <img
                            src={'assets/guide/menu-opened.png'}
                            alt='Menü geöffnet Abbildung'
                            className='card-img'
                        />
                    </div>
                    <div>
                        <h3 id='easy-language-card-title-3'>
                            So können Sie die Seite benutzen
                        </h3>
                        <p>
                            Ganz oben links stehen 3 Balken. Klicken Sie auf die
                            3 Balken. Dann öffnet sich ein Fenster.
                        </p>
                        <p>
                            Dort sehen Sie eine Übersicht von den Infos auf der
                            Seite:
                        </p>
                        <ul>
                            <li>Startseite</li>
                            <li>Status eingereichter Rechnungen</li>
                            <li>Große Anlagen</li>
                            <li>Unternehmenskontoverwaltung</li>
                            <li>Benutzerverwaltung</li>
                        </ul>
                        <p>
                            Klicken Sie auf eine &Uuml;berschrift. Dann kommen
                            Sie auf eine neue Seite.
                        </p>
                        <p>
                            Neben den Balken ist ein rotes Haus. Das rote Haus
                            steht auf allen Seiten. Sie schauen unsere Seiten an
                            und wollen dann zur&uuml;ck zur Start-Seite. Klicken
                            Sie daf&uuml;r einfach auf das rote Haus.
                        </p>
                    </div>
                </Card>

                <Card
                    aria-labelledby='easy-language-card-title-4'
                    className='flex-row align-items-center'
                >
                    <ReadableScreenReaderItem
                        id='easy-language-card-title-4'
                        text='Einloggen, Hilfe und Einstellungen Abbildung'
                    />
                    <div className='img-wrapper'>
                        <img
                            style={{ marginBottom: '1rem' }}
                            src={
                                'assets/guide/header-navigation-right-side.png'
                            }
                            alt='Einloggen, Hilfe und Einstellungen Abbildung'
                            className='card-img'
                        />
                    </div>
                    <div>
                        <p>
                            Ganz oben rechts stehen diese Zeichen. Klicken Sie
                            auf den Kasten mit dem Pfeil. Damit melden Sie sich
                            von der Seite wieder ab.
                        </p>
                        <p>
                            Klicken Sie auf das Frage-Zeichen. Dann kommen Sie
                            zu der Bedienungs-Anleitung von der Seite. Die
                            Bedienungs-Anleitung ist nicht in Leichter Sprache.
                        </p>
                        <p>
                            Klicken Sie auf das Zeichen ganz rechts. Dann kommen
                            Sie zu ihren pers&ouml;nlichen Einstellungen.
                        </p>
                    </div>
                </Card>

                <Card
                    aria-labelledby='easy-language-card-title-5'
                    className='flex-row align-items-center'
                >
                    <div className='img-wrapper flex-column'>
                        <img
                            style={{ marginBottom: '1rem' }}
                            src={'assets/guide/home-navigation.png'}
                            alt='Abbildung'
                            className='card-img'
                        />
                    </div>
                    <div>
                        <h3 id='easy-language-card-title-5'>
                            So können Sie eine Rechnung einreichen.
                        </h3>
                        <ul className='big-list'>
                            <li>
                                <p>
                                    <strong>Neue Rechnung erfassen:</strong>
                                </p>
                                <p>
                                    Hier können Sie mit einem Eingabe-Formular
                                    Rechnungen erfassen.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        Extern erstellte XRechnung einreichen
                                        (Upload):
                                    </strong>
                                </p>
                                <p>
                                    Hier können Sie eine schon erfasste Rechnung
                                    hochladen.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>
                                        Extern erstellte XRechnung per E-Mail
                                        senden:
                                    </strong>
                                </p>
                                <p>
                                    Hier können Sie eine schon erfasste Rechnung
                                    per E-Mail einreichen.
                                </p>
                            </li>

                            <li>
                                <p>
                                    <strong>
                                        Status eingereichter Rechnungen:
                                    </strong>
                                </p>
                                <p>
                                    Hier sehen Sie Ihre eingereichten
                                    Rechnungen.
                                </p>
                            </li>

                            <li>
                                <p>
                                    <strong>
                                        Große Mail-Anhänge verwalten:
                                    </strong>
                                </p>
                                <p>
                                    Hier können Sie Mail-Anhänge bis 200 MB
                                    hochladen und verwalten.
                                </p>
                            </li>

                            <li>
                                <p>
                                    <strong>Benutzerverwaltung:</strong>
                                </p>
                                <p>
                                    Hier verwalten Sie die Betreuer und Benutzer
                                    von der Seite.
                                </p>
                            </li>
                        </ul>
                    </div>
                </Card>

                <Card
                    aria-labelledby='easy-language-card-title-6'
                    className='flex-row align-items-center'
                >
                    <ReadableScreenReaderItem
                        text='Fußzeile der Website'
                        id='easy-language-card-title-6'
                    />
                    <div>
                        <p>
                            Ganz unten in der Mitte stehen diese Links zu Texten
                            und Infos:
                        </p>
                        <ul className='big-list'>
                            <li>
                                <strong>Impressum:</strong> Hier steht, wer
                                f&uuml;r die Seite verantwortlich ist
                            </li>
                            <li>
                                <strong>Datenschutz:</strong> Hinweise zum
                                Daten-Schutz
                            </li>
                            <li>
                                <strong>Nutzungsbedingungen:</strong> Hinweise
                                zur Nutzung von der Plattform
                            </li>
                            <li>
                                <strong>
                                    Erkl&auml;rung zur Barrierefreiheit
                                </strong>
                            </li>
                            <li>
                                <strong>Hilfe:</strong> Link zu einer Anleitung
                                zum Bedienen der Seite
                            </li>
                            <li>
                                <strong>FAQ:</strong> Seite mit häufig
                                gestellten Fragen
                            </li>
                            <li>
                                <strong>Kontaktformular:</strong> Hier können
                                Sie uns eine Nachricht schreiben
                            </li>
                            <li>
                                <strong>Rechtliche Hinweise:</strong> Hier
                                werden Urheber-Rechte und Lizenzen genannt
                            </li>
                            <li>
                                <strong>Barriere melden:</strong> ein
                                Kontakt-Formular, um Barrieren zu melden.
                            </li>
                        </ul>
                        <div className='img-wrapper'>
                            <img
                                src={'assets/guide/footer.png'}
                                style={{ maxWidth: '100%', marginTop: '30px' }}
                                alt='Abbildung der Fußzeile der Website'
                                className='card-img'
                            />
                        </div>
                    </div>
                </Card>

                <Card
                    aria-labelledby='easy-language-card-title-7'
                    className='flex-row align-items-center'
                >
                    <ReadableScreenReaderItem
                        text='Leichte Sprache und Geb&auml;rdensprache'
                        id='easy-language-card-title-7'
                    />
                    <div className='img-wrapper'>
                        <img
                            src={'assets/guide/footer-accessibility.png'}
                            alt='Abbildung: Leichte Sprache und Geb&auml;rdensprache'
                            className='card-img'
                            style={{ width: 'initial' }}
                        />
                    </div>
                    <div>
                        <p>Ganz rechts unten stehen diese Zeichen. </p>
                        <p>
                            Klicken&nbsp;Sie auf das Zeichen mit den
                            H&auml;nden.
                        </p>
                        <p>
                            Dann kommen Sie zu einem Video. In dem Video wird
                            erkl&auml;rt:
                        </p>
                        <ul>
                            <li>Was Sie auf dieser Seite finden.</li>
                            <li>Wie Sie diese Seite benutzen k&ouml;nnen.</li>
                        </ul>
                        <p>
                            Die Erkl&auml;rung ist in deutscher
                            Geb&auml;rden-Sprache.
                        </p>
                        <p>
                            Klicken Sie auf das Zeichen mit dem Buch. Dann
                            kommen Sie zu Infos über die Seite in Leichter
                            Sprache.
                        </p>
                    </div>
                </Card>

                <Card
                    aria-labelledby='easy-language-card-title-8'
                    className='flex-row align-items-center'
                >
                    <div>
                        <p>
                            <h3 id='easy-language-card-title-8'>
                                Erkl&auml;rung zu den wichtigsten Infos zur
                                Barriere-Freiheit
                            </h3>
                        </p>
                        <p>
                            Wir sind eine &ouml;ffentliche Beh&ouml;rde. Darum
                            muss unsere Internet-Seite barrierefrei sein. Dazu
                            sind wir verpflichtet.
                        </p>
                        <p>
                            Die Regeln daf&uuml;r stehen in der BITV 2.0. Die
                            BITV 2.0 sind Regeln f&uuml;r Barriere-Freiheit im
                            Internet.
                        </p>
                        <p>
                            Wir haben unsere Internet-Seite &uuml;berpr&uuml;fen
                            lassen. Eine unabh&auml;ngige Stelle hat die Seite
                            gepr&uuml;ft. Das hei&szlig;t: wir haben{' '}
                            <strong>nicht&nbsp;</strong>
                            selber gepr&uuml;ft. Unsere Internet-Seite ist
                            barriere-frei nach den Regeln von der BITV 2.0.
                        </p>
                        <p>
                            Sie haben eine&nbsp;Barriere auf unserer
                            Internet-Seite gefunden? Dann schreiben Sie uns dazu
                            eine E-Mail an:{' '}
                            <a href='mailto:support-xrechnung@bdr.de'>
                                support-xrechnung@bdr.de
                            </a>{' '}
                            oder Sie benutzen das Formular:{' '}
                            <a
                                target='_blank'
                                href={
                                    (isLoggedIn ? 'dashboard' : 'auth') +
                                    '/feedback/report-barrier'
                                }
                                rel='noreferrer'
                            >
                                Barriere melden
                            </a>
                            .
                        </p>
                        <p>
                            Sie finden: Wir haben keine gute L&ouml;sung
                            f&uuml;r das Problem? Dann k&ouml;nnen Sie sich an
                            die Schlichtungs-Stelle wenden. Schreiben Sie eine
                            E-Mail an:{' '}
                            <a href='mailto:info@schlichtungsstelle-bgg.de'>
                                info@schlichtungsstelle-bgg.de
                            </a>
                        </p>
                        <p>
                            In der Erkl&auml;rung zur Barriere-Freiheit stehen
                            Kontakt-Daten:
                        </p>
                        <ul>
                            <li>von Ansprech-Partnern bei uns</li>
                            <li>und von der Schlichtungs-Stelle.</li>
                        </ul>
                    </div>
                    <div className='img-wrapper'>
                        <BootstrapIcon
                            className='card-icon'
                            icon='universal-access'
                        />
                    </div>
                </Card>
            </section>

            {!isLoggedIn && (
                <>
                    <hr className='auth-separator' />
                    <AccessibleNavLink to={'/'}>
                        <Button
                            icon='chevron-left'
                            text={t('PasswordForgotten.CancelButton.Text')}
                            primary={true}
                        />
                    </AccessibleNavLink>
                </>
            )}
        </div>
    );
}
