import { StringTools, ValidationTools } from '@b4valuenet/ui-react';

import { Discount } from '../../../../models/Discount';
import { PaymentMeansCode } from '../../../../models/PaymentInfo';
import { PositionLine } from '../../../../models/PositionLine';
import { XRechnung } from '../../../../models/XRechnung';

export interface IXRechnungPageValidator {
    data: XRechnung | null;
    isFormSubmitted: boolean;
}

export default class XRechnungPagesValidator {
    static InvoiceDataValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        //TODO: Check if this is right - checks if discounts or delays have required fields
        if (
            data.invoiceData.messageType === 381 ||
            data.invoiceData.messageType === 384
        ) {
            if (data.invoiceData.referringInvoiceDate != null) {
                return ValidationTools.isRequiredFieldValueValid(
                    data.invoiceData.referringInvoiceReference,
                    isFormSubmitted
                );
            }
        }

        const discountsValid = this.InvoiceDataDiscountsValid({
            discounts: data.invoiceData.discounts,
        });

        const isPeriodRangePickerValid = this.IsDateRangePickerValid({
            start: data.invoiceData.invoicePeriodStartDate,
            end: data.invoiceData.invoicePeriodEndDate,
        });

        const paymentDueDateValid =
            data.invoiceData.messageType === 381 ||
            data.invoiceData.messageType === 384 ||
            ValidationTools.isRequiredFieldValueValid(
                data.invoiceData.paymentDueDate ? 'not empty' : null,
                isFormSubmitted
            );

        return (
            ValidationTools.isRequiredFieldValueValid(
                data.invoiceData.messageType ? 'not empty' : null,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.invoiceData.leitwegId,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.invoiceData.reference,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.invoiceData.docDate ? 'not empty' : null,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.invoiceData.currency ? 'not empty' : null,
                isFormSubmitted
            ) &&
            (!!data.invoiceData.orderNumberVendor ||
            data.invoiceData.orderNumberVendor?.length > 0
                ? ValidationTools.isRequiredFieldValueValid(
                      !!data?.invoiceData.poNumber ||
                          data?.invoiceData.poNumber?.length > 0
                          ? 'not empty'
                          : null,
                      isFormSubmitted
                  )
                : true) &&
            !((data?.invoiceData.paymentTerms ?? '').indexOf('#') > -1) &&
            paymentDueDateValid &&
            discountsValid &&
            isPeriodRangePickerValid
        );
    }

    static IsDateRangePickerValid({
        start,
        end,
    }: {
        start: Date | null;
        end: Date | null;
        isFormSubmitted?: boolean;
    }): boolean {
        if (
            (start === null && end === null) ||
            (start !== null && end !== null)
        ) {
            return true;
        }

        if (start !== null && end === null) {
            return false;
        }

        if (start === null && end !== null) {
            return false;
        }

        if (start!.getTime() < end!.getTime()) {
            return false;
        }

        return false;
    }

    static InvoiceDataDiscountsValid({
        discounts,
        isFormSubmitted = true,
    }: {
        discounts: Discount[] | undefined;
        isFormSubmitted?: boolean;
    }): boolean {
        if (!discounts) return true;

        let discountsValid = true;

        if (discounts.length > 0) {
            discountsValid = discounts.every((discount): boolean => {
                return (
                    ValidationTools.isRequiredFieldValueValid(
                        discount.dueDays?.toString() ?? undefined,
                        isFormSubmitted
                    ) &&
                    ValidationTools.isRequiredFieldValueValid(
                        discount.percentage?.toString() ?? undefined,
                        isFormSubmitted
                    )
                );
            });
        }

        return discountsValid;
    }

    static InvoiceLineValid({
        line,
        senderMicroenterpreneuer,
        isFormSubmitted,
        isRecursive = false,
    }: {
        line: PositionLine;
        senderMicroenterpreneuer: boolean;
        isFormSubmitted: boolean;
        isRecursive: boolean;
    }): boolean {
        let mainFieldsValid =
            ValidationTools.isRequiredFieldValueValid(
                line.positionNo,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                line.articleDescription,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                line.quantity !== null ? 'not empty' : null,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                line.unit !== null ? 'not empty' : null,
                isFormSubmitted
            ) &&
            line.vatRate !== -6 &&
            (isRecursive ||
                senderMicroenterpreneuer ||
                ValidationTools.isRequiredFieldValueValid(
                    line.vatRate !== null ? 'not empty' : null,
                    isFormSubmitted
                )) &&
            ValidationTools.isRequiredFieldValueValid(
                line.price !== null &&
                    line.price !== undefined &&
                    line.price > 0
                    ? 'not empty'
                    : null,
                isFormSubmitted
            );

        let rebatesValid = line.rebateGroups.every((group) => {
            return (
                ValidationTools.isRequiredFieldValueValid(
                    group.baseAmount?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    group.percent?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    group.amount?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    group.name?.toString(),
                    isFormSubmitted
                )
            );
        });

        let surchargesValid = line.surchargeGroups.every((group) => {
            return (
                ValidationTools.isRequiredFieldValueValid(
                    group.baseAmount?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    group.percent?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    group.amount?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    group.name?.toString(),
                    isFormSubmitted
                )
            );
        });

        let subLinesValid: boolean =
            line.subLines instanceof Array
                ? line.subLines.every((subLine: PositionLine) => {
                      return this.InvoiceLineValid({
                          line: subLine,
                          senderMicroenterpreneuer,
                          isFormSubmitted,
                          isRecursive: true,
                      });
                  }) ?? false
                : true;

        return (
            mainFieldsValid && subLinesValid && rebatesValid && surchargesValid
        );
    }

    static InvoiceLineItemsDataValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        if (data.positions && data.positions.length === 0) {
            return false;
        }

        return (data.positions ?? []).every((position) =>
            this.InvoiceLineValid({
                line: position,
                isFormSubmitted,
                senderMicroenterpreneuer:
                    data.senderData.senderMicroenterpreneuer,
                isRecursive: false,
            })
        );
    }

    static PaymentTotalsDataValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        const isAllowancesValid = this.PaymentTotalsAllowancesValid({
            data,
            isFormSubmitted,
        });

        const isAgiosValid = this.PaymentTotalsAgiosValid({
            data,
            isFormSubmitted,
        });

        const amountGroupsValid = this.PaymentTotalsAmountGroupsValid({
            data,
            isFormSubmitted,
        });

        const exemptionReasonRequired =
            data.documentTotalsData.amountGroups.length > 0 &&
            data.documentTotalsData.amountGroups.some(
                (group) =>
                    group.vatRate !== undefined &&
                    group.vatRate <= -1 &&
                    group.vatRate >= -5
            );

        let isExemptionReasonValid =
            !exemptionReasonRequired ||
            ValidationTools.isRequiredFieldValueValid(
                data.documentTotalsData.amountsTaxExemptionReason?.toString(),
                isFormSubmitted
            );

        let isAmountsValid =
            ValidationTools.isRequiredFieldValueValid(
                data.documentTotalsData.amountsItemAmount?.toString(),
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.documentTotalsData.totalNetAmount?.toString(),
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.documentTotalsData.totalVatAmount?.toString(),
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.documentTotalsData.total?.toString(),
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.documentTotalsData.amount?.toString(),
                isFormSubmitted
            );

        return (
            isAllowancesValid &&
            isAgiosValid &&
            amountGroupsValid &&
            isAmountsValid &&
            isExemptionReasonValid
        );
    }

    static PaymentTotalsAllowancesValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        return (data.documentTotalsData.allowances ?? []).every(
            (allowance) =>
                ValidationTools.isRequiredFieldValueValid(
                    allowance?.amount?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    allowance?.name?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    allowance?.vatRate?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    allowance?.baseAmount?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    allowance?.percent?.toString(),
                    isFormSubmitted
                )
        );
    }

    static PaymentTotalsAgiosValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        return (data.documentTotalsData.agios ?? []).every(
            (agio) =>
                ValidationTools.isRequiredFieldValueValid(
                    agio?.amount?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    agio?.name?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    agio?.vatRate?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    agio?.baseAmount?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    agio?.percent?.toString(),
                    isFormSubmitted
                )
        );
    }

    static PaymentTotalsAmountGroupsValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        return (data.documentTotalsData.amountGroups ?? []).every(
            (amountGroup) =>
                ValidationTools.isRequiredFieldValueValid(
                    amountGroup.netAmount?.toString(),
                    isFormSubmitted
                ) &&
                ValidationTools.isRequiredFieldValueValid(
                    amountGroup.vatAmount?.toString(),
                    isFormSubmitted
                )
        );
    }

    static PaymentMeansDataValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        let isValid = false;

        const sepaCountries = [
            'AD',
            'AT',
            'BE',
            'BG',
            'HR',
            'CY',
            'CZ',
            'DK',
            'EE',
            'FI',
            'FR',
            'DE',
            'GR',
            'HU',
            'IS',
            'IE',
            'IT',
            'LV',
            'LI',
            'LT',
            'LU',
            'MT',
            'MC',
            'NL',
            'NO',
            'PL',
            'PT',
            'RO',
            'SM',
            'SK',
            'SI',
            'ES',
            'SE',
            'CH',
            'GB',
            'PM',
        ];

        const sepaCountriesRequireBic = ['CH', 'SM', 'MC', 'PM'];

        // at least one payment needs to be defined
        if (
            data.paymentInfo.transfers.length === 0 &&
            data.paymentInfo.debit === null
        ) {
            return false;
        }

        //TODO: Server SEPA validation
        switch (data.paymentInfo.paymentMeans) {
            case PaymentMeansCode.SepaDirectDebit:
                isValid =
                    ValidationTools.isRequiredFieldValueValid(
                        data.paymentInfo.debit?.sepaCreditorReference
                    ) &&
                    ValidationTools.isRequiredFieldValueValid(
                        data.paymentInfo.debit?.sepaMandateReference
                    ) &&
                    ValidationTools.isIBANValid(
                        data.paymentInfo.debit?.buyerBankAccountIBAN ?? ''
                    ) &&
                    sepaCountries.includes(
                        data.paymentInfo.debit?.buyerBankAccountIBAN
                            ? data.paymentInfo.debit?.buyerBankAccountIBAN.slice(
                                  0,
                                  2
                              )
                            : ''
                    );
                break;
            case PaymentMeansCode.SepaCreditTransfer:
                isValid = true;

                data.paymentInfo.transfers.forEach((transfer) => {
                    const requiresBic = sepaCountriesRequireBic.includes(
                        (transfer?.payeeBankAccountIBAN ?? '').slice(0, 2)
                    );

                    const sourceBic = transfer?.payeeBankAccountBIC;
                    const underscoreCount = StringTools.getCountOfSpecificChars(
                        sourceBic ?? '',
                        ['_']
                    )['_'];

                    const bic = transfer?.payeeBankAccountBIC
                        ? transfer?.payeeBankAccountBIC
                              .toUpperCase()
                              .replace(/[^A-Z0-9]/g, '')
                        : '';

                    const isBicValid =
                        !requiresBic ||
                        (ValidationTools.isRequiredFieldValueValid(
                            bic,
                            isFormSubmitted
                        ) &&
                            underscoreCount === 0);

                    isValid =
                        isValid &&
                        ValidationTools.isIBANValid(
                            transfer?.payeeBankAccountIBAN ?? ''
                        ) &&
                        sepaCountries.includes(
                            (transfer?.payeeBankAccountIBAN ?? '').slice(0, 2)
                        ) &&
                        isBicValid;
                });

                break;
            case PaymentMeansCode.CreditTransfer:
                isValid = true;
                data.paymentInfo.transfers.forEach((transfer) => {
                    const sourceBic = transfer?.payeeBankAccountBIC;
                    const underscoreCount = StringTools.getCountOfSpecificChars(
                        sourceBic ?? '',
                        ['_']
                    )['_'];

                    const bic = transfer?.payeeBankAccountBIC
                        ? transfer?.payeeBankAccountBIC
                              .toUpperCase()
                              .replace(/[^A-Z0-9]/g, '')
                        : '';

                    const isBicValid =
                        ValidationTools.isRequiredFieldValueValid(
                            bic,
                            isFormSubmitted
                        ) && underscoreCount === 0;

                    isValid =
                        isValid &&
                        ValidationTools.isRequiredFieldValueValid(
                            transfer?.payeeBankAccountIBAN,
                            isFormSubmitted
                        ) &&
                        isBicValid;
                });

                break;
            default:
                break;
        }

        return !isFormSubmitted || isValid;
    }

    static AttachmentsValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        let allAttachmentsValid = (data.attachments ?? []).every(
            (attachment) => {
                return ValidationTools.isRequiredFieldValueValid(
                    attachment.identifier,
                    isFormSubmitted
                );
            }
        );

        return allAttachmentsValid ?? false;
    }

    static ReferencesValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        let allReferencesValid = (data.references ?? []).every((reference) => {
            return ValidationTools.isRequiredFieldValueValid(
                reference.identifier,
                isFormSubmitted
            );
        });

        return allReferencesValid;
    }
}
