import './InitialDialog.scss';

import {
    AsyncForm,
    Button,
    Dialog,
    IInputSearchResult,
    LoadingOverlay,
    RequestTools,
    RequiredFieldsNotice,
    Select,
    TextInput,
    Toast,
    useDebounce,
    useOutsideClickDetector
} from '@b4valuenet/ui-react';
import { createRef, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LeitwegIdSearchResult } from '../../../models/LeitwegIdSearchResult';
import { XRechnungMessageType } from '../../../models/XRechnungMessageType';
import MessagesService from '../../../services/MessagesService';
import SetupService from '../../../services/SetupService';

interface IInitialDialog {
    active: boolean;
    onClose: CallableFunction;
}

export default function InitialDialog(props: IInitialDialog) {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const leitwegIdInputRef = createRef<HTMLInputElement>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    function onClose() {
        if (props.onClose) props.onClose();
    }

    const [messageTypes, setMessageTypes] = useState<XRechnungMessageType[]>(
        []
    );

    const [messageType, setMessageType] = useState<string | number | null>(
        null
    );

    const [leitwegIdFieldValue, setLeitwegIdFieldValue] = useState<string>('');
    const [leitwegIdSearchActive, setLeitwegIdSearchActive] =
        useState<boolean>(false);
    const [leitwegIdSearchResults, setLeitwegIdSearchResults] = useState<
        LeitwegIdSearchResult[]
    >([]);
    const [leitwegIdSearchIsLoading, setLeitwegIdSearchIsLoading] =
        useState<boolean>(false);
    const [leitwegIdSearchError, setLeitwegIdSearchError] = useState<string>();

    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

    const [resultId, setResultId] = useState<number | null>(null);

    useDebounce(() => {
        async function doLeitwegIdSearch() {
            setLeitwegIdSearchIsLoading(true);

            try {
                setLeitwegIdSearchError(undefined);
                let result = await SetupService.search(
                    leitwegIdFieldValue.trim()
                );
                setLeitwegIdSearchResults(result);
            } catch (error) {
                let message = RequestTools.getApiErrorMessage(error);
                setLeitwegIdSearchError(message);
            }

            setLeitwegIdSearchIsLoading(false);
        }

        if (leitwegIdFieldValue.length >= 3) {
            doLeitwegIdSearch();
        } else {
            setLeitwegIdSearchResults([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leitwegIdFieldValue]);

    let transformedLeitwegIdSearchResults = leitwegIdSearchResults.map(
        (result) => {
            return {
                name: result.name,
                description: result.leitwegId,
                value: result.leitwegId,
                icon: 'building',
                active: result.leitwegId === leitwegIdFieldValue,
            } as IInputSearchResult;
        }
    );

    let isLeitwegIdValid =
        transformedLeitwegIdSearchResults.find(
            (result) => result.active === true
        ) !== undefined;

    useOutsideClickDetector(leitwegIdInputRef, () => {
        setLeitwegIdSearchActive(false);
    });

    useEffect(() => {
        const fetchMessageTypes = async () => {
            try {
                let types =
                    (await MessagesService.getXRechnungMessageTypes()) ?? [];
                setMessageTypes(types ?? []);
                setMessageType(types[0]?.key ?? null);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };

        fetchMessageTypes();
    }, []);

    async function handleOnFormSubmit() {
        setIsFormSubmitted(true);

        let isFormValid = isLeitwegIdValid && messageType !== null;
        if (!isFormValid) return;

        setIsLoading(true);

        try {
            let result = await SetupService.initialize(
                1,
                leitwegIdFieldValue,
                messageType ?? 0
            );

            setResultId(result);
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        setIsLoading(false);

        onClose();
    }

    useEffect(() => {
        if (resultId !== null) {
            navigate('/dashboard/xrechnung/' + resultId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultId]);

    return (
        <Dialog
            className='initial-dialog'
            title={t('Home.DataFormatDialogTitle.Text')}
            closeButtonTitle={t('Common.Close.Text')}
            onClose={() => onClose()}
            active={props.active}
        >
            <AsyncForm className='spaced-fields' onSubmit={handleOnFormSubmit}>
                <Select
                    value={'1'}
                    icon='boxes'
                    label={t('Setup.DataFormat.Text')}
                >
                    <option value={'1'}>XRechnung</option>
                </Select>
                <Select
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setMessageType(e.target.value)
                    }
                    icon='receipt'
                    value={messageType?.toString() ?? undefined}
                    label={t('Setup.MessageType.Text')}
                >
                    {messageTypes.map((type) => {
                        return (
                            <option key={type.key} value={type.key}>
                                {type.value}
                            </option>
                        );
                    })}
                </Select>
                <TextInput
                    inputRef={leitwegIdInputRef}
                    autoComplete='off'
                    value={leitwegIdFieldValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setLeitwegIdFieldValue(e.target.value);
                    }}
                    onFocus={() => {
                        setLeitwegIdSearchActive(true);
                    }}
                    isValid={
                        (leitwegIdSearchIsLoading &&
                            leitwegIdFieldValue.length > 0) ||
                        (!isFormSubmitted && leitwegIdFieldValue === '') ||
                        leitwegIdSearchActive ||
                        isLeitwegIdValid
                    }
                    validationError={
                        leitwegIdSearchError ??
                        t('ValidationMessage.InvalidLeitwegId.Text')
                    }
                    searchResults={{
                        startSearchOnLength: 1,
                        results: transformedLeitwegIdSearchResults,
                        isLoading: leitwegIdSearchIsLoading,
                        error: leitwegIdSearchError,
                        noDataText:
                            leitwegIdFieldValue.length < 3
                                ? t('ValidationMessage.ReferenceTooShort.Text')
                                : t('ValidationMessage.ReferenceNotFound.Text'),
                        onSelect: (leitwegId: string | number) => {
                            setLeitwegIdFieldValue(leitwegId.toString());
                            setLeitwegIdSearchActive(false);
                        },
                    }}
                    required={true}
                    type='text'
                    icon='search'
                    placeholder={t('XRechnung.LeitwegId.Text')}
                    label={t('XRechnung.LeitwegId.Text')}
                />

                <RequiredFieldsNotice
                    description={t(
                        'ValidationMessage.IndicatesARequiredField.Text'
                    )}
                />

                <div className='buttons-group'>
                    <Button
                        text={t('Common.CancelBtn.Text')}
                        icon={'x-lg'}
                        danger={true}
                        onClick={() => onClose()}
                    />
                    <Button
                        text={t('Common.ContinueBtn.Text')}
                        icon={'check-lg'}
                        primary={true}
                        disabled={!isLeitwegIdValid || messageType === null}
                        type={'submit'}
                    />
                </div>
                <LoadingOverlay active={isLoading} />
            </AsyncForm>
        </Dialog>
    );
}
