import { Card } from '@b4valuenet/ui-react';
import { ReceiverData } from '../../../../../../../../../models/ReceiverData';
import { useTranslation } from 'react-i18next';

export interface IBuyerMainInfo {
    receiverData: ReceiverData;
}
export default function BuyerMainInfo(props: IBuyerMainInfo) {
    const { t } = useTranslation();

    return (
        <>
            <Card title={t('XRechnung.ReceiverDataTab.Text')}>
                <div className='pseudo-table align-left cw-50'>
                    <table>
                        <tbody>
                            <tr>
                                <th>{t('XRechnung.LegalEntityName.Text')}</th>
                                <td>
                                    {props.receiverData.receiverLegalEntityName}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('XRechnung.ClientName.Text')}</th>
                                <td>
                                    {props.receiverData.receiverAddressName}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('XRechnung.VatId.Text')}</th>
                                <td>{props.receiverData.receiverVatId}</td>
                            </tr>
                            <tr>
                                <th>
                                    {t('XRechnung.SenderIDRefGroupValue.Text')}
                                </th>
                                <td>
                                    {props.receiverData.receiverIDRefGroupValue}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card>
        </>
    );
}
