import {
    Button,
    Card,
    NumberInput,
    Toast,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { createRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Discount } from '../../../../../../../models/Discount';
import { IXRechnungSubPage } from '../../../XRechnungPage';

export interface ICashDiscount {
    index: number;
    discount: Discount;
}

export default function CashDiscount(props: ICashDiscount & IXRechnungSubPage) {
    const { t } = useTranslation();

    const firstInputRef = createRef<HTMLInputElement>();

    useEffect(() => {
        if (firstInputRef) {
            firstInputRef.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card className='fade-in cash-discount spaced-fields'>
            <div className='flex-row'>
                <NumberInput
                    inputRef={firstInputRef}
                    min={0}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    placeholder={t('XRechnung.DueDays.Text')}
                    label={t('XRechnung.DueDays.Text')}
                    value={props.discount.dueDays}
                    required={true}
                    isValid={ValidationTools.isRequiredFieldValueValid(
                        props.discount.dueDays?.toString()
                    )}
                    validationError={t('ValidationMessage.Required.Text')}
                    onChange={(_e, rawValue) => {
                        if (props.data) {
                            props.updateData((prevData) => {
                                let newDiscounts = [
                                    ...prevData.invoiceData.discounts,
                                ];

                                newDiscounts[props.index].dueDays =
                                    rawValue || undefined;

                                return {
                                    ...prevData,
                                    invoiceData: {
                                        ...prevData.invoiceData,
                                        discounts: newDiscounts,
                                    },
                                };
                            });
                        }
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.DiscountDueDaysHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.DiscountDueDaysHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t('XRechnung.DiscountDueDays.Help')}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />

                <NumberInput
                    min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    placeholder={t('XRechnung.Percentage.Text')}
                    label={t('XRechnung.Percentage.Text')}
                    value={props.discount.percentage}
                    required={true}
                    isValid={ValidationTools.isRequiredFieldValueValid(
                        props.discount.percentage?.toString()
                    )}
                    validationError={t('ValidationMessage.Required.Text')}
                    onChange={(_e, rawValue) => {
                        if (props.data) {
                            props.updateData((prevData) => {
                                let newDiscounts = [
                                    ...prevData.invoiceData.discounts,
                                ];

                                newDiscounts[props.index].percentage =
                                    rawValue || undefined;

                                return {
                                    ...prevData,
                                    invoiceData: {
                                        ...prevData.invoiceData,
                                        discounts: newDiscounts,
                                    },
                                };
                            });
                        }
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.DiscountPercentageHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.DiscountPercentageHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.DiscountPercentage.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />

                <NumberInput
                    min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    placeholder={t('XRechnung.BaseAmount.Text')}
                    label={t('XRechnung.BaseAmount.Text')}
                    value={props.discount.baseAmount?.toString()}
                    onChange={(_e, rawValue) => {
                        if (props.data) {
                            props.updateData((prevData) => {
                                let newDiscounts = [
                                    ...prevData.invoiceData.discounts,
                                ];

                                newDiscounts[props.index].baseAmount = rawValue;

                                return {
                                    ...prevData,
                                    invoiceData: {
                                        ...prevData.invoiceData,
                                        discounts: newDiscounts,
                                    },
                                };
                            });
                        }
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.DiscountBaseAmountHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.DiscountBaseAmountHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.DiscountBaseAmount.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
            </div>
            <Button
                className='action-button'
                icon='trash'
                danger={true}
                text={t('XRechnung.RemoveDiscount.Text')}
                onClick={() => {
                    if (props.data) {
                        props.updateData((prevData) => {
                            let newDiscounts = [
                                ...prevData.invoiceData.discounts,
                            ];

                            newDiscounts.splice(props.index, 1);

                            return {
                                ...prevData,
                                invoiceData: {
                                    ...prevData.invoiceData,
                                    discounts: newDiscounts,
                                },
                            };
                        });

                        Toast.success(
                            t('UserFeedback.SuccessfullyRemoved.Text', {
                                field: t('XRechnung.Discount.Text'),
                            })
                        );
                    }
                }}
            />
        </Card>
    );
}
