import './LegalNotice.scss';

import {
    AccessibleNavLink,
    Accordion,
    AccordionItem,
    Button,
    ErrorOverlay,
    LoadingOverlay,
    RequestTools,
} from '@b4valuenet/ui-react';
import { useEffect, useState } from 'react';

import AppSettingsService from '../../../../services/AppSettingsService';
import { LegalNoticeItem } from '../../../../models/LegalNoticeItem';
import { useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

export interface ILegalNotice {}

export default function LegalNotice(props: ILegalNotice) {
    const { t } = useTranslation();

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    const jsonEndpoint = useAppSelector(
        (state) => state.app.settings?.legalNotice
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<string | null>(null);

    const [data, setData] = useState<LegalNoticeItem[] | null>();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                let jsonEndpointFileExtension = RequestTools.getFileExtension(
                    jsonEndpoint ?? ''
                );

                if (!jsonEndpoint || jsonEndpointFileExtension !== 'json')
                    throw new Error('Invalid json endpoint');

                let newData = await AppSettingsService.getLegalNotice(
                    jsonEndpoint
                );

                setData(newData);
            } catch (error) {
                setLoadingError(RequestTools.getApiErrorMessage(error));
            }

            setIsLoading(false);
        };

        fetchData();
    }, [jsonEndpoint]);

    return (
        <div className='sub-page legal-notice'>
            <h1>{t('Common.LegalNotice.Text')}</h1>
            <hr />
            {!isLoggedIn && (
                <>
                    <AccessibleNavLink to={'/'}>
                        <Button
                            icon='chevron-left'
                            text={t('PasswordForgotten.CancelButton.Text')}
                            primary={true}
                        />
                    </AccessibleNavLink>
                    <br />
                </>
            )}

            <Accordion className='legal-notice-content'>
                {(data !== null &&
                    data?.map((item, index) => {
                        return (
                            <AccordionItem
                                icon='box'
                                expanded={index === 0}
                                key={index}
                                title={
                                    <>
                                        <strong>{item.name}</strong>
                                        <span> - {item.licenseType}</span>
                                    </>
                                }
                                body={
                                    <pre lang='en'>{item.licenseContent}</pre>
                                }
                            />
                        );
                    })) ||
                    []}
            </Accordion>

            {!isLoggedIn && (
                <>
                    <hr className='auth-separator' />
                    <AccessibleNavLink to={'/'}>
                        <Button
                            icon='chevron-left'
                            text={t('PasswordForgotten.CancelButton.Text')}
                            primary={true}
                        />
                    </AccessibleNavLink>
                </>
            )}

            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={isLoading}
                isGlobal={true}
            />

            <ErrorOverlay
                active={loadingError !== null}
                isGlobal={false}
                errorText={loadingError ?? ''}
            />
        </div>
    );
}
