import './index.scss';
import './animations.css';
import '@b4valuenet/ui-react/dist/cjs/index.css';
import './assets/font/bootstrap-icons.scss';

import App from './App';
import NetworkService from './services/NetworkService';
import { Provider as ReduxProvider } from 'react-redux';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import i18next from 'i18next';
import { store } from './redux/store';

const container = document.getElementById('root')!;
export const root = createRoot(container);

export default axios;

NetworkService.setupInterceptors(store);

root.render(
    <ReduxProvider store={store}>
        <App />
    </ReduxProvider>
);

i18next.init();
