import { Debit } from '../../../../../../../../../models/Debit';
import { useTranslation } from 'react-i18next';

export interface IPaymentDebitInfo {
    debit: Debit | null;
}

export default function PaymentDebitInfo(props: IPaymentDebitInfo) {
    const { t } = useTranslation();

    return (
        <>
            <h3>{t('XRechnung.PaymentMeansSepaDebit.Text')}</h3>

            <div className='pseudo-table align-left cw-50'>
                <table>
                    <tbody>
                        <tr>
                            <th>{t('XRechnung.PayeeIDRefGroupValue.Text')}</th>
                            <td>{props.debit?.sepaMandateReference}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.IDRefGroupValue.Text')}</th>
                            <td>{props.debit?.sepaCreditorReference}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.BuyerBankAccountIBAN.Text')}</th>
                            <td>
                                {props.debit?.buyerBankAccountIBAN?.replaceAll(
                                    '_',
                                    ''
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}
