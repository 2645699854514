import { useAppSelector } from '../../../../redux/hooks';
import './Settings.scss';

import GeneralSettings from './components/GeneralSettings';
import PasswordSettings from './components/PasswordSettings';
import { useTranslation } from 'react-i18next';

export default function Settings() {
    const { t } = useTranslation();
    const authenticatedUser = useAppSelector((state) => state.auth.user);

    return (
        <section className='sub-page settings'>
            <h1>{t('PersonalSettings.Title.Text')}</h1>

            <div className='cards'>
                <GeneralSettings />
                {!authenticatedUser?.isElsterAccount && <PasswordSettings />}
            </div>
        </section>
    );
}
