import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { User } from '../../models/User';
import { login } from '../actions/auth';

interface AuthState {
    isLoggedIn: boolean;
    user: User | null;
    isLoading: boolean;
    logInError: string | null;
    sessionExpired: boolean;
}
const initialState: AuthState = {
    isLoggedIn: false,
    user: null,
    isLoading: false,
    logInError: null,
    sessionExpired: false,
};

const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        languageInSettingsChanged(state, action) {
            return {
                ...state,
                user: {
                    ...state.user,
                    language: action.payload,
                } as User,
            };
        },
        userSettingsChanged(state, action) {
            return {
                ...state,
                user: {
                    ...state.user,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                } as User,
            };
        },
        isAuthenticated(state, action) {
            return {
                ...state,
                isLoggedIn: true,
                sessionExpired: false,
                user: action.payload.user,
            };
        },
        isUnauthenticated(state, action: PayloadAction<boolean | undefined>) {
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                sessionExpired:
                    action.payload !== undefined
                        ? action.payload
                        : state.sessionExpired,
            };
        },
        logOut(state) {
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        },
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        builder
            .addMatcher(isAnyOf(login.pending), (state, action) => {
                return {
                    ...state,
                    isLoading: true,
                };
            })
            .addMatcher(isAnyOf(login.fulfilled), (state, action) => {
                return {
                    ...state,
                    isLoggedIn: !action.payload.forcePwdChange,
                    user: action.payload,
                    isLoading: false,
                    logInError: null,
                };
            })
            .addMatcher(isAnyOf(login.rejected), (state, action) => {
                return {
                    ...state,
                    isLoggedIn: false,
                    user: null,
                    isLoading: false,
                    logInError: action.payload?.message ?? null,
                };
            });
    },
});

export const {
    isAuthenticated,
    isUnauthenticated,
    logOut,
    languageInSettingsChanged,
    userSettingsChanged,
} = AuthSlice.actions;

export default AuthSlice.reducer;
