export enum UserMessageState {
    Unknown = 0,
    New = 1,
    Provided = 2,
    Canceled = 3,
    Processing = 4,
    Rejected = 5,
    Delivered = 6,
    RejectedDeletable = 7,
}
