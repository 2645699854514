import { Card } from '@b4valuenet/ui-react';
import { Country } from '../../../../../../../../../models/Country';
import { ReceiverData } from '../../../../../../../../../models/ReceiverData';
import { useTranslation } from 'react-i18next';

export interface IBuyerDeliveryDetails {
    receiverData: ReceiverData;
    countriesList: Country[];
}
export default function BuyerDeliveryDetails(props: IBuyerDeliveryDetails) {
    const { t } = useTranslation();

    let localizedDeliveryCountry =
        props.countriesList.find(
            (country) =>
                country.code === props.receiverData.deliveryAddressCountry
        )?.name ?? '';

    return (
        <Card title={t('XRechnung.OtherDeliveryDetails.Text')}>
            <div className='pseudo-table align-left cw-50'>
                <table>
                    <tbody>
                        {props.receiverData.deliveryAddressName && (
                            <tr>
                                <th>
                                    {t('XRechnung.DeliveryAddressName.Text')}
                                </th>
                                <td>
                                    {props.receiverData.deliveryAddressName}
                                </td>
                            </tr>
                        )}
                        {props.receiverData.deliveryIdRef && (
                            <tr>
                                <th>{t('XRechnung.DeliveryIdRef.Text')}</th>
                                <td>{props.receiverData.deliveryIdRef}</td>
                            </tr>
                        )}
                        {props.receiverData.deliveryDate && (
                            <tr>
                                <th>{t('XRechnung.DeliveryDate.Text')}</th>
                                <td>
                                    {props.receiverData.deliveryDate.toLocaleDateString()}
                                </td>
                            </tr>
                        )}
                        {props.receiverData.deliveryAddressStreet && (
                            <tr>
                                <th>
                                    {t('XRechnung.DeliveryAddressStreet.Text')}
                                </th>
                                <td>
                                    {props.receiverData.deliveryAddressStreet}
                                </td>
                            </tr>
                        )}
                        {(props.receiverData.deliveryAddressZip ||
                            props.receiverData.deliveryAddressCity) && (
                            <tr>
                                <th>
                                    {t('XRechnung.DeliveryAddressZipCity.Text')}
                                </th>
                                <td>
                                    {props.receiverData.deliveryAddressZip +
                                        ' ' +
                                        props.receiverData.deliveryAddressCity}
                                </td>
                            </tr>
                        )}
                        {props.receiverData.deliveryAddressSubLocality && (
                            <tr>
                                <th>
                                    {t(
                                        'XRechnung.DeliveryAddressSubLocality.Text'
                                    )}
                                </th>
                                <td>
                                    {
                                        props.receiverData
                                            .deliveryAddressSubLocality
                                    }
                                </td>
                            </tr>
                        )}
                        {props.receiverData.deliveryAddressAddGeoInf && (
                            <tr>
                                <th>
                                    {t(
                                        'XRechnung.DeliveryAddressAddGeoInf.Text'
                                    )}
                                </th>
                                <td>
                                    {
                                        props.receiverData
                                            .deliveryAddressAddGeoInf
                                    }
                                </td>
                            </tr>
                        )}
                        {props.receiverData.deliveryAddressLocality && (
                            <tr>
                                <th>
                                    {t(
                                        'XRechnung.DeliveryAddressLocality.Text'
                                    )}
                                </th>
                                <td>
                                    {props.receiverData.deliveryAddressLocality}
                                </td>
                            </tr>
                        )}
                        {localizedDeliveryCountry && (
                            <tr>
                                <th>{t('XRechnung.Country.Text')}</th>
                                <td>{localizedDeliveryCountry}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
