import { AxiosResponse } from 'axios';
import { RequestTools } from '@b4valuenet/ui-react';
import { User } from '../models/User';

export default class UserService {
    static ApiRoot = 'api/user/';

    static getCurrent = async (): Promise<User | null> => {
        try {
            let response = await RequestTools.get(this.ApiRoot + 'current');

            return (response.data.data as User) || null;
        } catch (error) {
            let errorCode = RequestTools.getApiErrorCode(error);

            if (errorCode === 401) return null;
            else throw error;
        }
    };

    static logout = async (): Promise<AxiosResponse> => {
        return RequestTools.post(this.ApiRoot + 'logout');
    };
}
