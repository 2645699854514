import { GenericActionResponse } from './GenericActionResponse';

export enum PasswordResetResultType {
    Success = 0,
    TokenInvalid = 1,
    TokenExpired = 2,
    TokenDepleted = 3,
    TokenNotFound = 4,
    EmailMissmatch = 5,
    UserLocked = 6,
}

export interface PasswordResetTokenValidationResponse
    extends GenericActionResponse {
    message: string | null;
    data: PasswordResetResultType;
}
