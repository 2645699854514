import { RequestTools } from '@b4valuenet/ui-react';
import { GetMessageOfTheDayResponse } from '../models/GetMessageOfTheDayResponse';

export default class HomeService {
    static ApiRoot = 'api/home/';

    static getMessageOfTheDay = async (
        language: string
    ): Promise<GetMessageOfTheDayResponse | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'message-of-the-day',
            {
                params: {
                    language: language,
                },
            }
        );

        let json = await response.data?.data;
        return (json as GetMessageOfTheDayResponse) || null;
    };

    static getEmail = async (): Promise<string | null> => {
        let response = await RequestTools.get(this.ApiRoot + 'getEmail');

        let json = await response.data;

        return (json.data as string) || null;
    };
}
