import './DashboardHeader.scss';

import {
    DropDown,
    FileTools,
    Header,
    HeaderItem,
    ReadableScreenReaderItem,
    Separator,
    TestSystemAlert,
    useWindowSize,
} from '@b4valuenet/ui-react';
import { createRef, useEffect, useState } from 'react';
import {
    headerMenuExpanded,
    headerMenuHidden,
} from '../../../redux/slices/AppSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { generateAssetLink } from '../../../App';
import { logout } from '../../../redux/actions/auth';
import { useTranslation } from 'react-i18next';

export default function DashbordHeader() {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const authenticatedUser = useAppSelector((state) => state.auth.user);

    const appSettings = useAppSelector((state) => state.app.settings);
    const [helpLink, setHelpLink] = useState<{
        title?: string;
        linkTo?: string;
        external?: boolean;
    } | null>(null);

    const menuExpanded = useAppSelector(
        (state) => state.app.isHeaderMenuExpanded
    );

    const windowSize = useWindowSize();

    useEffect(() => {
        const isHelpVisible = () => {
            if (
                appSettings?.providerSettings &&
                appSettings.providerSettings.footerItems
            ) {
                let newLink = appSettings.providerSettings.footerItems.find(
                    (item) => item.title === t('Common.Help.Text')
                );

                if (newLink) {
                    let newLinkAddress = newLink.link.replace(
                        '$basePath',
                        'dashboard'
                    );
                    const isExternal = newLink.external;
                    const isPDF =
                        FileTools.getFileExtension(newLinkAddress) === 'pdf';

                    let formattedLink = {
                        title: newLink.title + (isPDF ? ' (PDF)' : ''),
                        linkTo:
                            isPDF && !isExternal
                                ? generateAssetLink(newLinkAddress)
                                : newLinkAddress,
                        external: isExternal || isPDF,
                    };

                    setHelpLink(formattedLink);
                }
            }
        };

        isHelpVisible();
    }, [appSettings, t]);

    let regularMenuItems = [
        {
            title: t('Common.Home.Text'),
            linkTo: 'home',
            icon: 'house-fill',
        },
        {
            title: t('Common.Messages.Text'),
            linkTo: 'messages',
            icon: 'inbox-fill',
        },
        {
            title: t('Common.ClientAdministration.Text'),
            linkTo: 'client-administration',
            icon: 'gear-fill',
        },
    ];

    if (appSettings?.bigAttachmentsEnabled) {
        regularMenuItems.push({
            title: t('Common.BigAttachments.Text'),
            linkTo: 'attachments',
            icon: 'paperclip',
        });
    }

    let adminMenuItems = [
        {
            title: t('Common.UserAdministration.Text'),
            linkTo: 'user-administration',
            icon: 'person-fill',
        },
    ];

    let menuItems = authenticatedUser?.isAdmin
        ? [...regularMenuItems, ...adminMenuItems]
        : regularMenuItems;

    let homeButtonRef = createRef<HTMLAnchorElement>();

    let userFullName =
        authenticatedUser?.firstName + ' ' + authenticatedUser?.lastName;

    return (
        <Header
            aria-label={t('Common.HeaderLabel.Text')}
            focusLockActive={menuExpanded}
        >
            <ul className='flex left-side'>
                <DropDown
                    title='Menu'
                    aria-label='Menu'
                    expanded={menuExpanded}
                    onChange={(expanded: boolean) => {
                        dispatch(
                            expanded ? headerMenuExpanded() : headerMenuHidden()
                        );
                    }}
                    items={menuItems}
                    className='header-item'
                    homeButtonRef={homeButtonRef}
                />

                <Separator />

                <HeaderItem
                    icon='house-fill'
                    title={t('Common.Home.Text')}
                    linkTo={'home'}
                    external={false}
                    linkRef={homeButtonRef}
                    onFocus={() => {
                        if (menuExpanded) {
                            dispatch(headerMenuHidden());
                        }
                    }}
                />
            </ul>

            {appSettings?.isTestSystem && (
                <TestSystemAlert text={t('Common.TestSystem.Text')} />
            )}

            <ul className='flex right-side'>
                <HeaderItem
                    icon={'box-arrow-left'}
                    title={t('Common.Logout.Text')}
                    onPress={() => {
                        dispatch(logout());
                        dispatch(headerMenuHidden());
                    }}
                    linkTo={'/'}
                    external={true}
                    onFocus={() => {
                        if (menuExpanded) {
                            dispatch(headerMenuHidden());
                        }
                    }}
                />

                <Separator />

                {helpLink && (
                    <>
                        <HeaderItem
                            icon='question-circle-fill'
                            title={t('Common.Help.Text')}
                            aria-label={t('Common.Help.Text')}
                            linkTo={helpLink?.linkTo ?? ''}
                            external={helpLink.external ?? false}
                            onFocus={() => {
                                if (menuExpanded) {
                                    dispatch(headerMenuHidden());
                                }
                            }}
                        />
                        <Separator />
                    </>
                )}

                {windowSize !== null && windowSize.width < 1238 && (
                    <ReadableScreenReaderItem text={userFullName} />
                )}

                <HeaderItem
                    text={
                        windowSize !== null && windowSize.width >= 1238
                            ? userFullName
                            : undefined
                    }
                    icon='person-fill'
                    title={t('Common.PersonalSettings.Text')}
                    aria-label={t('Common.PersonalSettings.Text')}
                    linkTo={'settings'}
                    external={false}
                    onFocus={() => {
                        if (menuExpanded) {
                            dispatch(headerMenuHidden());
                        }
                    }}
                />
            </ul>
        </Header>
    );
}
