import {
    AsyncForm,
    BootstrapIcon,
    Button,
    LoadingOverlay,
    PasswordRequirements,
    PasswordRequirementsCheck,
    RequestTools,
    RequiredFieldsNotice,
    TextInput,
    Toast,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import OutboxBanner from '../../components/OutboxBanner/OutboxBanner';
import { PasswordResetResultType } from '../../../../models/PasswordResetTokenValidationResponse';
import PasswordService from '../../../../services/PasswordService';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IPasswordReset {
    becauseExpired: boolean;
}

export default function PasswordReset(props: IPasswordReset) {
    const { t } = useTranslation();
    const { token } = useParams();

    const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);
    const [passwordRequirements, setPasswordRequirements] =
        useState<PasswordRequirements | null>(null);

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [formSended, setFormSended] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string | null>(null);
    const [successText, setSuccessText] = useState<string | null>(null);

    useEffect(() => {
        if (token !== undefined) {
            const validateToken = async (token: string) => {
                let valid = false;

                setIsLoading(true);

                try {
                    let result =
                        await PasswordService.validatePasswordResetToken(token);

                    if (result) {
                        valid = result.data === PasswordResetResultType.Success;

                        switch (result.data) {
                            case PasswordResetResultType.TokenDepleted:
                            case PasswordResetResultType.TokenExpired:
                                setErrorText(
                                    t('ResetPassword.TokenExpired.Text')
                                );
                                break;
                            case PasswordResetResultType.TokenInvalid:
                            case PasswordResetResultType.TokenNotFound:
                                setErrorText(
                                    t('ResetPassword.TokenInvalid.Text')
                                );
                                break;

                            case PasswordResetResultType.EmailMissmatch:
                                setErrorText(
                                    t('ValidationMessage.PasswordNotEqual.Text')
                                );
                                break;

                            case PasswordResetResultType.UserLocked:
                                setErrorText(
                                    t('ResetPassword.UserLocked.Text')
                                );
                                break;
                        }
                    } else {
                        Toast.error('Empty result.');
                    }
                } catch (error) {
                    let errorCode = RequestTools.getApiErrorCode(error);
                    let errorMessage = RequestTools.getApiErrorMessage(error);

                    if (errorCode === 400) {
                        setErrorText(t('ResetPassword.TokenInvalid.Text'));
                    } else {
                        setErrorText(errorMessage);
                        Toast.error(errorMessage);
                    }
                }

                setIsLoading(false);
                setIsTokenValid(valid);

                return valid;
            };

            const getPasswordRequirements = async (token: string) => {
                setIsLoading(true);

                try {
                    let result =
                        await PasswordService.getPasswordRequirements();

                    if (result) {
                        setPasswordRequirements(result.data);

                        setSuccessText(result.message);
                    } else {
                        Toast.error('Empty result.');
                    }
                } catch (error) {
                    let errorCode = RequestTools.getApiErrorCode(error);
                    let errorMessage = RequestTools.getApiErrorMessage(error);

                    if (errorCode === 400) {
                        setErrorText(t('ResetPassword.TokenInvalid.Text'));
                        setIsTokenValid(false);
                    } else {
                        setErrorText(errorMessage);
                        Toast.error(errorMessage);
                    }
                }

                setIsLoading(false);
            };

            validateToken(token).then((valid) => {
                if (valid) getPasswordRequirements(token);
            });
        }
    }, [token, t, props.becauseExpired]);

    const beautifyPasswordRequirements = (
        passwordRequirements: PasswordRequirements | null
    ): JSX.Element => {
        if (!passwordRequirements) {
            return <></>;
        }

        return (
            <PasswordRequirementsCheck
                translations={{
                    title: t('Password.Requirements.Text'),
                    minDigits: t('Password.MinDigits.Text', {
                        numberDigits: passwordRequirements.minimumNumberDigits,
                    }),
                    minLetters: t('Password.MinLetters.Text', {
                        numberLetters:
                            passwordRequirements.minimumNumberLetters,
                    }),
                    minSpecials: t('Password.MinSpecials.Text', {
                        numberSpecials:
                            passwordRequirements.minimumNumberSpecialCharacters,
                    }),
                    minLength: t('Password.MinLength.Text', {
                        minLength: passwordRequirements.minimumPasswordLength,
                    }),
                }}
                password={password}
                requirements={passwordRequirements}
            />
        );
    };

    const isFormDataValid = useCallback(
        (
            email: string,
            password: string,
            confirmPassword: string,
            passwordRequirements: PasswordRequirements
        ): boolean => {
            if (passwordRequirements !== null) {
                // E-mail validation
                let isFormValid = ValidationTools.isEmailValid(email);

                // Password match validation
                isFormValid = isFormValid && password === confirmPassword;

                // Password requirements validation
                isFormValid =
                    isFormValid &&
                    ValidationTools.isPasswordMatchRequirements(
                        password,
                        passwordRequirements
                    );

                return isFormValid;
            } else {
                return false;
            }
        },
        []
    );

    useEffect(() => {
        if (passwordRequirements !== null) {
            setIsFormValid(
                isFormDataValid(
                    email,
                    password,
                    confirmPassword,
                    passwordRequirements
                )
            );

            setErrorText(null);
        }
    }, [
        email,
        password,
        confirmPassword,
        passwordRequirements,
        isFormDataValid,
    ]);

    async function handleSubmit() {
        setFormSended(true);

        if (!isFormValid) return;

        setIsLoading(true);

        if (isFormValid && token) {
            try {
                let result = await (props.becauseExpired
                    ? PasswordService.updateExpiredPassword(
                          token,
                          email,
                          password,
                          confirmPassword
                      )
                    : PasswordService.resetPassword(
                          token,
                          email,
                          password,
                          confirmPassword
                      ));

                if (result) {
                    setSuccessText(result.message);
                }
            } catch (error) {
                let errorCode = RequestTools.getApiErrorCode(error);
                let errorMessage = RequestTools.getApiErrorMessage(error);

                if (errorCode !== 400) {
                    Toast.error(errorMessage);
                }

                setErrorText(errorMessage);
            }
        } else {
            setErrorText(t('ValidationMessage.RequestValidationError.Text'));
        }

        setIsLoading(false);
    }

    return (
        <div className='auth-form-wrapper'>
            <AsyncForm className='auth-form' onSubmit={() => handleSubmit()}>
                <OutboxBanner />

                <section>
                    <h2>{t('ResetPassword.FormTitle.Text')}</h2>

                    {isTokenValid && !successText && (
                        <>
                            <RequiredFieldsNotice
                                description={t(
                                    'ValidationMessage.IndicatesARequiredField.Text'
                                )}
                            />
                            <TextInput
                                type='text'
                                label={t('ResetPassword.Email.Text')}
                                placeholder={t('ResetPassword.Email.Text')}
                                autoComplete='email'
                                value={email}
                                isValid={ValidationTools.isEmailFieldValueValid(
                                    email,
                                    formSended
                                )}
                                validationError={t(
                                    'ValidationMessage.EmailInvalid.Text'
                                )}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setEmail(e.target.value)
                                }
                                icon='envelope'
                            />

                            {beautifyPasswordRequirements(passwordRequirements)}

                            <TextInput
                                type='password'
                                label={t('ResetPassword.Password.Text')}
                                placeholder={t('ResetPassword.Password.Text')}
                                autoComplete='new-password'
                                value={password}
                                isValid={
                                    (!formSended && password.length === 0) ||
                                    (passwordRequirements !== null &&
                                        ValidationTools.isPasswordMatchRequirements(
                                            password,
                                            passwordRequirements
                                        ))
                                }
                                validationError={
                                    password.length <
                                    (passwordRequirements?.minimumPasswordLength ??
                                        0)
                                        ? t(
                                              'ValidationMessage.MinLength.Text',
                                              {
                                                  minLength:
                                                      passwordRequirements?.minimumPasswordLength ??
                                                      1,
                                              }
                                          )
                                        : t(
                                              'ValidationMessage.MeetRequirements.Text'
                                          )
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setPassword(e.target.value)
                                }
                                icon='key'
                            />

                            <TextInput
                                type='password'
                                label={t('ResetPassword.ConfirmPassword.Text')}
                                placeholder={t(
                                    'ResetPassword.ConfirmPassword.Text'
                                )}
                                autoComplete='new-password'
                                value={confirmPassword}
                                isValid={
                                    (!formSended &&
                                        confirmPassword.length === 0) ||
                                    confirmPassword === password
                                }
                                validationError={
                                    confirmPassword === password
                                        ? t(
                                              'ValidationMessage.MeetRequirements.Text'
                                          )
                                        : t(
                                              'ValidationMessage.PasswordNotEqual.Text'
                                          )
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setConfirmPassword(e.target.value)
                                }
                                icon='key'
                            />

                            <Button
                                type='submit'
                                disabled={!isFormValid}
                                primary={true}
                                className='auth-action-button'
                                text={t('ResetPassword.SubmitBtn.Text')}
                            />
                        </>
                    )}

                    {successText && (
                        <p className='success'>
                            <BootstrapIcon icon='check-circle-fill' />
                            {successText}
                        </p>
                    )}

                    {errorText && !isTokenValid && (
                        <p className='error-alt'>
                            <BootstrapIcon icon='x-circle-fill' />
                            {errorText}
                        </p>
                    )}
                    <Button
                        isLink={true}
                        linkTo={'/auth/login'}
                        type='button'
                        primary={false}
                        className='auth-action-button'
                        text={t('ResetPassword.BackToLoginBtn.Text')}
                    />

                    {errorText && isTokenValid && (
                        <p className='error'>{errorText}</p>
                    )}
                </section>
            </AsyncForm>
            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={isLoading}
            />
        </div>
    );
}
