import { Card } from '@b4valuenet/ui-react';
import { SenderData } from '../../../../../../../../../models/SenderData';
import { useTranslation } from 'react-i18next';

export interface ISellerMainInfo {
    senderData: SenderData;
}
export default function SellerMainInfo(props: ISellerMainInfo) {
    const { t } = useTranslation();

    return (
        <>
            <Card title={t('XRechnung.SenderInformation.Text')}>
                <div className='pseudo-table align-left cw-50'>
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    {t(
                                        'XRechnung.SenderMicroenterpreneur.Text'
                                    )}
                                </th>
                                <td>
                                    {t(
                                        props.senderData
                                            .senderMicroenterpreneuer
                                            ? 'Common.Yes.Text'
                                            : 'Common.No.Text'
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('XRechnung.LegalEntityName.Text')}</th>
                                <td>
                                    {props.senderData.senderLegalEntityName}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('XRechnung.ClientName.Text')}</th>
                                <td>{props.senderData.senderName}</td>
                            </tr>
                            <tr>
                                <th>
                                    {t('XRechnung.SenderIDRefGroupValue.Text')}
                                </th>
                                <td>
                                    {props.senderData.senderIDRefGroupValue}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('XRechnung.VatId.Text')}</th>
                                <td>{props.senderData.senderVatId}</td>
                            </tr>
                            <tr>
                                <th>{t('XRechnung.TaxNo.Text')}</th>
                                <td>{props.senderData.senderTaxNo}</td>
                            </tr>
                            <tr>
                                <th>
                                    {t(
                                        'XRechnung.SenderLegalEntityLegalInformation.Text'
                                    )}
                                </th>
                                <td>
                                    {
                                        props.senderData
                                            .senderLegalEntityLegalInformation
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Card>
        </>
    );
}
