import { ChangeEvent, useEffect, useState } from 'react';

import { AsyncForm } from '@b4valuenet/ui-react';
import { BootstrapIcon } from '@b4valuenet/ui-react';
import { Button } from '@b4valuenet/ui-react';
import { LoadingOverlay } from '@b4valuenet/ui-react';
import OutboxBanner from '../../components/OutboxBanner/OutboxBanner';
import PasswordService from '../../../../services/PasswordService';
import { RequestTools } from '@b4valuenet/ui-react';
import { RequiredFieldsNotice } from '@b4valuenet/ui-react';
import { TextInput } from '@b4valuenet/ui-react';
import { Toast } from '@b4valuenet/ui-react';
import { ValidationTools } from '@b4valuenet/ui-react';
import { useTranslation } from 'react-i18next';

export default function PasswordForgotten() {
    const { t } = useTranslation();

    const [email, setEmail] = useState<string>('');

    const [isFormValid, setIsFormValid] = useState<boolean>(
        isFormDataValid(email)
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string | null>(null);
    const [successText, setSuccessText] = useState<string | null>(null);

    const [formSended, setFormSended] = useState<boolean>(false);

    function isFormDataValid(email: string): boolean {
        // E-mail validation
        let isFormValid = ValidationTools.isEmailValid(email);

        return isFormValid;
    }

    useEffect(() => {
        setIsFormValid(isFormDataValid(email));
        setErrorText('');
    }, [email]);

    async function handleSubmit() {
        setIsLoading(true);
        setFormSended(true);

        if (isFormValid) {
            try {
                let result = await PasswordService.requestPasswordReset(email);

                if (result) {
                    setSuccessText(result.message);
                }
            } catch (error) {
                let errorCode = RequestTools.getApiErrorCode(error);
                let errorMessage = RequestTools.getApiErrorMessage(error);

                if (errorCode !== 400) {
                    Toast.error(errorMessage);
                }

                setErrorText(errorMessage);
            }
        }

        setIsLoading(false);
    }

    return (
        <div className='auth-form-wrapper'>
            <AsyncForm className='auth-form' onSubmit={() => handleSubmit()}>
                <OutboxBanner />

                <section>
                    <h2>{t('PasswordForgotten.FormTitle.Text')}</h2>

                    {successText === null && (
                        <>
                            <RequiredFieldsNotice
                                description={t(
                                    'ValidationMessage.IndicatesARequiredField.Text'
                                )}
                            />
                            <TextInput
                                required={true}
                                type='text'
                                label={t('Login.Email.Text')}
                                placeholder={t('Login.Email.Text')}
                                value={email}
                                isValid={
                                    (!formSended && email.length === 0) ||
                                    ValidationTools.isEmailValid(email)
                                }
                                validationError={
                                    email.length === 0
                                        ? t('ValidationMessage.Required.Text')
                                        : t(
                                              'ValidationMessage.EmailInvalid.Text'
                                          )
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setEmail(e.target.value)
                                }
                                icon='envelope'
                                autoComplete='email'
                            />

                            <Button
                                type='submit'
                                disabled={!isFormValid}
                                primary={true}
                                className='auth-action-button'
                                text={t(
                                    'PasswordForgotten.SendResetEmailBtn.Text'
                                )}
                            />
                        </>
                    )}

                    {successText && (
                        <p className='success'>
                            <BootstrapIcon icon='check-circle-fill' />
                            {successText}
                        </p>
                    )}

                    <Button
                        isLink={true}
                        linkTo={'/auth/login'}
                        type='button'
                        className='auth-action-button'
                        primary={false}
                        text={t('PasswordForgotten.CancelBtn.Text')}
                    />

                    {errorText && <p className='error'>{errorText}</p>}
                </section>
            </AsyncForm>

            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={isLoading}
            />
        </div>
    );
}
