import './ClientAdministration.scss';

import CompanySettings from './components/CompanySettings';
import DeleteAccountCard from './components/DeleteAccountCard';
import PeppolVerificationCard from './components/PeppolVerificationCard';
import { useTranslation } from 'react-i18next';

export default function ClientAdministration() {
    const { t } = useTranslation();

    return (
        <section className='sub-page client-administration'>
            <h1 className='page-title'>
                {t('ClientAdministration.Settings.Text')}
            </h1>

            <div className='cards'>
                <CompanySettings />

                <DeleteAccountCard />

                <PeppolVerificationCard />
            </div>
        </section>
    );
}
