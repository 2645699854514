import { Button, RequiredFieldsNotice } from '@b4valuenet/ui-react';

import { IXRechnungSubPage } from '../../XRechnungPage';
import InvoicePositionLine from './components/InvoicePositionLine';
import { PositionLine } from '../../../../../../models/PositionLine';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

export function XRechnungInvoiceLineItems(
    props: IXRechnungSubPage & { senderMicroenterpreneuer: boolean }
) {
    const { t } = useTranslation();

    const appSettings = useAppSelector((state) => state.app.settings);

    return (
        <div className='spaced-fields'>
            <RequiredFieldsNotice
                description={t(
                    'ValidationMessage.IndicatesARequiredField.Text'
                )}
            />
            {props.data?.positions.map(
                (position: PositionLine, index: number) => {
                    return (
                        <InvoicePositionLine
                            key={index}
                            line={position}
                            unitOptions={props.unitOptions}
                            taxCategories={props.taxCategories}
                            isFormSubmitted={props.isFormSubmitted}
                            isSubLine={false}
                            senderMicroenterpreneuer={
                                props.senderMicroenterpreneuer
                            }
                            messageTypes={props.messageTypes}
                            data={props.data}
                            updateData={props.updateData}
                            onChange={(updatedLine: PositionLine) => {
                                if (props.data) {
                                    props.updateData((prevData) => {
                                        let newPositions = [
                                            ...prevData.positions,
                                        ];

                                        newPositions[index] = updatedLine;

                                        return {
                                            ...prevData,
                                            positions: newPositions,
                                        };
                                    });
                                }
                            }}
                            onRemove={() => {
                                if (props.data) {
                                    props.updateData((prevData) => {
                                        let newPositions = [
                                            ...prevData.positions,
                                        ];

                                        newPositions.splice(index, 1);

                                        return {
                                            ...prevData,
                                            positions: newPositions,
                                        };
                                    });
                                }
                            }}
                        />
                    );
                }
            )}
            {props.data?.positions && props.data.positions.length === 0 ? (
                <p
                    style={{
                        textAlign: 'center',
                        color: 'var(--ui-error)',
                    }}
                >
                    {t('XRechnung.AtLeastOnePositionItem.Text')}
                </p>
            ) : (
                ''
            )}
            {props.data?.positions &&
            props.data.positions.length < (appSettings?.subItemLimit ?? 100) ? (
                <Button
                    onClick={() => {
                        if (props.data) {
                            props.updateData((prevData) => {
                                let newPositions = [...prevData.positions];

                                newPositions.push({
                                    unit: null,
                                    vatRate: props.senderMicroenterpreneuer
                                        ? props.taxCategories.find(
                                              (taxCat) =>
                                                  parseInt(taxCat.key) === -1
                                          )?.key
                                        : null,
                                    surchargeGroups: [],
                                    rebateGroups: [],
                                    subLines: [],
                                    quantity: 0,
                                } as any);

                                return {
                                    ...prevData,
                                    positions: newPositions,
                                };
                            });
                        }
                    }}
                    className='add-invoice-line-item'
                    icon='plus-lg'
                    text={t('XRechnung.AddPositionBtn.Text')}
                />
            ) : (
                // TODO: Alert Component
                <p
                    style={{
                        textAlign: 'center',
                        color: 'var(--ui-error)',
                    }}
                >
                    {t('XRechnung.LimitSublineItemsReached.Text')}
                </p>
            )}
        </div>
    );
}
