import { titleChanged } from '../../../../redux/slices/AppSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface IRealPage {
    appTitleShortcut?: string;
    title?: string;
    i18title?: string;
    component: JSX.Element;
}

export const RealPage = (props: IRealPage) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    let title = props.title ?? (props.i18title ? t(props.i18title) : '');
    let appTitle = props.appTitleShortcut ?? 'LEA';

    useEffect(() => {
        document.title = appTitle + ' - ' + title;

        dispatch(titleChanged(title ?? ''));
    }, [appTitle, title, t, dispatch]);

    return props.component;
};
