import './ReceiverDataDiffDialog.scss';

import {
    Button,
    Dialog,
    Toast
} from '@b4valuenet/ui-react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Country } from '../../../../../../../models/Country';
import { ReceiverData } from '../../../../../../../models/ReceiverData';
import LocaleService from '../../../../../../../services/LocaleService';

export interface IReceiverDataDiffDialog {
    active: boolean;
    title: string;
    data1: ReceiverData | null;
    data2: ReceiverData | null;
    onClose: (confirmed: boolean) => void;
}

function ReceiverDataDiffDialog(props: IReceiverDataDiffDialog) {
    const { t } = useTranslation();

    const i18nKeys = {
        receiverLegalEntityName: 'XRechnung.LegalEntityName.Text',
        receiverAddressStreet: 'XRechnung.Street.Text',
        receiverAddressPoBox: 'XRechnung.PoBox.Text',
        receiverAddressCity: 'XRechnung.City.Text',
        receiverAddressZip: 'XRechnung.Zip.Text',
        receiverAddressCountry: 'XRechnung.Country.Text',
        leitwegId: 'XRechnung.LeitwegId.Text',
    };

    const [countries, setCountries] = useState<Country[]>([]);

    const compareData = (data1: any, data2: any) => {
        let result: {
            [key: string]: {
                diff: boolean;
                value1: string | null;
                value2: string | null;
                value: string | null;
            };
        } = {};

        for (let key in data1) {
            if (data1[key] !== data2[key]) {
                result[key] = {
                    diff: true,
                    value1: data1[key],
                    value2: data2[key],
                    value: null,
                };
            } else {
                result[key] = {
                    diff: false,
                    value: data1[key],
                    value1: null,
                    value2: null,
                };
            }
        }

        return result;
    };

    //If there is no difference close dialog immediately
    useEffect(() => {
        var anyDiff = Object.values(compareData(props.data1, props.data2)).some(
            (e) => e.diff
        );

        if (!anyDiff && props.data1 && props.data2) {
            props.onClose(false);
        }
    }, [props]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                let countryData = await LocaleService.getCountriesList();

                if (countryData !== null) {
                    setCountries(countryData);
                }
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };

        fetchCountries();
    }, []);

    const getCountryByKey = (key: string): string => {
        var country = countries.find((e) => e.code === key)?.name;

        if (country === null || country === undefined) {
            return key;
        }

        return country;
    };

    const comparedData =
        props.data1 && props.data2 ? compareData(props.data1, props.data2) : {};

    return (
        <Dialog
            active={props.active}
            title={props.title}
            onClose={() => props.onClose(false)}
        >
            <div className='receiverDataDiff'>
                {Object.entries(comparedData).map(
                    ([key, { diff, value1, value2, value }]: [
                        key: any,
                        data: any
                    ]) => (
                        <>
                            {diff && (value || value1 || value2) && (
                                <div
                                    key={key}
                                    className='receiverDataDiff-item'
                                >
                                    <div className='receiverDataDiff-itemTitle'>
                                        {t((i18nKeys as any)[key])}
                                    </div>
                                    <div className='receiverDataDiff-itemValue'>
                                        <div className='row'>
                                            {value1 && (
                                                <s className='receiverDataDiff-itemValueDiff before'>
                                                    {key ===
                                                    'receiverAddressCountry'
                                                        ? getCountryByKey(
                                                              value1
                                                          )
                                                        : value1}
                                                </s>
                                            )}
                                            {value2 && (
                                                <span className='receiverDataDiff-itemValueDiff after'>
                                                    {key ===
                                                    'receiverAddressCountry'
                                                        ? getCountryByKey(
                                                              value2
                                                          )
                                                        : value2}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )
                )}
            </div>
            <div className='buttons-group'>
                <Button
                    primary={true}
                    danger={true}
                    text={t('Common.Yes.Text')}
                    onClick={() => props.onClose(true)}
                />
                <Button
                    text={t('Common.No.Text')}
                    onClick={() => props.onClose(false)}
                />
            </div>
        </Dialog>
    );
}

export default ReceiverDataDiffDialog;
