import './Messages.scss';

import {
    AnimationTools,
    Button,
    Column,
    ConsolePlaceTag,
    ConsoleTools,
    FilteringOdataFunction,
    FilteringSelectOption,
    FilteringType,
    GridActionsRightSide,
    GridTable,
    IActiveFilter,
    IHeadingTitle,
    Pagination,
    ReadableScreenReaderItem,
    RequestTools,
    Row,
    SortState,
    TableTools,
    Toast,
    findAndFocusModalCallerElement,
    useDebounce,
} from '@b4valuenet/ui-react';
import React, { useCallback, useEffect, useState } from 'react';

import DeleteModal from './modal/DeleteModal';
import ItemPreviewDialog from './dialog/ItemPreviewDialog';
import { Message } from '../../../../models/Message';
import MessageProtocolDialog from './dialog/MessageProtocolDialog';
import MessagesService from '../../../../services/MessagesService';
import SubmitExternallyCreatedInvoiceDialog from '../../../../shared/components/invoice/SubmitExternallyCreatedInvoiceDialog';
import { UILibraryTranslations } from '../../../../shared/UILibraryTranslations';
import { XRechnungMessageType } from '../../../../models/XRechnungMessageType';
import { useAppSelector } from '../../../../redux/hooks';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

let consolePlaceTag = new ConsolePlaceTag({
    text: 'Messages',
});

export function Messages() {
    const { t } = useTranslation();

    const dashboardRoute = useAppSelector((state) => state.app.dashboardRoute);
    const appSettings = useAppSelector((state) => state.app.settings);

    let itemsProPage = 25;

    const [searchParams, setSearchParams] = useSearchParams();

    let currentPage: number = TableTools.parsePageFromQuery(searchParams);
    let queryFiltering = TableTools.parseQueryFiltering(searchParams);

    const [pageCount, setPageCount] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingText, setLoadingText] = useState<string>();
    const [loadingError, setLoadingError] = useState<string | null>(null);

    const [tableHeadingTitles, setTableHeadingTitles] = useState<
        IHeadingTitle[]
    >([]);

    const [totalCount, setTotalCount] = useState<number>(0);

    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

    const [data, setData] = useState<Message[] | null>(null);

    const [activeFiltering, setActiveFiltering] = useState<IActiveFilter[]>(
        TableTools.queryFilteringToGridTableFormat(
            queryFiltering,
            tableHeadingTitles
        )
    );
    const [filteringString, setFilteringString] = useState<string>(
        TableTools.buildOdataFilterString(activeFiltering)
    );
    const [activeSort, setActiveSort] = useState<SortState>(
        TableTools.sortStateFromString(
            searchParams.get('sort-direction') ?? 'none'
        )
    );
    const [activeSortProperty, setActiveSortProperty] = useState<string | null>(
        TableTools.getValidSortProperty(
            searchParams.get('sort'),
            tableHeadingTitles
        )
    );

    const [selectedIds, setSelectedIds] = useState<(number | string)[]>();
    const [debouncedSelectedIds, setDebouncedSelectedIds] =
        useState<(number | string)[]>();

    const [messageTypes, setMessageTypes] = useState<XRechnungMessageType[]>(
        []
    );

    const [isDeleteModalActive, setIsDeleteModalActive] =
        useState<boolean>(false);

    const [modalCallerElement, setModalCallerElement] = useState<
        HTMLButtonElement | HTMLInputElement | HTMLAnchorElement | null
    >(null);

    const [uploadExtInvoiceDialogFile, setUploadExtInvoiceDialogFile] =
        useState<File | null>(null);
    const [isUploadExtInvoiceDialogActive, setIsUploadExtInvoiceDialogActive] =
        useState<boolean>(false);

    const [isItemPreviewDialogActive, setIsItemPreviewDialogActive] =
        useState<boolean>(false);
    const [itemPreviewDialogId, setItemPreviewDialogId] = useState<
        number | null
    >(null);

    const [isItemProtocolDialogActive, setIsItemProtocolDialogActive] =
        useState<boolean>(false);
    const [itemProtocolDialogMessage, setItemProtocolDialogMessage] =
        useState<Message | null>(null);

    useDebounce(() => {
        setDebouncedSelectedIds(selectedIds);
    }, [selectedIds]);

    const refresh = useCallback(
        (showToast: boolean = true) => {
            setRefreshTrigger(!refreshTrigger);

            if (showToast) Toast.info(t('Common.ListUpdateSuccess.Text'));
        },
        [refreshTrigger, t]
    );

    useEffect(() => {
        if (data !== null) {
            if (currentPage < 1) {
                searchParams.set('page', '1');
                setSearchParams(searchParams);
            }
            if (pageCount > 0 && currentPage > pageCount) {
                searchParams.set('page', pageCount.toString());
                setSearchParams(searchParams);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, pageCount, data]);

    useEffect(() => {
        TableTools.updateActiveSortOnQueryParamsChange(
            setActiveSort,
            setActiveSortProperty,
            searchParams,
            tableHeadingTitles
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    useEffect(() => {
        setActiveFiltering(
            TableTools.queryFilteringToGridTableFormat(
                queryFiltering,
                tableHeadingTitles
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        ConsoleTools.log({ activeSort, activeSortProperty }, consolePlaceTag);
    }, [activeSort, activeSortProperty]);

    useEffect(() => {
        if ((filteringString?.length ?? 0) === 0) refresh(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteringString]);

    useEffect(() => {
        const fetchInboxData = async () => {
            setIsLoading(true);

            try {
                let content = await MessagesService.getMessages(
                    currentPage,
                    itemsProPage,
                    activeSort,
                    activeSortProperty,
                    filteringString
                );

                setTotalCount(content?.['@odata.count'] || 0);
                setData(content?.value ?? null);
                setLoadingError(null);

                let allItemsCount =
                    content !== null ? content['@odata.count'] : 0;
                let newPageCount = Math.ceil(allItemsCount / itemsProPage);

                setPageCount(newPageCount);

                setIsLoading(false);
            } catch (error) {
                if (error instanceof Error) {
                    setLoadingError(RequestTools.getApiErrorMessage(error));
                }

                setIsLoading(false);
            }
        };

        const fetchMessageTypes = async () => {
            try {
                let types =
                    (await MessagesService.getXRechnungMessageTypes()) ?? [];
                setMessageTypes(types ?? []);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };

        fetchInboxData();
        fetchMessageTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentPage,
        itemsProPage,
        activeSort,
        activeSortProperty,
        refreshTrigger,
    ]);

    useEffect(() => {
        let messageTypesList: FilteringSelectOption[] = [
            {
                key: '',
                value: t('Common.NoSelection.Text'),
            },
        ];

        messageTypes.forEach((type) => {
            messageTypesList.push({
                key: type.internalKey.toString(),
                value: type.value,
            });
        });

        messageTypesList.push({
            key: '1',
            value: t('Messages.Unknown.Text'),
        });

        let tableHeadingTitles: IHeadingTitle[] = [
            {
                title: t('Messages.Id.Text'),
                propertyName: 'id',
                sortable: true,
                filtering: FilteringType.Number,
                filteringOdataFunction: FilteringOdataFunction.Equal,
            },
            {
                title: t('Messages.DataFormat.Text'),
                propertyName: 'messageType',
                sortable: true,
                filtering: FilteringType.NumberList,
                filteringOdataFunction: FilteringOdataFunction.Equal,
                filteringOptionsList: messageTypesList,
            },
            {
                title: t('Messages.BuyerReference.Text'),
                propertyName: 'buyerReference',
                sortable: true,
                filtering: FilteringType.String,
                filteringOdataFunction: FilteringOdataFunction.Contains,
            },
            {
                title: t('Messages.Reference.Text'),
                propertyName: 'reference',
                sortable: true,
                filtering: FilteringType.String,
                filteringOdataFunction: FilteringOdataFunction.Contains,
            },
            {
                title: t('Messages.Created.Text'),
                propertyName: 'created',
                sortable: true,
                filtering: FilteringType.Date,
                filteringOdataFunction: FilteringOdataFunction.DateDefault,
            },
            {
                title: t('Messages.ReadyForDelivery.Text'),
                propertyName: 'readyForDelivery',
                sortable: true,
                filtering: FilteringType.Date,
                filteringOdataFunction: FilteringOdataFunction.DateDefault,
            },
            {
                title: t('Messages.State.Text'),
                propertyName: 'state',
                sortable: true,
                filtering: FilteringType.StringList,
                filteringOdataFunction: FilteringOdataFunction.Equal,
                filteringOptionsList: [
                    {
                        key: '',
                        value: t('Common.NoSelection.Text'),
                    },
                    {
                        key: 'New',
                        value: t('Messages.StateNew.Text'),
                    },
                    {
                        key: 'Provided',
                        value: t('Messages.StateProvided.Text'),
                    },
                    {
                        key: 'Processing',
                        value: t('Messages.StateProcessing.Text'),
                    },
                    {
                        key: ['Rejected', 'RejectedDeletable'],
                        value: t('Messages.StateRejected.Text'),
                    },
                    {
                        key: 'Delivered',
                        value: t('Messages.StateDelivered.Text'),
                    },
                ],
            },
            {
                title: t('Messages.UploadDeliveryChannel.Text'),
                propertyName: 'uploadDeliveryChannel',
                sortable: true,
                filtering: FilteringType.StringList,
                filteringOdataFunction: FilteringOdataFunction.Equal,
                filteringOptionsList: [
                    {
                        key: '',
                        value: t('Common.NoSelection.Text'),
                    },
                    {
                        key: 'Email',
                        value: t('Messages.DeliveryChannel_Email.Text'),
                    },
                    {
                        key: 'As2',
                        value: t('Messages.DeliveryChannel_As2.Text'),
                    },
                    {
                        key: 'PortalUpload',
                        value: t('Messages.DeliveryChannel_PortalUpload.Text'),
                    },
                    {
                        key: 'WebEdi',
                        value: t('Messages.DeliveryChannel_WebEdi.Text'),
                    },
                    {
                        key: 'PortalUploadFromTemplate',
                        value: t(
                            'Messages.DeliveryChannel_PortalUploadFromTemplate.Text'
                        ),
                    },
                ],
            },
            { title: t('Messages.Actions.Text') },
        ];

        setTableHeadingTitles(tableHeadingTitles);
    }, [t, messageTypes]);

    async function handleItemDownloadButtonClick(
        documentId: number,
        e: React.MouseEvent<HTMLButtonElement>
    ) {
        let button = e.target as HTMLButtonElement;

        setLoadingText(t('Messages.DownloadPrepared.Text'));
        setIsLoading(true);

        button.disabled = true;

        try {
            await MessagesService.downloadFile(documentId);
            Toast.info(t('Messages.DownloadStarted.Text'));
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        button.disabled = false;

        setIsLoading(false);
        setLoadingText(undefined);
    }

    async function handleItemDownloadPdfButtonClick(
        documentId: number,
        e: React.MouseEvent<HTMLButtonElement>
    ) {
        let button = e.target as HTMLButtonElement;

        setLoadingText(t('Messages.DownloadPrepared.Text'));
        setIsLoading(true);

        button.disabled = true;

        try {
            await MessagesService.downloadPdfFile(documentId);
            Toast.info(t('Messages.DownloadStarted.Text'));
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        button.disabled = false;

        setIsLoading(false);
        setLoadingText(undefined);
    }

    async function handleShowProtocolButton(
        message: Message,
        e: React.MouseEvent<HTMLButtonElement>
    ) {
        setModalCallerElement(e.target as any);

        setItemProtocolDialogMessage(message);
        AnimationTools.activateModal(setIsItemProtocolDialogActive);
    }

    async function handleItemPreviewButtonClick(
        documentId: number,
        e: React.MouseEvent<HTMLButtonElement>
    ) {
        let button = e.target as HTMLButtonElement;

        setItemPreviewDialogId(documentId);
        setModalCallerElement(button ?? null);
        AnimationTools.activateModal(setIsItemPreviewDialogActive);
    }

    async function handleExportCSVButtonClick(
        e: React.MouseEvent<HTMLButtonElement>
    ) {
        let button = e.target as HTMLButtonElement;

        setLoadingText(t('Messages.DownloadPrepared.Text'));
        setIsLoading(true);

        button.disabled = true;

        try {
            await MessagesService.exportCSV();
            Toast.info(t('Messages.DownloadStarted.Text'));
        } catch (error) {
            let code = RequestTools.getApiErrorCode(error);
            let message = RequestTools.getApiErrorMessage(error);

            if (code !== 401) Toast.error(message);
        }

        button.disabled = false;

        setIsLoading(false);
        setLoadingText(undefined);
    }

    let isContentVisible = !isLoading;

    return (
        <>
            <GridTable
                enableSelection={true}
                disableCtrlA={true}
                isSubPage={true}
                className='messages-grid'
                title={t('Common.Messages.Text')}
                onEmptyMessage={t('Messages.NoRecords.Text')}
                isLoading={isLoading}
                loadingText={loadingText ?? t('Common.LoadingMessage.Text')}
                loadingError={loadingError}
                tableHeadingTitles={tableHeadingTitles}
                tableData={data}
                selectedItems={debouncedSelectedIds}
                onSelectItems={(items: (number | string)[]) => {
                    if (items.length !== debouncedSelectedIds?.length)
                        setSelectedIds(items);
                }}
                pagination={
                    pageCount > 1 ? (
                        <Pagination
                            routePath={dashboardRoute}
                            maxItemsCount={5}
                            currentPage={currentPage}
                            pageCount={pageCount}
                            labels={{
                                goToStartPage: t(
                                    'Common.PaginationGoToStartPageLabel.Text'
                                ),
                                goToPage: t(
                                    'Common.PaginationGoToPageLabel.Text'
                                ),
                                goToEndPage: t(
                                    'Common.PaginationGoToEndPageLabel.Text'
                                ),
                            }}
                        />
                    ) : null
                }
                filteringTranslation={{
                    toggleButtonTitle: t(
                        'Common.Filtering_ToggleButtonTitle.Text'
                    ),
                    resetButtonTitle: t(
                        'Common.Filtering_ResetButtonTitle.Text'
                    ),
                    calendarTranslation:
                        UILibraryTranslations.getCalendarTranslation(t),
                }}
                filteringActionButtons={
                    <>
                        <Button
                            onClick={() => refresh(false)}
                            primary={true}
                            icon={'search'}
                            disabled={(filteringString?.length ?? 0) === 0}
                            text={t('Setup.SearchBtn.Text')}
                        />
                    </>
                }
                showCount={true}
                totalCount={totalCount}
                countPerPage={itemsProPage}
                currentPage={currentPage}
                countTranslation={{
                    of: t('Common.PaginatorOf.Text'),
                    items: t('Messages.Invoices.Text'),
                }}
                activeFiltering={activeFiltering ?? []}
                activeSort={activeSort}
                activeSortProperty={activeSortProperty}
                onSort={(
                    newActiveSort: SortState,
                    newActiveSortProperty: string | null
                ) => {
                    TableTools.updateQueryParamsOnSort(
                        newActiveSort,
                        newActiveSortProperty,
                        searchParams,
                        setSearchParams
                    );
                }}
                enableFiltering={true}
                onFilter={(filtering, newFilteringString) => {
                    TableTools.updateQueryParamsOnFilter(
                        filtering,
                        searchParams,
                        setSearchParams
                    );

                    setSelectedIds([]);
                    setDebouncedSelectedIds([]);
                    setFilteringString(newFilteringString);
                }}
                checkboxAllTitle={t('Common.SelectAll.Text', {
                    items: t('Messages.Invoices.Text'),
                })}
                srOnlyCheckBoxTitle={true}
                rowRendering={(element: Message, index: number) => {
                    return (
                        <Row
                            selectionDisabled={!element.deletable}
                            id={element.id}
                            key={index}
                            checkboxTitle={t('Common.Select.Text', {
                                item: t('XRechnung.MessageType380.Text'),
                            })}
                            srOnlyCheckBoxTitle={true}
                        >
                            <Column size='small'>{element.id}</Column>
                            <Column size='medium'>
                                {messageTypes.find(
                                    (msgType) =>
                                        element.messageType ===
                                        msgType.internalKey
                                )?.value || t('Messages.Unknown.Text')}
                            </Column>
                            <Column size='medium'>
                                {element.buyerReference}
                            </Column>
                            <Column size='medium'>{element.reference}</Column>
                            <Column size='big'>
                                {TableTools.toLocaleDateString(element.created)}
                            </Column>
                            <Column size='medium'>
                                {element.readyForDelivery
                                    ? TableTools.toLocaleDateString(
                                          element.readyForDelivery
                                      )
                                    : null}
                            </Column>
                            <Column size='medium'>
                                {t('Messages.State' + element.state + '.Text')}
                            </Column>
                            <Column size='medium'>
                                {t(
                                    'Messages.DeliveryChannel_' +
                                        element.uploadDeliveryChannel +
                                        '.Text'
                                )}
                            </Column>
                            <Column className='actions'>
                                {element.showViewButton && (
                                    <Button
                                        aria-label={t(
                                            'Messages.ViewBtn.Tooltip'
                                        )}
                                        title={t('Messages.ViewBtn.Tooltip')}
                                        onClick={(
                                            e: React.MouseEvent<HTMLButtonElement>
                                        ) =>
                                            handleItemPreviewButtonClick(
                                                element.id,
                                                e
                                            )
                                        }
                                        primary={true}
                                        tabIndex={isContentVisible ? 0 : -1}
                                        icon='eye'
                                    />
                                )}
                                {element.showDownloadButton && (
                                    <Button
                                        aria-label={t(
                                            'Messages.DownloadPdfInvoiceBtn.Tooltip'
                                        )}
                                        title={t(
                                            'Messages.DownloadPdfInvoiceBtn.Tooltip'
                                        )}
                                        onClick={(
                                            e: React.MouseEvent<HTMLButtonElement>
                                        ) =>
                                            handleItemDownloadPdfButtonClick(
                                                element.id,
                                                e
                                            )
                                        }
                                        primary={true}
                                        tabIndex={isContentVisible ? 0 : -1}
                                        icon='eye'
                                    />
                                )}
                                {element.showDownloadButton && (
                                    <Button
                                        aria-label={t(
                                            'Messages.DownloadXmlInvoiceBtn.Tooltip'
                                        )}
                                        title={t(
                                            'Messages.DownloadXmlInvoiceBtn.Tooltip'
                                        )}
                                        onClick={(
                                            e: React.MouseEvent<HTMLButtonElement>
                                        ) =>
                                            handleItemDownloadButtonClick(
                                                element.id,
                                                e
                                            )
                                        }
                                        primary={true}
                                        tabIndex={isContentVisible ? 0 : -1}
                                        icon='download'
                                    />
                                )}
                                {element.showProtocolButton && (
                                    <Button
                                        aria-label={t(
                                            'Messages.ProtocolBtn.Tooltip'
                                        )}
                                        title={t(
                                            'Messages.ProtocolBtn.Tooltip'
                                        )}
                                        onClick={(
                                            e: React.MouseEvent<HTMLButtonElement>
                                        ) =>
                                            handleShowProtocolButton(element, e)
                                        }
                                        primary={true}
                                        tabIndex={isContentVisible ? 0 : -1}
                                        icon='search'
                                    />
                                )}
                                {element.showContinueButton && (
                                    <Button
                                        text={t(
                                            'Messages.ContinueDataInput.Text'
                                        )}
                                        isLink={true}
                                        linkTo={
                                            '/dashboard/xrechnung/' + element.id
                                        }
                                        primary={true}
                                        tabIndex={isContentVisible ? 0 : -1}
                                        icon='pen'
                                        className='continue-button'
                                    />
                                )}
                            </Column>
                        </Row>
                    );
                }}
                actionButtons={
                    <>
                        <Button
                            onClick={() => refresh()}
                            icon='arrow-clockwise'
                            text={t('Messages.RefreshBtn.Text')}
                            className='refresh'
                            primary={true}
                        />
                        <Button
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                                setIsUploadExtInvoiceDialogActive(true);

                                if (e.target instanceof HTMLInputElement)
                                    setModalCallerElement(e.target);
                            }}
                            onFileChange={(files: File[]) => {
                                setUploadExtInvoiceDialogFile(
                                    files ? files[0] : null
                                );
                            }}
                            isFileUpload={true}
                            fileAccept={appSettings?.uploadFileExtensions}
                            text={t('Messages.UploadBtn.Text')}
                            icon={'upload'}
                            primary={true}
                        />
                        <Button
                            onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                                if (selectedIds?.length === 0) return;

                                if (e.target instanceof HTMLButtonElement)
                                    setModalCallerElement(e.target);

                                AnimationTools.activateModal(
                                    setIsDeleteModalActive
                                );
                            }}
                            icon='trash'
                            text={t('Messages.DeleteBtn.Text')}
                            className='delete'
                            primary={true}
                            disabled={(selectedIds?.length ?? 0) <= 0}
                        />
                        {data && data.length > 0 && (
                            <GridActionsRightSide>
                                <Button
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => handleExportCSVButtonClick(e)}
                                    icon='download'
                                    text={t('Common.CSVExport.Text')}
                                    className='csv-export'
                                />
                            </GridActionsRightSide>
                        )}
                    </>
                }
            />

            {data && activeFiltering && (
                <div aria-live={'polite'}>
                    {data.length > 0 && (
                        <ReadableScreenReaderItem
                            text={
                                t('Messages.FoundInvoices.Text') +
                                ': ' +
                                data.length
                            }
                        />
                    )}
                    {data.length === 0 && (
                        <ReadableScreenReaderItem
                            text={t('Messages.NoRecords.Text')}
                        />
                    )}
                </div>
            )}

            <DeleteModal
                selectedIds={selectedIds ?? []}
                active={isDeleteModalActive}
                onClose={(actionConfirmed: boolean) => {
                    AnimationTools.deactivateModal(
                        setIsDeleteModalActive,
                        () => {
                            if (actionConfirmed) {
                                refresh(false);
                                setSelectedIds([]);
                            }

                            findAndFocusModalCallerElement(
                                modalCallerElement,
                                () => {
                                    setModalCallerElement(null);
                                }
                            );
                        }
                    );
                }}
            />

            <SubmitExternallyCreatedInvoiceDialog
                active={isUploadExtInvoiceDialogActive}
                onClose={() => {
                    setIsUploadExtInvoiceDialogActive(false);
                    refresh(false);

                    setTimeout(() => {
                        findAndFocusModalCallerElement(
                            modalCallerElement,
                            () => {
                                setModalCallerElement(null);
                            }
                        );
                    }, 400);
                }}
                selectedFile={uploadExtInvoiceDialogFile}
            />

            <ItemPreviewDialog
                id={itemPreviewDialogId}
                active={isItemPreviewDialogActive}
                onClose={() => {
                    AnimationTools.deactivateModal(
                        setIsItemPreviewDialogActive,
                        () => {
                            setItemPreviewDialogId(null);

                            findAndFocusModalCallerElement(
                                modalCallerElement,
                                () => {
                                    setModalCallerElement(null);
                                }
                            );
                        }
                    );
                }}
            />
            <MessageProtocolDialog
                message={itemProtocolDialogMessage}
                active={isItemProtocolDialogActive}
                onClose={() => {
                    AnimationTools.deactivateModal(
                        setIsItemProtocolDialogActive,
                        () => {
                            setItemProtocolDialogMessage(null);
                            setTimeout(() => {
                                findAndFocusModalCallerElement(
                                    modalCallerElement,
                                    () => {
                                        setModalCallerElement(null);
                                    }
                                );
                            }, 100);
                        }
                    );
                }}
            />
        </>
    );
}
