import {
    BootstrapIcon,
    Button,
    RequestTools,
    Toast,
} from '@b4valuenet/ui-react';

import { ModalWindow } from '@b4valuenet/ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientService from '../../../../../services/ClientService';

export interface IDeleteModal {
    active: boolean;
    onClose: CallableFunction;
}

export default function DeleteAccountDialog(props: IDeleteModal) {
    const { t } = useTranslation();

    const [error, setError] = useState<string | null>(null);

    function onClose(actionConfirmed: boolean) {
        props.onClose(actionConfirmed);
    }

    function handleCancelButtonClick() {
        onClose(false);
    }

    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function handleFormSubmit() {
        setIsLoading(true);

        try {
            let response = await ClientService.deleteClient();

            if (response !== null) {
                Toast.success(response.message, 350);
                onClose(true);
            }
        } catch (error) {
            const response = RequestTools.getApiErrorMessage(error);

            setError(response);
        }

        setIsLoading(false);
    }

    return (
        <ModalWindow
            isLoading={isLoading}
            aria-labelledby='delete-client-modal-delete-title'
            active={props.active}
            onSubmit={() => handleFormSubmit()}
            onEscapeKeyPressed={() => {
                if (!isLoading) onClose(false);
            }}
        >
            <h2 id='delete-client-modal-delete-title'>
                {t(
                    'ClientAdministration.DeactivateConfirmationDialogTitle.Text'
                )}
            </h2>
            <div className='modal-main'>
                {t(
                    'ClientAdministration.DeactivateConfirmationDialogMessage.Text'
                )}
                <div aria-live='polite'>
                    {error && (
                        <p className='error'>
                            <BootstrapIcon icon='x-circle-fill' />
                            {error}
                        </p>
                    )}
                </div>
            </div>
            <div className='footer'>
                <Button
                    onClick={() => handleCancelButtonClick()}
                    text={t('Common.No.Text')}
                />
                <Button
                    type='submit'
                    primary={true}
                    danger={true}
                    text={t(
                        'ClientAdministration.DeactivateClientSaveBtn.Text'
                    )}
                />
            </div>
        </ModalWindow>
    );
}
