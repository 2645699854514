import {
    AsyncForm,
    BootstrapIcon,
    Button,
    Card,
    ErrorOverlay,
    LoadingOverlay,
    RequestTools,
    RequiredFieldsNotice,
    Select,
    TextInput,
    Toast,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClientAdministrationData } from '../../../../../models/ClientAdministrationData';
import { ClientChangeResultType } from '../../../../../models/ClientChangeResultType';
import { Country } from '../../../../../models/Country';
import { GenericActionResponse } from '../../../../../models/GenericActionResponse';
import { Language } from '../../../../../models/Language';
import { UpdateClientFields } from '../../../../../models/UpdateClientFields';
import { useAppSelector } from '../../../../../redux/hooks';
import ClientService from '../../../../../services/ClientService';
import LocaleService from '../../../../../services/LocaleService';

export default function CompanySettings() {
    const { t } = useTranslation();
    const authenticatedUser = useAppSelector((state) => state.auth.user);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingError, setLoadingError] = useState<string | null>(null);
    const [data, setData] = useState<ClientAdministrationData | null>(null);
    const [formSended, setFormSended] = useState<boolean>(false);

    const [fieldErrorTarget, setFieldErrorTarget] =
        useState<UpdateClientFields | null>(null);
    const [fieldError, setFieldError] = useState<string | null>(null);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const [availableLanguagesList, setAvailableLanguagesList] = useState<
        Language[] | null
    >();
    const [countriesList, setCountriesList] = useState<Country[] | null>();

    let uidRequired = (data?.taxId?.length ?? 0) === 0;
    let taxIdRequired = (data?.uid?.length ?? 0) === 0;

    const navigate = useNavigate();

    async function saveChanges(isFormValid: boolean) {
        setFormSended(true);

        if (data === null || !isFormValid) return;

        setIsLoading(true);

        try {
            let response = await ClientService.updateClientAdministrationData(
                data
            );
            if (response !== null) {
                Toast.success(response.message, 350);
                setFieldError(null);
                setFieldErrorTarget(null);
                setError(null);
            }
        } catch (error: any) {
            if (error.response && error.response.data !== null) {
                const response: GenericActionResponse = error.response.data;
                if (response.data === ClientChangeResultType.InvalidVatId) {
                    setFieldError(response.message);
                    setFieldErrorTarget(UpdateClientFields.UID);
                    setIsLoading(false);
                    return;
                }

                setError(response.message);
            } else {
                let message = RequestTools.getApiErrorMessage(error);
                setError(message);
            }
        }

        setIsLoading(false);
    }

    useEffect(() => {
        const fetchMainData = async () => {
            setIsLoading(true);

            try {
                let data = await ClientService.getClientAdministrationData();

                setData(data ?? null);
                setLoadingError(null);

                setIsLoading(false);
            } catch (error) {
                if (error instanceof Error) {
                    let code = RequestTools.getApiErrorCode(error);

                    if (code === 403) {
                        navigate('/dashboard/');
                    }

                    setLoadingError(RequestTools.getApiErrorMessage(error));
                }

                setIsLoading(false);
            }
        };

        fetchMainData();
    }, []);

    function handleInputChange(
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        property: string,
        propertyParent?: string
    ) {
        let newValue = e.target.value;

        if (data !== null) {
            let newData = {} as ClientAdministrationData;

            if (propertyParent !== undefined) {
                newData = {
                    ...data,
                    [propertyParent]: {
                        ...(data as any)[propertyParent],
                        [property]: newValue,
                    },
                };
            } else {
                newData = {
                    ...data,
                    [property]: newValue,
                };
            }

            setData(newData);
        }
    }

    useEffect(() => {
        // if elster account is active language can be changed
        if (authenticatedUser?.isElsterAccount) {
            setIsFormValid(true);
            return;
        }

        setIsFormValid(
            data !== null &&
                data.companyName.length !== 0 &&
                data.companyName.length <= 200 &&
                data.street?.length <= 120 &&
                data.zip?.length <= 10 &&
                data.city?.length <= 120 &&
                data.uid?.length <= 120 &&
                data.taxId?.length <= 120 &&
                (!uidRequired || data.uid.length !== 0) &&
                (!taxIdRequired || data.taxId.length !== 0)
        );
    }, [data, taxIdRequired, uidRequired, authenticatedUser]);

    useEffect(() => {
        const fetchAvailableLanguages = async () => {
            try {
                let languages = await LocaleService.getLanguageList();
                setAvailableLanguagesList(languages);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };

        const fetchCountries = async () => {
            try {
                let countries = await LocaleService.getCountriesList();
                setCountriesList(countries);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };

        fetchAvailableLanguages();
        fetchCountries();
    }, []);

    return (
        <div>
            <Card title={t('ClientAdministration.Company.Text')}>
                <AsyncForm
                    onSubmit={() => saveChanges(isFormValid)}
                    className='card-content'
                >
                    <RequiredFieldsNotice
                        description={t(
                            'ValidationMessage.IndicatesARequiredField.Text'
                        )}
                    />
                    <TextInput
                        required={true}
                        readOnly={authenticatedUser?.isElsterAccount}
                        icon='building'
                        type='text'
                        autoComplete='organization'
                        label={t('ClientAdministration.CompanyName.Text')}
                        placeholder={t('ClientAdministration.CompanyName.Text')}
                        isValid={
                            ValidationTools.isRequiredFieldValueValid(
                                data?.companyName ?? '',
                                formSended
                            ) &&
                            ValidationTools.hasValidMaxLength(
                                data?.companyName,
                                formSended
                            )
                        }
                        validationError={
                            (data?.companyName.length ?? 0) > 200
                                ? t('ValidationMessage.MaxLength.Text', {
                                      maxLength: 200,
                                  })
                                : t('ValidationMessage.Required.Text')
                        }
                        value={data?.companyName ?? ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e, 'companyName')
                        }
                        infoButtonEnabled={true}
                        infoButtonTooltipText={t(
                            'ClientAdministration.CompanyName.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    />

                    <TextInput
                        readOnly={authenticatedUser?.isElsterAccount}
                        icon='signpost'
                        type='text'
                        autoComplete='address-line1'
                        label={t('ClientAdministration.Street.Text')}
                        placeholder={t('ClientAdministration.Street.Text')}
                        value={data?.street ?? ''}
                        isValid={ValidationTools.hasValidMaxLength(
                            data?.street,
                            formSended,
                            120
                        )}
                        validationError={t('ValidationMessage.MaxLength.Text', {
                            maxLength: 120,
                        })}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e, 'street')
                        }
                        infoButtonEnabled={true}
                        infoButtonTooltipText={t(
                            'ClientAdministration.Street.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    />

                    <div className='flex-row'>
                        <TextInput
                            readOnly={authenticatedUser?.isElsterAccount}
                            icon='mailbox'
                            type='text'
                            autoComplete='postal-code'
                            label={t('ClientAdministration.Zip.Text')}
                            placeholder={t('ClientAdministration.Zip.Text')}
                            className='postal-code'
                            value={data?.zip ?? ''}
                            isValid={ValidationTools.hasValidMaxLength(
                                data?.zip,
                                formSended,
                                10
                            )}
                            validationError={t(
                                'ValidationMessage.MaxLength.Text',
                                {
                                    maxLength: 10,
                                }
                            )}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e, 'zip')
                            }
                            infoButtonEnabled={true}
                            infoButtonTooltipText={t(
                                'ClientAdministration.Zip.Help'
                            )}
                            infoButtonTitle={t('Common.InputHelp.Text')}
                            infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        />
                        <TextInput
                            readOnly={authenticatedUser?.isElsterAccount}
                            icon='geo-alt'
                            type='text'
                            autoComplete='address-level2'
                            label={t('ClientAdministration.City.Text')}
                            placeholder={t('ClientAdministration.City.Text')}
                            value={data?.city ?? ''}
                            isValid={ValidationTools.hasValidMaxLength(
                                data?.city,
                                formSended,
                                120
                            )}
                            validationError={t(
                                'ValidationMessage.MaxLength.Text',
                                {
                                    maxLength: 120,
                                }
                            )}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e, 'city')
                            }
                            infoButtonEnabled={true}
                            infoButtonTooltipText={t(
                                'ClientAdministration.City.Help'
                            )}
                            infoButtonTitle={t('Common.InputHelp.Text')}
                            infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        />
                    </div>
                    {!authenticatedUser?.isElsterAccount && (
                        <div className='flex-row'>
                            <TextInput
                                icon='bank'
                                type='text'
                                label={t('ClientAdministration.UID.Text')}
                                placeholder={t('ClientAdministration.UID.Text')}
                                value={data?.uid ?? ''}
                                required={uidRequired}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    handleInputChange(e, 'uid');
                                    setError('');
                                    setFieldError(null);
                                    setFieldErrorTarget(null);
                                }}
                                isValid={
                                    fieldErrorTarget !==
                                        UpdateClientFields.UID &&
                                    ValidationTools.hasValidMaxLength(
                                        data?.uid,
                                        formSended,
                                        120
                                    ) &&
                                    (!uidRequired ||
                                        ValidationTools.isRequiredFieldValueValid(
                                            data?.uid ?? '',
                                            formSended
                                        ))
                                }
                                validationError={t(
                                    fieldErrorTarget ===
                                        UpdateClientFields.UID &&
                                        fieldError !== null
                                        ? fieldError
                                        : data?.uid?.length === 0
                                        ? t('ValidationMessage.Required.Text')
                                        : 'ValidationMessage.MaxLength.Text',
                                    {
                                        maxLength: 120,
                                    }
                                )}
                                infoButtonEnabled={true}
                                infoButtonTooltipText={t(
                                    'ClientAdministration.UID.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                            />

                            <TextInput
                                icon='bank'
                                type='text'
                                label={t('ClientAdministration.TaxId.Text')}
                                placeholder={t(
                                    'ClientAdministration.TaxId.Text'
                                )}
                                value={data?.taxId ?? ''}
                                required={taxIdRequired}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleInputChange(e, 'taxId')
                                }
                                isValid={
                                    ValidationTools.hasValidMaxLength(
                                        data?.taxId,
                                        formSended,
                                        120
                                    ) &&
                                    (!taxIdRequired ||
                                        ValidationTools.isRequiredFieldValueValid(
                                            data?.taxId ?? '',
                                            formSended
                                        ))
                                }
                                validationError={
                                    data?.uid?.length === 0
                                        ? t('ValidationMessage.Required.Text')
                                        : t(
                                              'ValidationMessage.MaxLength.Text',
                                              {
                                                  maxLength: 120,
                                              }
                                          )
                                }
                                infoButtonEnabled={true}
                                infoButtonTooltipText={t(
                                    'ClientAdministration.TaxId.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                            />
                        </div>
                    )}

                    <Select
                        icon='pin-map'
                        disabled={true}
                        autoComplete='country'
                        label={t('ClientAdministration.Country.Text')}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                            handleInputChange(e, 'country')
                        }
                        value={data?.country ?? ''}
                        infoButtonEnabled={true}
                        infoButtonTooltipText={t(
                            'ClientAdministration.Country.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    >
                        {countriesList?.map((country, index) => {
                            return (
                                <option key={index} value={country.code}>
                                    {country.name}
                                </option>
                            );
                        })}
                    </Select>

                    <Select
                        icon='globe'
                        autoComplete='language'
                        label={t('ClientAdministration.CompanyLanguage.Text')}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                            handleInputChange(e, 'language')
                        }
                        value={data?.language ?? ''}
                        infoButtonEnabled={true}
                        infoButtonTooltipText={t(
                            'ClientAdministration.CompanyLanguage.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    >
                        {availableLanguagesList?.map((language, index) => {
                            return (
                                <option key={index} value={language.code}>
                                    {language.text}
                                </option>
                            );
                        })}
                    </Select>
                    <div aria-live='polite'>
                        {error && (
                            <p className='error'>
                                <BootstrapIcon icon='x-circle-fill' />
                                {error}
                            </p>
                        )}
                    </div>
                    {authenticatedUser?.isElsterAccount && (
                        <div className='elster-logo-wrapper'>
                            <img
                                className='elster-logo'
                                src='assets/elster-muk-logo.svg'
                                alt='ELSTER logo'
                            />
                            <p className='description'>
                                {t(
                                    'ClientAdministration.ElsterDescription.Text'
                                )}
                            </p>
                        </div>
                    )}

                    <Button
                        type='submit'
                        disabled={!isFormValid}
                        text={t('ClientAdministration.UpdateBtn.Text')}
                        primary={true}
                    />
                </AsyncForm>
                <LoadingOverlay
                    loadingMessage={t('Common.LoadingMessage.Text')}
                    active={isLoading}
                    isGlobal={false}
                />
                <ErrorOverlay
                    active={loadingError !== null}
                    isGlobal={false}
                    errorText={loadingError ?? ''}
                />
            </Card>
        </div>
    );
}
