import { Card } from '@b4valuenet/ui-react';
import { InvoiceData } from '../../../../../../../../models/InvoiceData';
import { XRechnungMessageType } from '../../../../../../../../models/XRechnungMessageType';
import { useTranslation } from 'react-i18next';

export interface IInvoiceDataSummary {
    invoiceData: InvoiceData;
    messageTypes: XRechnungMessageType[];
}

export default function InvoiceDataSummary(props: IInvoiceDataSummary) {
    const { t } = useTranslation();

    let currentTypeCode = props.invoiceData.messageType;
    let currentType = props.messageTypes.find((type) => {
        return +type.key === currentTypeCode;
    });

    return (
        <Card title={t('XRechnung.InvoiceDataTab.Text')}>
            <div className='pseudo-table align-left cw-50'>
                <table>
                    <tbody>
                        <tr>
                            <th>{t('XRechnung.MessageType.Text')}</th>
                            <td>{currentType?.value}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.LeitwegId.Text')}</th>
                            <td>{props.invoiceData.leitwegId}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.Reference.Text')}</th>
                            <td>{props.invoiceData.reference}</td>
                        </tr>
                        {props.invoiceData.referringInvoiceReference && (
                            <tr>
                                <th>
                                    {t(
                                        'XRechnung.ReferringInvoiceReference.Text'
                                    )}
                                </th>
                                <td>
                                    {
                                        props.invoiceData
                                            .referringInvoiceReference
                                    }
                                </td>
                            </tr>
                        )}
                        {props.invoiceData.referringInvoiceDate && (
                            <tr>
                                <th>
                                    {t('XRechnung.ReferringInvoiceDate.Text')}
                                </th>
                                <td>
                                    {props.invoiceData.referringInvoiceDate.toLocaleDateString()}
                                </td>
                            </tr>
                        )}
                        {props.invoiceData.docDate && (
                            <tr>
                                <th>{t('XRechnung.DocDate.Text')}</th>
                                <td>
                                    {props.invoiceData.docDate.toLocaleDateString()}
                                </td>
                            </tr>
                        )}
                        {props.invoiceData.currency && (
                            <tr>
                                <th>{t('XRechnung.Currency.Text')}</th>
                                <td>{props.invoiceData.currency}</td>
                            </tr>
                        )}
                        {props.invoiceData.poNumber && (
                            <tr>
                                <th>{t('XRechnung.PoNumber.Text')}</th>
                                <td>{props.invoiceData.poNumber}</td>
                            </tr>
                        )}
                        {props.invoiceData.paymentDueDate && (
                            <tr>
                                <th>{t('XRechnung.PaymentDueDate.Text')}</th>
                                <td>
                                    {props.invoiceData.paymentDueDate.toLocaleDateString()}
                                </td>
                            </tr>
                        )}
                        {props.invoiceData.paymentDueDate && (
                            <tr>
                                <th>{t('XRechnung.PaymentTerms.Text')}</th>
                                <td>{props.invoiceData.paymentTerms}</td>
                            </tr>
                        )}
                        {props.invoiceData.invoicePeriodStartDate &&
                            props.invoiceData.invoicePeriodEndDate && (
                                <tr>
                                    <th>{t('XRechnung.InvoicePeriod.Text')}</th>
                                    <td>
                                        {props.invoiceData.invoicePeriodStartDate.toLocaleDateString()}{' '}
                                        -{' '}
                                        {props.invoiceData.invoicePeriodEndDate.toLocaleDateString()}
                                    </td>
                                </tr>
                            )}
                        {props.invoiceData.noteContent && (
                            <tr>
                                <th>{t('XRechnung.NoteContent.Text')}</th>
                                <td>{props.invoiceData.noteContent}</td>
                            </tr>
                        )}
                        {props.invoiceData.projectNumber && (
                            <tr>
                                <th>{t('XRechnung.ProjectNumber.Text')}</th>
                                <td>{props.invoiceData.projectNumber}</td>
                            </tr>
                        )}
                        {props.invoiceData.contractNumber && (
                            <tr>
                                <th>{t('XRechnung.ContractNumber.Text')}</th>
                                <td>{props.invoiceData.contractNumber}</td>
                            </tr>
                        )}
                        {props.invoiceData.orderNumberVendor && (
                            <tr>
                                <th>{t('XRechnung.OrderNumberVendor.Text')}</th>
                                <td>{props.invoiceData.orderNumberVendor}</td>
                            </tr>
                        )}
                        {props.invoiceData.tenderNumber && (
                            <tr>
                                <th>{t('XRechnung.TenderNumber.Text')}</th>
                                <td>{props.invoiceData.tenderNumber}</td>
                            </tr>
                        )}
                        {props.invoiceData.invoicedObjectReference && (
                            <tr>
                                <th>
                                    {t(
                                        'XRechnung.InvoicedObjectReference.Text'
                                    )}
                                </th>
                                <td>
                                    {props.invoiceData.invoicedObjectReference}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
