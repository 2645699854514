import {
    Button,
    Card,
    StringTools,
    TextArea,
    TextInput,
    ValidationTools,
} from '@b4valuenet/ui-react';

import { Attachment } from '../../../../../../../models/Attachment';
import { useTranslation } from 'react-i18next';

export interface IInvoiceAttachment {
    attachment: Attachment;
    isFormSubmitted: boolean;
    onChange: (updatedAttachment: Attachment) => void;
    onRemove: () => void;
}

export default function InvoiceAttachment(props: IInvoiceAttachment) {
    const { t } = useTranslation();

    return (
        <Card className={'fade-in spaced-fields'}>
            <TextInput
                type={'text'}
                icon={'file-earmark-text'}
                placeholder={t('XRechnung.AttachmentName.Text')}
                label={t('XRechnung.AttachmentName.Text')}
                value={props.attachment.name}
                readOnly={true}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.AttachmentNameHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.AttachmentNameHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.AttachmentName.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <TextInput
                type={'text'}
                icon={'hash'}
                placeholder={t('XRechnung.AttachmentIdentifier.Text')}
                label={t('XRechnung.AttachmentIdentifier.Text')}
                value={props.attachment.identifier}
                required={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.onChange({
                        ...props.attachment,
                        identifier: e.target.value,
                    });
                }}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.attachment.identifier,
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.AttachmentIdentifierHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.AttachmentIdentifierHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.AttachmentIdentifier.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <TextInput
                type={'text'}
                icon={'box'}
                placeholder={t('XRechnung.AttachmentFileSize.Text')}
                label={t('XRechnung.AttachmentFileSize.Text')}
                value={StringTools.humanSize(props.attachment.fileSize)}
                readOnly={true}
                infoButtonEnabled={true}
                infoButtonTooltipTitle={t(
                    'XRechnung.AttachmentFileSizeHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.AttachmentFileSize.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <TextArea
                icon={'card-list'}
                placeholder={t('XRechnung.AttachmentDescription.Text')}
                label={t('XRechnung.AttachmentDescription.Text')}
                value={props.attachment.description?.toString()}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    props.onChange({
                        ...props.attachment,
                        description: e.target.value,
                    });
                }}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.AttachmentDescriptionHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.AttachmentDescriptionHelpTitle.Text'
                )}
                infoButtonTooltipText={t(
                    'XRechnung.AttachmentDescription.Help'
                )}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <Button
                icon='trash'
                className='action-button'
                danger={true}
                text={t('XRechnung.RemoveAttachment.Text')}
                onClick={() => {
                    props.onRemove();
                }}
            />
        </Card>
    );
}
