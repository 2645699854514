export enum UserState {
    Inactive = 0,
    Active = 1,
    Locked = 2,
    ResetPwd = 3,
    Initial = 4,
    InitialLocked = 5,
}

export interface UsersListItem {
    id: number;
    firstName: string;
    lastName: string;
    state: number;
}
