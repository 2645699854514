import { Button, ModalWindow } from '@b4valuenet/ui-react';
import { useTranslation } from 'react-i18next';

export interface IDeleteModal {
    isActive: boolean;
    onClose: (actionConfirmed: boolean) => void;
}

export default function PeppolUnlinkDialog(props: IDeleteModal) {
    const { t } = useTranslation();

    function onClose(actionConfirmed: boolean) {
        props.onClose(actionConfirmed);
    }

    function handleCancelButtonClick() {
        onClose(false);
    }

    async function handleFormSubmit() {
        onClose(true);
    }

    return (
        <ModalWindow
            isLoading={false}
            aria-labelledby='unlink-peppol-modal-title'
            active={props.isActive}
            onSubmit={() => handleFormSubmit()}
            onEscapeKeyPressed={() => {
                onClose(false);
            }}
        >
            <h2 id='unlink-peppol-modal-title'>
                {t('ClientAdministration.UnlinkPeppolDialogTitle.Text')}
            </h2>
            <div className='modal-main'>
                {t('ClientAdministration.UnlinkPeppolDialogMessage.Text')}
            </div>
            <div className='footer'>
                <Button
                    onClick={() => handleCancelButtonClick()}
                    text={t('Common.No.Text')}
                />
                <Button
                    type='submit'
                    primary={true}
                    danger={true}
                    text={t('ClientAdministration.UnlinkPeppolSaveBtn.Text')}
                />
            </div>
        </ModalWindow>
    );
}
