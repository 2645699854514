import {
    Accordion,
    RequiredFieldsNotice,
    Select,
    TextInput,
    ValidationTools,
} from '@b4valuenet/ui-react';

import { IXRechnungSubPage } from '../../XRechnungPage';
import { PositionLine } from '../../../../../../models/PositionLine';
import { SellerInformation } from './components/SenderInformation';
import { SellerOtherPayeeDetails } from './components/SellerOtherPayeeDetails';
import { XRechnungAccordionItem } from '../../components/XRechnungAccordionItem';
import XRechnungSellerValidators from '../../validators/SellerValidators';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function XRechnungSeller(props: IXRechnungSubPage) {
    const { t } = useTranslation();

    let senderMicroenterpreneuer =
        props.data?.senderData.senderMicroenterpreneuer;

    function recursiveFillPositionsVatRate(
        positions: PositionLine[],
        fillValue: number
    ) {
        return positions.map((position) => {
            position.vatRate = -1;

            if (position.subLines?.length > 0) {
                position.subLines = recursiveFillPositionsVatRate(
                    position.subLines,
                    fillValue
                );
            }
            return position;
        });
    }

    /**
     * Check for text replacement
     */
    useEffect(() => {
        if (
            senderMicroenterpreneuer &&
            (props.data?.senderData.senderLegalEntityLegalInformation?.length ??
                0) === 0
        ) {
            if (props.data) {
                props.updateData((prevData) => {
                    let newPositions = recursiveFillPositionsVatRate(
                        prevData.positions,
                        -1
                    );
                    return {
                        ...prevData,
                        senderData: {
                            ...prevData.senderData,
                            senderLegalEntityLegalInformation: t(
                                'XRechnung.VatFreeSender.Text'
                            ),
                        },
                        positions: newPositions,
                    };
                });
            }
        } else if (
            !senderMicroenterpreneuer &&
            props.data?.senderData.senderLegalEntityLegalInformation ===
                t('XRechnung.VatFreeSender.Text')
        ) {
            props.updateData((prevData) => ({
                ...prevData,
                senderData: {
                    ...prevData.senderData,
                    senderLegalEntityLegalInformation: '',
                },
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [senderMicroenterpreneuer, t]);

    useEffect(() => {
        if (
            senderMicroenterpreneuer &&
            (props.data?.documentTotalsData.amountsTaxExemptionReason?.length ??
                0) === 0
        ) {
            if (props.data) {
                props.updateData((prevData) => ({
                    ...prevData,
                    documentTotalsData: {
                        ...prevData.documentTotalsData,
                        amountsTaxExemptionReason: t(
                            'XRechnung.VatFreeSender.Text'
                        ),
                    },
                }));
            }
        } else if (
            !senderMicroenterpreneuer &&
            props.data?.documentTotalsData.amountsTaxExemptionReason ===
                t('XRechnung.VatFreeSender.Text')
        ) {
            props.updateData((prevData) => ({
                ...prevData,
                documentTotalsData: {
                    ...prevData.documentTotalsData,
                    amountsTaxExemptionReason: '',
                },
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [senderMicroenterpreneuer, t]);

    let sellerTaxRepGroupIsNotEmpty: boolean =
        XRechnungSellerValidators.SellerTaxRepGroupIsNotEmpty(props.data);

    let pageHaveErrors = !XRechnungSellerValidators.DataValid({
        data: props.data,
        isFormSubmitted: props.isFormSubmitted,
    });

    return (
        <div className='spaced-fields'>
            <RequiredFieldsNotice
                description={t(
                    'ValidationMessage.IndicatesARequiredField.Text'
                )}
            />
            <Accordion>
                <SellerInformation
                    data={props.data}
                    isFormSubmitted={props.isFormSubmitted}
                    updateData={props.updateData}
                />
                <XRechnungAccordionItem
                    icon='people-fill'
                    title={t('XRechnung.SellerTaxRepresentative.Text')}
                    expanded={pageHaveErrors}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            <TextInput
                                type='text'
                                icon='person'
                                placeholder={t('XRechnung.Name.Text')}
                                label={t('XRechnung.Name.Text')}
                                required={sellerTaxRepGroupIsNotEmpty}
                                isValid={
                                    !sellerTaxRepGroupIsNotEmpty ||
                                    ValidationTools.isRequiredFieldValueValid(
                                        props.data?.senderData.sellerTaxRepName,
                                        props.isFormSubmitted
                                    )
                                }
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                                value={
                                    props.data?.senderData.sellerTaxRepName ||
                                    ''
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                sellerTaxRepName:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.TaxRepresentativeSellerHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.TaxRepresentativeSellerHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.TaxRepresentativeSeller.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <TextInput
                                type='text'
                                icon='bank'
                                placeholder={t('XRechnung.VatId.Text')}
                                label={t('XRechnung.VatId.Text')}
                                required={sellerTaxRepGroupIsNotEmpty}
                                isValid={
                                    !sellerTaxRepGroupIsNotEmpty ||
                                    ValidationTools.isRequiredFieldValueValid(
                                        props.data?.senderData
                                            .sellerTaxRepVatId,
                                        props.isFormSubmitted
                                    )
                                }
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                                value={
                                    props.data?.senderData.sellerTaxRepVatId ||
                                    ''
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                sellerTaxRepVatId:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.TaxRepresentativeSellerTaxIdHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.TaxRepresentativeSellerTaxIdHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.TaxRepresentativeSellerTaxId.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <TextInput
                                type='text'
                                icon='signpost'
                                placeholder={t('XRechnung.Street.Text')}
                                label={t('XRechnung.Street.Text')}
                                value={
                                    props.data?.senderData.sellerTaxRepStreet ||
                                    ''
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                sellerTaxRepStreet:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.TaxRepresentativeSellerStreetHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.TaxRepresentativeSellerStreetHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.TaxRepresentativeSellerStreet.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <TextInput
                                type='text'
                                icon='mailbox'
                                placeholder={t(
                                    'XRechnung.SenderSubLocality.Text'
                                )}
                                label={t('XRechnung.SenderSubLocality.Text')}
                                value={
                                    props.data?.senderData.sellerTaxRepName2 ||
                                    ''
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                sellerTaxRepName2:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.TaxRepresentativeSellerBoxHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.TaxRepresentativeSellerBoxHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.TaxRepresentativeSellerBox.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <div className='flex-row'>
                                <TextInput
                                    type='text'
                                    icon='mailbox'
                                    placeholder={t('XRechnung.Zip.Text')}
                                    label={t('XRechnung.Zip.Text')}
                                    value={
                                        props.data?.senderData
                                            .sellerTaxRepZip || ''
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (props.data) {
                                            props.updateData((prevData) => ({
                                                ...prevData,
                                                senderData: {
                                                    ...prevData.senderData,
                                                    sellerTaxRepZip:
                                                        e.target.value,
                                                },
                                            }));
                                        }
                                    }}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.TaxRepresentativeSellerZipHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.TaxRepresentativeSellerZipHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.TaxRepresentativeSellerZip.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                />
                                <TextInput
                                    type='text'
                                    placeholder={t('XRechnung.City.Text')}
                                    label={t('XRechnung.City.Text')}
                                    value={
                                        props.data?.senderData
                                            .sellerTaxRepCity || ''
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (props.data) {
                                            props.updateData((prevData) => ({
                                                ...prevData,
                                                senderData: {
                                                    ...prevData.senderData,
                                                    sellerTaxRepCity:
                                                        e.target.value,
                                                },
                                            }));
                                        }
                                    }}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.TaxRepresentativeSellerCityHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.TaxRepresentativeSellerCityHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.TaxRepresentativeSellerCity.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                />
                            </div>
                            <TextInput
                                type='text'
                                icon='geo-alt'
                                placeholder={t('XRechnung.Locality.Text')}
                                label={t('XRechnung.Locality.Text')}
                                value={
                                    props.data?.senderData
                                        .sellerTaxRepLocality || ''
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                sellerTaxRepLocality:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.TaxRepresentativeSellerStateHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.TaxRepresentativeSellerStateHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.TaxRepresentativeSellerState.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <Select
                                placeholder={t('XRechnung.SelectCountry.Text')}
                                icon='pin-map'
                                autoComplete='country'
                                label={t('ClientAdministration.Country.Text')}
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                sellerTaxRepCountry:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                required={sellerTaxRepGroupIsNotEmpty}
                                isValid={
                                    !sellerTaxRepGroupIsNotEmpty ||
                                    ValidationTools.isRequiredFieldValueValid(
                                        props.data?.senderData
                                            .sellerTaxRepCountry,
                                        props.isFormSubmitted
                                    )
                                }
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                                value={
                                    props.data?.senderData
                                        .sellerTaxRepCountry || undefined
                                }
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.TaxRepresentativeSellerCountryHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.TaxRepresentativeSellerCountryHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.TaxRepresentativeSellerCountry.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            >
                                <option value=''>
                                    {t('Common.NoSelection.Text')}
                                </option>
                                {props.countriesList?.map((country, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={country.code}
                                        >
                                            {country.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        </div>
                    }
                />
                <XRechnungAccordionItem
                    icon='pin-map-fill'
                    title={t('XRechnung.SenderAddress.Text')}
                    expanded={pageHaveErrors}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            <TextInput
                                type='text'
                                icon='signpost'
                                placeholder={t('XRechnung.Street.Text')}
                                label={t('XRechnung.Street.Text')}
                                value={
                                    props.data?.senderData.senderStreet || ''
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                senderStreet: e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.SenderStreetHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.SenderStreetHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.SenderStreet.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <TextInput
                                type='text'
                                icon='mailbox'
                                placeholder={t(
                                    'XRechnung.SenderSubLocality.Text'
                                )}
                                label={t('XRechnung.SenderSubLocality.Text')}
                                value={
                                    props.data?.senderData.senderSubLocality ||
                                    ''
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                senderSubLocality:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.SenderSubLocalityHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.SenderSubLocalityHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.SenderSubLocality.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <div className='flex-row'>
                                <TextInput
                                    type='text'
                                    icon='mailbox'
                                    placeholder={t('XRechnung.Zip.Text')}
                                    label={t('XRechnung.Zip.Text')}
                                    required={true}
                                    isValid={ValidationTools.isRequiredFieldValueValid(
                                        props.data?.senderData.senderZip,
                                        props.isFormSubmitted
                                    )}
                                    validationError={t(
                                        'ValidationMessage.Required.Text'
                                    )}
                                    value={
                                        props.data?.senderData.senderZip || ''
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (props.data) {
                                            props.updateData((prevData) => ({
                                                ...prevData,
                                                senderData: {
                                                    ...prevData.senderData,
                                                    senderZip: e.target.value,
                                                },
                                            }));
                                        }
                                    }}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.SenderZipHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.SenderZipHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.SenderZip.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                    combineInfoButtonTextWithTitle={true}
                                />
                                <TextInput
                                    type='text'
                                    placeholder={t('XRechnung.City.Text')}
                                    label={t('XRechnung.City.Text')}
                                    required={true}
                                    isValid={ValidationTools.isRequiredFieldValueValid(
                                        props.data?.senderData.senderCity,
                                        props.isFormSubmitted
                                    )}
                                    validationError={t(
                                        'ValidationMessage.Required.Text'
                                    )}
                                    value={
                                        props.data?.senderData.senderCity || ''
                                    }
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (props.data) {
                                            props.updateData((prevData) => ({
                                                ...prevData,
                                                senderData: {
                                                    ...prevData.senderData,
                                                    senderCity: e.target.value,
                                                },
                                            }));
                                        }
                                    }}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.SenderCityHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.SenderCityHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.SenderCity.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                    combineInfoButtonTextWithTitle={true}
                                />
                            </div>
                            <TextInput
                                type='text'
                                icon='geo-alt'
                                placeholder={t('XRechnung.Locality.Text')}
                                label={t('XRechnung.Locality.Text')}
                                value={
                                    props.data?.senderData.senderLocality || ''
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                senderLocality: e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.SenderLocalityHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.SenderLocalityHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.SenderLocality.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <Select
                                placeholder={t('XRechnung.SelectCountry.Text')}
                                icon='pin-map'
                                autoComplete='country'
                                label={t('ClientAdministration.Country.Text')}
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                senderCountry: e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                value={
                                    props.data?.senderData.senderCountry ||
                                    undefined
                                }
                                required={true}
                                isValid={ValidationTools.isRequiredFieldValueValid(
                                    props.data?.senderData.senderCountry,
                                    props.isFormSubmitted
                                )}
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.SenderCountryHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.SenderCountryHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.SenderCountry.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            >
                                <option value=''>
                                    {t('Common.NoSelection.Text')}
                                </option>
                                {props.countriesList?.map((country, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={country.code}
                                        >
                                            {country.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        </div>
                    }
                />

                <XRechnungAccordionItem
                    icon='person-lines-fill'
                    title={t('XRechnung.ContactDetails.Text')}
                    expanded={pageHaveErrors}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            <TextInput
                                type='text'
                                icon='person'
                                placeholder={t('XRechnung.ContactPerson.Text')}
                                required={true}
                                label={t('XRechnung.ContactPerson.Text')}
                                value={
                                    props.data?.senderData
                                        .contactContactPerson || ''
                                }
                                isValid={ValidationTools.isRequiredFieldValueValid(
                                    props.data?.senderData.contactContactPerson,
                                    props.isFormSubmitted
                                )}
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                contactContactPerson:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ContactPersonHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ContactPersonHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ContactPerson.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <TextInput
                                type='text'
                                autoComplete='email'
                                icon='envelope'
                                placeholder={t('XRechnung.ContactEmail.Text')}
                                required={true}
                                label={t('XRechnung.ContactEmail.Text')}
                                value={
                                    props.data?.senderData
                                        .contactContactEmail || ''
                                }
                                isValid={ValidationTools.isEmailFieldValueValid(
                                    props.data?.senderData
                                        .contactContactEmail ?? '',
                                    props.isFormSubmitted
                                )}
                                validationError={t(
                                    (props.data?.senderData?.contactContactEmail
                                        ?.length ?? 0) > 0
                                        ? 'ValidationMessage.EmailInvalid.Text'
                                        : 'ValidationMessage.Required.Text'
                                )}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                contactContactEmail:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ContactEmailHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ContactEmailHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ContactEmail.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <TextInput
                                type='tel'
                                icon='telephone'
                                placeholder={t('XRechnung.ContactPhoneNo.Text')}
                                required={true}
                                label={t('XRechnung.ContactPhoneNo.Text')}
                                value={
                                    props.data?.senderData
                                        .contactContactPhoneNo || ''
                                }
                                isValid={ValidationTools.isRequiredFieldValueValid(
                                    props.data?.senderData
                                        .contactContactPhoneNo,
                                    props.isFormSubmitted
                                )}
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            senderData: {
                                                ...prevData.senderData,
                                                contactContactPhoneNo:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ContactPhoneNoHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ContactPhoneNoHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ContactPhoneNo.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                        </div>
                    }
                />

                <SellerOtherPayeeDetails
                    payeeAddressName={
                        props.data?.senderData.payeeAddressName || ''
                    }
                    payeeIdRef={props.data?.senderData.payeeIdRef || ''}
                    payeeLegalEntityIdRef={
                        props.data?.senderData.payeeLegalEntityIdRef || ''
                    }
                    isFormSubmitted={props.isFormSubmitted}
                    onChange={(key, value) => {
                        if (props.data) {
                            props.updateData((prevData) => ({
                                ...prevData,
                                senderData: {
                                    ...prevData.senderData,
                                    [key]: value,
                                },
                            }));
                        }
                    }}
                />
            </Accordion>
        </div>
    );
}
