import { LeitwegIdSearchResult } from '../models/LeitwegIdSearchResult';
import { ReceiverData } from '../models/ReceiverData';
import { RequestTools } from '@b4valuenet/ui-react';

export default class SetupService {
    static ApiRoot = 'api/setup/';

    static search = async (query: string): Promise<LeitwegIdSearchResult[]> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'search/leitwegid/' + query
        );

        let json = await response.data;
        let result = (json.data as LeitwegIdSearchResult[]) || [];

        return result ?? [];
    };

    static requestReceiverData = async (
        leitwegId: string
    ): Promise<ReceiverData | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'search/leitwegId/receiver/' + leitwegId
        );

        let json = await response.data;
        return (json.data as ReceiverData) || null;
    };

    static initialize = async (
        dataFormat: number,
        leitwegId: string,
        messageType: number | string
    ): Promise<number | null> => {
        let response = await RequestTools.post(this.ApiRoot + 'initialize', {
            dataFormat: dataFormat,
            leitwegId: leitwegId,
            messageType: messageType,
        });

        let json = await response.data;
        let result = (json.data as number) || null;

        return result ?? null;
    };
}
