import {
    Accordion,
    Button,
    Card,
    NumberInput,
    RequiredFieldsNotice,
    TextArea,
    ValidationTools,
} from '@b4valuenet/ui-react';

import { Agio } from '../../../../../../models/Agio';
import { Allowance } from '../../../../../../models/Allowance';
import { AmountGroupData } from '../../../../../../models/AmountGroupData';
import { IXRechnungSubPage } from '../../XRechnungPage';
import InvoiceAgio from './components/InvoiceAgio';
import InvoiceAllowance from './components/InvoiceAllowance';
import InvoiceAmountGroup from './components/InvoiceAmountGroup';
import { XRechnungAccordionItem } from '../../components/XRechnungAccordionItem';
import XRechnungPagesValidator from '../../XRechnungPagesValidator';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

export function XRechnungPaymentTotals(props: IXRechnungSubPage) {
    const { t } = useTranslation();

    const appSettings = useAppSelector((state) => state.app.settings);

    let allAmountsNotShown = props.data?.documentTotalsData.amountGroups.every(
        (group: AmountGroupData) => {
            return group.vatRate === null;
        }
    );

    let exemptionReasonRequired =
        props.data?.documentTotalsData.amountGroups &&
        props.data?.documentTotalsData.amountGroups.length > 0 &&
        props.data?.documentTotalsData.amountGroups.some(
            (group) =>
                group.vatRate !== undefined &&
                group.vatRate <= -1 &&
                group.vatRate >= -5
        );

    let allowancesHaveErrors =
        !XRechnungPagesValidator.PaymentTotalsAllowancesValid({
            data: props.data,
            isFormSubmitted: props.isFormSubmitted,
        });

    let agiosHaveErrors = !XRechnungPagesValidator.PaymentTotalsAllowancesValid(
        {
            data: props.data,
            isFormSubmitted: props.isFormSubmitted,
        }
    );

    return (
        <div className='spaced-fields'>
            <RequiredFieldsNotice
                description={t(
                    'ValidationMessage.IndicatesARequiredField.Text'
                )}
            />
            <Accordion>
                <XRechnungAccordionItem
                    predefinedBT={[
                        t('XRechnung.AmountsAllowanceHelpTitle.ShortText'),
                        t(
                            'XRechnung.AmountsAllowanceTaxCategoryHelpTitle.ShortText'
                        ),
                        t('XRechnung.AmountsAllowanceBaseHelpTitle.ShortText'),
                        t('XRechnung.AmountsAllowancePerceHelpTitle.ShortText'),
                        t('XRechnung.AmountsAllowanceNameHelpTitle.ShortText'),
                    ]}
                    title={t('XRechnung.Allowances.Text')}
                    expanded={allowancesHaveErrors}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            {props.data?.documentTotalsData?.allowances?.map(
                                (allowance: Allowance, index: number) => {
                                    return (
                                        <InvoiceAllowance
                                            key={index}
                                            allowance={allowance}
                                            isFormSubmitted={
                                                props.isFormSubmitted
                                            }
                                            taxCategories={props.taxCategories}
                                            unitOptions={props.unitOptions}
                                            messageTypes={props.messageTypes}
                                            data={props.data}
                                            updateData={props.updateData}
                                            onChange={(
                                                updatedAllowance: Allowance
                                            ) => {
                                                if (props.data) {
                                                    let newAllowances = [
                                                        ...(props.data
                                                            .documentTotalsData
                                                            .allowances ?? []),
                                                    ];

                                                    newAllowances[index] =
                                                        updatedAllowance;

                                                    props.updateData(
                                                        (prevData) => ({
                                                            ...prevData,
                                                            documentTotalsData:
                                                                {
                                                                    ...prevData.documentTotalsData,
                                                                    allowances:
                                                                        newAllowances,
                                                                },
                                                        })
                                                    );
                                                }
                                            }}
                                            onRemove={() => {
                                                if (props.data) {
                                                    let newAllowances = [
                                                        ...(props.data
                                                            .documentTotalsData
                                                            .allowances ?? []),
                                                    ];

                                                    newAllowances.splice(
                                                        index,
                                                        1
                                                    );
                                                    props.updateData(
                                                        (prevData) => ({
                                                            ...prevData,
                                                            documentTotalsData:
                                                                {
                                                                    ...prevData.documentTotalsData,
                                                                    allowances:
                                                                        newAllowances,
                                                                },
                                                        })
                                                    );
                                                }
                                            }}
                                        />
                                    );
                                }
                            )}

                            {props.data?.documentTotalsData.allowances &&
                            props.data.documentTotalsData.allowances.length <
                                (appSettings?.subItemLimit ?? 100) ? (
                                <Button
                                    onClick={() => {
                                        if (props.data) {
                                            let newAllowances = [
                                                ...(props.data
                                                    .documentTotalsData
                                                    .allowances ?? []),
                                            ];

                                            newAllowances.push({
                                                vatRate: 0.0,
                                                amount: 0,
                                                baseAmount: 0,
                                                percent: 0,
                                            } as any);

                                            props.updateData((prevData) => ({
                                                ...prevData,
                                                documentTotalsData: {
                                                    ...prevData.documentTotalsData,
                                                    allowances: newAllowances,
                                                },
                                            }));
                                        }
                                    }}
                                    className='add-allowance'
                                    icon='plus-lg'
                                    primary={true}
                                    text={t(
                                        'XRechnung.AddAmountsAllowance.Text'
                                    )}
                                />
                            ) : (
                                // TODO: Alert Component
                                <p
                                    style={{
                                        textAlign: 'center',
                                        color: 'var(--ui-error)',
                                    }}
                                >
                                    {t(
                                        'XRechnung.LimitSublineItemsReached.Text'
                                    )}
                                </p>
                            )}
                        </div>
                    }
                />
                <XRechnungAccordionItem
                    predefinedBT={[
                        t('XRechnung.AmountsAgioHelpTitle.ShortText'),
                        t(
                            'XRechnung.AmountsAgioTaxCategoryHelpTitle.ShortText'
                        ),
                        t('XRechnung.AmountsAgioBaseHelpTitle.ShortText'),
                        t('XRechnung.AmountsAgioPerceHelpTitle.ShortText'),
                        t('XRechnung.AmountsAgioNameHelpTitle.ShortText'),
                    ]}
                    title={t('XRechnung.Agios.Text')}
                    expanded={agiosHaveErrors}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            {props.data?.documentTotalsData?.agios?.map(
                                (agio: Agio, index: number) => {
                                    return (
                                        <InvoiceAgio
                                            key={index}
                                            agio={agio}
                                            isFormSubmitted={
                                                props.isFormSubmitted
                                            }
                                            taxCategories={props.taxCategories}
                                            unitOptions={props.unitOptions}
                                            messageTypes={props.messageTypes}
                                            data={props.data}
                                            updateData={props.updateData}
                                            onChange={(updatedAgio: Agio) => {
                                                if (props.data) {
                                                    props.updateData(
                                                        (prevData) => {
                                                            let newAgios = [
                                                                ...(prevData
                                                                    .documentTotalsData
                                                                    .agios ??
                                                                    []),
                                                            ];

                                                            newAgios[index] =
                                                                updatedAgio;

                                                            return {
                                                                ...prevData,
                                                                documentTotalsData:
                                                                    {
                                                                        ...prevData.documentTotalsData,
                                                                        agios: newAgios,
                                                                    },
                                                            };
                                                        }
                                                    );
                                                }
                                            }}
                                            onRemove={() => {
                                                if (props.data) {
                                                    props.updateData(
                                                        (prevData) => {
                                                            let newAgios = [
                                                                ...(prevData
                                                                    .documentTotalsData
                                                                    .agios ??
                                                                    []),
                                                            ];

                                                            newAgios.splice(
                                                                index,
                                                                1
                                                            );

                                                            return {
                                                                ...prevData,
                                                                documentTotalsData:
                                                                    {
                                                                        ...prevData.documentTotalsData,
                                                                        agios: newAgios,
                                                                    },
                                                            };
                                                        }
                                                    );
                                                }
                                            }}
                                        />
                                    );
                                }
                            )}

                            {props.data?.documentTotalsData.agios &&
                            props.data.documentTotalsData.agios.length <
                                (appSettings?.subItemLimit ?? 100) ? (
                                <Button
                                    onClick={() => {
                                        if (props.data) {
                                            props.updateData((prevData) => {
                                                let newAgios = [
                                                    ...(prevData
                                                        .documentTotalsData
                                                        .agios ?? []),
                                                ];

                                                newAgios.push({
                                                    vatRate: 0.0,
                                                    amount: 0,
                                                    baseAmount: 0,
                                                    percent: 0,
                                                } as any);

                                                return {
                                                    ...prevData,
                                                    documentTotalsData: {
                                                        ...prevData.documentTotalsData,
                                                        agios: newAgios,
                                                    },
                                                };
                                            });
                                        }
                                    }}
                                    className='add-allowance'
                                    icon='plus-lg'
                                    primary={true}
                                    text={t('XRechnung.AddAmountsAgio.Text')}
                                />
                            ) : (
                                // TODO: Alert Component
                                <p
                                    style={{
                                        textAlign: 'center',
                                        color: 'var(--ui-error)',
                                    }}
                                >
                                    {t(
                                        'XRechnung.LimitSublineItemsReached.Text'
                                    )}
                                </p>
                            )}
                        </div>
                    }
                />
            </Accordion>

            <Card title={t('XRechnung.AmountGroupsHeader.Text')}>
                {allAmountsNotShown && (
                    <p>{t('XRechnung.AmountsAllZero.Help')}</p>
                )}

                {!allAmountsNotShown &&
                    props.data?.documentTotalsData.amountGroups.map(
                        (group, index) => {
                            return (
                                <InvoiceAmountGroup
                                    key={index}
                                    group={group}
                                    isFormSubmitted={props.isFormSubmitted}
                                    unitOptions={props.unitOptions}
                                    taxCategories={props.taxCategories}
                                    messageTypes={props.messageTypes}
                                    data={props.data}
                                    updateData={props.updateData}
                                    onChange={(updatedGroup) => {
                                        if (props.data) {
                                            props.updateData((prevData) => {
                                                let newAmountGroups = [
                                                    ...(prevData
                                                        .documentTotalsData
                                                        .amountGroups ?? []),
                                                ];

                                                newAmountGroups[index] =
                                                    updatedGroup;

                                                return {
                                                    ...prevData,
                                                    documentTotalsData: {
                                                        ...prevData.documentTotalsData,
                                                        amountGroups:
                                                            newAmountGroups,
                                                    },
                                                };
                                            });
                                        }
                                    }}
                                />
                            );
                        }
                    )}

                {!allAmountsNotShown && (
                    <TextArea
                        icon={'card-list'}
                        placeholder={t(
                            'XRechnung.AmountsTaxExemptionReason.Text'
                        )}
                        label={t('XRechnung.AmountsTaxExemptionReason.Text')}
                        value={
                            props.data?.documentTotalsData
                                .amountsTaxExemptionReason || undefined
                        }
                        rows={4}
                        maxLength={250}
                        onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                            if (props.data) {
                                props.updateData((prevData) => {
                                    return {
                                        ...prevData,
                                        documentTotalsData: {
                                            ...prevData.documentTotalsData,
                                            amountsTaxExemptionReason:
                                                e.target.value,
                                        },
                                    };
                                });
                            }
                        }}
                        required={exemptionReasonRequired}
                        isValid={
                            !exemptionReasonRequired ||
                            ValidationTools.isRequiredFieldValueValid(
                                props.data?.documentTotalsData
                                    .amountsTaxExemptionReason,
                                props.isFormSubmitted
                            )
                        }
                        validationError={t('ValidationMessage.Required.Text')}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.AmountsTaxExemptionReasonHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.AmountsTaxExemptionReasonHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.AmountsTaxExemptionReason.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                )}
            </Card>

            <fieldset className='spaced-fields'>
                <legend>{t('XRechnung.Amounts.Text')}</legend>
                <div className='flex-row'>
                    <NumberInput
                        min={
                            -+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.data?.documentTotalsData.amountsItemAmount?.toString(),
                            props.isFormSubmitted
                        )}
                        validationError={t('ValidationMessage.Required.Text')}
                        placeholder={t('XRechnung.AmountsItemAmount.Text')}
                        label={t('XRechnung.AmountsItemAmount.Text')}
                        value={props.data?.documentTotalsData.amountsItemAmount}
                        onChange={(_e, rawValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    documentTotalsData: {
                                        ...prevData.documentTotalsData,
                                        amountsItemAmount: rawValue,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.AmountsItemAmountHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.AmountsItemAmountHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.AmountsItemAmount.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />

                    <NumberInput
                        min={
                            -+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        placeholder={t('XRechnung.SumAllowance.Text')}
                        label={t('XRechnung.SumAllowance.Text')}
                        value={props.data?.documentTotalsData.sumAllowance}
                        onChange={(_e, rawValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    documentTotalsData: {
                                        ...prevData.documentTotalsData,
                                        sumAllowance: rawValue,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.SumAllowanceHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.SumAllowanceHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t('XRechnung.SumAllowance.Help')}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />

                    <NumberInput
                        min={
                            -+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        placeholder={t('XRechnung.SumCharges.Text')}
                        label={t('XRechnung.SumCharges.Text')}
                        value={props.data?.documentTotalsData.sumCharges}
                        onChange={(_e, rawValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    documentTotalsData: {
                                        ...prevData.documentTotalsData,
                                        sumCharges: rawValue,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.SumChargesHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.SumChargesHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t('XRechnung.SumCharges.Help')}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                </div>

                <div className='flex-row'>
                    <NumberInput
                        min={
                            -+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.data?.documentTotalsData.totalNetAmount?.toString(),
                            props.isFormSubmitted
                        )}
                        validationError={t('ValidationMessage.Required.Text')}
                        placeholder={t('XRechnung.AmountsNetAmount.Text')}
                        label={t('XRechnung.AmountsNetAmount.Text')}
                        value={props.data?.documentTotalsData.totalNetAmount}
                        onChange={(_e, rawValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    documentTotalsData: {
                                        ...prevData.documentTotalsData,
                                        totalNetAmount: rawValue,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.AmountsNetAmountHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.AmountsNetAmountHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.AmountsNetAmount.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                    <NumberInput
                        min={
                            -+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.data?.documentTotalsData.totalVatAmount?.toString(),
                            props.isFormSubmitted
                        )}
                        validationError={t('ValidationMessage.Required.Text')}
                        placeholder={t('XRechnung.AmountsVatAmount.Text')}
                        label={t('XRechnung.AmountsVatAmount.Text')}
                        value={props.data?.documentTotalsData.totalVatAmount}
                        onChange={(_e, rawValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    documentTotalsData: {
                                        ...prevData.documentTotalsData,
                                        totalVatAmount: rawValue,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.AmountsVatAmountHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.AmountsVatAmountHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.AmountsVatAmount.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                        decimalPlacesShownOnBlur={2}
                        decimalPlacesShownOnFocus={13}
                    />

                    <NumberInput
                        min={
                            -+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.data?.documentTotalsData.total?.toString(),
                            props.isFormSubmitted
                        )}
                        validationError={t('ValidationMessage.Required.Text')}
                        placeholder={t('XRechnung.Total.Text')}
                        label={t('XRechnung.Total.Text')}
                        value={props.data?.documentTotalsData.total}
                        onChange={(_e, rawValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    documentTotalsData: {
                                        ...prevData.documentTotalsData,
                                        total: rawValue,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t('XRechnung.TotalHelpTitle.ShortText')}
                        infoButtonTooltipTitle={t(
                            'XRechnung.TotalHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t('XRechnung.Total.Help')}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                </div>

                <div className='flex-row'>
                    <NumberInput
                        min={
                            -+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        placeholder={t('XRechnung.AmountsPrepaidAmount.Text')}
                        label={t('XRechnung.AmountsPrepaidAmount.Text')}
                        value={
                            props.data?.documentTotalsData.amountsPrepaidAmount
                        }
                        onChange={(_e, rawValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    documentTotalsData: {
                                        ...prevData.documentTotalsData,
                                        amountsPrepaidAmount: rawValue,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.AmountsPrepaidAmountHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.AmountsPrepaidAmountHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.AmountsPrepaidAmount.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                        maxPrecision={2}
                    />

                    <NumberInput
                        min={
                            -+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        placeholder={t('XRechnung.AmountsRoundingAmount.Text')}
                        label={t('XRechnung.AmountsRoundingAmount.Text')}
                        value={
                            props.data?.documentTotalsData.amountsRoundingAmount
                        }
                        onChange={(_e, rawValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    documentTotalsData: {
                                        ...prevData.documentTotalsData,
                                        amountsRoundingAmount: rawValue,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.AmountsRoundingAmountHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.AmountsRoundingAmountHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.AmountsRoundingAmount.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                        maxPrecision={2}
                    />

                    <NumberInput
                        min={
                            -+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.data?.documentTotalsData.amount?.toString(),
                            props.isFormSubmitted
                        )}
                        validationError={t('ValidationMessage.Required.Text')}
                        placeholder={t('XRechnung.Amount.Text')}
                        label={t('XRechnung.Amount.Text')}
                        value={props.data?.documentTotalsData.amount}
                        onChange={(_e, rawValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    documentTotalsData: {
                                        ...prevData.documentTotalsData,
                                        amount: rawValue,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.AmountHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.AmountHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t('XRechnung.Amount.Help')}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                        maxPrecision={2}
                    />
                </div>
            </fieldset>
        </div>
    );
}
