import './XRechnungAccordionItem.scss';

import { AccordionItem, IAccordionItem } from '@b4valuenet/ui-react';
import { createRef, useEffect, useState } from 'react';

export interface XRechnungAccordionItemProps {
    predefinedBT?: string[];
}
export function XRechnungAccordionItem(
    props: IAccordionItem & XRechnungAccordionItemProps
) {
    const generatedRef = createRef<HTMLDivElement>();

    const overridedProps = { ...props } as any;
    delete overridedProps.title;

    let ref = props.itemRef ?? generatedRef;

    const [summary, setSummary] = useState<string>(
        props.predefinedBT ? props.predefinedBT.join(', ') : ''
    );

    useEffect(() => {
        if (ref.current) {
            let findedBT: string[] = props.predefinedBT
                ? props.predefinedBT
                : [];

            let btInfoItems = ref.current.querySelectorAll(
                '.info-button-wrapper button span'
            );

            btInfoItems?.forEach((item) => {
                let cleanValue = item.innerHTML.trim();

                if (!findedBT.includes(cleanValue)) {
                    findedBT.push(cleanValue);
                }
            });

            setSummary(findedBT.join(', '));
        }
    }, [props.predefinedBT, ref]);

    return (
        <div className='xrechnung-accordion-item-wrapper'>
            <AccordionItem
                title={
                    <>
                        <span className='title-main'>{props.title}</span>
                        <span className='subtitle'>{summary}</span>
                    </>
                }
                itemRef={ref}
                {...overridedProps}
            />
        </div>
    );
}
