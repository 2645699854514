import { Button, Dialog } from '@b4valuenet/ui-react';

import { useTranslation } from 'react-i18next';

export interface ISchematronValidationErrorDialog {
    active: boolean;
    onClose: CallableFunction;
    schematronErrors: string[];
}

export default function SchematronValidationErrorDialog(
    props: ISchematronValidationErrorDialog
) {
    const { t } = useTranslation();

    return (
        <Dialog
            aria-labelledby='schematron-validation-modal-title'
            closeButtonTitle={t('Common.Close.Text')}
            active={props.active}
            onClose={() => props.onClose()}
        >
            <h2 id='schematron-validation-modal-title'>
                {t('UserFeedback.XRechnungValidationFailed.Text')}
            </h2>
            <div className='modal-main'>
                {props.schematronErrors.map((error, index) => (
                    <p key={index}>{error}</p>
                ))}
            </div>
            <div className='footer'>
                <Button
                    onClick={() => props.onClose()}
                    text={t('Common.Close.Text')}
                />
            </div>
        </Dialog>
    );
}
