import {
    Accordion,
    AccordionItem,
    Button,
    Card,
    NumberInput,
    Select,
    SmoothVisibility,
    TextArea,
    TextInput,
    Toast,
    ValidationTools,
    useDebounce,
} from '@b4valuenet/ui-react';
import React, { createRef, useEffect, useState } from 'react';

import { IXRechnungSubPage } from '../../../XRechnungPage';
import InvoiceRebateGroup from './InvoiceRebateGroup';
import InvoiceSurchargeGroup from './InvoiceSurchargeGroup';
import { KeyValuePair } from '../../../../../../../models/KeyValuePair';
import { PositionLine } from '../../../../../../../models/PositionLine';
import { RebateGroup } from '../../../../../../../models/RebateGroup';
import { SurchargeGroup } from '../../../../../../../models/SurchargeGroup';
import { XRechnungAccordionItem } from '../../../components/XRechnungAccordionItem';
import XRechnungPagesValidator from '../../../XRechnungPagesValidator';
import { useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

export interface IInvoicePositionLine {
    line: PositionLine;
    unitOptions: KeyValuePair[];
    taxCategories: KeyValuePair[];
    isFormSubmitted: boolean;
    isSubLine?: boolean;
    parentVatRate?: string;
    recursionLevel?: number;
    senderMicroenterpreneuer: boolean;
    onChange: (updatedLine: PositionLine) => void;
    onRemove: () => void;
}

export default function InvoicePositionLine({
    recursionLevel = 0,
    ...props
}: IInvoicePositionLine & IXRechnungSubPage) {
    const { t } = useTranslation();

    const appSettings = useAppSelector((state) => state.app.settings);

    let sumRebate = (props.line.rebateGroups ?? []).reduce(
        (accumulator, group) => accumulator + (group.amount ?? 0),
        0
    );
    let sumSurcharges = (props.line.surchargeGroups ?? []).reduce(
        (accumulator, group) => accumulator + (group.amount ?? 0),
        0
    );

    const firstInputRef = createRef<HTMLInputElement>();
    const accordionRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (firstInputRef && recursionLevel === 0) {
            firstInputRef.current?.focus();
        }

        if (accordionRef && recursionLevel > 0) {
            accordionRef.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let itemPriceBaseQuantity = 1;

        let round =
            (props.line.quantity ?? 0) *
                ((props.line.price ?? 0) / itemPriceBaseQuantity) -
            sumRebate +
            sumSurcharges;

        round = Math.round(round * 100) / 100;

        props.onChange({
            ...props.line,
            value: round,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.line.quantity, props.line.price, sumRebate, sumSurcharges]);

    let pageHaveErrors = !XRechnungPagesValidator.InvoiceLineValid({
        line: props.line,
        isRecursive: recursionLevel > 0,
        senderMicroenterpreneuer: props.senderMicroenterpreneuer,
        isFormSubmitted: props.isFormSubmitted,
    });

    let vatRateValue: any = props.senderMicroenterpreneuer
        ? props.taxCategories.find((taxCat) => parseInt(taxCat.key) === -1)
              ?.value
        : props.parentVatRate || (props.line.vatRate?.toString() ?? undefined);

    let vatRateValueNum = vatRateValue ? +vatRateValue : -100;

    const [customVatRateVisible, setCustomVatRateVisible] =
        useState<boolean>(false);

    useDebounce(
        () => {
            let visible =
                !!vatRateValue &&
                (vatRateValueNum === -6 ||
                    vatRateValue === '' ||
                    !props.taxCategories.find(
                        (tc) => +tc.key === vatRateValueNum
                    )) &&
                !props.senderMicroenterpreneuer;

            setCustomVatRateVisible(visible);
        },
        [
            props.senderMicroenterpreneuer,
            props.parentVatRate,
            props.line.vatRate,
        ],
        1000
    );

    return (
        <Card className='fade-in'>
            <div className='spaced-fields'>
                <div className='flex-row'>
                    <TextInput
                        inputRef={firstInputRef}
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.line.positionNo,
                            props.isFormSubmitted
                        )}
                        validationError={t('ValidationMessage.Required.Text')}
                        type='text'
                        icon='hash'
                        placeholder={t('XRechnung.PositionNo.Text')}
                        label={t('XRechnung.PositionNo.Text')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let updatedLine = { ...props.line } as PositionLine;
                            updatedLine.positionNo = e.target.value;
                            props.onChange(updatedLine);
                        }}
                        value={props.line.positionNo}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.PositionNoHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.PositionNoHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t('XRechnung.PositionNo.Help')}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />

                    <TextInput
                        type='text'
                        icon='hash'
                        placeholder={t('XRechnung.ArticleNoSupplier.Text')}
                        label={t('XRechnung.ArticleNoSupplier.Text')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let updatedLine = { ...props.line } as PositionLine;
                            updatedLine.articleNoSupplier = e.target.value;
                            props.onChange(updatedLine);
                        }}
                        value={props.line.articleNoSupplier}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.ArticleNoSupplierHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.ArticleNoSupplierHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.ArticleNoSupplier.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                    <TextInput
                        type='text'
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.line.articleDescription,
                            props.isFormSubmitted
                        )}
                        validationError={t('ValidationMessage.Required.Text')}
                        icon='tag'
                        placeholder={t('XRechnung.ArticleDescription.Text')}
                        label={t('XRechnung.ArticleDescription.Text')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let updatedLine = { ...props.line } as PositionLine;
                            updatedLine.articleDescription = e.target.value;
                            props.onChange(updatedLine);
                        }}
                        value={props.line.articleDescription}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.ArticleDescriptionHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.ArticleDescriptionHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.ArticleDescription.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                </div>

                <TextArea
                    icon='card-list'
                    placeholder={t('XRechnung.ArticleDescription2.Text')}
                    label={t('XRechnung.ArticleDescription2.Text')}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        let updatedLine = { ...props.line } as PositionLine;
                        updatedLine.articleDescription2 = e.target.value;
                        props.onChange(updatedLine);
                    }}
                    value={props.line.articleDescription2 || ''}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.ArticleDescription2HelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.ArticleDescription2HelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.ArticleDescription2.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />

                <div className='flex-row'>
                    <NumberInput
                        min={
                            -(process.env.REACT_APP_MAX_NUM ?? -999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        maxPrecision={15}
                        placeholder={'0,0000'}
                        label={t('XRechnung.PositionQuantity.Text')}
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.line.quantity !== null ? 'not empty' : null,
                            props.isFormSubmitted
                        )}
                        validationError={t('ValidationMessage.Required.Text')}
                        onChange={(_e, rawValue) => {
                            let updatedLine = { ...props.line } as PositionLine;
                            updatedLine.quantity = rawValue;
                            props.onChange(updatedLine);
                        }}
                        value={props.line.quantity}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.PositionQuantityHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.PositionQuantityHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.PositionQuantity.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                    <Select
                        placeholder={t('XRechnung.PositionUnit.Text')}
                        label={t('XRechnung.PositionUnit.Text')}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            let updatedLine = { ...props.line } as PositionLine;
                            updatedLine.unit = e.target.value;
                            props.onChange(updatedLine);
                        }}
                        value={props.line.unit || undefined}
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.line.unit,
                            props.isFormSubmitted
                        )}
                        validationError={t('ValidationMessage.Required.Text')}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.PositionUnitHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.PositionUnitHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t('XRechnung.PositionUnit.Help')}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    >
                        {props.unitOptions.map((option) => {
                            return (
                                <option key={option.key} value={option.key}>
                                    {option.value}
                                </option>
                            );
                        })}
                    </Select>
                </div>

                <div className='flex-row'>
                    <NumberInput
                        min={0}
                        maxPrecision={15}
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.line.price !== null &&
                                props.line.price !== undefined &&
                                props.line.price > 0
                                ? 'not empty'
                                : null,
                            props.isFormSubmitted
                        )}
                        validationError={
                            props.line.price === null
                                ? t('ValidationMessage.Required.Text')
                                : t(
                                      'ValidationMessage.MustBeGreaterThanZero.Text'
                                  )
                        }
                        placeholder={'0,0000'}
                        label={t('XRechnung.PositionPrice.Text')}
                        onChange={(_e, rawValue) => {
                            let updatedLine = { ...props.line } as PositionLine;
                            updatedLine.price = rawValue;
                            props.onChange(updatedLine);
                        }}
                        value={props.line.price}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.PositionPriceHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.PositionPriceHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.PositionPrice.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />

                    <Select
                        placeholder={t('XRechnung.PositionTaxCategory.Text')}
                        label={t('XRechnung.PositionTaxCategory.Text')}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            let updatedLine = { ...props.line } as PositionLine;
                            updatedLine.vatRate = +e.target.value;

                            let visible =
                                (updatedLine.vatRate === -6 ||
                                    e.target.value === '' ||
                                    !props.taxCategories.find(
                                        (tc) => +tc.key === updatedLine.vatRate
                                    )) &&
                                !props.senderMicroenterpreneuer;
                            if (visible) {
                                setCustomVatRateVisible(true);
                            } else {
                                setCustomVatRateVisible(false);
                            }

                            let updatedSubLines = [...props.line.subLines];
                            updatedSubLines.forEach((subLine) => {
                                subLine.vatRate = updatedLine.vatRate;
                            });

                            updatedLine.subLines = updatedSubLines;

                            props.onChange(updatedLine);
                        }}
                        disabled={props.senderMicroenterpreneuer}
                        required={true}
                        isValid={
                            props.parentVatRate !== undefined ||
                            props.senderMicroenterpreneuer ||
                            ValidationTools.isRequiredFieldValueValid(
                                props.line.vatRate?.toString() || undefined,
                                props.isFormSubmitted
                            )
                        }
                        validationError={t('ValidationMessage.Required.Text')}
                        infoButtonEnabled={!customVatRateVisible}
                        infoButtonText={t(
                            'XRechnung.PositionTaxCategoryHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.PositionTaxCategoryHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.PositionTaxCategory.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    >
                        {props.taxCategories.map((option) => {
                            return (
                                <option
                                    disabled={
                                        props.parentVatRate !== undefined &&
                                        props.parentVatRate !== option.key
                                    }
                                    key={option.key}
                                    value={option.key}
                                    selected={
                                        customVatRateVisible &&
                                        +option.key === -6
                                            ? true
                                            : vatRateValueNum === +option.key &&
                                              !customVatRateVisible
                                            ? true
                                            : undefined
                                    }
                                >
                                    {option.value}
                                </option>
                            );
                        })}
                    </Select>
                    <SmoothVisibility visible={customVatRateVisible}>
                        <NumberInput
                            icon='percent'
                            placeholder={t(
                                'XRechnung.PositionTaxCategory.Text'
                            )}
                            label={t('XRechnung.PositionTaxCategory.Text')}
                            required={true}
                            infoButtonEnabled={customVatRateVisible}
                            infoButtonText={t(
                                'XRechnung.PositionTaxCategoryHelpTitle.ShortText'
                            )}
                            infoButtonTooltipTitle={t(
                                'XRechnung.PositionTaxCategoryHelpTitle.Text'
                            )}
                            infoButtonTooltipText={t(
                                'XRechnung.PositionTaxCategory.Help'
                            )}
                            infoButtonTitle={t('Common.InputHelp.Text')}
                            infoButtonAriaLabel={t('Common.InputHelp.Text')}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                let updatedLine = {
                                    ...props.line,
                                } as PositionLine;

                                updatedLine.vatRate = +e.target.value.replace(
                                    ',',
                                    '.'
                                );

                                if (e.target.value.length === 0) {
                                    updatedLine.vatRate = -6;
                                }

                                let updatedSubLines = [...props.line.subLines];
                                updatedSubLines.forEach((subLine) => {
                                    subLine.vatRate = updatedLine.vatRate;
                                });

                                updatedLine.subLines = updatedSubLines;

                                props.onChange(updatedLine);
                            }}
                            value={vatRateValueNum}
                            isValid={
                                props.parentVatRate !== undefined ||
                                props.senderMicroenterpreneuer ||
                                ValidationTools.isRequiredFieldValueValid(
                                    (props.line?.vatRate &&
                                    props.line.vatRate > 0
                                        ? 'not empty'
                                        : undefined) || undefined,
                                    props.isFormSubmitted
                                )
                            }
                            validationError={t(
                                'ValidationMessage.Required.Text'
                            )}
                            min={0}
                        />
                    </SmoothVisibility>

                    <NumberInput
                        min={
                            -+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        placeholder={'0,00'}
                        label={t('XRechnung.PositionValue.Text')}
                        onChange={(_e, rawValue) => {
                            let updatedLine = { ...props.line } as PositionLine;
                            updatedLine.value = rawValue || undefined;
                            props.onChange(updatedLine);
                        }}
                        value={props.line.value}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (props.line.value && e.target.value) {
                                let updatedLine = {
                                    ...props.line,
                                } as PositionLine;

                                let numValue = +(e.target.value.replaceAll(',', '.'));

                                updatedLine.value =
                                    Math.round(numValue * 100) / 100;


                                props.onChange(updatedLine);
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.PositionValueHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.PositionValueHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.PositionValue.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                </div>

                <Accordion>
                    <XRechnungAccordionItem
                        title={t('XRechnung.FurtherPositionData.Text')}
                        expanded={false}
                        textWhenErrorsArePresent={t(
                            'ValidationMessage.ErrorsPresent.Text'
                        )}
                        body={
                            <div className='flex-row'>
                                <TextInput
                                    type='text'
                                    placeholder={t(
                                        'XRechnung.PositionCostCenter.Text'
                                    )}
                                    label={t(
                                        'XRechnung.PositionCostCenter.Text'
                                    )}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        let updatedLine = {
                                            ...props.line,
                                        } as PositionLine;
                                        updatedLine.costCenter = e.target.value;
                                        props.onChange(updatedLine);
                                    }}
                                    value={props.line.costCenter}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.PositionCostCenterHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.PositionCostCenterHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.PositionCostCenter.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                />
                                <NumberInput
                                    placeholder={t(
                                        'XRechnung.PositionPoPos.Text'
                                    )}
                                    label={t('XRechnung.PositionPoPos.Text')}
                                    onChange={(_e, rawValue) => {
                                        let updatedLine = {
                                            ...props.line,
                                        } as PositionLine;
                                        updatedLine.poPos = rawValue;
                                        props.onChange(updatedLine);
                                    }}
                                    value={props.line.poPos}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.PositionPoPosHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.PositionPoPosHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.PositionPoPos.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                />
                                <NumberInput
                                    placeholder={t(
                                        'XRechnung.ArticleNoBuyer.Text'
                                    )}
                                    label={t('XRechnung.ArticleNoBuyer.Text')}
                                    onChange={(_e, rawValue) => {
                                        let updatedLine = {
                                            ...props.line,
                                        } as PositionLine;
                                        updatedLine.articleNoBuyer =
                                            rawValue ?? '';
                                        props.onChange(updatedLine);
                                    }}
                                    value={props.line.articleNoBuyer}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.ArticleNoBuyerHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.ArticleNoBuyerHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.ArticleNoBuyer.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                />
                            </div>
                        }
                    />

                    <XRechnungAccordionItem
                        title={t('XRechnung.PositionRebateGroups.Text')}
                        expanded={pageHaveErrors}
                        textWhenErrorsArePresent={t(
                            'ValidationMessage.ErrorsPresent.Text'
                        )}
                        predefinedBT={[
                            t('XRechnung.RebateGroupNameHelpTitle.ShortText'),
                            t(
                                'XRechnung.RebateGroupBaseAmountHelpTitle.ShortText'
                            ),
                            t(
                                'XRechnung.RebateGroupPercentHelpTitle.ShortText'
                            ),
                            t('XRechnung.RebateGroupAmountHelpTitle.ShortText'),
                        ]}
                        body={
                            <div className='spaced-fields'>
                                {props.line.rebateGroups?.map(
                                    (group: RebateGroup, i: number) => {
                                        return (
                                            <InvoiceRebateGroup
                                                key={i}
                                                isFormSubmitted={
                                                    props.isFormSubmitted
                                                }
                                                group={group}
                                                onChange={(
                                                    updatedGroup: RebateGroup
                                                ) => {
                                                    let updatedLine = {
                                                        ...props.line,
                                                    } as PositionLine;

                                                    let updatedRebateGroups = [
                                                        ...updatedLine.rebateGroups,
                                                    ];

                                                    updatedRebateGroups[i] =
                                                        updatedGroup;
                                                    updatedLine.rebateGroups =
                                                        updatedRebateGroups;

                                                    props.onChange(updatedLine);
                                                }}
                                                onRemove={() => {
                                                    let updatedLine = {
                                                        ...props.line,
                                                    } as PositionLine;

                                                    let updatedRebateGroups = [
                                                        ...updatedLine.rebateGroups,
                                                    ];

                                                    updatedRebateGroups.splice(
                                                        i,
                                                        1
                                                    );

                                                    updatedLine.rebateGroups =
                                                        updatedRebateGroups;

                                                    props.onChange(updatedLine);
                                                }}
                                            />
                                        );
                                    }
                                )}

                                {props.line?.rebateGroups &&
                                props.line.rebateGroups.length <
                                    (appSettings?.subItemLimit ?? 100) ? (
                                    <Button
                                        onClick={() => {
                                            let updatedLine = {
                                                ...props.line,
                                            } as PositionLine;

                                            let updatedRebateGroups = [
                                                ...(updatedLine.rebateGroups ??
                                                    []),
                                            ];

                                            updatedRebateGroups.push({} as any);
                                            updatedLine.rebateGroups =
                                                updatedRebateGroups;

                                            props.onChange(updatedLine);
                                        }}
                                        primary={true}
                                        className={
                                            'action-buttons add-invoice-line-item-rebate'
                                        }
                                        text={t(
                                            'XRechnung.AddRebateGroup.Text'
                                        )}
                                        icon={'plus-lg'}
                                    />
                                ) : (
                                    // TODO: Alert Component
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            color: 'var(--ui-error)',
                                        }}
                                    >
                                        {t(
                                            'XRechnung.LimitSublineItemsReached.Text'
                                        )}
                                    </p>
                                )}
                            </div>
                        }
                    />

                    <XRechnungAccordionItem
                        title={t('XRechnung.PositionSurchargeGroups.Text')}
                        expanded={pageHaveErrors}
                        predefinedBT={[
                            t(
                                'XRechnung.SurchargeGroupNameHelpTitle.ShortText'
                            ),
                            t(
                                'XRechnung.SurchargeGroupBaseAmountHelpTitle.ShortText'
                            ),
                            t(
                                'XRechnung.SurchargeGroupPercentHelpTitle.ShortText'
                            ),
                            t(
                                'XRechnung.SurchargeGroupAmountHelpTitle.ShortText'
                            ),
                        ]}
                        textWhenErrorsArePresent={t(
                            'ValidationMessage.ErrorsPresent.Text'
                        )}
                        body={
                            <div className='spaced-fields'>
                                {props.line.surchargeGroups?.map(
                                    (group: SurchargeGroup, i: number) => {
                                        return (
                                            <InvoiceSurchargeGroup
                                                key={i}
                                                isFormSubmitted={
                                                    props.isFormSubmitted
                                                }
                                                group={group}
                                                onChange={(
                                                    updatedGroup: RebateGroup
                                                ) => {
                                                    let updatedLine = {
                                                        ...props.line,
                                                    } as PositionLine;

                                                    let updatedSurchargeGroups =
                                                        [
                                                            ...updatedLine.surchargeGroups,
                                                        ];

                                                    updatedSurchargeGroups[i] =
                                                        updatedGroup;
                                                    updatedLine.surchargeGroups =
                                                        updatedSurchargeGroups;

                                                    props.onChange(updatedLine);
                                                }}
                                                onRemove={() => {
                                                    let updatedLine = {
                                                        ...props.line,
                                                    } as PositionLine;

                                                    let updatedSurchargeGroups =
                                                        [
                                                            ...updatedLine.surchargeGroups,
                                                        ];

                                                    updatedSurchargeGroups.splice(
                                                        i,
                                                        1
                                                    );
                                                    updatedLine.surchargeGroups =
                                                        updatedSurchargeGroups;

                                                    props.onChange(updatedLine);
                                                }}
                                            />
                                        );
                                    }
                                )}

                                {props.line?.surchargeGroups &&
                                props.line.surchargeGroups.length <
                                    (appSettings?.subItemLimit ?? 100) ? (
                                    <Button
                                        onClick={() => {
                                            let updatedLine = {
                                                ...props.line,
                                            } as PositionLine;

                                            let updatedSurchargeGroups = [
                                                ...(updatedLine.surchargeGroups ??
                                                    []),
                                            ];

                                            updatedSurchargeGroups.push(
                                                {} as any
                                            );
                                            updatedLine.surchargeGroups =
                                                updatedSurchargeGroups;

                                            props.onChange(updatedLine);
                                        }}
                                        primary={true}
                                        className={
                                            'action-buttons add-invoice-line-item-surcharge'
                                        }
                                        text={t(
                                            'XRechnung.AddSurchargeGroup.Text'
                                        )}
                                        icon={'plus-lg'}
                                    />
                                ) : (
                                    // TODO: Alert Component
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            color: 'var(--ui-error)',
                                        }}
                                    >
                                        {t(
                                            'XRechnung.LimitSublineItemsReached.Text'
                                        )}
                                    </p>
                                )}
                            </div>
                        }
                    />
                </Accordion>
                <Accordion>
                    {props.line.subLines?.map(
                        (subLine: PositionLine, index: number) => {
                            let subPageHaveErrors =
                                !XRechnungPagesValidator.InvoiceLineValid({
                                    line: subLine,
                                    senderMicroenterpreneuer:
                                        props.senderMicroenterpreneuer,
                                    isRecursive: true,
                                    isFormSubmitted: props.isFormSubmitted,
                                });

                            return (
                                <AccordionItem
                                    key={index}
                                    title={
                                        <>
                                            <strong>
                                                {t(
                                                    'XRechnung.PositionNo.Text'
                                                ) + ':'}
                                                &nbsp;
                                            </strong>
                                            {subLine.positionNo ?? ''}
                                            <Button
                                                onClick={(
                                                    e: React.MouseEvent
                                                ) => {
                                                    e.preventDefault();

                                                    let updatedSubLines = [
                                                        ...props.line.subLines,
                                                    ];

                                                    updatedSubLines.splice(
                                                        index,
                                                        1
                                                    );

                                                    props.onChange({
                                                        ...props.line,
                                                        subLines:
                                                            updatedSubLines,
                                                    });

                                                    Toast.success(
                                                        t(
                                                            'UserFeedback.SuccessfullyRemoved.Text',
                                                            {
                                                                field: t(
                                                                    'XRechnung.PositionLine.Text'
                                                                ),
                                                            }
                                                        )
                                                    );
                                                }}
                                                icon='trash'
                                                title={t(
                                                    'XRechnung.RemovePositionBtn.Text'
                                                )}
                                                aria-label={t(
                                                    'XRechnung.RemovePositionBtn.Text'
                                                )}
                                                size={'mini'}
                                                danger={true}
                                                className='action-button'
                                            />
                                        </>
                                    }
                                    expanded={subPageHaveErrors}
                                    textWhenErrorsArePresent={t(
                                        'ValidationMessage.ErrorsPresent.Text'
                                    )}
                                    body={
                                        <InvoicePositionLine
                                            line={subLine}
                                            recursionLevel={recursionLevel + 1}
                                            isSubLine={true}
                                            parentVatRate={
                                                props.parentVatRate ??
                                                props.line.vatRate?.toString() ??
                                                undefined
                                            }
                                            unitOptions={props.unitOptions}
                                            taxCategories={props.taxCategories}
                                            isFormSubmitted={
                                                props.isFormSubmitted
                                            }
                                            senderMicroenterpreneuer={
                                                props.senderMicroenterpreneuer
                                            }
                                            onChange={(
                                                updatedLine: PositionLine
                                            ) => {
                                                let updatedSubLines = [
                                                    ...props.line.subLines,
                                                ];

                                                updatedSubLines[index] =
                                                    updatedLine;

                                                props.onChange({
                                                    ...props.line,
                                                    subLines: updatedSubLines,
                                                });
                                            }}
                                            onRemove={() => {
                                                let updatedSubLines = [
                                                    ...props.line.subLines,
                                                ];

                                                updatedSubLines.splice(
                                                    index,
                                                    1
                                                );

                                                props.onChange({
                                                    ...props.line,
                                                    subLines: updatedSubLines,
                                                });
                                            }}
                                            messageTypes={props.messageTypes}
                                            data={props.data}
                                            updateData={props.updateData}
                                        />
                                    }
                                />
                            );
                        }
                    )}
                </Accordion>

                {props.line?.subLines &&
                props.line.subLines.length <
                    (appSettings?.subItemLimit ?? 100) &&
                recursionLevel < 3 ? (
                    <Button
                        onClick={() => {
                            let updatedLine = {
                                ...props.line,
                            } as PositionLine;

                            let updatedSubLines = [
                                ...(updatedLine.subLines ?? []),
                            ];

                            updatedSubLines.push({
                                positionNo:
                                    (props.line.positionNo ?? '') +
                                    '.' +
                                    ((props.line.subLines?.length ?? 0) + 1),
                                unit: null,
                                vatRate: props.line.vatRate,
                                surchargeGroups: [],
                                rebateGroups: [],
                                subLines: [],
                                quantity: 0,
                            } as any);

                            updatedLine.subLines = updatedSubLines;

                            props.onChange(updatedLine);
                        }}
                        className='action-button add-sub-invoice-line'
                        icon={'plus-lg'}
                        text={t('XRechnung.AddSubLineBtn.Text')}
                        primary={true}
                    />
                ) : (
                    // TODO: Alert Component
                    <p
                        style={{
                            textAlign: 'center',
                            color: 'var(--ui-error)',
                        }}
                    >
                        {t('XRechnung.LimitSublineItemsReached.Text')}
                    </p>
                )}
            </div>

            <Button
                onClick={() => {
                    props.onRemove();

                    Toast.success(
                        t('UserFeedback.SuccessfullyRemoved.Text', {
                            field: t('XRechnung.PositionLine.Text'),
                        })
                    );
                }}
                className='action-button delete-invoice-line-item'
                icon='trash'
                text={t('XRechnung.RemovePositionBtn.Text')}
                danger={true}
            />
        </Card>
    );
}
