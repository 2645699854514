import {
    BootstrapIcon,
    Button,
    RequiredFieldsNotice,
    Select,
    TextInput,
    Toast,
} from '@b4valuenet/ui-react';
import { useEffect, useState } from 'react';

import { IBANValidationPattern } from '../../../../../../models/IBANValidationPattern';
import { IXRechnungSubPage } from '../../XRechnungPage';
import InvoiceSepaDirectDebit from './components/InvoiceSepaDirectDebit';
import InvoiceTransfer from './components/InvoiceTransfer';
import { KeyValuePair } from '../../../../../../models/KeyValuePair';
import { PaymentMeansCode } from '../../../../../../models/PaymentInfo';
import { Transfer } from '../../../../../../models/Transfer';
import XRechnungService from '../../../../../../services/XRechnungService';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

export function XRechnungPaymentMeans(props: IXRechnungSubPage) {
    const { t } = useTranslation();

    const appSettings = useAppSelector((state) => state.app.settings);

    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    let paymentMeans: KeyValuePair[] = [
        {
            key: PaymentMeansCode.SepaCreditTransfer.toString(),
            value: t('XRechnung.PaymentMeansSepaTransfer.Text'),
        },
        {
            key: PaymentMeansCode.CreditTransfer.toString(),
            value: t('XRechnung.PaymentMeansTransfer.Text'),
        },
        {
            key: PaymentMeansCode.SepaDirectDebit.toString(),
            value: t('XRechnung.PaymentMeansSepaDebit.Text'),
        },
    ];

    const [IBANValidationPatterns, setIBANValidationPatterns] = useState<
        IBANValidationPattern[]
    >([]);

    useEffect(() => {
        async function fetchIBANValidationPatterns() {
            try {
                let result = await XRechnungService.getIBANValidationPatterns();
                setIBANValidationPatterns(result || []);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        }

        fetchIBANValidationPatterns();
    }, []);

    useEffect(() => {
        if (
            props.data?.paymentInfo.transfers.length === 0 &&
            props.data?.paymentInfo.debit === null
        ) {
            setIsFormValid(false);
        } else {
            setIsFormValid(true);
        }
    }, [props.data?.paymentInfo]);

    return (
        <div className='spaced-fields'>
            <RequiredFieldsNotice
                description={t(
                    'ValidationMessage.IndicatesARequiredField.Text'
                )}
            />
            <TextInput
                type='text'
                placeholder={t('XRechnung.PaymentReference.Text')}
                label={t('XRechnung.PaymentReference.Text')}
                value={props.data?.paymentInfo.paymentReference?.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (props.data) {
                        props.updateData((prevData) => ({
                            ...prevData,
                            paymentInfo: {
                                ...prevData.paymentInfo,
                                paymentReference: e.target.value,
                            },
                        }));
                    }
                }}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.PaymentReferenceHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.PaymentReferenceHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.PaymentReference.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />
            <Select
                value={props.data?.paymentInfo.paymentMeans.toString()}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    if (props.data) {
                        //TODO: is this the right place to check. That only one of transfers/debit exist ?
                        //ensure if its debit no transfers are listed
                        if (
                            +e.target.value === PaymentMeansCode.SepaDirectDebit
                        ) {
                            return props.updateData((prevData) => ({
                                ...prevData,
                                paymentInfo: {
                                    ...prevData.paymentInfo,
                                    paymentMeans: +e.target.value,
                                    transfers: [],
                                },
                            }));
                        }

                        //TODO: if transfers is an empty array no validators catch. Therefore here the workaround
                        props.updateData((prevData) => ({
                            ...prevData,
                            paymentInfo: {
                                ...prevData.paymentInfo,
                                paymentMeans: +e.target.value,
                                debit: null,
                                transfers:
                                    prevData.paymentInfo.transfers.length === 0
                                        ? [{} as any]
                                        : prevData.paymentInfo.transfers,
                            },
                        }));
                    }
                }}
                icon='bank'
                label={t('XRechnung.PaymentMeans.Text')}
                infoButtonEnabled={true}
                infoButtonText={t('XRechnung.PaymentMeansHelpTitle.ShortText')}
                infoButtonTooltipTitle={t(
                    'XRechnung.PaymentMeansHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.PaymentMeans.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            >
                {paymentMeans.map((type) => {
                    return (
                        <option key={type.key} value={type.key}>
                            {type.value}
                        </option>
                    );
                })}
            </Select>

            {props.data?.paymentInfo.paymentMeans !==
                PaymentMeansCode.SepaDirectDebit && (
                <div className='payment-info-transfers'>
                    <div className='spaced-fields'>
                        {props.data?.paymentInfo?.transfers?.map(
                            (transfer: Transfer, index: number) => {
                                return (
                                    <InvoiceTransfer
                                        key={index}
                                        IBANValidationPatterns={
                                            IBANValidationPatterns
                                        }
                                        requireBic={
                                            props.data?.paymentInfo
                                                .paymentMeans ===
                                            PaymentMeansCode.CreditTransfer
                                        }
                                        transfer={transfer}
                                        disableIBANValidation={
                                            props.data?.paymentInfo
                                                .paymentMeans ===
                                            PaymentMeansCode.CreditTransfer
                                        }
                                        isFormSubmitted={props.isFormSubmitted}
                                        onChange={(
                                            updatedTransfer: Transfer
                                        ) => {
                                            if (props.data) {
                                                props.updateData((prevData) => {
                                                    let newTransfers = [
                                                        ...(prevData.paymentInfo
                                                            .transfers ?? []),
                                                    ];

                                                    newTransfers[index] =
                                                        updatedTransfer;

                                                    return {
                                                        ...prevData,
                                                        paymentInfo: {
                                                            ...prevData.paymentInfo,
                                                            transfers:
                                                                newTransfers,
                                                        },
                                                    };
                                                });
                                            }
                                        }}
                                        onRemove={() => {
                                            if (props.data) {
                                                props.updateData((prevData) => {
                                                    let newTransfers = [
                                                        ...(prevData.paymentInfo
                                                            .transfers ?? []),
                                                    ];

                                                    newTransfers.splice(
                                                        index,
                                                        1
                                                    );

                                                    return {
                                                        ...prevData,
                                                        paymentInfo: {
                                                            ...prevData.paymentInfo,
                                                            transfers:
                                                                newTransfers,
                                                        },
                                                    };
                                                });
                                            }
                                        }}
                                    />
                                );
                            }
                        )}
                    </div>

                    {props.data?.paymentInfo.transfers &&
                    props.data.paymentInfo.transfers.length <
                        (appSettings?.subItemLimit ?? 100) ? (
                        <Button
                            onClick={() => {
                                if (props.data) {
                                    let newTransfers = [
                                        ...(props.data.paymentInfo.transfers ??
                                            []),
                                    ];

                                    newTransfers.push({} as any);

                                    props.updateData((prevData) => ({
                                        ...prevData,
                                        paymentInfo: {
                                            ...prevData.paymentInfo,
                                            transfers: newTransfers,
                                        },
                                    }));
                                }
                            }}
                            className='add-payment-transfer'
                            icon='plus-lg'
                            primary={true}
                            text={t('XRechnung.AddTransfer.Text')}
                        />
                    ) : (
                        // TODO: Alert Component
                        <p
                            style={{
                                textAlign: 'center',
                                color: 'var(--ui-error)',
                            }}
                        >
                            {t('XRechnung.LimitSublineItemsReached.Text')}
                        </p>
                    )}
                </div>
            )}

            {props.data?.paymentInfo.paymentMeans ===
                PaymentMeansCode.SepaDirectDebit && (
                <InvoiceSepaDirectDebit
                    debit={props.data.paymentInfo.debit}
                    onChange={(updatedDebit) => {
                        if (props.data) {
                            props.updateData((prevData) => ({
                                ...prevData,
                                paymentInfo: {
                                    ...prevData.paymentInfo,
                                    debit: updatedDebit,
                                },
                            }));
                        }
                    }}
                    isFormSubmitted={props.isFormSubmitted}
                    IBANValidationPatterns={IBANValidationPatterns}
                />
            )}

            <div aria-live='polite'>
                {!isFormValid && (
                    <p className='error'>
                        <BootstrapIcon icon='x-circle-fill' />
                        {t('UserFeedback.NoBankAccounts.Text')}
                    </p>
                )}
            </div>
        </div>
    );
}
