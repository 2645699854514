import { Breadcrumb } from '@b4valuenet/ui-react';
import { IBreadcrumbItem } from '@b4valuenet/ui-react';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

export default function DashboardBreadcrumb() {
    const headerMenuExpanded = useAppSelector(
        (state) => state.app.isHeaderMenuExpanded
    );

    const currentRoute = useAppSelector((state) => state.app.dashboardRoute);
    const currentPageTitle = useAppSelector((state) => state.app.pageTitle);

    const { t } = useTranslation();

    const breadcrumbItems: IBreadcrumbItem[] = [
        {
            text: 'LEA',
            linkTo: 'home',
            external: false,
            isActive: !headerMenuExpanded,
        },
        {
            text: currentPageTitle,
            linkTo: currentRoute,
            external: false,
            isActive: !headerMenuExpanded,
        },
    ];

    return (
        <Breadcrumb
            aria-label={t('Common.BreadcrumbLabel.Text')}
            aria-hidden={headerMenuExpanded ? true : undefined}
            className={headerMenuExpanded ? 'inactive' : ''}
            items={breadcrumbItems}
        />
    );
}
