import {
    AsyncForm,
    BootstrapIcon,
    Button,
    Card,
    LoadingOverlay,
    PasswordRequirements,
    PasswordRequirementsCheck,
    RequestTools,
    RequiredFieldsNotice,
    TextInput,
    Toast,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { GenericActionResponse } from '../../../../../models/GenericActionResponse';
import { PasswordChangeResultType } from '../../../../../models/PasswordChangeResultType';
import { UpdatePasswordField } from '../../../../../models/UpdatePasswordField';
import PasswordService from '../../../../../services/PasswordService';
import PersonalSettingsService from '../../../../../services/PersonalSettingsService';

export default function PasswordSettings() {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [error, setError] = useState<string | null>(null);

    const [fieldErrorTarget, setFieldErrorTarget] =
        useState<UpdatePasswordField | null>(null);
    const [fieldError, setFieldError] = useState<string | null>(null);

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [formSended, setIsFormSended] = useState<boolean>(false);

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [isNewPasswordValid, setIsNewPasswordValid] =
        useState<boolean>(false);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] =
        useState<boolean>(false);

    const [passwordRequirements, setPasswordRequirements] =
        useState<PasswordRequirements | null>(null);

    async function handlePasswordUpdateFormSubmit() {
        setIsFormSended(true);

        if (!isFormValid) {
            return;
        }

        setIsLoading(true);

        try {
            let response: any = await PersonalSettingsService.updatePassword(
                currentPassword,
                newPassword,
                confirmPassword
            );

            if (response !== null) {
                if (response.data !== null && response.data !== undefined) {
                    setFieldError(response.message);
                    setFieldErrorTarget(response.data);
                } else {
                    Toast.success(response.message, 350);
                    setIsFormSended(false);
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                    setError('');
                }
            } else {
                throw new Error('Empty response from server.');
            }
        } catch (error: any) {
            if (error.response && error.response.data !== null) {
                const response: GenericActionResponse = error.response.data;

                if (
                    response.data ===
                        PasswordChangeResultType.UserNamePasswordPolicyViolation ||
                    response.data ===
                        PasswordChangeResultType.PasswordDoesNotFullfilRequirements ||
                    response.data ===
                        PasswordChangeResultType.NewAndConfirmPasswordNotEqual ||
                    response.data ===
                        PasswordChangeResultType.PasswordDoesNotFullfilRequirements
                ) {
                    setFieldError(response.message);
                    setFieldErrorTarget(UpdatePasswordField.NewPassword);
                    setIsLoading(false);
                    return;
                }

                if (
                    response.data ===
                    PasswordChangeResultType.CurrentPasswordNotMatching
                ) {
                    setFieldError(response.message);
                    setFieldErrorTarget(UpdatePasswordField.CurrentPassword);
                    setIsLoading(false);
                    return;
                }

                setError(response.message);
            } else {
                let message = RequestTools.getApiErrorMessage(error);
                setError(message);
            }
        }

        setIsLoading(false);
    }

    const beautifyPasswordRequirements = (
        passwordRequirements: PasswordRequirements | null
    ): JSX.Element => {
        if (!passwordRequirements) {
            return <></>;
        }

        return (
            <PasswordRequirementsCheck
                translations={{
                    title: t('Password.Requirements.Text'),
                    minDigits: t('Password.MinDigits.Text', {
                        numberDigits: passwordRequirements.minimumNumberDigits,
                    }),
                    minLetters: t('Password.MinLetters.Text', {
                        numberLetters:
                            passwordRequirements.minimumNumberLetters,
                    }),
                    minSpecials: t('Password.MinSpecials.Text', {
                        numberSpecials:
                            passwordRequirements.minimumNumberSpecialCharacters,
                    }),
                    minLength: t('Password.MinLength.Text', {
                        minLength: passwordRequirements.minimumPasswordLength,
                    }),
                }}
                password={newPassword}
                requirements={passwordRequirements}
            />
        );
    };

    useEffect(() => {
        const validatePasswordUpdateForm = () => {
            let currentPasswordValid = currentPassword !== '';
            let newPasswordValid =
                passwordRequirements !== null
                    ? ValidationTools.isPasswordMatchRequirements(
                          newPassword,
                          passwordRequirements
                      )
                    : false;
            let confirmPasswordValid = confirmPassword === newPassword;

            setIsFormValid(
                currentPasswordValid && newPasswordValid && confirmPasswordValid
            );

            setIsNewPasswordValid(newPasswordValid);
            setIsConfirmPasswordValid(confirmPasswordValid);
        };

        validatePasswordUpdateForm();
    }, [currentPassword, newPassword, confirmPassword, passwordRequirements]);

    useEffect(() => {
        const fetchPasswordRequirements = async () => {
            setIsLoading(true);

            try {
                let result = await PasswordService.getPasswordRequirements();

                if (result) {
                    setPasswordRequirements(result.data);
                } else {
                    Toast.error('Empty result.');
                }

                setIsLoading(false);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);

                setIsLoading(false);
            }
        };

        fetchPasswordRequirements();
    }, []);

    return (
        <Card
            className='password-settings'
            title={t('PersonalSettings.Password.Text')}
        >
            <AsyncForm
                onSubmit={() => handlePasswordUpdateFormSubmit()}
                className='card-content'
            >
                <RequiredFieldsNotice
                    description={t(
                        'ValidationMessage.IndicatesARequiredField.Text'
                    )}
                />
                <TextInput
                    icon='key'
                    type='password'
                    autoComplete='current-password'
                    required={true}
                    label={t('PersonalSettings.CurrentPassword.Text')}
                    placeholder={t('PersonalSettings.CurrentPassword.Help')}
                    value={currentPassword}
                    isValid={
                        fieldErrorTarget !==
                            UpdatePasswordField.CurrentPassword &&
                        ((!formSended && currentPassword === '') ||
                            currentPassword !== '')
                    }
                    validationError={
                        fieldErrorTarget ===
                            UpdatePasswordField.CurrentPassword &&
                        fieldError !== null
                            ? fieldError
                            : t('ValidationMessage.Required.Text')
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setCurrentPassword(e.target.value);
                        setError('');
                        setFieldError(null);
                        setFieldErrorTarget(null);
                    }}
                />
                {beautifyPasswordRequirements(passwordRequirements)}
                <TextInput
                    icon='key'
                    type='password'
                    autoComplete='new-password'
                    required={true}
                    label={t('PersonalSettings.NewPassword.Text')}
                    placeholder={t('PersonalSettings.NewPassword.Help')}
                    value={newPassword}
                    isValid={
                        fieldErrorTarget !== UpdatePasswordField.NewPassword &&
                        ((!formSended && newPassword === '') ||
                            isNewPasswordValid)
                    }
                    validationError={
                        fieldErrorTarget === UpdatePasswordField.NewPassword &&
                        fieldError !== null
                            ? fieldError
                            : newPassword.length <
                              (passwordRequirements?.minimumPasswordLength ?? 0)
                            ? t('ValidationMessage.MinLength.Text', {
                                  minLength:
                                      passwordRequirements?.minimumPasswordLength ??
                                      1,
                              })
                            : t('ValidationMessage.MeetRequirements.Text')
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setNewPassword(e.target.value);
                        setError('');
                        setFieldError(null);
                        setFieldErrorTarget(null);
                    }}
                />
                <TextInput
                    icon='key'
                    type='password'
                    autoComplete='new-password'
                    required={true}
                    label={t('PersonalSettings.ConfirmPassword.Text')}
                    placeholder={t('PersonalSettings.ConfirmPassword.Help')}
                    value={confirmPassword}
                    isValid={
                        fieldErrorTarget !==
                            UpdatePasswordField.ConfirmPassword &&
                        ((!formSended && confirmPassword === '') ||
                            isConfirmPasswordValid)
                    }
                    validationError={
                        fieldErrorTarget ===
                            UpdatePasswordField.ConfirmPassword &&
                        fieldError !== null
                            ? fieldError
                            : t('ValidationMessage.PasswordNotEqual.Text')
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setConfirmPassword(e.target.value);
                        setError('');
                        setFieldError(null);
                        setFieldErrorTarget(null);
                    }}
                />
                <hr className='big-separator' />
                <div aria-live='polite'>
                    {error && (
                        <p className='error'>
                            <BootstrapIcon icon='x-circle-fill' />
                            {error}
                        </p>
                    )}
                </div>
                <Button
                    type='submit'
                    disabled={!isFormValid}
                    text={t('PersonalSettings.SavePasswordBtn.Text')}
                    primary={true}
                />
            </AsyncForm>

            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={isLoading}
                isGlobal={false}
            />
        </Card>
    );
}
