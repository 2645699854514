import './Home.scss';

import {
    Button,
    CommonTools,
    RequestTools,
    Toast,
    findAndFocusModalCallerElement,
} from '@b4valuenet/ui-react';
import { createRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

import { Card } from '@b4valuenet/ui-react';
import HomeService from '../../../../services/HomeService';
import InitialDialog from '../../../../shared/components/invoice/InitialDialog';
import SubmitExternallyCreatedInvoiceDialog from '../../../../shared/components/invoice/SubmitExternallyCreatedInvoiceDialog';
import UploadService from '../../../../services/UploadService';
import { isLoadingUpdated } from '../../../../redux/slices/AppSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Home() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const authenticatedUser = useAppSelector((state) => state.auth.user);
    const appSettings = useAppSelector((state) => state.app.settings);

    const [email, setEmail] = useState<string | null>(null);
    const [emailCopied, setEmailCopied] = useState<boolean>(false);
    const [emailCopiedResetTimeout, setEmailCopiedResetTimeout] =
        useState<NodeJS.Timeout | null>(null);

    const [uploadExtInvoiceDialogFile, setUploadExtInvoiceDialogFile] =
        useState<File | null>(null);
    const [isUploadExtInvoiceDialogActive, setIsUploadExtInvoiceDialogActive] =
        useState<boolean>(false);

    const [isInitialDialogActive, setIsInitialDialogActive] =
        useState<boolean>(false);

    const [modalCallerElement, setModalCallerElement] = useState<
        HTMLButtonElement | HTMLInputElement | HTMLAnchorElement | null
    >(null);

    const selectTemplateButtonRef = createRef<HTMLInputElement>();

    async function copyEmailToClipboard() {
        if (email === null) return;
        if (emailCopiedResetTimeout) clearTimeout(emailCopiedResetTimeout);

        let copied = await CommonTools.copyTextToClipboard(email);

        setEmailCopied(copied);

        let timeout = setTimeout(() => {
            setEmailCopied(false);
        }, 2000);

        setEmailCopiedResetTimeout(timeout);
    }

    // TODO: move to right place
    async function uploadInvoiceTemplate(file: File | null) {
        if (file == null) {
            return;
        }

        dispatch(isLoadingUpdated({ isLoading: true }));

        try {
            let submissionResult = await UploadService.uploadInvoiceTemplate(
                file
            );
            dispatch(isLoadingUpdated({ isLoading: false }));
            navigate(`/dashboard/xrechnung/${submissionResult}`);
        } catch (error) {
            if (
                selectTemplateButtonRef !== null &&
                selectTemplateButtonRef.current !== null
            ) {
                selectTemplateButtonRef.current.value = '';
            }

            let message = RequestTools.getApiErrorMessage(error);
            dispatch(isLoadingUpdated({ isLoading: false }));
            Toast.error(message);
        }
    }

    useEffect(() => {
        let fetchEmail = async () => {
            try {
                let newEmail = await HomeService.getEmail();
                setEmail(newEmail);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };

        fetchEmail();
    }, []);

    return (
        <>
            <div className='sub-page home'>
                <section className='home-content'>
                    <h1>{t('Home.Title.Text')}</h1>

                    <div className='cards'>
                        <Card
                            title={t('Home.SetupTitle.Text')}
                            description={t('Home.SetupDescription.Text')}
                        >
                            <Button
                                text={t('Home.SetupBtn.Text')}
                                primary={true}
                                onClick={(e: React.MouseEvent<any>) => {
                                    setIsInitialDialogActive(true);
                                    setModalCallerElement(e.target as any);
                                }}
                                icon={'chevron-right'}
                                iconPosition={'right'}
                            />
                            <Button
                                isFileUpload={true}
                                icon={'upload'}
                                text={t('Home.UploadXRechnungDocumentBtn.Text')}
                                primary={false}
                                fileAccept={appSettings?.uploadFileExtensions}
                                onFileChange={(files: File[]) => {
                                    uploadInvoiceTemplate(
                                        files ? files[0] : null
                                    );
                                }}
                                inputRef={selectTemplateButtonRef}
                            />
                        </Card>

                        <Card
                            title={t('Home.UploadDocumentTitle.Text')}
                            description={t(
                                'Home.UploadDocumentDescription.Text'
                            )}
                        >
                            <Button
                                onClick={(e: React.MouseEvent<any>) => {
                                    setIsUploadExtInvoiceDialogActive(true);

                                    setModalCallerElement(e.target as any);
                                }}
                                onFileChange={(files: File[]) => {
                                    setUploadExtInvoiceDialogFile(
                                        files ? files[0] : null
                                    );
                                }}
                                isFileUpload={true}
                                fileAccept={appSettings?.uploadFileExtensions}
                                text={t('Home.UploadDocumentBtn.Text')}
                                primary={true}
                                icon={'chevron-right'}
                                iconPosition={'right'}
                            />
                        </Card>

                        <Card
                            title={t('Home.Email2OutboxTitle.Text')}
                            description={t('Home.Email2OutobxDescription.Text')}
                        >
                            <Button
                                isLink={true}
                                isLoading={email === null}
                                linkTo={email ? 'mailto:' + email : ''}
                                linkExternal={true}
                                text={t('Home.Email2OutboxAddress.Text')}
                                primary={true}
                                icon={'send'}
                                iconPosition={'right'}
                            />

                            <Button
                                icon={emailCopied ? 'check-lg' : 'clipboard'}
                                isLoading={email === null}
                                text={t(
                                    emailCopied
                                        ? 'Home.CopyEmailAddressSuccessBtn.Text'
                                        : 'Home.CopyEmailAddressBtn.Text'
                                )}
                                primary={false}
                                className={
                                    'email-copy' +
                                    (emailCopied ? ' copied' : '')
                                }
                                onClick={() => copyEmailToClipboard()}
                            />
                        </Card>

                        <Card
                            title={t('Home.MessageGridTitle.Text')}
                            description={t('Home.MessageGridDescription.Text')}
                        >
                            <Button
                                isLink={true}
                                linkTo='/dashboard/messages'
                                text={t('Home.MessageGridBtn.Text')}
                                primary={true}
                                icon={'chevron-right'}
                                iconPosition={'right'}
                            />
                        </Card>

                        {appSettings?.bigAttachmentsEnabled ? (
                            <Card
                                title={t('Home.BigAttachmentsTitle.Text')}
                                description={t(
                                    'Home.BigAttachmentsDescription.Text'
                                )}
                            >
                                <Button
                                    isLink={true}
                                    linkTo='/dashboard/attachments'
                                    text={t(
                                        'Home.ForwardToBigAttachmentsBtn.Text'
                                    )}
                                    primary={true}
                                    icon={'chevron-right'}
                                    iconPosition={'right'}
                                />
                            </Card>
                        ) : (
                            <Card
                                title={t('Home.ClientAdministrationTitle.Text')}
                                description={t(
                                    'Home.ClientAdministrationDescription.Text'
                                )}
                            >
                                <Button
                                    isLink={true}
                                    linkTo='/dashboard/client-administration'
                                    text={t(
                                        'Home.ForwardToClientAdministrationBtn.Text'
                                    )}
                                    primary={true}
                                    icon={'chevron-right'}
                                    iconPosition={'right'}
                                />
                            </Card>
                        )}

                        {authenticatedUser?.isAdmin && (
                            <Card
                                title={t('Home.UserAdministrationTitle.Text')}
                                description={t(
                                    'Home.UserAdministrationDescription.Text'
                                )}
                            >
                                <Button
                                    isLink={true}
                                    linkTo='/dashboard/user-administration'
                                    text={t(
                                        'Home.ForwardToUserAdministrationBtn.Text'
                                    )}
                                    primary={true}
                                    icon={'chevron-right'}
                                    iconPosition={'right'}
                                />
                            </Card>
                        )}
                    </div>
                </section>
            </div>
            <InitialDialog
                active={isInitialDialogActive}
                onClose={() => {
                    setIsInitialDialogActive(false);

                    setTimeout(() => {
                        findAndFocusModalCallerElement(
                            modalCallerElement,
                            () => {
                                setModalCallerElement(null);
                            }
                        );
                    }, 400);
                }}
            />
            <SubmitExternallyCreatedInvoiceDialog
                active={isUploadExtInvoiceDialogActive}
                onClose={() => {
                    setIsUploadExtInvoiceDialogActive(false);

                    setTimeout(() => {
                        findAndFocusModalCallerElement(
                            modalCallerElement,
                            () => {
                                setModalCallerElement(null);
                            }
                        );
                    }, 400);
                }}
                selectedFile={uploadExtInvoiceDialogFile}
            />
        </>
    );
}
