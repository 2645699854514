import {
    PaymentInfo,
    PaymentMeansCode,
} from '../../../../../../../../models/PaymentInfo';

import { Card } from '@b4valuenet/ui-react';
import PaymentDebitInfo from './components/PaymentDebitInfo';
import PaymentTransferSummary from './components/PaymentTransferSummary';
import { useTranslation } from 'react-i18next';

export interface IPaymentInfoSummary {
    paymentInfo: PaymentInfo;
}

export default function PaymentInfoSummary(props: IPaymentInfoSummary) {
    const { t } = useTranslation();

    return (
        <Card title={t('XRechnung.PaymentInfoTab.Text')}>
            <div className='pseudo-table'>
                <div className='pseudo-row'>
                    <div className='pseudo-column'>
                        <strong>{t('XRechnung.PaymentReference.Text')}</strong>
                    </div>
                    <div className='pseudo-column'>
                        {props.paymentInfo.paymentReference}
                    </div>
                </div>
            </div>

            {[
                PaymentMeansCode.SepaDirectDebit,
                PaymentMeansCode.DebitTransfer,
            ].includes(props.paymentInfo.paymentMeans) && (
                <PaymentDebitInfo debit={props.paymentInfo.debit} />
            )}

            {[
                PaymentMeansCode.SepaCreditTransfer,
                PaymentMeansCode.CreditTransfer,
            ].includes(props.paymentInfo.paymentMeans) && (
                <div className='spaced-fields'>
                    {props.paymentInfo.transfers.map((transfer, index) => {
                        return (
                            <PaymentTransferSummary
                                isSepaTransfer={
                                    props.paymentInfo.paymentMeans ===
                                    PaymentMeansCode.SepaCreditTransfer
                                }
                                transfer={transfer}
                                index={index}
                                key={index}
                            />
                        );
                    })}
                </div>
            )}
        </Card>
    );
}
