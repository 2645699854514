import { RequestTools } from '@b4valuenet/ui-react';
import { AppSettings } from '../models/AppSettings';
import { LegalNoticeItem } from '../models/LegalNoticeItem';

export default class AppSettingsService {
    static ApiRoot = 'api/appsettings/';

    static get = async (locale: string): Promise<AppSettings | null> => {
        let response = await RequestTools.get(
            `${this.ApiRoot}get?lang=${locale}`
        );

        let json = await response.data;
        let result = (json.data as AppSettings) || null;

        return result ?? null;
    };

    static getLegalNotice = async (
        endpoint: string
    ): Promise<LegalNoticeItem[] | null> => {
        let response = await RequestTools.get(endpoint);

        let json = await response.data;
        let result = (json as LegalNoticeItem[]) || null;

        return result;
    };
}
