import { createSlice } from '@reduxjs/toolkit';
import { AppSettings } from '../../models/AppSettings';

interface AppState {
    pageTitle: string;
    dashboardRoute: string;
    supportLastVisitedPage: string | null;
    isHeaderMenuExpanded: boolean;
    isLoading: boolean;
    loadingError: string | null;
    canBeVisible: boolean;
    settings: AppSettings | null;
}

const initialState: AppState = {
    pageTitle: '',
    dashboardRoute: '',
    supportLastVisitedPage: null,
    isLoading: false,
    loadingError: null,
    canBeVisible: false,
    isHeaderMenuExpanded: false,
    settings: null,
};

const AppSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        appVisiabilityUpdated(state, action) {
            return {
                ...state,
                canBeVisible: action.payload.isVisible,
            };
        },

        supportContactCalled(state, action) {
            return {
                ...state,
                supportLastVisitedPage: action.payload.supportLastVisitedPage,
            };
        },

        supportContactPageOpened(state) {
            return {
                ...state,
                supportLastVisitedPage: null,
            };
        },

        titleChanged(state, action) {
            return {
                ...state,
                pageTitle: action.payload,
            };
        },

        routeChanged(state, action) {
            return {
                ...state,
                dashboardRoute: action.payload,
            };
        },

        isLoadingUpdated(state, action) {
            return {
                ...state,
                isLoading: action.payload.isLoading,
                loadingError: action.payload.loadingError ?? null,
            };
        },

        headerMenuExpanded(state) {
            return {
                ...state,
                isHeaderMenuExpanded: true,
            };
        },

        headerMenuHidden(state) {
            return {
                ...state,
                isHeaderMenuExpanded: false,
            };
        },

        appSettingsLoaded(state, action) {
            return {
                ...state,
                settings: action.payload.appSettings,
            };
        },
    },
});

export const {
    isLoadingUpdated,
    appVisiabilityUpdated,
    appSettingsLoaded,
    supportContactCalled,
    supportContactPageOpened,
    titleChanged,
    routeChanged,
    headerMenuExpanded,
    headerMenuHidden,
} = AppSlice.actions;

export default AppSlice.reducer;
