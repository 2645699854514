import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit';

import AuthService from '../../services/AuthService';
import { AxiosError } from 'axios';
import { LoginData } from '../../models/LoginData';
import { LoginError } from '../../models/LoginError';
import { RequestTools } from '@b4valuenet/ui-react';
import { Toast } from '@b4valuenet/ui-react';
import { User } from '../../models/User';
import { logOut } from '../slices/AuthSlice';

export const login = createAsyncThunk<
    // Return type of the payload creator
    User,
    // First argument to the payload creator
    LoginData,
    // Types for ThunkAPI
    {
        rejectValue: LoginError;
    }
>(
    'auth/logIn',

    async (loginData, thunkApi) => {
        try {
            return await AuthService.login(
                loginData.email,
                loginData.password,
                loginData.language
            );
        } catch (error) {
            if (error instanceof AxiosError) {
                const message = RequestTools.getApiErrorMessage(error);

                return thunkApi.rejectWithValue({ message } as LoginError);
            } else {
                throw error;
            }
        }
    }
);

export const logout = () => async (dispatch: Dispatch) => {
    try {
        await AuthService.logout();
    } catch (error) {
        Toast.error(RequestTools.getApiErrorMessage(error));
    }

    dispatch(logOut());
};
