import { Dispatch } from '@reduxjs/toolkit';
import { changedLanguage } from '../slices/UserSlice';

export const changeUserLanguage =
    (newLanguage: string) => (dispatch: Dispatch) => {
        dispatch(changedLanguage(newLanguage));
    };

export const changeUserLanguageInSettings =
    (newLanguage: string) => (dispatch: Dispatch) => {
        dispatch(changedLanguage(newLanguage));
        //dispatch(languageInSettingsChanged(newLanguage));
    };
