import { Button } from '@b4valuenet/ui-react';

import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAppSelector } from '../../redux/hooks';

const Accessibility = () => {
    const { t } = useTranslation();

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    return (
        <div className='sub-page accessibility'>
            <h1>{t('AccessibilityPage.Block1.Text')}</h1>
            {!isLoggedIn && (
                <Button
                    isLink={true}
                    linkTo={'/'}
                    icon='chevron-left'
                    text={t('PasswordForgotten.CancelButton.Text')}
                    primary={true}
                />
            )}
            <br />
            <hr />
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {t('AccessibilityPage.Content.Text')}
            </ReactMarkdown>
        </div>
    );
};

export default Accessibility;
