import './ItemPreviewDialog.scss';

import {
    Button,
    Dialog,
    ErrorOverlay,
    LoadingOverlay,
    RequestTools,
    StepsProgress
} from '@b4valuenet/ui-react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { InvoiceValidationResult } from '../../../../../models/InvoiceValidationResult';
import { Message } from '../../../../../models/Message';
import { MessageStatusStep } from '../../../../../models/MessageStatusStep';
import { SchematronReport } from '../../../../../models/SchematronReport';
import { UserMessageState } from '../../../../../models/UserMessageState';
import { ValidationResultMessageStep } from '../../../../../models/ValidationResultMessageStep';
import MessagesService from '../../../../../services/MessagesService';
import { ValidationResult } from '../../../../../shared/components/invoice/components/ValidationResult';

interface IMessageProtocolDialog {
    active: boolean;
    onClose?: CallableFunction;
    message: Message | null;
}

export default function MessageProtocolDialog(props: IMessageProtocolDialog) {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<string | null>(null);

    const [messageProtocol, setMessageProtocol] = useState<
        ValidationResultMessageStep[] | null
    >(null);
    const [schematronProtocol, setSchematronProtocol] =
        useState<SchematronReport | null>(null);
    const [validationResult, setValidationResult] =
        useState<InvoiceValidationResult | null>(null);
    const [messageStatusSteps, setMessageStatusSteps] = useState<
        MessageStatusStep[] | null
    >(null);

    function onClose() {
        setLoadingError(null);

        setTimeout(() => {
            setMessageProtocol(null);
            setSchematronProtocol(null);
            setValidationResult(null);
        }, 300);

        if (props.onClose) props.onClose();
    }

    useEffect(() => {
        const fetchMainData = async () => {
            setIsLoading(true);

            const fetchMessageProtocol = async () => {
                if (props.message === null || messageProtocol !== null) {
                    return;
                }

                try {
                    let messageProtocol =
                        await MessagesService.getMessageProtocol(
                            props.message.id
                        );

                    setMessageProtocol(messageProtocol);
                } catch (error) {
                    let message = RequestTools.getApiErrorMessage(error);

                    setLoadingError(message);
                }
            };

            const fetchMessageStatus = async () => {
                if (!props.message) {
                    return;
                }

                try {
                    let messageStatus = await MessagesService.getMessageStatus(
                        props.message.id
                    );

                    setMessageStatusSteps(messageStatus);
                } catch (error) {
                    let message = RequestTools.getApiErrorMessage(error);

                    setLoadingError(message);
                }
            };

            const fetchSchematronProtocol = async () => {
                if (props.message === null || schematronProtocol !== null) {
                    return;
                }

                try {
                    let schematronProtocol =
                        await MessagesService.getSchematronProtocol(
                            props.message.id
                        );

                    setSchematronProtocol(schematronProtocol);
                } catch (error) {
                    let message = RequestTools.getApiErrorMessage(error);
                    setLoadingError(message);
                }
            };

            await fetchMessageProtocol();
            await fetchSchematronProtocol();
            await fetchMessageStatus();

            setValidationResult({
                messageSteps: messageProtocol ?? [],
                schematronReport: schematronProtocol,
            });

            setIsLoading(false);
        };

        fetchMainData();
    }, [messageProtocol, props.message, schematronProtocol]);

    let stepsIconMap = {
        [UserMessageState.Unknown]: 'question-diamond',
        [UserMessageState.New]: 'file-text',
        [UserMessageState.Provided]: 'cpu',
        [UserMessageState.Canceled]: 'send',
        [UserMessageState.Processing]: 'box-arrow-in-up-right',
        [UserMessageState.Rejected]: 'send',
        [UserMessageState.Delivered]: 'send',
        [UserMessageState.RejectedDeletable]: 'send',
    };

    return (
        <Dialog
            className='message-proto-dialog'
            title={t('XRechnung.ProtocolDialog.Text')}
            closeButtonTitle={t('Common.Close.Text')}
            onClose={() => onClose()}
            active={props.active}
        >
            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={isLoading}
                isGlobal={false}
            />

            <ErrorOverlay
                active={loadingError !== null}
                errorText={loadingError ?? ''}
            />

            <StepsProgress
                type='simple-small'
                steps={
                    messageStatusSteps?.map((step) => {
                        return {
                            done: step.isDone,
                            error: step.hasError,
                            current: step.isActive,
                            showStatusIcon: step.isDone,
                            title: t(
                                `Messages.State${
                                    UserMessageState[step.status]
                                }.Text`
                            ),
                            icon: stepsIconMap[step.status],
                        };
                    }) ?? []
                }
            />

            {validationResult && <ValidationResult result={validationResult} />}
            <div className='buttons-group'>
                <Button
                    text={t('Messages.ProtocolCloseBtn.Text')}
                    onClick={() => onClose()}
                    danger={true}
                    icon={'x-lg'}
                />
            </div>
        </Dialog>
    );
}
