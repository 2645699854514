import {
    Accordion,
    DatePicker,
    DatePickerValue,
    RequiredFieldsNotice,
    Select,
    TextInput,
    ValidationTools,
} from '@b4valuenet/ui-react';

import { useTranslation } from 'react-i18next';
import { UILibraryTranslations } from '../../../../../../shared/UILibraryTranslations';
import { IXRechnungSubPage } from '../../XRechnungPage';
import { XRechnungAccordionItem } from '../../components/XRechnungAccordionItem';
import XRechnungBuyerValidators from '../../validators/BuyerValidators';

export function XRechnungBuyer(props: IXRechnungSubPage) {
    const { t } = useTranslation();

    let pageHaveErrors = !XRechnungBuyerValidators.BuyerDataValid({
        data: props.data,
        isFormSubmitted: props.isFormSubmitted,
    });

    let deliveryInfoHasErrors =
        !XRechnungBuyerValidators.BuyerDataDeliveryInfoValid({
            data: props.data,
            isFormSubmitted: props.isFormSubmitted,
        });

    let deliveryInfoHasContent =
        XRechnungBuyerValidators.BuyerDeliveryInfoHasContent(props.data);

    return (
        <div className='spaced-fields'>
            <RequiredFieldsNotice
                description={t(
                    'ValidationMessage.IndicatesARequiredField.Text'
                )}
            />
            <Accordion>
                <XRechnungAccordionItem
                    icon='info-circle'
                    title={t('XRechnung.ReceiverInformation.Text')}
                    expanded={true}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            <TextInput
                                required={true}
                                type='text'
                                icon='building'
                                placeholder={t(
                                    'XRechnung.LegalEntityName.Text'
                                )}
                                label={t('XRechnung.LegalEntityName.Text')}
                                value={
                                    props.data?.receiverData
                                        .receiverLegalEntityName
                                }
                                isValid={ValidationTools.isRequiredFieldValueValid(
                                    props.data?.receiverData
                                        .receiverLegalEntityName,
                                    props.isFormSubmitted
                                )}
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                receiverLegalEntityName:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ReceiverLegalEntityNameHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ReceiverLegalEntityNameHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ReceiverLegalEntityName.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <TextInput
                                type='text'
                                icon='tags'
                                placeholder={t('XRechnung.ClientName.Text')}
                                label={t('XRechnung.ClientName.Text')}
                                value={
                                    props.data?.receiverData.receiverAddressName
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                receiverAddressName:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ReceiverAddressNameHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ReceiverAddressNameHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ReceiverAddressName.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <TextInput
                                type='text'
                                icon='hash'
                                placeholder={t(
                                    'XRechnung.ReceiverIDRefGroupValue.Text'
                                )}
                                label={t(
                                    'XRechnung.ReceiverIDRefGroupValue.Text'
                                )}
                                value={
                                    props.data?.receiverData
                                        .receiverIDRefGroupValue
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                receiverIDRefGroupValue:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ReceiverIDRefGroupValueHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ReceiverIDRefGroupValueHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ReceiverIDRefGroupValue.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <TextInput
                                type='text'
                                icon='bank'
                                placeholder={t('XRechnung.VatId.Text')}
                                label={t('XRechnung.VatId.Text')}
                                value={props.data?.receiverData.receiverVatId}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                receiverVatId: e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ReceiverVatIdHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ReceiverVatIdHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ReceiverVatId.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                                required={XRechnungBuyerValidators.IsBuyerVatIdRequired(
                                    {
                                        data: props.data,
                                        isFormSubmitted: props.isFormSubmitted,
                                    }
                                )}
                                isValid={XRechnungBuyerValidators.IsBuyerVatIdValid(
                                    {
                                        data: props.data,
                                        isFormSubmitted: props.isFormSubmitted,
                                    }
                                )}
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                            />
                        </div>
                    }
                />

                <XRechnungAccordionItem
                    icon='pin-map-fill'
                    title={t('XRechnung.ReceiverAddress.Text')}
                    expanded={true}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            <TextInput
                                type='text'
                                icon='signpost'
                                placeholder={t('XRechnung.Street.Text')}
                                label={t('XRechnung.Street.Text')}
                                value={
                                    props.data?.receiverData
                                        .receiverAddressStreet
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                receiverAddressStreet:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ReceiverStreetHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ReceiverStreetHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ReceiverStreet.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <TextInput
                                type='text'
                                icon='mailbox'
                                placeholder={t('XRechnung.PoBox.Text')}
                                label={t('XRechnung.PoBox.Text')}
                                value={
                                    props.data?.receiverData
                                        .receiverAddressPoBox
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                receiverAddressPoBox:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ReceiverAddressPoBoxHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ReceiverAddressPoBoxHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ReceiverAddressPoBox.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <TextInput
                                type='text'
                                icon='signpost-2'
                                placeholder={t(
                                    'XRechnung.receiverAddressSubLocality.Text'
                                )}
                                label={t(
                                    'XRechnung.receiverAddressSubLocality.Text'
                                )}
                                value={
                                    props.data?.receiverData
                                        .receiverAddressSubLocality
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                receiverAddressSubLocality:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ReceiverAddressSubLocalityHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ReceiverAddressSubLocalityHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ReceiverAddressSubLocality.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <div className='flex-row'>
                                <TextInput
                                    type='text'
                                    icon='mailbox'
                                    placeholder={t('XRechnung.Zip.Text')}
                                    label={t('XRechnung.Zip.Text')}
                                    required={true}
                                    value={
                                        props.data?.receiverData
                                            .receiverAddressZip
                                    }
                                    isValid={ValidationTools.isRequiredFieldValueValid(
                                        props.data?.receiverData
                                            .receiverAddressZip,
                                        props.isFormSubmitted
                                    )}
                                    validationError={t(
                                        'ValidationMessage.Required.Text'
                                    )}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (props.data) {
                                            props.updateData((prevData) => ({
                                                ...prevData,
                                                receiverData: {
                                                    ...prevData.receiverData,
                                                    receiverAddressZip:
                                                        e.target.value,
                                                },
                                            }));
                                        }
                                    }}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.ReceiverAddressZipHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.ReceiverAddressZipHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.ReceiverAddressZip.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                    combineInfoButtonTextWithTitle={true}
                                />
                                <TextInput
                                    type='text'
                                    placeholder={t('XRechnung.City.Text')}
                                    label={t('XRechnung.City.Text')}
                                    required={true}
                                    value={
                                        props.data?.receiverData
                                            .receiverAddressCity
                                    }
                                    isValid={ValidationTools.isRequiredFieldValueValid(
                                        props.data?.receiverData
                                            .receiverAddressCity,
                                        props.isFormSubmitted
                                    )}
                                    validationError={t(
                                        'ValidationMessage.Required.Text'
                                    )}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (props.data) {
                                            props.updateData((prevData) => ({
                                                ...prevData,
                                                receiverData: {
                                                    ...prevData.receiverData,
                                                    receiverAddressCity:
                                                        e.target.value,
                                                },
                                            }));
                                        }
                                    }}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.ReceiverAddressCityHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.ReceiverAddressCityHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.ReceiverAddressCity.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                    combineInfoButtonTextWithTitle={true}
                                />
                            </div>
                            <TextInput
                                type='text'
                                icon='geo-alt'
                                placeholder={t('XRechnung.Locality.Text')}
                                label={t('XRechnung.Locality.Text')}
                                value={
                                    props.data?.receiverData
                                        .receiverAddressLocality
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                receiverAddressLocality:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ReceiverAddressLocalityHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ReceiverAddressLocalityHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ReceiverAddressLocality.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <Select
                                placeholder={t('XRechnung.SelectCountry.Text')}
                                icon='pin-map'
                                autoComplete='country'
                                label={t('XRechnung.Country.Text')}
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                receiverAddressCountry:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                value={
                                    props.data?.receiverData
                                        .receiverAddressCountry || undefined
                                }
                                required={true}
                                isValid={ValidationTools.isRequiredFieldValueValid(
                                    props.data?.receiverData
                                        .receiverAddressCountry,
                                    props.isFormSubmitted
                                )}
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ReceiverAddressCountryHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ReceiverAddressCountryHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ReceiverAddressCountry.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            >
                                <option value=''>
                                    {t('Common.NoSelection.Text')}
                                </option>
                                {props.countriesList?.map((country, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={country.code}
                                        >
                                            {country.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        </div>
                    }
                />

                <XRechnungAccordionItem
                    icon='person-lines-fill'
                    title={t('XRechnung.BuyerDetails.Text')}
                    expanded={pageHaveErrors}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            <TextInput
                                type='text'
                                icon='person'
                                placeholder={t(
                                    'XRechnung.BuyerContactPerson.Text'
                                )}
                                label={t('XRechnung.BuyerContactPerson.Text')}
                                value={
                                    props.data?.receiverData
                                        .buyerContactContactPerson
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                buyerContactContactPerson:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.BuyerContactPersonHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.BuyerContactPersonHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.BuyerContactPerson.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <TextInput
                                type='text'
                                autoComplete='email'
                                icon='envelope'
                                placeholder={t(
                                    'XRechnung.BuyerContactEmail.Text'
                                )}
                                label={t('XRechnung.BuyerContactEmail.Text')}
                                value={
                                    props.data?.receiverData
                                        .buyerContactContactEmail
                                }
                                isValid={
                                    ValidationTools.isEmailFieldValueValid(
                                        props.data?.receiverData
                                            .buyerContactContactEmail ?? '',
                                        false
                                    ) &&
                                    ValidationTools.isRequiredFieldValueValid(
                                        props.data?.receiverData
                                            .buyerContactContactEmail,
                                        props.isFormSubmitted
                                    )
                                }
                                required={true}
                                validationError={
                                    ValidationTools.isRequiredFieldValueValid(
                                        props.data?.receiverData
                                            .buyerContactContactEmail,
                                        props.isFormSubmitted
                                    )
                                        ? t(
                                              'ValidationMessage.EmailInvalid.Text'
                                          )
                                        : t('ValidationMessage.Required.Text')
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                buyerContactContactEmail:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.BuyerContactEmailHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.BuyerContactEmailHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.BuyerContactEmail.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <TextInput
                                type='tel'
                                icon='telephone'
                                placeholder={t(
                                    'XRechnung.BuyerContactPhoneNo.Text'
                                )}
                                label={t('XRechnung.BuyerContactPhoneNo.Text')}
                                value={
                                    props.data?.receiverData
                                        .buyerContactContactPhoneNo
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                buyerContactContactPhoneNo:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.BuyerContactPhoneNoHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.BuyerContactPhoneNoHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.BuyerContactPhoneNo.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                        </div>
                    }
                />

                <XRechnungAccordionItem
                    icon='truck'
                    title={t('XRechnung.OtherDeliveryDetails.Text')}
                    expanded={deliveryInfoHasErrors}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            <TextInput
                                type='text'
                                icon='tags'
                                placeholder={t(
                                    'XRechnung.DeliveryAddressName.Text'
                                )}
                                label={t('XRechnung.DeliveryAddressName.Text')}
                                value={
                                    props.data?.receiverData.deliveryAddressName
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                deliveryAddressName:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.DeliveryAddressNameHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.DeliveryAddressNameHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.DeliveryAddressName.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <TextInput
                                type='text'
                                icon='hash'
                                placeholder={t('XRechnung.DeliveryIdRef.Text')}
                                label={t('XRechnung.DeliveryIdRef.Text')}
                                value={props.data?.receiverData.deliveryIdRef}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                deliveryIdRef: e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.DeliveryIdRefHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.DeliveryIdRefHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.DeliveryIdRef.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <DatePicker
                                translation={UILibraryTranslations.getCalendarTranslation(
                                    t
                                )}
                                isFullScreen={true}
                                isValid={XRechnungBuyerValidators.isBuyerDeliveryDateValid(
                                    {
                                        data: props.data,
                                        isFormSubmitted: props.isFormSubmitted,
                                    }
                                )}
                                internalValidationEnabled={true}
                                value={{
                                    date:
                                        props.data?.receiverData.deliveryDate ||
                                        undefined,
                                }}
                                label={t('XRechnung.DeliveryDate.Text')}
                                onChange={(newValue: DatePickerValue) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                deliveryDate:
                                                    newValue.date || undefined,
                                            },
                                        }));
                                    }
                                }}
                                validationError={t(
                                    'UserFeedback.InvalidDate.Text'
                                )}
                                showFormat={true}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.DeliveryDateHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.DeliveryDateHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.DeliveryDate.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                                required={XRechnungBuyerValidators.IsBuyerDeliveryDateRequired(
                                    {
                                        data: props.data,
                                        isFormSubmitted: props.isFormSubmitted,
                                    }
                                )}
                            />

                            <TextInput
                                type='text'
                                icon='signpost'
                                placeholder={t(
                                    'XRechnung.DeliveryAddressStreet.Text'
                                )}
                                label={t(
                                    'XRechnung.DeliveryAddressStreet.Text'
                                )}
                                value={
                                    props.data?.receiverData
                                        .deliveryAddressStreet
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                deliveryAddressStreet:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.DeliveryAddressStreetHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.DeliveryAddressStreetHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.DeliveryAddressStreet.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <div className='flex-row'>
                                <TextInput
                                    type='text'
                                    icon='mailbox'
                                    placeholder={t('XRechnung.Zip.Text')}
                                    label={t('XRechnung.Zip.Text')}
                                    value={
                                        props.data?.receiverData
                                            .deliveryAddressZip
                                    }
                                    required={deliveryInfoHasContent}
                                    isValid={
                                        !deliveryInfoHasContent ||
                                        ValidationTools.isRequiredFieldValueValid(
                                            props.data?.receiverData
                                                .deliveryAddressZip,
                                            props.isFormSubmitted
                                        )
                                    }
                                    validationError={t(
                                        'ValidationMessage.Required.Text'
                                    )}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (props.data) {
                                            props.updateData((prevData) => ({
                                                ...prevData,
                                                receiverData: {
                                                    ...prevData.receiverData,
                                                    deliveryAddressZip:
                                                        e.target.value,
                                                },
                                            }));
                                        }
                                    }}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.DeliveryAddressZipHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.DeliveryAddressZipHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.DeliveryAddressZip.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                    combineInfoButtonTextWithTitle={true}
                                />
                                <TextInput
                                    type='text'
                                    placeholder={t('XRechnung.City.Text')}
                                    label={t('XRechnung.City.Text')}
                                    value={
                                        props.data?.receiverData
                                            .deliveryAddressCity
                                    }
                                    required={deliveryInfoHasContent}
                                    isValid={
                                        !deliveryInfoHasContent ||
                                        ValidationTools.isRequiredFieldValueValid(
                                            props.data?.receiverData
                                                .deliveryAddressCity,
                                            props.isFormSubmitted
                                        )
                                    }
                                    validationError={t(
                                        'ValidationMessage.Required.Text'
                                    )}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (props.data) {
                                            props.updateData((prevData) => ({
                                                ...prevData,
                                                receiverData: {
                                                    ...prevData.receiverData,
                                                    deliveryAddressCity:
                                                        e.target.value,
                                                },
                                            }));
                                        }
                                    }}
                                    infoButtonEnabled={true}
                                    infoButtonText={t(
                                        'XRechnung.DeliveryAddressCityHelpTitle.ShortText'
                                    )}
                                    infoButtonTooltipTitle={t(
                                        'XRechnung.DeliveryAddressCityHelpTitle.Text'
                                    )}
                                    infoButtonTooltipText={t(
                                        'XRechnung.DeliveryAddressCity.Help'
                                    )}
                                    infoButtonTitle={t('Common.InputHelp.Text')}
                                    infoButtonAriaLabel={t(
                                        'Common.InputHelp.Text'
                                    )}
                                    combineInfoButtonTextWithTitle={true}
                                />
                            </div>
                            <TextInput
                                type='text'
                                icon='signpost-2'
                                placeholder={t(
                                    'XRechnung.DeliveryAddressSubLocality.Text'
                                )}
                                label={t(
                                    'XRechnung.DeliveryAddressSubLocality.Text'
                                )}
                                value={
                                    props.data?.receiverData
                                        .deliveryAddressSubLocality
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                deliveryAddressSubLocality:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.DeliveryAddressSubLocalityHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.DeliveryAddressSubLocalityHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.DeliveryAddressSubLocality.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <TextInput
                                type='text'
                                icon='signpost-2'
                                placeholder={t(
                                    'XRechnung.DeliveryAddressAddGeoInf.Text'
                                )}
                                label={t(
                                    'XRechnung.DeliveryAddressAddGeoInf.Text'
                                )}
                                value={
                                    props.data?.receiverData
                                        .deliveryAddressAddGeoInf
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                deliveryAddressAddGeoInf:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.DeliveryAddressAddGeoInfHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.DeliveryAddressAddGeoInfHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.DeliveryAddressAddGeoInf.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />

                            <TextInput
                                type='text'
                                icon='geo-alt'
                                placeholder={t(
                                    'XRechnung.DeliveryAddressLocality.Text'
                                )}
                                label={t(
                                    'XRechnung.DeliveryAddressLocality.Text'
                                )}
                                value={
                                    props.data?.receiverData
                                        .deliveryAddressLocality
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                deliveryAddressLocality:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.DeliveryAddressLocalityHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.DeliveryAddressLocalityHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.DeliveryAddressLocality.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <Select
                                placeholder={t('XRechnung.SelectCountry.Text')}
                                icon='pin-map'
                                autoComplete='country'
                                label={t(
                                    'XRechnung.DeliveryAddressCountry.Text'
                                )}
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            receiverData: {
                                                ...prevData.receiverData,
                                                deliveryAddressCountry:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                required={deliveryInfoHasContent}
                                isValid={
                                    !deliveryInfoHasContent ||
                                    ValidationTools.isRequiredFieldValueValid(
                                        props.data?.receiverData
                                            .deliveryAddressCountry,
                                        props.isFormSubmitted
                                    )
                                }
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                                value={
                                    props.data?.receiverData
                                        .deliveryAddressCountry || undefined
                                }
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.DeliveryAddressCountryHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.DeliveryAddressCountryHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.DeliveryAddressCountry.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            >
                                <option value=''>
                                    {t('Common.NoSelection.Text')}
                                </option>
                                {props.countriesList?.map((country, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={country.code}
                                        >
                                            {country.name}
                                        </option>
                                    );
                                })}
                            </Select>
                        </div>
                    }
                />
            </Accordion>
        </div>
    );
}
