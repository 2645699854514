import { RequestTools } from '@b4valuenet/ui-react';
import { GenericActionResponse } from '../models/GenericActionResponse';
import { GetPasswordRequirementsResponse } from '../models/GetPasswordRequirementsResponse';
import { PasswordResetTokenValidationResponse } from '../models/PasswordResetTokenValidationResponse';

export default class PasswordService {
    static ApiRoot = 'api/password/';

    static getPasswordRequirements =
        async (): Promise<GetPasswordRequirementsResponse | null> => {
            let response = await RequestTools.get(
                this.ApiRoot + 'getpwdrestrictions'
            );

            let json = await response.data;
            let result = (json as GetPasswordRequirementsResponse) || null;

            return result;
        };

    static requestPasswordReset = async (
        email: string
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.post(
            this.ApiRoot + 'requestPwdReset',
            {
                email: email,
            }
        );

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result;
    };

    static validatePasswordResetToken = async (
        token: string
    ): Promise<PasswordResetTokenValidationResponse | null> => {
        let response = await RequestTools.post(
            this.ApiRoot + 'validatePwdResetToken',
            {
                token: token,
            }
        );

        let json = await response.data;
        let result = (json as PasswordResetTokenValidationResponse) || null;

        return result;
    };

    static resetPassword = async (
        token: string,
        email: string,
        password: string,
        confirmPassword: string
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.put(this.ApiRoot + 'reset', {
            token: token,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
        });

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result;
    };

    static updateExpiredPassword = async (
        token: string,
        email: string,
        password: string,
        confirmPassword: string
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.post(
            this.ApiRoot + 'updateExpiredPwd',
            {
                token: token,
                email: email,
                password: password,
                confirmPassword: confirmPassword,
            }
        );

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result;
    };
}
