import {
    Accordion,
    Button,
    CalendarMode,
    DatePicker,
    DatePickerValue,
    IInputSearchResult,
    LoadingOverlay,
    RequestTools,
    RequiredFieldsNotice,
    Select,
    TextArea,
    TextInput,
    Toast,
    ValidationTools,
    useDebounce,
    useOutsideClickDetector,
} from '@b4valuenet/ui-react';
import {
    IXRechnungSubPage,
    getTempValue,
    setTempValue,
} from '../../XRechnungPage';
import { createRef, useEffect, useState } from 'react';

import CashDiscount from './components/CashDiscount';
import { Discount } from '../../../../../../models/Discount';
import { KeyValuePair } from '../../../../../../models/KeyValuePair';
import { LeitwegIdSearchResult } from '../../../../../../models/LeitwegIdSearchResult';
import { ReceiverData } from '../../../../../../models/ReceiverData';
import ReceiverDataDiffDialog from './components/ReceiverDataDiffDialog';
import SetupService from '../../../../../../services/SetupService';
import { UILibraryTranslations } from '../../../../../../shared/UILibraryTranslations';
import { XRechnungAccordionItem } from '../../components/XRechnungAccordionItem';
import XRechnungPagesValidator from '../../XRechnungPagesValidator';
import XRechnungService from '../../../../../../services/XRechnungService';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

export function XRechnungInvoiceData(props: IXRechnungSubPage) {
    const { t } = useTranslation();

    const leitwegIdInputRef = createRef<HTMLInputElement>();

    const appSettings = useAppSelector((state) => state.app.settings);

    const [currencyList, setCurrencyList] = useState<KeyValuePair[]>([]);

    const [leitwegIdFieldValue, setLeitwegIdFieldValue] = useState<string>(
        props.data?.invoiceData.leitwegId ?? ''
    );
    const [leitwegIdSearchActive, setLeitwegIdSearchActive] =
        useState<boolean>(false);
    const [leitwegIdSearchResults, setLeitwegIdSearchResults] = useState<
        LeitwegIdSearchResult[]
    >([]);
    const [leitwegIdSearchIsLoading, setLeitwegIdSearchIsLoading] =
        useState<boolean>(false);
    const [leitwegIdSearchError, setLeitwegIdSearchError] = useState<string>();

    const [receiverDataDiffDialogActive, setReceiverDataDiffDialogActive] =
        useState<boolean>(false);
    const [receiverDataDiffDialogData1, setReceiverDataDiffDialogData1] =
        useState<ReceiverData | null>(null);
    const [receiverDataDiffDialogData2, setReceiverDataDiffDialogData2] =
        useState<ReceiverData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setLeitwegIdFieldValue(props.data?.invoiceData.leitwegId ?? '');
    }, [props.data?.invoiceData.leitwegId]);

    useEffect(() => {
        const fetchCurrencyOptions = async () => {
            try {
                let list = (await XRechnungService.getCurrencyOptions()) ?? [];
                setCurrencyList(list ?? []);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        };
        fetchCurrencyOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useDebounce(() => {
        async function doLeitwegIdSearch() {
            setLeitwegIdSearchIsLoading(true);

            try {
                setLeitwegIdSearchError(undefined);
                let result = await SetupService.search(leitwegIdFieldValue);
                setLeitwegIdSearchResults(result);
            } catch (error) {
                let message = RequestTools.getApiErrorMessage(error);
                setLeitwegIdSearchError(message);
            }

            setLeitwegIdSearchIsLoading(false);
        }

        if (leitwegIdFieldValue.length >= 3) {
            doLeitwegIdSearch();
        } else {
            setLeitwegIdSearchResults([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leitwegIdFieldValue]);

    let transformedLeitwegIdSearchResults = leitwegIdSearchResults.map(
        (result) => {
            return {
                name: result.name,
                description: result.leitwegId,
                value: result.leitwegId,
                icon: 'building',
                active: result.leitwegId === leitwegIdFieldValue,
            } as IInputSearchResult;
        }
    );

    let isLeitwegIdValid =
        transformedLeitwegIdSearchResults.find(
            (result) => result.active === true
        ) !== undefined;

    useOutsideClickDetector(leitwegIdInputRef, () => {
        setLeitwegIdSearchActive(false);
    });

    let discountsHaveErrors =
        !XRechnungPagesValidator.InvoiceDataDiscountsValid({
            discounts: props.data?.invoiceData.discounts,
        });

    let noDataText =
        leitwegIdFieldValue.length < 3
            ? t('ValidationMessage.ReferenceTooShort.Text')
            : t('ValidationMessage.ReferenceNotFound.Text');
    let searchNotActiveText = t('Common.SearchActiveWhen.Text', { length: 3 });

    return (
        <div className='spaced-fields'>
            <ReceiverDataDiffDialog
                data1={receiverDataDiffDialogData1}
                data2={receiverDataDiffDialogData2}
                active={receiverDataDiffDialogActive}
                onClose={(confirmed: boolean) => {
                    if (confirmed && receiverDataDiffDialogData2) {
                        props.updateData((prevData) => ({
                            ...prevData,
                            receiverData: receiverDataDiffDialogData2,
                        }));
                    }

                    setReceiverDataDiffDialogActive(false);
                }}
                title={t('XRechnung.DataReplaceConfirmation.Text') + ': '}
            />
            <RequiredFieldsNotice
                description={t(
                    'ValidationMessage.IndicatesARequiredField.Text'
                )}
            />
            <Select
                value={props.data?.invoiceData.messageType.toString()}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    if (props.data) {
                        props.updateData((prevData) => ({
                            ...prevData,
                            invoiceData: {
                                ...prevData.invoiceData,
                                messageType: +e.target.value,
                            },
                        }));
                    }
                }}
                icon='receipt'
                placeholder={t('XRechnung.MessageType.Text')}
                label={t('XRechnung.MessageType.Text')}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.data?.invoiceData.messageType ? 'not empty' : null,
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                infoButtonEnabled={true}
                infoButtonText={t('XRechnung.MessageTypeHelpTitle.ShortText')}
                infoButtonTooltipTitle={t(
                    'XRechnung.MessageTypeHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.MessageType.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            >
                {props.messageTypes.map((type) => {
                    return (
                        <option key={type.key} value={type.key}>
                            {type.value}
                        </option>
                    );
                })}
            </Select>
            <div
                onBlur={(e: React.FocusEvent<HTMLDivElement>) => {
                    let input = leitwegIdInputRef.current;
                    setTimeout(() => {
                        if (input instanceof HTMLElement) {
                            let inputWrapper = input.parentElement;

                            if (
                                document.activeElement &&
                                (inputWrapper?.contains(
                                    document.activeElement
                                ) ||
                                    document.activeElement === inputWrapper)
                            ) {
                            } else {
                                setLeitwegIdSearchActive(false);
                            }
                        }
                    }, 100);
                }}
            >
                <TextInput
                    inputRef={leitwegIdInputRef}
                    autoComplete='off'
                    value={leitwegIdFieldValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (props.data) {
                            props.updateData((prevData) => ({
                                ...prevData,
                                invoiceData: {
                                    ...prevData.invoiceData,
                                    leitwegId: e.target.value,
                                },
                            }));
                        }
                    }}
                    onFocus={() => {
                        if (leitwegIdFieldValue.length > 3) {
                            setLeitwegIdSearchActive(true);
                        }
                    }}
                    isValid={
                        (leitwegIdSearchIsLoading &&
                            leitwegIdFieldValue.length > 0) ||
                        (!props.isFormSubmitted &&
                            leitwegIdFieldValue === '') ||
                        leitwegIdSearchActive ||
                        isLeitwegIdValid
                    }
                    validationError={t(
                        leitwegIdFieldValue.length >= 3
                            ? leitwegIdSearchError ??
                                  'ValidationMessage.InvalidLeitwegId.Text'
                            : t('ValidationMessage.ReferenceTooShort.Text')
                    )}
                    searchResults={{
                        startSearchOnLength: 1,
                        results: transformedLeitwegIdSearchResults,
                        isLoading: leitwegIdSearchIsLoading,
                        error: leitwegIdSearchError,
                        onSelect: async (leitwegId: string | number) => {
                            let result = leitwegIdSearchResults.find(
                                (r) => r.leitwegId === leitwegId
                            );

                            if (result) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    invoiceData: {
                                        ...prevData.invoiceData,
                                        leitwegId: leitwegId.toString(),
                                    },
                                }));
                                setLeitwegIdFieldValue(leitwegId.toString());
                                setLeitwegIdSearchActive(false);

                                if (result.requestReceiverData) {
                                    setIsLoading(true);

                                    let newData =
                                        await SetupService.requestReceiverData(
                                            result.leitwegId
                                        );

                                    let data = {
                                        ...(props.data?.receiverData ??
                                            ({} as any)),
                                        ...newData,
                                    };

                                    setReceiverDataDiffDialogData1(
                                        props.data?.receiverData ?? null
                                    );
                                    setReceiverDataDiffDialogData2(data);
                                    setReceiverDataDiffDialogActive(true);

                                    setIsLoading(false);
                                }
                            }
                        },
                        noDataText: noDataText,
                        searchNotActiveText: searchNotActiveText,
                    }}
                    required={true}
                    type='text'
                    icon='search'
                    placeholder={t('XRechnung.LeitwegId.Text')}
                    label={t('XRechnung.LeitwegId.Text')}
                    infoButtonEnabled={true}
                    infoButtonText={t('XRechnung.LeitwegIdHelpTitle.ShortText')}
                    infoButtonTooltipTitle={t(
                        'XRechnung.LeitwegIdHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t('XRechnung.LeitwegId.Help')}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
            </div>

            <TextInput
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.data?.invoiceData.reference,
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                type='text'
                icon='bank'
                placeholder={t('XRechnung.Reference.Text')}
                label={t('XRechnung.Reference.Text')}
                value={props.data?.invoiceData.reference}
                maxLength={128}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (props.data) {
                        props.updateData((prevData) => ({
                            ...prevData,
                            invoiceData: {
                                ...prevData.invoiceData,
                                reference: e.target.value,
                            },
                        }));
                    }
                }}
                infoButtonEnabled={true}
                infoButtonText={t('XRechnung.ReferenceHelpTitle.ShortText')}
                infoButtonTooltipTitle={t('XRechnung.ReferenceHelpTitle.Text')}
                infoButtonTooltipText={t('XRechnung.Reference.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            {(props.data?.invoiceData.messageType === 381 ||
                props.data?.invoiceData.messageType === 384) && (
                <>
                    <TextInput
                        isValid={
                            props.data.invoiceData.referringInvoiceDate != null
                                ? ValidationTools.isRequiredFieldValueValid(
                                      props.data?.invoiceData
                                          .referringInvoiceReference,
                                      props.isFormSubmitted
                                  )
                                : true
                        }
                        required={
                            props.data.invoiceData.referringInvoiceDate != null
                        }
                        validationError={t('ValidationMessage.Required.Text')}
                        type='text'
                        icon='receipt'
                        placeholder={t(
                            'XRechnung.ReferringInvoiceReference.Text'
                        )}
                        label={t('XRechnung.ReferringInvoiceReference.Text')}
                        value={
                            props.data?.invoiceData.referringInvoiceReference
                        }
                        maxLength={200}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    invoiceData: {
                                        ...prevData.invoiceData,
                                        referringInvoiceReference:
                                            e.target.value,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.ReferringInvoiceReferenceHelp.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.ReferringInvoiceReferenceHelp.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.ReferringInvoiceReference.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                    <DatePicker
                        translation={UILibraryTranslations.getCalendarTranslation(
                            t
                        )}
                        isFullScreen={false}
                        value={{
                            date:
                                props.data?.invoiceData.referringInvoiceDate ||
                                undefined,
                        }}
                        rawStartDateTextValue={getTempValue(
                            'XInvoiceDataReferringInvoiceDate'
                        )}
                        onStartDateMaskedInputChange={(e) =>
                            setTempValue(
                                'XInvoiceDataReferringInvoiceDate',
                                e.target.value
                            )
                        }
                        label={t('XRechnung.ReferringInvoiceDate.Text')}
                        validationError={t('UserFeedback.InvalidDate.Text')}
                        onChange={(newValue: DatePickerValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    invoiceData: {
                                        ...prevData.invoiceData,
                                        referringInvoiceDate:
                                            newValue.date || null,
                                    },
                                }));
                            }
                        }}
                        showFormat={true}
                        internalValidationEnabled={true}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.ReferringInvoiceDateHelp.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.ReferringInvoiceDateHelp.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.ReferringInvoiceDate.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                </>
            )}

            <DatePicker
                translation={UILibraryTranslations.getCalendarTranslation(t)}
                isFullScreen={true}
                value={{ date: props.data?.invoiceData.docDate || undefined }}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.data?.invoiceData.docDate ? 'not empty' : null,
                    props.isFormSubmitted
                )}
                validationError={t('UserFeedback.InvalidDate.Text')}
                internalValidationEnabled={true}
                label={t('XRechnung.DocDate.Text')}
                onChange={(newValue: DatePickerValue) => {
                    if (props.data) {
                        props.updateData((prevData) => ({
                            ...prevData,
                            invoiceData: {
                                ...prevData.invoiceData,
                                docDate: newValue.date || undefined,
                            },
                        }));
                    }
                }}
                rawStartDateTextValue={getTempValue('XInvoiceDataDocDate')}
                onStartDateMaskedInputChange={(e) =>
                    setTempValue('XInvoiceDataDocDate', e.target.value)
                }
                showFormat={true}
                infoButtonEnabled={true}
                infoButtonText={t('XRechnung.DocDateHelpTitle.ShortText')}
                infoButtonTooltipTitle={t('XRechnung.DocDateHelpTitle.Text')}
                infoButtonTooltipText={t('XRechnung.DocDate.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <Select
                icon='cash'
                label={t('XRechnung.Currency.Text')}
                value={props.data?.invoiceData.currency}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    if (props.data) {
                        props.updateData((prevData) => ({
                            ...prevData,
                            invoiceData: {
                                ...prevData.invoiceData,
                                currency: e.target.value,
                            },
                        }));
                    }
                }}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.data?.invoiceData.messageType ? 'not empty' : null,
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                infoButtonEnabled={true}
                infoButtonText={t('XRechnung.CurrencyHelpTitle.ShortText')}
                infoButtonTooltipTitle={t('XRechnung.CurrencyHelpTitle.Text')}
                infoButtonTooltipText={t('XRechnung.Currency.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            >
                {currencyList.map((type) => {
                    return (
                        <option key={type.key} value={type.key}>
                            {type.value}
                        </option>
                    );
                })}
            </Select>

            <TextInput
                type='text'
                icon='bank'
                placeholder={t('XRechnung.PoNumber.Text')}
                label={t('XRechnung.PoNumber.Text')}
                value={props.data?.invoiceData.poNumber}
                required={
                    !!props.data?.invoiceData.orderNumberVendor &&
                    props.data?.invoiceData.orderNumberVendor.length > 0
                }
                isValid={
                    !!props.data?.invoiceData.orderNumberVendor ||
                    (props.data?.invoiceData.orderNumberVendor?.length ?? 0) > 0
                        ? ValidationTools.isRequiredFieldValueValid(
                              !!props.data?.invoiceData.poNumber ||
                                  (props.data?.invoiceData.poNumber?.length ??
                                      0) > 0
                                  ? 'not empty'
                                  : null,
                              props.isFormSubmitted
                          )
                        : true
                }
                validationError={t('ValidationMessage.Required.Text')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (props.data) {
                        props.updateData((prevData) => ({
                            ...prevData,
                            invoiceData: {
                                ...prevData.invoiceData,
                                poNumber: e.target.value,
                            },
                        }));
                    }
                }}
                infoButtonEnabled={true}
                infoButtonText={t('XRechnung.OrderNumberHelpTitle.ShortText')}
                infoButtonTooltipTitle={t(
                    'XRechnung.OrderNumberHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.OrderNumber.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            {props.data?.invoiceData.messageType !== 381 &&
                props.data?.invoiceData.messageType !== 384 && (
                    <DatePicker
                        translation={UILibraryTranslations.getCalendarTranslation(
                            t
                        )}
                        isFullScreen={true}
                        value={{
                            date:
                                props.data?.invoiceData.paymentDueDate ||
                                undefined,
                        }}
                        rawStartDateTextValue={getTempValue(
                            'XInvoiceDataPaymentDueDate'
                        )}
                        onStartDateMaskedInputChange={(e) =>
                            setTempValue(
                                'XInvoiceDataPaymentDueDate',
                                e.target.value
                            )
                        }
                        required={true}
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.data?.invoiceData.paymentDueDate
                                ? 'not empty'
                                : null,
                            props.isFormSubmitted
                        )}
                        internalValidationEnabled={true}
                        validationError={t('ValidationMessage.Required.Text')}
                        onChange={(newValue: DatePickerValue) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    invoiceData: {
                                        ...prevData.invoiceData,
                                        paymentDueDate:
                                            newValue.date || undefined,
                                    },
                                }));
                            }
                        }}
                        label={t('XRechnung.PaymentDueDate.Text')}
                        showFormat={true}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.PaymentDueDateHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.PaymentDueDateHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.PaymentDueDate.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                )}
            <Accordion>
                <XRechnungAccordionItem
                    icon='percent'
                    predefinedBT={[
                        t('XRechnung.DiscountDueDaysHelpTitle.ShortText'),
                    ]}
                    title={t('XRechnung.Discounts.Text')}
                    expanded={discountsHaveErrors}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            {props.data?.invoiceData.discounts.map(
                                (discount: Discount, index: number) => {
                                    return (
                                        <CashDiscount
                                            key={index}
                                            index={index}
                                            isFormSubmitted={
                                                props.isFormSubmitted
                                            }
                                            unitOptions={props.unitOptions}
                                            taxCategories={props.taxCategories}
                                            messageTypes={props.messageTypes}
                                            data={props.data}
                                            updateData={props.updateData}
                                            discount={discount}
                                        />
                                    );
                                }
                            )}

                            {props.data?.invoiceData.discounts &&
                            props.data.invoiceData.discounts.length <
                                (appSettings?.subItemLimit ?? 100) ? (
                                <Button
                                    className='center'
                                    icon='plus-lg'
                                    primary={true}
                                    text={t('XRechnung.AddDiscount.Text')}
                                    onClick={() => {
                                        if (props.data) {
                                            props.updateData((prevData) => ({
                                                ...prevData,
                                                invoiceData: {
                                                    ...prevData.invoiceData,
                                                    discounts: [
                                                        ...prevData.invoiceData
                                                            .discounts,
                                                        {
                                                            dueDays: undefined,
                                                            percentage:
                                                                undefined,
                                                            baseAmount:
                                                                undefined,
                                                        },
                                                    ],
                                                },
                                            }));
                                        }
                                    }}
                                />
                            ) : (
                                // TODO: Alert Component
                                <p
                                    style={{
                                        textAlign: 'center',
                                        color: 'var(--ui-error)',
                                    }}
                                >
                                    {t(
                                        'XRechnung.LimitSublineItemsReached.Text'
                                    )}
                                </p>
                            )}
                        </div>
                    }
                />
            </Accordion>
            <TextArea
                icon='card-checklist'
                rows={4}
                placeholder={t('XRechnung.PaymentTerms.Text')}
                label={t('XRechnung.PaymentTerms.Text')}
                value={props.data?.invoiceData.paymentTerms ?? ''}
                maxLength={250}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    if (props.data) {
                        props.updateData((prevData) => ({
                            ...prevData,
                            invoiceData: {
                                ...prevData.invoiceData,
                                paymentTerms: e.target.value,
                            },
                        }));
                    }
                }}
                infoButtonEnabled={true}
                infoButtonText={t('XRechnung.PaymentTermsHelpTitle.ShortText')}
                infoButtonTooltipTitle={t(
                    'XRechnung.PaymentTermsHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.PaymentTerms.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
                isValid={
                    !(
                        (props.data?.invoiceData.paymentTerms ?? '').indexOf(
                            '#'
                        ) > -1
                    )
                }
                validationError={t('ValidationMessage.HashtagsNotAllowed.Text')}
            />

            <fieldset>
                <legend>{t('XRechnung.InvoicePeriod.Text')}</legend>
                <DatePicker
                    translation={UILibraryTranslations.getCalendarTranslation(
                        t
                    )}
                    isFullScreen={true}
                    mode={CalendarMode.Range}
                    value={{
                        range: {
                            start:
                                props.data?.invoiceData
                                    .invoicePeriodStartDate || undefined,
                            end:
                                props.data?.invoiceData.invoicePeriodEndDate ||
                                undefined,
                        },
                    }}
                    rawStartDateTextValue={getTempValue(
                        'XInvoiceDataInvoicePeriodStartDate'
                    )}
                    onStartDateMaskedInputChange={(e) =>
                        setTempValue(
                            'XInvoiceDataInvoicePeriodStartDate',
                            e.target.value
                        )
                    }
                    rawEndDateTextValue={getTempValue(
                        'XInvoiceDataInvoicePeriodEndDate'
                    )}
                    onEndDateMaskedInputChange={(e) =>
                        setTempValue(
                            'XInvoiceDataInvoicePeriodEndDate',
                            e.target.value
                        )
                    }
                    onChange={(newValue: DatePickerValue) => {
                        if (props.data) {
                            props.updateData((prevData) => ({
                                ...prevData,
                                invoiceData: {
                                    ...prevData.invoiceData,
                                    invoicePeriodStartDate:
                                        newValue.range?.start || null,
                                    invoicePeriodEndDate:
                                        newValue.range?.end || null,
                                },
                            }));
                        }
                    }}
                    label={t('XRechnung.PaymentDueDate.Text')}
                    showFormat={true}
                    required={
                        (props.data?.invoiceData.invoicePeriodStartDate !==
                            null &&
                            props.data?.invoiceData.invoicePeriodEndDate ===
                                null) ||
                        (props.data?.invoiceData.invoicePeriodStartDate ===
                            null &&
                            props.data?.invoiceData.invoicePeriodEndDate !==
                                null)
                    }
                    isValid={
                        (props.data?.invoiceData.invoicePeriodStartDate ===
                            null &&
                            props.data?.invoiceData.invoicePeriodEndDate ===
                                null) ||
                        (props.data?.invoiceData.invoicePeriodStartDate !==
                            null &&
                            props.data?.invoiceData.invoicePeriodEndDate ===
                                null) ||
                        (props.data?.invoiceData.invoicePeriodStartDate !==
                            null &&
                            props.data?.invoiceData.invoicePeriodEndDate !==
                                null)
                    }
                    isEndDateValid={
                        (props.data?.invoiceData.invoicePeriodStartDate ===
                            null &&
                            props.data?.invoiceData.invoicePeriodEndDate ===
                                null) ||
                        (props.data?.invoiceData.invoicePeriodStartDate ===
                            null &&
                            props.data?.invoiceData.invoicePeriodEndDate !==
                                null) ||
                        (props.data?.invoiceData.invoicePeriodStartDate !==
                            null &&
                            props.data?.invoiceData.invoicePeriodEndDate !==
                                null)
                    }
                    validationError={t('UserFeedback.InvalidDate.Text')}
                    validationErrorEnd={t('UserFeedback.InvalidDate.Text')}
                    internalValidationEnabled={true}
                    rangeStartInfoButtonEnabled={true}
                    rangeStartInfoButtonText={t(
                        'XRechnung.InvoicePeriodStartDateHelpTitle.ShortText'
                    )}
                    rangeStartInfoButtonTooltipTitle={t(
                        'XRechnung.InvoicePeriodStartDateHelpTitle.Text'
                    )}
                    rangeStartInfoButtonTooltipText={t(
                        'XRechnung.InvoicePeriodStartDate.Help'
                    )}
                    rangeStartInfoButtonTitle={t('Common.InputHelp.Text')}
                    rangeStartInfoButtonAriaLabel={t('Common.InputHelp.Text')}
                    rangeEndInfoButtonEnabled={true}
                    rangeEndInfoButtonText={t(
                        'XRechnung.InvoicePeriodEndDateHelpTitle.ShortText'
                    )}
                    rangeEndInfoButtonTooltipTitle={t(
                        'XRechnung.InvoicePeriodEndDateHelpTitle.Text'
                    )}
                    rangeEndInfoButtonTooltipText={t(
                        'XRechnung.InvoicePeriodEndDate.Help'
                    )}
                    rangeEndInfoButtonTitle={t('Common.InputHelp.Text')}
                    rangeEndInfoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
            </fieldset>

            <TextArea
                icon='card-list'
                rows={4}
                placeholder={t('XRechnung.NoteContent.Text')}
                label={t('XRechnung.NoteContent.Text')}
                value={props.data?.invoiceData.noteContent ?? ''}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    if (props.data) {
                        props.updateData((prevData) => ({
                            ...prevData,
                            invoiceData: {
                                ...prevData.invoiceData,
                                noteContent: e.target.value,
                            },
                        }));
                    }
                }}
                infoButtonEnabled={true}
                infoButtonText={t('XRechnung.NoteContentHelpTitle.ShortText')}
                infoButtonTooltipTitle={t(
                    'XRechnung.NoteContentHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.NoteContent.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <Accordion>
                <XRechnungAccordionItem
                    icon='link-45deg'
                    title={t('XRechnung.References.Text')}
                    expanded={false}
                    textWhenErrorsArePresent={t(
                        'ValidationMessage.ErrorsPresent.Text'
                    )}
                    body={
                        <div className='spaced-fields'>
                            <TextInput
                                type='text'
                                placeholder={t('XRechnung.ProjectNumber.Text')}
                                label={t('XRechnung.ProjectNumber.Text')}
                                value={props.data?.invoiceData.projectNumber}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            invoiceData: {
                                                ...prevData.invoiceData,
                                                projectNumber: e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ProjectNumberHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ProjectNumberHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ProjectNumber.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <TextInput
                                type='text'
                                placeholder={t('XRechnung.ContractNumber.Text')}
                                label={t('XRechnung.ContractNumber.Text')}
                                value={props.data?.invoiceData.contractNumber}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            invoiceData: {
                                                ...prevData.invoiceData,
                                                contractNumber: e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.ContractNumberVendorHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.ContractNumberVendorHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.ContractNumber.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <TextInput
                                type='text'
                                placeholder={t(
                                    'XRechnung.OrderNumberVendor.Text'
                                )}
                                label={t('XRechnung.OrderNumberVendor.Text')}
                                value={
                                    props.data?.invoiceData.orderNumberVendor
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            invoiceData: {
                                                ...prevData.invoiceData,
                                                orderNumberVendor:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.OrderNumberVendorHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.OrderNumberVendorHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.OrderNumberVendor.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <TextInput
                                type='text'
                                placeholder={t('XRechnung.TenderNumber.Text')}
                                label={t('XRechnung.TenderNumber.Text')}
                                value={props.data?.invoiceData.tenderNumber}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            invoiceData: {
                                                ...prevData.invoiceData,
                                                tenderNumber: e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.TenderNumberVendorHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.TenderNumberVendorHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.TenderNumberVendor.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                            <TextInput
                                type='text'
                                placeholder={t(
                                    'XRechnung.InvoicedObjectReference.Text'
                                )}
                                label={t(
                                    'XRechnung.InvoicedObjectReference.Text'
                                )}
                                value={
                                    props.data?.invoiceData
                                        .invoicedObjectReference
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (props.data) {
                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            invoiceData: {
                                                ...prevData.invoiceData,
                                                invoicedObjectReference:
                                                    e.target.value,
                                            },
                                        }));
                                    }
                                }}
                                infoButtonEnabled={true}
                                infoButtonText={t(
                                    'XRechnung.InvoicedObjectReferenceHelpTitle.ShortText'
                                )}
                                infoButtonTooltipTitle={t(
                                    'XRechnung.InvoicedObjectReferenceHelpTitle.Text'
                                )}
                                infoButtonTooltipText={t(
                                    'XRechnung.InvoicedObjectReference.Help'
                                )}
                                infoButtonTitle={t('Common.InputHelp.Text')}
                                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                                combineInfoButtonTextWithTitle={true}
                            />
                        </div>
                    }
                />
            </Accordion>
            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={isLoading}
            />
        </div>
    );
}
