import {
    Button,
    Dialog,
    LoadingOverlay,
    Toast
} from '@b4valuenet/ui-react';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientService from '../../../../../services/ClientService';

export interface IPeppolVerificationDialog {
    onClose: CallableFunction;
    isActive: boolean;
    expiryDate: Date | null;
}

export default function PeppolVerificationDialog(
    props: IPeppolVerificationDialog
) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDownloadButtonOnClick = async () => {
        setIsLoading(true);

        try {
            await ClientService.downloadPeppolVerificationAttachment();
            Toast.info(t('Messages.DownloadStarted.Text'));
            setIsLoading(false);
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);

            setIsLoading(false);
        }
    };

    return (
        <Dialog
            title={t('ClientAdministration.PeppolDownloadAttachment.Text')}
            closeButtonTitle={t('Common.Close.Text')}
            onClose={() => props.onClose()}
            active={props.isActive}
            className='peppol-verification-dialog'
        >
            <p style={{ textAlign: 'justify' }}>
                {t(
                    'ClientAdministration.LEAPeppolAssignmentCompleteDescription.Text'
                )}
            </p>
            <p>
                {t('ClientAdministration.TokenExpiryMessage.Text')}
                {': '}
                <strong>
                    {props.expiryDate &&
                        new Date(props.expiryDate).toLocaleTimeString('de-DE', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })}
                </strong>
            </p>
            <div className='buttons-group'>
                <Button
                    text={t(
                        'ClientAdministration.PeppolDownloadAttachment.Text'
                    )}
                    onClick={() => {
                        handleDownloadButtonOnClick();
                    }}
                    icon={'download'}
                    primary={true}
                />
                <Button
                    text={t('ClientAdministration.Close.Text')}
                    icon={'x-lg'}
                    onClick={() => props.onClose()}
                />
            </div>
            <LoadingOverlay
                loadingMessage={t('Messages.DownloadPrepared.Text')}
                active={isLoading}
                isGlobal={false}
            />
        </Dialog>
    );
}
