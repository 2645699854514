import { Button, Toast } from '@b4valuenet/ui-react';

import { ModalWindow } from '@b4valuenet/ui-react';
import UserAdministrationService from '../../../../../services/UserAdministrationService';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface IDeleteItemsModal {
    active: boolean;
    onClose: CallableFunction;
    selectedIds: (number | string)[];
    deleteId: number | null;
}

export default function DeleteItemsModal(props: IDeleteItemsModal) {
    const { t } = useTranslation();

    function onClose(actionConfirmed: boolean) {
        props.onClose(actionConfirmed);
    }

    function handleCancelButtonClick() {
        onClose(false);
    }

    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function handleFormSubmit() {
        setIsLoading(true);

        if (props.selectedIds.length > 0 || props.deleteId) {
            try {
                let response = await UserAdministrationService.delete(
                    props.deleteId ? [props.deleteId] : props.selectedIds
                );
                if (response !== null) {
                    Toast.success(response.message, 350);
                }
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        }

        setIsLoading(false);

        onClose(true);
    }

    return (
        <ModalWindow
            isLoading={isLoading}
            aria-labelledby='users-modal-delete-title'
            active={props.active}
            onSubmit={() => handleFormSubmit()}
            onEscapeKeyPressed={() => {
                if (!isLoading) onClose(false);
            }}
        >
            <h2 id='users-modal-delete-title'>
                {t(
                    props.selectedIds.length > 1
                        ? 'UserAdministration.DeleteDialogMultiple.Text'
                        : 'UserAdministration.DeleteDialog.Text'
                )}
            </h2>
            <div className='modal-main'>
                <p>
                    {t(
                        props.selectedIds.length > 1
                            ? 'UserAdministration.DeleteDialogMultiple.Tooltip'
                            : 'UserAdministration.DeleteDialog.Tooltip',
                        {
                            messageCount: props.selectedIds.length,
                        }
                    )}
                </p>
            </div>
            <div className='footer'>
                <Button
                    onClick={() => handleCancelButtonClick()}
                    text={t('Common.No.Text')}
                />
                <Button
                    type='submit'
                    primary={true}
                    text={t('Common.Yes.Text')}
                />
            </div>
        </ModalWindow>
    );
}
