import './Imprint.scss';

import { Button } from '@b4valuenet/ui-react';

import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAppSelector } from '../../redux/hooks';

const Imprint = () => {
    const { t } = useTranslation();

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    return (
        <div className='sub-page imprint'>
            <h1>{t('Common.Imprint.Text')}</h1>
            <p>{t('ImprintPage.Block2.Text')}</p>
            <p>{t('ImprintPage.Block3.Text')}</p>
            {!isLoggedIn && (
                <Button
                    linkTo={'/'}
                    isLink={true}
                    icon='chevron-left'
                    text={t('PasswordForgotten.CancelButton.Text')}
                    primary={true}
                />
            )}
            <br />
            <hr />
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {t('ImprintPage.Content.Text', {
                    datenschutzLink: 'auth/privacy-policy',
                })}
            </ReactMarkdown>
        </div>
    );
};

export default Imprint;
