import { GenericActionResponse } from '../models/GenericActionResponse';
import { RegisterCompanyData } from '../models/RegisterCompanyData';
import { RegisterUserData } from '../models/RegisterUserData';
import { RegistrationConfirmationResult } from '../models/RegistrationConfirmationResult';
import { RequestTools } from '@b4valuenet/ui-react';

export default class RegistrationService {
    static ApiRoot = 'api/registration/';

    static register = async (
        companyData: RegisterCompanyData,
        userData: RegisterUserData
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.post(this.ApiRoot + 'register', {
            companyName: companyData.name,
            street: companyData.street,
            zip: companyData.zip,
            city: companyData.city,
            language: companyData.language,
            country: companyData.country,
            uId: companyData.vatId,
            taxId: companyData.taxId,
            firstName: userData.firstName,
            lastName: userData.lastName,
            userLanguage: userData.language,
            email: userData.email,
            password: userData.password,
            confirmedPassword: userData.confirmPassword,
        });

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result;
    };

    static confirmRegistration = async (
        token: string
    ): Promise<RegistrationConfirmationResult | null> => {
        let response = await RequestTools.post(
            this.ApiRoot + 'confirm?t=' + token
        );

        let json = await response.data;
        let result = (json.data as RegistrationConfirmationResult) || null;

        return result;
    };
}
