import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import {
    Button,
    CookiesBanner,
    ErrorOverlay,
    LoadingOverlay,
    MainContentLayerProvider,
    RequestTools,
    WindowSizeObserverProvider,
} from '@b4valuenet/ui-react';
import {
    CloseButtonProps,
    ToastContainer,
    cssTransition,
} from 'react-toastify';
import { I18nContext, I18nextProvider } from 'react-i18next';
import { checkAuth, getAppSettings } from './redux/actions/app';
import { createContext, useContext, useEffect, useState } from 'react';
import i18next, { InitOptions } from 'i18next';
import { useAppDispatch, useAppSelector } from './redux/hooks';

import LocaleService from './services/LocaleService';
import { MemorizedAppRouter } from './AppRouter';
import ReactMarkdown from 'react-markdown';
import { isLoadingUpdated } from './redux/slices/AppSlice';
import { useCookies } from 'react-cookie';
import { IconLibrary, UnifiedIconsContext } from '@b4valuenet/ui-react';

export const generateAssetLink = (asset: string): string => {
    return (process.env.REACT_APP_ASSETS_ENDPOINT ?? './') + asset;
};

export const AppMainContext = createContext<{
    translationsLoaded: boolean;
} | null>(null);

export const useAppMainContext = () => useContext(AppMainContext);

export default function App() {
    const dispatch = useAppDispatch();

    const appSettings = useAppSelector((state) => state.app.settings);
    const userSettings = useAppSelector((state) => state.userSettings);
    const authenticatedUser = useAppSelector((state) => state.auth.user);
    const appCanBeVisible = useAppSelector((state) => state.app.canBeVisible);
    const isLoading = useAppSelector((state) => state.app.isLoading);
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    const loadingError = useAppSelector((state) => state.app.loadingError);

    const [locale, setLocale] = useState<string>('de');

    const [translationDE, setTranslationDE] = useState<object>();
    const [translationEN, setTranslationEN] = useState<object>();

    const [cookies, setCookie] = useCookies([
        'dashboard-route',
        'lea-cookies-accepted',
    ]);

    const [cookiesBarCanBeVisible, setCookiesBarCanBeVisible] =
        useState<boolean>(true);

    const [routerBasename, setRouterBasename] = useState<string | null>(null);

    const [isLoadingDialogActive, setIsLoadingDialogActive] =
        useState<boolean>(false);

    useEffect(() => {
        setIsLoadingDialogActive(isLoading);
    }, [isLoading]);

    // Auto updates locale when auth or settings changed
    useEffect(() => {
        const defaultLang = 'en';
        const browserLang = LocaleService.getBrowserLanguage();
        const localStorageLang = localStorage.getItem('lang');

        let authenticatedUserLang = authenticatedUser
            ? authenticatedUser.language
            : null;

        if (authenticatedUserLang) {
            setLocale(authenticatedUserLang);
        } else {
            let newLang = defaultLang;

            if (!userSettings.lang) {
                if (!localStorageLang) {
                    let browserLangCountryCode = browserLang
                        ? browserLang.slice(0, 2)
                        : defaultLang;

                    if (
                        !['en', 'de'].includes(
                            browserLangCountryCode?.toLowerCase()
                        )
                    ) {
                        browserLangCountryCode = defaultLang;
                    }

                    localStorage.setItem('lang', browserLangCountryCode);

                    newLang = browserLangCountryCode;
                } else {
                    newLang = localStorageLang;
                }
            } else {
                newLang = userSettings.lang;
                localStorage.setItem('lang', userSettings.lang);
            }
            setLocale(newLang);
        }
    }, [authenticatedUser, userSettings]);

    // Auto updates router basename
    useEffect(() => {
        let baseElement = document.querySelector('base');
        let baseElementHref =
            baseElement !== null
                ? RequestTools.getUrlParts(baseElement.href).pathname
                : null;

        if (baseElement !== null && baseElementHref !== routerBasename) {
            setRouterBasename(baseElementHref);
        }
    }, [routerBasename]);

    // Gets translations, app info and checks auth
    useEffect(() => {
        const fetchMainData = async () => {
            dispatch(isLoadingUpdated({ isLoading: true }));

            try {
                let newTranslationEN = await LocaleService.getTranslation('en');
                let newTranslationDE = await LocaleService.getTranslation('de');

                setTranslationEN(newTranslationEN);
                setTranslationDE(newTranslationDE);

                await dispatch(checkAuth());
            } catch (error) {
                let errorText = RequestTools.getApiErrorMessage(error);
                let errorCode = RequestTools.getApiErrorCode(error);

                dispatch(
                    isLoadingUpdated({
                        isLoading: false,
                        loadingError:
                            errorCode !== 404
                                ? errorText + ' on fetchMainData (translations)'
                                : 'ERROR: translation not found.',
                    })
                );
            }
        };

        fetchMainData();
    }, [dispatch]);

    // Auto updates i18next when locale updated
    useEffect(() => {
        document.querySelector('html')?.setAttribute('lang', locale);

        i18next.init({
            lng: locale,
            interpolation: {
                prefix: '{',
                suffix: '}',
            },
            resources: {
                de: {
                    translation: translationDE,
                },
                en: {
                    translation: translationEN,
                },
            },
            //debug: true,
        } as InitOptions);
    }, [locale, translationEN, translationDE, dispatch]);

    useEffect(() => {
        if (locale.length > 0) dispatch(getAppSettings(locale));
    }, [locale, dispatch]);

    // Toast show and hide animations
    const toastAnimation = cssTransition({
        enter: 'toast-show',
        exit: 'toast-hide',
    });

    // Component to override default toast close button
    const toastCloseButton = ({ closeToast }: CloseButtonProps) => (
        <I18nContext.Consumer>
            {(i18n) => {
                return (
                    <Button
                        aria-label={i18n.i18n.t('Common.Close.Text')}
                        title={i18n.i18n.t('Common.Close.Text')}
                        className='close'
                        icon='x'
                        onClick={closeToast}
                    />
                );
            }}
        </I18nContext.Consumer>
    );

    return (
        <I18nextProvider i18n={i18next}>
            <UnifiedIconsContext.Provider
                 value={{ library: IconLibrary.Bootstrap }}
            >  
                <AppMainContext.Provider
                    value={{
                        translationsLoaded:
                            translationEN !== undefined &&
                            translationDE !== undefined,
                    }}
                >
                    <WindowSizeObserverProvider>
                        <MainContentLayerProvider>
                            <div className='app'>
                                {appCanBeVisible && (
                                    <MemorizedAppRouter
                                        basename={routerBasename}
                                        isLoggedIn={isLoggedIn}
                                        cookies={cookies}
                                        appTitleShortcut={
                                            appSettings?.providerSettings.shortcut
                                        }
                                    />
                                )}
                            </div>
                        </MainContentLayerProvider>
                        <ToastContainer
                            className='toast-position'
                            position='bottom-right'
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            limit={10}
                            draggable
                            pauseOnHover
                            role='alert'
                            transition={toastAnimation}
                            closeButton={toastCloseButton}
                        />
                        <LoadingOverlay
                            loadingMessage={
                                locale === 'en'
                                    ? 'Please wait...'
                                    : 'Bitte warten...'
                            }
                            isGlobal={true}
                            active={isLoadingDialogActive}
                        />
                        <ErrorOverlay
                            errorText={loadingError ?? ''}
                            active={loadingError !== null}
                            isGlobal={true}
                        />

                        <I18nContext.Consumer>
                            {(i18n) => {
                                return (
                                    <CookiesBanner
                                        title={i18n.i18n.t(
                                            'Common.Cookies.Tooltip'
                                        )}
                                        text={
                                            <ReactMarkdown>
                                                {i18n.i18n.t('Common.Cookies.Text')}
                                            </ReactMarkdown>
                                        }
                                        buttons={[
                                            {
                                                primary: true,
                                                text: i18n.i18n.t(
                                                    'Common.CookieAccept.Text'
                                                ),
                                                onClick: () => {
                                                    setCookie(
                                                        'lea-cookies-accepted',
                                                        'true',
                                                        {
                                                            path:
                                                                routerBasename ??
                                                                '/',
                                                            expires: new Date(
                                                                99999999999999
                                                            ),
                                                        }
                                                    );
                                                    setCookiesBarCanBeVisible(
                                                        false
                                                    );
                                                },
                                            },
                                        ]}
                                        active={
                                            i18n.i18n.isInitialized &&
                                            i18n.i18n.t('Common.Cookies.Text') !==
                                                'Common.Cookies.Text' &&
                                            cookies['lea-cookies-accepted'] !==
                                                'true' &&
                                            cookiesBarCanBeVisible &&
                                            !appSettings?.disableCookiesBanner
                                        }
                                    />
                                );
                            }}
                        </I18nContext.Consumer>
                    </WindowSizeObserverProvider>
                </AppMainContext.Provider>
            </UnifiedIconsContext.Provider>   
        </I18nextProvider>
    );
}
