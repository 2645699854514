import {
    AsyncForm,
    Button,
    CommonTools,
    InputLink,
    LoadingOverlay,
    RequiredFieldsNotice,
    TextInput,
    Toast,
    ValidationTools,
    showPopup,
    useMainContentLayerContext,
} from '@b4valuenet/ui-react';
import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';

import HomeService from '../../../../services/HomeService';
import { LoginData } from '../../../../models/LoginData';
import OutboxBanner from '../../components/OutboxBanner/OutboxBanner';
import { User } from '../../../../models/User';
import { login } from '../../../../redux/actions/auth';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

export default function Login() {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const auth = useAppSelector((state) => state.auth);
    const userLanguage = useAppSelector((state) => state.userSettings.lang);
    const sessionExpired = useAppSelector((state) => state.auth.sessionExpired);
    const appSettings = useAppSelector((state) => state.app.settings);

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const [, setIsMainContentLayerHidden] = useMainContentLayerContext();

    const [formSended, setFormSended] = useState<boolean>(false);

    const [cookies, setCookie] = useCookies([
        'disable-daily-messages',
        'dashboard-route',
    ]);

    const [autofillCheckEnabled, setAutofillCheckEnabled] = useState(true);

    const [disableLoginButton, setDisableLoginButton] =
        useState<boolean>(false);

    const [elsterSsoUrl, setElsterSsoUrl] = useState(
        `${
            appSettings?.elsterSamlSettings?.ssoUrl
        }?returnUrl=${encodeURIComponent(
            cookies['dashboard-route'] ?? '/dashboard'
        )}`
    );
    // Show error toast on Elster login failed
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const elsterLoginFailedKey = 'elsterLoginFailed';
        const elsterLoginFailed = searchParams.get(elsterLoginFailedKey);
        if (elsterLoginFailed === '1') {
            Toast.error(t('Login.ElsterLoginFailed.Text'));
            searchParams.delete(elsterLoginFailedKey);
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    const emailInputRef = createRef<HTMLInputElement>();
    const passwordInputRef = createRef<HTMLInputElement>();
    const timerIdRef = useRef<NodeJS.Timeout | null>(null);

    function startLoginTimer() {
        setDisableLoginButton(true);
        timerIdRef.current = setTimeout(() => {
            setDisableLoginButton(false);
        }, 3000); // 3 seconds
    }

    useEffect(() => {
        return () => {
            if (timerIdRef.current) {
                clearTimeout(timerIdRef.current);
            }
        };
    }, []);

    async function showMessageOfTheDay(language: string) {
        if (cookies['disable-daily-messages'] === 'true') {
            return;
        }

        if (setIsMainContentLayerHidden) {
            let messageOfTheDay = await HomeService.getMessageOfTheDay(
                language
            );

            if (
                messageOfTheDay !== null &&
                messageOfTheDay.messageOfTheDayContent !== null
            ) {
                setIsMainContentLayerHidden(true);

                let base64content = CommonTools.base64(
                    messageOfTheDay.messageOfTheDayContent
                );

                showPopup({
                    title: t('Common.MessageOfTheDay.Text'),
                    closeButtonText: t('Common.Close.Text'),
                    icon: 'bell-fill',
                    content: (
                        <>
                            <iframe
                                title={t('Common.MessageOfTheDay.Text')}
                                src={'data:text/html;base64,' + base64content}
                            ></iframe>
                        </>
                    ),
                    showCheckbox: true,
                    checkboxTitle: t('Common.DontShowAgain.Text'),
                    onClosed: (checkboxChecked: boolean) => {
                        setIsMainContentLayerHidden(false);

                        if (checkboxChecked) {
                            setCookie('disable-daily-messages', true, {
                                path: '/',
                            });
                        }
                    },
                });
            }
        }
    }

    async function handleSubmit() {
        setFormSended(true);

        if (isFormValid) {
            if (disableLoginButton) return;
            let loginData: LoginData = {
                email,
                password,
                language: userLanguage,
            };

            let loginResult = await dispatch(login(loginData));

            startLoginTimer();

            if (loginResult.type === 'auth/logIn/fulfilled') {
                let user = (loginResult.payload as User) ?? null;

                if (
                    setIsMainContentLayerHidden &&
                    loginResult.payload &&
                    user !== null
                ) {
                    if (user.forcePwdChange) {
                        navigate('/auth/reset-expired-password/' + user.token);
                        return;
                    }

                    showMessageOfTheDay(user.language ?? 'de');
                }
            }
        }
    }

    function isFormDataValid(email: string, password: string): boolean {
        // E-mail validation
        let isFormValid =
            ValidationTools.isEmailValid(email) && password.length > 0;

        return isFormValid;
    }

    useEffect(() => {
        setIsFormValid(isFormDataValid(email, password));
    }, [email, password]);

    useEffect(() => {
        if (autofillCheckEnabled) {
            let interval = setInterval(() => {
                if (emailInputRef.current && passwordInputRef.current) {
                    if (
                        emailInputRef.current.matches(':-webkit-autofill') &&
                        passwordInputRef.current.matches(':-webkit-autofill')
                    ) {
                        setIsFormValid(true);
                        setAutofillCheckEnabled(false);
                        clearInterval(interval);
                    }
                }
            }, 300);

            return () => clearInterval(interval);
        }
    });

    useEffect(() => {
        if (appSettings?.elsterSamlSettings?.isEnabled) {
            const returnUrl = encodeURIComponent(
                cookies['dashboard-route'] ?? '/dashboard'
            );

            setElsterSsoUrl(
                `${appSettings?.elsterSamlSettings?.ssoUrl}?returnUrl=${returnUrl}`
            );
        }
    }, [cookies['dashboard-route']]);

    return (
        <div className='auth-form-wrapper'>
            <AsyncForm className='auth-form' onSubmit={() => handleSubmit()}>
                <OutboxBanner />

                <section>
                    <h2>{t('Login.LoginCard.Text')}</h2>
                    <RequiredFieldsNotice
                        description={t(
                            'ValidationMessage.IndicatesARequiredField.Text'
                        )}
                    />
                    <TextInput
                        required={true}
                        type='text'
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setEmail(e.target.value)
                        }
                        value={email}
                        isValid={
                            (!formSended && email.length === 0) ||
                            ValidationTools.isEmailValid(email)
                        }
                        validationError={
                            email.length === 0
                                ? t('ValidationMessage.Required.Text')
                                : t('ValidationMessage.EmailInvalid.Text')
                        }
                        label={t('Login.Email.Text')}
                        placeholder='user@example.com'
                        icon='envelope'
                        autoComplete='email'
                        inputRef={emailInputRef}
                    />
                    <TextInput
                        required={true}
                        type='password'
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setPassword(e.target.value)
                        }
                        value={password}
                        isValid={
                            (!formSended && password.length === 0) ||
                            password.length > 0
                        }
                        validationError={t('ValidationMessage.Required.Text')}
                        label={t('Login.Password.Text')}
                        placeholder='••••••••'
                        icon='key'
                        autoComplete='current-password'
                        inputRef={passwordInputRef}
                        links={
                            <InputLink
                                to={'/auth/password-forgotten'}
                                title={t('Login.PasswordForgotten.Text')}
                            />
                        }
                    />

                    <Button
                        type='submit'
                        disabled={!isFormValid || disableLoginButton}
                        primary={true}
                        className='auth-action-button'
                        text={t('Login.LoginBtn.Text')}
                    />

                    <Button
                        isLink={true}
                        text={t('Login.Register.Link')}
                        linkTo='/auth/register'
                        className='auth-action-button'
                    />

                    <div aria-live='polite'>
                        {(auth.logInError || sessionExpired) && (
                            <p className='error'>
                                {auth.logInError
                                    ? t(auth.logInError)
                                    : t('Login.SessionExpired.Text')}
                            </p>
                        )}
                    </div>

                    {appSettings?.elsterSamlSettings?.isEnabled === true && (
                        <>
                            <div className='separator'></div>

                            <div className='elster-logo-wrapper'>
                                <img
                                    className='elster-logo'
                                    src='assets/elster-muk-logo.svg'
                                    alt='ELSTER logo'
                                />
                            </div>
                            <Button
                                isLink={true}
                                linkTo={elsterSsoUrl}
                                linkExternal={true}
                                primary={true}
                                text={t('Login.ElsterLoginBtn.Text')}
                                className='auth-action-button'
                            />
                            <Button
                                isLink={true}
                                linkTo={
                                    appSettings?.elsterSamlSettings
                                        .registerUrl ?? ''
                                }
                                linkExternal={true}
                                text={t('Login.ElsterRegisterBtn.Text')}
                                className='auth-action-button'
                            />
                        </>
                    )}
                </section>
            </AsyncForm>
            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={auth.isLoading}
            />
        </div>
    );
}
