import './TermsOfUse.scss';

import { AccordionItem, Button } from '@b4valuenet/ui-react';

import { Accordion } from '@b4valuenet/ui-react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAppSelector } from '../../redux/hooks';

const TermsOfUse = () => {
    const { t } = useTranslation();

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    return (
        <div className='sub-page terms-of-use'>
            <h1>{t('Common.TermsOfUse.Text')}</h1>
            <p>{t('TermsOfUsePage.Block2.Text')}</p>
            <p>{t('TermsOfUsePage.ContentReleaseDate.Text')}</p>
            {!isLoggedIn && (
                <Button
                    linkTo={'/'}
                    isLink={true}
                    icon='chevron-left'
                    text={t('PasswordForgotten.CancelButton.Text')}
                    primary={true}
                />
            )}
            <hr />
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className={'text-justify'}
            >
                {t('TermsOfUsePage.Content.Text')}
            </ReactMarkdown>
            <Accordion className='mb-1'>
                <AccordionItem
                    title={`${t('Common.OldTermsOfUse.Text')} - ${t(
                        'TermsOfUsePage.OldContentReleaseDate.Text'
                    )} - ${t('TermsOfUsePage.OldContentValidUntil.Text')}`}
                    expanded={false}
                    body={
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            className={'text-justify'}
                        >
                            {t('TermsOfUsePage.OldContent.Text')}
                        </ReactMarkdown>
                    }
                />
            </Accordion>
        </div>
    );
};

export default TermsOfUse;
