import {
    Button,
    Card,
    NumberInput,
    Select,
    SmoothVisibility,
    TextInput,
    ValidationTools,
    useDebounce,
} from '@b4valuenet/ui-react';
import { useEffect, useState } from 'react';

import { Agio } from '../../../../../../../models/Agio';
import { IXRechnungSubPage } from '../../../XRechnungPage';
import { KeyValuePair } from '../../../../../../../models/KeyValuePair';
import { useTranslation } from 'react-i18next';

export interface IInvoiceAgio {
    agio: Agio;
    isFormSubmitted: boolean;
    taxCategories: KeyValuePair[];
    onChange: (updatedAgio: Agio) => void;
    onRemove: () => void;
}

export default function InvoiceAgio(props: IInvoiceAgio & IXRechnungSubPage) {
    const { t } = useTranslation();

    useEffect(() => {
        let updatedGroup = {
            ...props.agio,
        } as Agio;

        if (!!updatedGroup.baseAmount && !!updatedGroup.percent) {
            updatedGroup.amount =
                updatedGroup.baseAmount * ((updatedGroup.percent || 0) / 100);

            updatedGroup.amount = Math.round(updatedGroup.amount * 100) / 100;

            props.onChange(updatedGroup);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.agio.baseAmount, props.agio.percent]);

    useEffect(() => {
        let updatedGroup = {
            ...props.agio,
        } as Agio;

        if (
            !!updatedGroup.amount &&
            !!updatedGroup.baseAmount
        ) {
            updatedGroup.percent =
                (updatedGroup.amount * 100) / updatedGroup.baseAmount;

            updatedGroup.percent = Math.round(updatedGroup.percent * 100) / 100;

            props.onChange(updatedGroup);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.agio.amount]);

    let vatRateValue: any = props.agio.vatRate;

    let vatRateValueNum = vatRateValue !== undefined ? +vatRateValue : -100;

    const [customVatRateVisible, setCustomVatRateVisible] =
        useState<boolean>(false);

    useDebounce(
        () => {
            let visible =
                (vatRateValueNum === -6 ||
                    vatRateValue === '' ||
                    !props.taxCategories.find(
                        (tc) => +tc.key === vatRateValueNum
                    )) &&
                vatRateValue !== 0;

            setCustomVatRateVisible(visible);
        },
        [props.agio.vatRate],
        1000
    );

    const [isBaseAmountOrPercentFilled, setIsBaseAmountOrPercentFilled] = useState(false);

    useEffect(() => {
        if (props.agio.baseAmount || props.agio.percent) {
            setIsBaseAmountOrPercentFilled(true);
        } else {
            setIsBaseAmountOrPercentFilled(false);
        }
    }, [props.agio.baseAmount, props.agio.percent]);

    return (
        <Card className='fade-in invoice-rebate-group spaced-fields'>
            <div className='flex-row'>
                <NumberInput
                    required={true}
                    min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    isValid={ValidationTools.isRequiredFieldValueValid(
                        props.agio?.amount?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t('ValidationMessage.Required.Text')}
                    placeholder={t('XRechnung.AmountsAgio.Text')}
                    label={t('XRechnung.AmountsAgio.Text')}
                    value={props.agio.amount ?? undefined}
                    maxPrecision={2}
                    onBlur={(e) => {
                        if(e.target.value !== '') return;
                        
                        props.onChange({
                            ...props.agio,
                            amount: undefined,
                        });
                    }}
                    onChange={(_e, rawValue) => {
                        props.onChange({
                            ...props.agio,
                            amount: rawValue,
                        });
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.AmountsAgioHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.AmountsAgioHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t('XRechnung.AmountsAgio.Help')}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
                <Select
                    placeholder={t('XRechnung.AmountsAgioTaxCategory.Text')}
                    label={t('XRechnung.AmountsAgioTaxCategory.Text')}
                    value={
                        !props.taxCategories.some(
                            (c) =>
                                c.key.toString() === vatRateValueNum.toString()
                        )
                            ? '-6'
                            : vatRateValueNum.toString()
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        let newValue = +e.target.value;

                        let visible =
                            (newValue === -6 ||
                                !props.taxCategories.find(
                                    (tc) => +tc.key === vatRateValueNum
                                )) &&
                            vatRateValue !== 0;

                        setCustomVatRateVisible(visible);
                        props.onChange({
                            ...props.agio,
                            vatRate: newValue,
                        });
                    }}
                    required={true}
                    isValid={ValidationTools.isRequiredFieldValueValid(
                        props.agio?.vatRate?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t('ValidationMessage.Required.Text')}
                    infoButtonEnabled={!customVatRateVisible}
                    infoButtonText={t(
                        'XRechnung.AmountsAgioTaxCategoryHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.AmountsAgioTaxCategoryHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.AmountsAgioTaxCategory.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                >
                    {props.taxCategories.map((option) => {
                        return (
                            <option key={option.key} value={option.key}>
                                {option.value}
                            </option>
                        );
                    })}
                </Select>
                <SmoothVisibility visible={customVatRateVisible}>
                    <NumberInput
                        min={0}
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        placeholder={t('XRechnung.AmountsAgioTaxCategory.Text')}
                        label={t('XRechnung.AmountsAgioTaxCategory.Text')}
                        required={true}
                        value={vatRateValueNum}
                        maxPrecision={2}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let updatedItem = {
                                ...props.agio,
                            } as Agio;

                            updatedItem.vatRate = +e.target.value.replace(
                                ',',
                                '.'
                            );

                            if (e.target.value.length === 0) {
                                updatedItem.vatRate = -6;
                            }

                            props.onChange(updatedItem);
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.AmountsAgioTaxCategoryHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.AmountsAgioTaxCategoryHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.AmountsAgioTaxCategory.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                </SmoothVisibility>
            </div>
            <div className='flex-row'>
                <NumberInput
                    min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    placeholder={t('XRechnung.AmountsAgioBase.Text')}
                    label={t('XRechnung.AmountsAgioBase.Text')}
                    value={props.agio.baseAmount || undefined}
                    required={isBaseAmountOrPercentFilled}
                    isValid={
                        !isBaseAmountOrPercentFilled ||
                        ValidationTools.isRequiredFieldValueValid(
                        props.agio.baseAmount?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t(
                        'ValidationMessage.BaseAmountMissing.Text'
                    )}
                    maxPrecision={2}
                    onBlur={(e) => {
                        if(e.target.value !== '') return;
                        
                        props.onChange({
                            ...props.agio,
                            baseAmount: undefined,
                        });
                    }}
                    onChange={(_e, rawValue) => {
                        props.onChange({
                            ...props.agio,
                            baseAmount: rawValue,
                        });
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.AmountsAgioBaseHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.AmountsAgioBaseHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t('XRechnung.AmountsAgioBase.Help')}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
                <NumberInput
                    min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    placeholder={t('XRechnung.AmountsAgioPerce.Text')}
                    label={t('XRechnung.AmountsAgioPerce.Text')}
                    value={props.agio.percent || undefined}
                    required={isBaseAmountOrPercentFilled}
                    isValid={
                        !isBaseAmountOrPercentFilled ||
                        ValidationTools.isRequiredFieldValueValid(
                        props.agio.percent?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t('ValidationMessage.PercentMissing.Text')}
                    maxPrecision={2}
                    onBlur={(e) => {
                        if(e.target.value !== '') return;
                        
                        props.onChange({
                            ...props.agio,
                            percent: undefined,
                        });
                    }}
                    onChange={(_e, rawValue) => {
                        props.onChange({
                            ...props.agio,
                            percent: rawValue,
                        });
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.AmountsAgioPerceHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.AmountsAgioPerceHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t('XRechnung.AmountsAgioPerce.Help')}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
            </div>
            <TextInput
                type={'text'}
                placeholder={t('XRechnung.AmountsAgioName.Text')}
                label={t('XRechnung.AmountsAgioName.Text')}
                value={props.agio.name?.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.onChange({
                        ...props.agio,
                        name: e.target.value,
                    });
                }}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.agio?.name,
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.AmountsAgioNameHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.AmountsAgioNameHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.AmountsAgioName.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />
            <Button
                className='action-button'
                icon='trash'
                danger={true}
                text={t('XRechnung.RemoveAmountsAgio.Text')}
                onClick={() => {
                    props.onRemove();
                }}
            />
        </Card>
    );
}
