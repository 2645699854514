import { ValidationTools } from '@b4valuenet/ui-react';
import { XRechnung } from '../../../../../models/XRechnung';
import { IXRechnungPageValidator } from '../XRechnungPagesValidator';

export default class XRechnungBuyerValidators {
    static BuyerDataValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (!data) return false;

        return (
            ValidationTools.isRequiredFieldValueValid(
                data.receiverData.receiverLegalEntityName,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.receiverData.receiverAddressZip,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.receiverData.receiverAddressCity,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.receiverData.receiverAddressCountry,
                isFormSubmitted
            ) &&
            ValidationTools.isEmailFieldValueValid(
                data.receiverData.buyerContactContactEmail ?? '',
                false
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data.receiverData.buyerContactContactEmail,
                isFormSubmitted
            ) &&
            this.BuyerDataDeliveryInfoValid({ data, isFormSubmitted }) &&
            this.IsBuyerVatIdValid({
                data,
                isFormSubmitted,
            })
        );
    }

    static IsBuyerVatIdRequired({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        //VatReverseCharge
        if (data?.positions.some((p) => p.vatRate === -2)) {
            //BT-32
            const isSenderTaxNoEmpty =
                (data?.senderData?.senderTaxNo?.length ?? 0) === 0;

            //BT-31
            const isSenderVatIdEmpty =
                (data?.senderData?.senderVatId?.length ?? 0) === 0;

            return isSenderTaxNoEmpty || isSenderVatIdEmpty;
        } else if (data?.positions.some((p) => p.vatRate === -3)) {
            //BT-32
            const isSenderTaxNoEmpty =
                (data?.senderData?.senderTaxNo?.length ?? 0) === 0;

            //BT-31
            const isSenderVatIdEmpty =
                (data?.senderData?.senderVatId?.length ?? 0) === 0;

            return isSenderTaxNoEmpty || isSenderVatIdEmpty;
        } else {
            return false;
        }
    }

    static IsBuyerVatIdValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (this.IsBuyerVatIdRequired({ data, isFormSubmitted })) {
            return ValidationTools.isRequiredFieldValueValid(
                data?.receiverData.receiverVatId,
                isFormSubmitted
            );
        }

        return true;
    }

    static IsBuyerDeliveryDateRequired({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (
            data?.positions.some((p) => p.vatRate === -3) ||
            data?.documentTotalsData.allowances.some((p) => p.vatRate === -3) ||
            data?.documentTotalsData.agios.some((p) => p.vatRate === -3)
        ) {
            if (data.invoiceData.invoicePeriodStartDate === null) {
                return true;
            }
        }

        return false;
    }

    static isBuyerDeliveryDateValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        if (
            this.IsBuyerDeliveryDateRequired({ data, isFormSubmitted }) &&
            !ValidationTools.isRequiredFieldValueValid(
                data?.receiverData.deliveryDate ? 'not empty' : null,
                isFormSubmitted
            )
        ) {
            return false;
        }

        return true;
    }

    static BuyerDataDeliveryInfoValid({
        data,
        isFormSubmitted,
    }: IXRechnungPageValidator): boolean {
        var deliveryDateRequired = this.IsBuyerDeliveryDateRequired({
            data,
            isFormSubmitted,
        });

        let deliveryInfoHasContent = this.BuyerDeliveryInfoHasContent(data);

        //if group is not set no fields are required
        if (!deliveryInfoHasContent && !deliveryDateRequired) {
            return true;
        }

        return (
            ValidationTools.isRequiredFieldValueValid(
                data?.receiverData.deliveryAddressZip,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data?.receiverData.deliveryAddressCity,
                isFormSubmitted
            ) &&
            ValidationTools.isRequiredFieldValueValid(
                data?.receiverData.deliveryAddressCountry,
                isFormSubmitted
            ) &&
            this.isBuyerDeliveryDateValid({ data, isFormSubmitted })
        );
    }

    static BuyerDeliveryInfoHasContent(data: XRechnung | null): boolean {
        var content =
            data?.receiverData.deliveryAddressName ||
            data?.receiverData.deliveryIdRef ||
            data?.receiverData.deliveryDate ||
            data?.receiverData.deliveryAddressStreet ||
            data?.receiverData.deliveryAddressZip ||
            data?.receiverData.deliveryAddressCity ||
            data?.receiverData.deliveryAddressSubLocality ||
            data?.receiverData.deliveryAddressAddGeoInf ||
            data?.receiverData.deliveryAddressLocality ||
            data?.receiverData.deliveryAddressCountry;

        if (content == null || content === '') {
            return false;
        }

        return true;
    }
}
