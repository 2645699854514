import {
    BootstrapIcon,
    Button,
    LoadingOverlay,
    RequestTools,
    Toast,
} from '@b4valuenet/ui-react';
import { useEffect, useState } from 'react';

import OutboxBanner from '../../components/OutboxBanner/OutboxBanner';
import RegistrationService from '../../../../services/RegistrationService';
import { useAppMainContext } from '../../../../App';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function RegistrationConfirm() {
    const { t } = useTranslation();
    const { token } = useParams();

    const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [text, setText] = useState<string | null>(null);

    const appMainContext = useAppMainContext();

    useEffect(() => {
        if (!appMainContext || !appMainContext.translationsLoaded) return;

        if (token !== undefined) {
            const validateToken = async (token: string) => {
                let valid = false;

                setIsLoading(true);

                try {
                    let result = await RegistrationService.confirmRegistration(
                        token
                    );

                    if (result) {
                        valid = result.isSuccessful;
                        setText(result.message);
                    } else {
                        Toast.error('Empty result.');
                    }
                } catch (error) {
                    let errorCode = RequestTools.getApiErrorCode(error);
                    let errorMessage = RequestTools.getApiErrorMessage(error);

                    if (errorCode === 400) {
                        setText(t('ResetPassword.TokenInvalid.Text'));
                    } else {
                        setText(errorMessage);
                        Toast.error(errorMessage);
                    }
                }

                setIsLoading(false);
                setIsTokenValid(valid);

                return valid;
            };

            validateToken(token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, t]);

    return (
        <div className='auth-form-wrapper'>
            <div className='auth-form'>
                <OutboxBanner />

                <section>
                    <h2>{t('Registration.ConfirmationCard.Text')}</h2>

                    {isTokenValid && (
                        <p className='success'>
                            <BootstrapIcon icon='check-circle-fill' />
                            {text}
                        </p>
                    )}

                    {!isTokenValid && (
                        <p className='error-alt'>
                            <BootstrapIcon icon='x-circle-fill' />
                            {text}
                        </p>
                    )}
                    <Button
                        isLink={true}
                        linkTo={'/auth/login'}
                        type='button'
                        primary={false}
                        className='auth-action-button'
                        text={t('Registration.ForwardToLogin.Text')}
                    />
                </section>
            </div>
            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={isLoading}
            />
        </div>
    );
}
