import { IRealPage, RealPage } from './RealPage';
import { ITestPage, TestPage } from './TestPage';

export interface IPage {
    isInTestMode: boolean;
}

export const Page = (props: IPage & (IRealPage | ITestPage)) => {
    return (
        <>
            {(!props.isInTestMode && <RealPage {...props} />) || (
                <TestPage {...props} />
            )}
        </>
    );
};
