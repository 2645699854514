import { GenericActionResponse } from '../models/GenericActionResponse';
import { RequestTools } from '@b4valuenet/ui-react';
import { UserModel } from '../models/UserModel';
import { UsersListItem } from '../models/UsersListItem';

export default class UserAdministrationService {
    static ApiRoot = 'api/useradministration/';

    static getAll = async (): Promise<UsersListItem[] | null> => {
        let response = await RequestTools.get(this.ApiRoot + 'getAll');

        return (response.data.data as UsersListItem[]) || null;
    };

    static get = async (id: number): Promise<UserModel | null> => {
        let response = await RequestTools.get(this.ApiRoot + 'get', {
            params: {
                id: id,
            },
        });

        return (response.data.data as UserModel) || null;
    };

    static create = async (
        user: UserModel
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.post(this.ApiRoot + 'create', user);

        return (response.data as GenericActionResponse) || null;
    };

    static update = async (
        user: UserModel
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.put(this.ApiRoot + 'update', user);

        return (response.data as GenericActionResponse) || null;
    };

    static lock = async (
        ids: (string | number)[]
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.put(
            this.ApiRoot + 'lock',
            JSON.stringify(ids),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return (response.data as GenericActionResponse) || null;
    };

    static unlock = async (
        ids: (string | number)[]
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.put(
            this.ApiRoot + 'unlock',
            JSON.stringify(ids),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return (response.data as GenericActionResponse) || null;
    };

    static delete = async (
        ids: (string | number)[]
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.delete(this.ApiRoot + 'delete', {
            data: JSON.stringify(ids),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return (response.data as GenericActionResponse) || null;
    };
}
