import { Card } from '@b4valuenet/ui-react';
import { useTranslation } from 'react-i18next';
import { DocumentTotalsData } from '../../../../../../../../models/DocumentTotalsData';

interface IPaymentTotalsSummary {
    documentTotals: DocumentTotalsData;
}

export default function PaymentTotalsSummary(props: IPaymentTotalsSummary) {
    const { t } = useTranslation();

    return (
        <Card title={t('XRechnung.Sums.Text')}>
            <div
                className='pseudo-table align-left cw-25'
                style={{ display: 'flex', flexDirection: 'row' }}
            >
                <table className='w-50'>
                    <tbody>
                        <tr>
                            <th style={{ width: '35%' }}>
                                {t('XRechnung.AmountsItemAmount.Text')}
                            </th>
                            <td>{props.documentTotals.amountsItemAmount}</td>
                        </tr>
                        <tr>
                            <th style={{ width: '35%' }}>
                                {t('XRechnung.SumAllowance.Text')}
                            </th>
                            <td>{props.documentTotals.sumAllowance}</td>
                        </tr>
                        <tr>
                            <th style={{ width: '35%' }}>
                                {t('XRechnung.SumCharges.Text')}
                            </th>
                            <td>{props.documentTotals.sumCharges}</td>
                        </tr>
                        <tr>
                            <th style={{ width: '35%' }}>
                                {t('XRechnung.AmountsNetAmount.Text')}
                            </th>
                            <td>{props.documentTotals.totalNetAmount}</td>
                        </tr>
                        <tr>
                            <th style={{ width: '35%' }}>
                                {t('XRechnung.AmountsVatAmount.Text')}
                            </th>
                            <td>{props.documentTotals.totalVatAmount}</td>
                        </tr>
                        <tr>
                            <th style={{ width: '35%' }}>
                                {t('XRechnung.AmountsTaxExemptionReason.Text')}
                            </th>
                            <td>
                                {props.documentTotals.amountsTaxExemptionReason}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className='w-50'>
                    <tbody>
                        <tr>
                            <th>{t('XRechnung.Total.Text')}</th>
                            <td>{props.documentTotals.total}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.AmountsPrepaidAmount.Text')}</th>
                            <td>{props.documentTotals.amountsPrepaidAmount}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.AmountsRoundingAmount.Text')}</th>
                            <td>
                                {props.documentTotals.amountsRoundingAmount}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.Amount.Text')}</th>
                            <td>{props.documentTotals.amount}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
