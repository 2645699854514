import './PrivacyPolicy.scss';

import { Accordion, AccordionItem, Button } from '@b4valuenet/ui-react';

import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAppSelector } from '../../redux/hooks';

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    return (
        <div className='sub-page privacy-policy'>
            <h1>{t('Common.DataProtection.Text')}</h1>
            <p>{t('ImprintPage.Block2.Text')}</p>
            <p>{t('PrivacyPage.ContentReleaseDate.Text')}</p>
            {!isLoggedIn && (
                <Button
                    linkTo={'/'}
                    isLink={true}
                    icon='chevron-left'
                    text={t('PasswordForgotten.CancelButton.Text')}
                    primary={true}
                />
            )}
            <hr />
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className={'text-justify'}
            >
                {t('PrivacyPage.Content.Text')}
            </ReactMarkdown>
            <hr />

            <Accordion className='mb-1'>
                <AccordionItem
                    title={`${t('Common.OldDataProtection.Text')} - ${t(
                        'PrivacyPage.OldContentReleaseDate.Text'
                    )}`}
                    expanded={false}
                    body={
                        <>
                            <p>{t('PrivacyPage.OldContentReleaseDate.Text')}</p>
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                className={'text-justify'}
                            >
                                {t('PrivacyPage.OldContent.Text')}
                            </ReactMarkdown>
                        </>
                    }
                />
            </Accordion>
        </div>
    );
};

export default PrivacyPolicy;
