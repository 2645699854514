import {
    Button,
    Checkbox,
    ModalWindow,
    Toast,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { UserModel } from '../../../../../models/UserModel';
import UserAdministrationService from '../../../../../services/UserAdministrationService';
import UserListModalFields from './generic/UserListModalFields';

export interface IEditItemModal {
    active: boolean;
    onClose: CallableFunction;
    onInputValueChange: CallableFunction;
    item: UserModel | null;
}

export default function EditItemModal(props: IEditItemModal) {
    const { t } = useTranslation();

    const [isModalItemValid, setIsModalItemValid] = useState<boolean>(true);

    function onClose(actionConfirmed: boolean) {
        setFormSended(false);
        props.onClose(actionConfirmed);
    }

    function handleCancelButtonClick() {
        onClose(false);
    }

    useEffect(() => {
        setIsModalItemValid(
            props.item !== null &&
                props.item.firstName.length > 0 &&
                props.item.firstName.length <= 200 &&
                props.item.lastName.length > 0 &&
                props.item.lastName.length <= 200 &&
                ValidationTools.isEmailValid(props.item.email) &&
                props.item.hasAdminAccess !== null &&
                props.item.hasAdminAccess !== undefined
        );
    }, [props.item]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formSended, setFormSended] = useState<boolean>(false);

    async function handleFormSubmit() {
        setFormSended(true);

        if (props.item === null || !isModalItemValid) return;

        setIsLoading(true);

        try {
            let result = await UserAdministrationService.update(props.item);

            if (result?.message) {
                Toast.success(result.message, 350);
            }

            props.onClose(true);
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        setIsLoading(false);
    }

    return (
        <ModalWindow
            isLoading={isLoading}
            aria-labelledby='user-administration-modal-edit-title'
            active={props.active}
            onSubmit={() => handleFormSubmit()}
            onEscapeKeyPressed={() => {
                if (!isLoading) onClose(false);
            }}
        >
            <h2 id='user-administration-modal-edit-title'>
                {t('UserAdministration.EditDialog.Text')}
            </h2>
            <div className='modal-main'>
                <UserListModalFields
                    formSended={formSended}
                    item={props.item}
                    onInputValueChange={(
                        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
                        property: string
                    ) => {
                        props.onInputValueChange(e, property);
                    }}
                />
                <Checkbox
                    checked={props.item?.sendPwdResetLink}
                    onChange={(
                        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
                    ) => {
                        props.onInputValueChange(e, 'sendPwdResetLink', true);
                    }}
                    title={t('UserAdministration.SendResetLink.Text')}
                />
                <Checkbox
                    checked={props.item?.forcePwdChange}
                    onChange={(
                        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
                    ) => {
                        props.onInputValueChange(e, 'forcePwdChange', true);
                    }}
                    title={t('UserAdministration.ForcePwdChange.Text')}
                />
                <Checkbox
                    checked={props.item?.hasAdminAccess}
                    onChange={(
                        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
                    ) => {
                        props.onInputValueChange(e, 'hasAdminAccess', true);
                    }}
                    title={t('UserAdministration.HasAdminAccess.Text')}
                />
            </div>
            <div className='footer'>
                <Button
                    onClick={() => handleCancelButtonClick()}
                    text={t('UserAdministration.UserDialogCancelBtn.Text')}
                />
                <Button
                    type='submit'
                    disabled={!isModalItemValid}
                    primary={true}
                    text={t('UserAdministration.EditUserSaveBtn.Text')}
                />
            </div>
        </ModalWindow>
    );
}
