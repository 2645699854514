import {
    Button,
    Card,
    TextArea,
    TextInput,
    ValidationTools,
} from '@b4valuenet/ui-react';

import { ExternalReference } from '../../../../../../../models/ExternalReference';
import { useTranslation } from 'react-i18next';

export interface IInvoiceExternalReference {
    reference: ExternalReference;
    isFormSubmitted: boolean;
    onChange: (updatedReference: ExternalReference) => void;
    onRemove: () => void;
}

export default function InvoiceExternalReference(
    props: IInvoiceExternalReference
) {
    const { t } = useTranslation();

    return (
        <Card className={'fade-in spaced-fields'}>
            <TextInput
                type={'text'}
                icon={'file-earmark-text'}
                placeholder={t('XRechnung.ExternalReferenceName.Text')}
                label={t('XRechnung.ExternalReferenceName.Text')}
                value={props.reference.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.onChange({
                        ...props.reference,
                        name: e.target.value,
                    });
                }}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.ExternalReferenceNameHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.ExternalReferenceNameHelpTitle.Text'
                )}
                infoButtonTooltipText={t(
                    'XRechnung.ExternalReferenceName.Help'
                )}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <TextInput
                type={'text'}
                icon={'hash'}
                placeholder={t('XRechnung.ExternalReferenceIdentifier.Text')}
                label={t('XRechnung.ExternalReferenceIdentifier.Text')}
                value={props.reference.identifier}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.reference.identifier,
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.onChange({
                        ...props.reference,
                        identifier: e.target.value,
                    });
                }}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.ExternalReferenceIdentifierHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.ExternalReferenceIdentifierHelpTitle.Text'
                )}
                infoButtonTooltipText={t(
                    'XRechnung.ExternalReferenceIdentifier.Help'
                )}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <TextArea
                icon={'card-list'}
                placeholder={t('XRechnung.ExternalReferenceDescription.Text')}
                label={t('XRechnung.ExternalReferenceDescription.Text')}
                value={props.reference.description?.toString()}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    props.onChange({
                        ...props.reference,
                        description: e.target.value,
                    });
                }}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.ExternalReferenceDescriptionHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.ExternalReferenceDescriptionHelpTitle.Text'
                )}
                infoButtonTooltipText={t(
                    'XRechnung.ExternalReferenceDescription.Help'
                )}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <Button
                icon='trash'
                danger={true}
                className={'action-button'}
                text={t('XRechnung.RemoveReference.Text')}
                onClick={() => {
                    props.onRemove();
                }}
            />
        </Card>
    );
}
