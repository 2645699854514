import { Card } from '@b4valuenet/ui-react';
import { SenderData } from '../../../../../../../../../models/SenderData';
import { useTranslation } from 'react-i18next';

export interface ISellerContactDetails {
    senderData: SenderData;
}
export default function SellerContactDetails(props: ISellerContactDetails) {
    const { t } = useTranslation();

    return (
        <Card title={t('XRechnung.ContactDetails.Text')}>
            <div className='pseudo-table align-left cw-50'>
                <table>
                    <tbody>
                        {props.senderData.contactContactPerson && (
                            <tr>
                                <th>{t('XRechnung.ContactPerson.Text')}</th>
                                <td>
                                    {props?.senderData.contactContactPerson}
                                </td>
                            </tr>
                        )}
                        {props.senderData.contactContactEmail && (
                            <tr>
                                <th>{t('XRechnung.ContactEmail.Text')}</th>
                                <td>{props?.senderData.contactContactEmail}</td>
                            </tr>
                        )}
                        {props.senderData.contactContactPhoneNo && (
                            <tr>
                                <th>{t('XRechnung.ContactPhoneNo.Text')}</th>
                                <td>
                                    {props?.senderData.contactContactPhoneNo}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
