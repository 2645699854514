import { Card, StringTools } from '@b4valuenet/ui-react';

import { Attachment } from '../../../../../../../../models/Attachment';
import { useTranslation } from 'react-i18next';

interface IAttachmentSummary {
    attachments: Attachment[];
}

export default function AttachmentSummary(props: IAttachmentSummary) {
    const { t } = useTranslation();

    return (
        <Card
            className='grid-big-item'
            title={t('XRechnung.AttachmentTab.Text')}
        >
            <div className='pseudo-table'>
                <table>
                    <thead>
                        <tr>
                            <th>{t('XRechnung.AttachmentName.Text')}</th>
                            <th>{t('XRechnung.AttachmentIdentifier.Text')}</th>
                            <th>{t('XRechnung.AttachmentFileSize.Text')}</th>
                            <th>{t('XRechnung.AttachmentDescription.Text')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.attachments.map((attachment, index) => {
                            return (
                                <tr key={index}>
                                    <td>{attachment.name}</td>
                                    <td>{attachment.identifier}</td>
                                    <td>
                                        {StringTools.humanSize(
                                            attachment.fileSize
                                        )}
                                    </td>
                                    <td>{attachment.description}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
