import './ItemPreviewDialog.scss';

import { PDFViewerWindow, RequestTools } from '@b4valuenet/ui-react';
import { useEffect, useState } from 'react';

import { MessageVisualizeData } from '../../../../../models/MessageVisualizeData';
import MessagesService from '../../../../../services/MessagesService';
import { useTranslation } from 'react-i18next';

interface IItemPreviewDialog {
    active: boolean;
    onClose?: () => void;
    id: number | null;
}

export default function ItemPreviewDialog(props: IItemPreviewDialog) {
    const { t } = useTranslation();

    const [data, setData] = useState<MessageVisualizeData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setErrorText(null);

            if (props.active && props.id) {
                setIsLoading(true);

                try {
                    let newData = await MessagesService.visualize(props.id);

                    if (newData) {
                        setData(newData);
                    }

                    setIsLoading(false);
                } catch (e) {
                    let errorText = RequestTools.getApiErrorMessage(e);
                    setIsLoading(false);
                    setErrorText(errorText);
                }
            }
        };

        fetchData();
    }, [props.active, props.id]);

    return (
        <PDFViewerWindow
            active={props.active}
            onClose={props.onClose}
            isLoading={isLoading}
            errorText={errorText ?? undefined}
            content={data ?? { pageCount: 0, fullsize: [], preview: [] }}
            loadingText={t('Common.LoadingMessage.Text')}
            title={
                data?.message.id
                    ? data?.message.id.toString()
                    : t('Common.LoadingMessage.Text')
            }
            translation={{
                window: {
                    header: {
                        close: t('Common.Close.Text'),
                    },
                },
                header: {
                    actions: {
                        showSidebar: t('Common.PDFViewerShowSidebar.Text'),
                        hideSidebar: t('Common.PDFViewerHideSidebar.Text'),
                        toFirstPage: t('Common.PDFViewerToFirstPage.Text'),
                        toPrevPage: t('Common.PDFViewerToPrevPage.Text'),
                        toNextPage: t('Common.PDFViewerToNextPage.Text'),
                        toLastPage: t('Common.PDFViewerToLastPage.Text'),
                        zoomIn: t('Common.PDFViewerZoomIn.Text'),
                        zoomOut: t('Common.PDFViewerZoomOut.Text'),
                        resetZoom: t('Common.PDFViewerResetZoom.Text'),
                    },
                },
                sidebar: {
                    page: t('Common.PDFViewerSidebarPage.Text'),
                },
            }}
        />
    );
}
