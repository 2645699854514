import {
    Button,
    Card,
    NumberInput,
    Select,
    SmoothVisibility,
    TextInput,
    ValidationTools,
    useDebounce,
} from '@b4valuenet/ui-react';
import { useEffect, useState } from 'react';

import { Allowance } from '../../../../../../../models/Allowance';
import { IXRechnungSubPage } from '../../../XRechnungPage';
import { KeyValuePair } from '../../../../../../../models/KeyValuePair';
import { useTranslation } from 'react-i18next';

export interface IInvoiceAllowance {
    allowance: Allowance;
    isFormSubmitted: boolean;
    taxCategories: KeyValuePair[];
    onChange: (updatedAllowance: Allowance) => void;
    onRemove: () => void;
}

export default function InvoiceAllowance(
    props: IInvoiceAllowance & IXRechnungSubPage
) {
    const { t } = useTranslation();

    useEffect(() => {
        let updatedGroup = {
            ...props.allowance,
        } as Allowance;

        if (!!updatedGroup.baseAmount && !!updatedGroup.percent) {
            updatedGroup.amount =
                updatedGroup.baseAmount * ((updatedGroup.percent || 0) / 100);

            updatedGroup.amount = Math.round(updatedGroup.amount * 100) / 100;

            props.onChange(updatedGroup);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.allowance.baseAmount, props.allowance.percent]);

    useEffect(() => {
        let updatedGroup = {
            ...props.allowance,
        } as Allowance;

        if (
            !!updatedGroup.amount &&
            updatedGroup.amount !== 0 &&
            !!updatedGroup.baseAmount
        ) {
            updatedGroup.percent =
                (updatedGroup.amount * 100) / updatedGroup.baseAmount;

            updatedGroup.percent = Math.round(updatedGroup.percent * 100) / 100;

            props.onChange(updatedGroup);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.allowance.amount]);

    let vatRateValue: any = props.allowance.vatRate;

    let vatRateValueNum = vatRateValue !== undefined ? +vatRateValue : -100;

    const [customVatRateVisible, setCustomVatRateVisible] =
        useState<boolean>(false);

    useDebounce(
        () => {
            let visible =
                (vatRateValueNum === -6 ||
                    vatRateValue === '' ||
                    !props.taxCategories.find(
                        (tc) => +tc.key === vatRateValueNum
                    )) &&
                vatRateValue !== 0;

            setCustomVatRateVisible(visible);
        },
        [props.allowance.vatRate],
        1000
    );

    const [isBaseAmountOrPercentFilled, setIsBaseAmountOrPercentFilled] = useState(false);
    
    useEffect(() => {
        if (props.allowance.baseAmount || props.allowance.percent) {
            setIsBaseAmountOrPercentFilled(true);
        } else {
            setIsBaseAmountOrPercentFilled(false);
        }
    }, [props.allowance.baseAmount, props.allowance.percent]);

    return (
        <Card className='fade-in invoice-rebate-group spaced-fields'>
            <div className='flex-row'>
                <NumberInput
                    required={true}
                    min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    isValid={ValidationTools.isRequiredFieldValueValid(
                        props.allowance?.amount?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t('ValidationMessage.Required.Text')}
                    placeholder={t('XRechnung.AmountsAllowance.Text')}
                    label={t('XRechnung.AmountsAllowance.Text')}
                    value={props.allowance.amount}
                    maxPrecision={2}
                    onBlur={(e) => {
                        if(e.target.value !== '') return;
                        
                        props.onChange({
                            ...props.allowance,
                            amount: undefined,
                        });
                    }}
                    onChange={(_e, rawValue) => {
                        props.onChange({
                            ...props.allowance,
                            amount: rawValue,
                        });
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.AmountsAllowanceHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.AmountsAllowanceHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t('XRechnung.AmountsAllowance.Help')}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
                <Select
                    placeholder={t(
                        'XRechnung.AmountsAllowanceTaxCategory.Text'
                    )}
                    label={t('XRechnung.AmountsAllowanceTaxCategory.Text')}
                    value={
                        !props.taxCategories.some(
                            (c) =>
                                c.key.toString() === vatRateValueNum.toString()
                        )
                            ? '-6'
                            : vatRateValueNum.toString()
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        let newValue = +e.target.value;

                        let visible =
                            (newValue === -6 ||
                                !props.taxCategories.find(
                                    (tc) => +tc.key === vatRateValueNum
                                )) &&
                            vatRateValue !== 0;

                        setCustomVatRateVisible(visible);
                        props.onChange({
                            ...props.allowance,
                            vatRate: newValue,
                        });
                    }}
                    required={true}
                    isValid={ValidationTools.isRequiredFieldValueValid(
                        props.allowance?.vatRate?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t('ValidationMessage.Required.Text')}
                    infoButtonEnabled={!customVatRateVisible}
                    infoButtonText={t(
                        'XRechnung.AmountsAllowanceTaxCategoryHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.AmountsAllowanceTaxCategoryHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.AmountsAllowanceTaxCategory.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                >
                    {props.taxCategories.map((option) => {
                        return (
                            <option key={option.key} value={option.key}>
                                {option.value}
                            </option>
                        );
                    })}
                </Select>
                <SmoothVisibility visible={customVatRateVisible}>
                    <NumberInput
                        min={0}
                        max={
                            +(process.env.REACT_APP_MAX_NUM ?? 999999999999999)
                        }
                        placeholder={t(
                            'XRechnung.AmountsAllowanceTaxCategory.Text'
                        )}
                        label={t('XRechnung.AmountsAllowanceTaxCategory.Text')}
                        required={true}
                        value={vatRateValueNum}
                        maxPrecision={2}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let updatedItem = {
                                ...props.allowance,
                            } as Allowance;

                            updatedItem.vatRate = +e.target.value.replace(
                                ',',
                                '.'
                            );

                            if (e.target.value.length === 0) {
                                updatedItem.vatRate = -6;
                            }

                            props.onChange(updatedItem);
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.AmountsAllowanceTaxCategoryHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.AmountsAllowanceTaxCategoryHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.AmountsAllowanceTaxCategory.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                </SmoothVisibility>
            </div>
            <div className='flex-row'>
                <NumberInput
                    min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    placeholder={t('XRechnung.AmountsAllowanceBase.Text')}
                    label={t('XRechnung.AmountsAllowanceBase.Text')}
                    value={props.allowance.baseAmount || undefined}
                    required={isBaseAmountOrPercentFilled}
                    isValid={
                        !isBaseAmountOrPercentFilled ||
                        ValidationTools.isRequiredFieldValueValid(
                        props.allowance.baseAmount?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t(
                        'ValidationMessage.BaseAmountMissing.Text'
                    )}
                    maxPrecision={2}
                    onBlur={(e) => {
                        if(e.target.value !== '') return;
                        
                        props.onChange({
                            ...props.allowance,
                            baseAmount: undefined,
                        });
                    }}
                    onChange={(_e, rawValue) => {
                        props.onChange({
                            ...props.allowance,
                            baseAmount: rawValue,
                        });
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.AmountsAllowanceBaseHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.AmountsAllowanceBaseHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.AmountsAllowanceBase.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
                <NumberInput
                    min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    placeholder={t('XRechnung.AmountsAllowancePerce.Text')}
                    label={t('XRechnung.AmountsAllowancePerce.Text')}
                    value={props.allowance.percent || undefined}
                    required={isBaseAmountOrPercentFilled}
                    isValid={
                        !isBaseAmountOrPercentFilled ||
                        ValidationTools.isRequiredFieldValueValid(
                        props.allowance.percent?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t('ValidationMessage.PercentMissing.Text')}
                    maxPrecision={2}
                    onBlur={(e) => {
                        if(e.target.value !== '') return;
                        
                        props.onChange({
                            ...props.allowance,
                            percent: undefined,
                        });
                    }}
                    onChange={(_e, rawValue) => {
                        props.onChange({
                            ...props.allowance,
                            percent: rawValue,
                        });
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.AmountsAllowancePerceHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.AmountsAllowancePerceHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.AmountsAllowancePerce.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
            </div>
            <TextInput
                type={'text'}
                placeholder={t('XRechnung.AmountsAllowanceName.Text')}
                label={t('XRechnung.AmountsAllowanceName.Text')}
                value={props.allowance.name?.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.onChange({
                        ...props.allowance,
                        name: e.target.value,
                    });
                }}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.allowance?.name,
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.AmountsAllowanceNameHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.AmountsAllowanceNameHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.AmountsAllowanceName.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />
            <Button
                className='action-button'
                icon='trash'
                danger={true}
                text={t('XRechnung.RemoveAmountsAllowance.Text')}
                onClick={() => {
                    props.onRemove();
                }}
            />
        </Card>
    );
}
