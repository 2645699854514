import { FileTools, Footer, IFooterMenuItem } from '@b4valuenet/ui-react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { AppFooterItem } from '../../../models/AppFooterItem';
import GebaerdenSpracheIcon from '../../../assets/icons/sprache-gebaerdensprache.svg';
import LeichteSpracheIcon from '../../../assets/icons/sprache-leichte.svg';
import { TFunction } from 'i18next';
import { generateAssetLink } from '../../../App';
import { supportContactCalled } from '../../../redux/slices/AppSlice';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const mapBackendFooterItems = (
    items: AppFooterItem[],
    translate: TFunction,
    isLoggedIn: boolean
) => {
    return items.map((item) => {
        const link = item.link.replace(
            '$basePath',
            isLoggedIn ? 'dashboard' : 'auth'
        );
        const isExternal = item.external;
        const isPDF = FileTools.getFileExtension(link) === 'pdf';
        const isLegalNotice = item.type === 'legalNotice';

        return {
            title: item.title + (isPDF ? ' (PDF)' : ''),
            linkTo: !isLegalNotice
                ? isPDF && !isExternal
                    ? generateAssetLink(link)
                    : link
                : 'legal-notice',
            external: isExternal || isPDF,
            itemType: item.itemType
        };
    });
};

export default function AppFooter() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    const mainRoute = isLoggedIn ? 'dashboard' : 'auth';
    const currentRoute = useAppSelector((state) => state.app.dashboardRoute);
    const backendFooterItemsRaw = useAppSelector(
        (state) => state.app.settings?.providerSettings.footerItems
    );
    const showRightSide = useAppSelector(
        (state) =>
            state.app.settings?.providerSettings.showFooterAccessibilityIcons
    );

    const sortedBackendFooterItemsRaw = useMemo(
        () =>
            backendFooterItemsRaw
                ? [...backendFooterItemsRaw].sort((a, b) =>
                      a.order > b.order ? 1 : -1
                  )
                : [],
        [backendFooterItemsRaw]
    );

    const backendFooterItems = useMemo(
        () => mapBackendFooterItems(sortedBackendFooterItemsRaw, t, isLoggedIn),
        [sortedBackendFooterItemsRaw, t, isLoggedIn]
    );

    const footerItems: IFooterMenuItem[] = backendFooterItems;

    const footerRightSideItems: IFooterMenuItem[] = [
        {
            tooltip: t('Common.SignLanguage.Text'),
            linkTo: '/' + mainRoute + '/sign-language',
            external: false,
            iconSrc: GebaerdenSpracheIcon,
            itemType: 'link',
        },
        {
            tooltip: t('Common.EasyLanguage.Text'),
            linkTo: '/' + mainRoute + '/easy-language',
            external: false,
            iconSrc: LeichteSpracheIcon,
            itemType: 'link',
        },
    ];

    return (
        <Footer
            design = 'default'
            items={[
                ...footerItems,
                {
                    title: t('Common.ReportBarrier.Text'),
                    onClick: () =>
                        dispatch(
                            supportContactCalled({
                                supportLastVisitedPage: currentRoute,
                            })
                        ),
                    linkTo: '/' + mainRoute + '/feedback/report-barrier',
                    external: false,
                    itemType: 'link'
                },
            ]}
            rightSideItems={showRightSide ? footerRightSideItems : []}
        />
    );
}
