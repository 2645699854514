import {
    Action,
    ThunkAction,
    combineReducers,
    configureStore,
} from '@reduxjs/toolkit';

import app from './slices/AppSlice';
import auth from './slices/AuthSlice';
import userSettings from './slices/UserSlice';

const reducers = combineReducers({ userSettings, auth, app });

export const store = configureStore({
    reducer: reducers,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
