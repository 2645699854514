import './Auth.scss';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
    TestSystemAlert,
    WindowSizeObserverProvider,
} from '@b4valuenet/ui-react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useEffect, useState } from 'react';

import AppFooter from '../../shared/components/global/AppFooter';
import LanguageChange from './components/LanguageChange/LanguageChange';
import LocaleService from '../../services/LocaleService';
import { changeUserLanguage } from '../../redux/actions/userSettings';
import { checkAuth } from '../../redux/actions/app';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

export default function Auth() {
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    const userLanguage = useAppSelector((state) => state.userSettings.lang);
    const appSettings = useAppSelector((state) => state.app.settings);

    let browserLang = LocaleService.getBrowserLanguage();
    let browserLangCountryCode = browserLang && browserLang.slice(0, 2);

    const [checkAuthInitialized, setCheckAuthInitialized] =
        useState<boolean>(false);

    const [cookies] = useCookies(['dashboard-route']);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    useEffect(() => {
        if (isLoggedIn) navigate(cookies['dashboard-route'] ?? '/dashboard');
    }, [isLoggedIn, navigate, cookies]);

    function onLanguageChange(newLanguage: string) {
        dispatch(changeUserLanguage(newLanguage));
    }

    useEffect(() => {
        if (!checkAuthInitialized) {
            setCheckAuthInitialized(true);
        } else {
            dispatch(checkAuth());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, dispatch]);

    return (
        <WindowSizeObserverProvider>
            <div className='page auth-page'>
                <header>
                    {appSettings?.isTestSystem && (
                        <TestSystemAlert text={t('Common.TestSystem.Text')} />
                    )}
                    <LanguageChange
                        language={userLanguage || browserLangCountryCode}
                        onChange={(newLanguage: string) =>
                            onLanguageChange(newLanguage)
                        }
                    />
                </header>
                <main>
                    <Outlet />
                </main>
                <AppFooter />
            </div>
        </WindowSizeObserverProvider>
    );
}
