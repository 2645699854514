import { Outlet, useLocation } from 'react-router-dom';

import { useAppRouterContext } from '../../../../AppRouter';

export interface IPageWrapper {
    component: JSX.Element;
}

export const PageWrapper = (props: IPageWrapper) => {
    const appRouterContext = useAppRouterContext();
    const location = useLocation();

    if (appRouterContext && !appRouterContext.isInTestMode)
        return props.component;
    else
        return (
            <div>
                <p>
                    <b>Wrapped </b>
                    <Outlet />
                    <b> in </b>
                    {props.component.type.name}
                </p>
                <code data-testid={'location-path'}>{location.pathname}</code>
            </div>
        );
};
