import { RequestTools } from '@b4valuenet/ui-react';
import { AxiosResponse } from 'axios';

export default class AuthService {
    static ApiRoot = 'api/auth/';

    static login = (
        email: string,
        password: string,
        language: string | null
    ) => {
        return RequestTools.post(this.ApiRoot + 'login', {
            email,
            password,
            language,
        }).then((response) => {
            return response.data;
        });
    };

    static isAuthenticated = async (): Promise<boolean> => {
        try {
            let response = await RequestTools.get(
                this.ApiRoot + 'isauthenticated'
            );

            return response.data === true;
        } catch (error) {
            let errorCode = RequestTools.getApiErrorCode(error);

            if (errorCode === 401) return false;
            else throw error;
        }
    };

    static logout = async (): Promise<AxiosResponse> => {
        return RequestTools.post(this.ApiRoot + 'current/logout');
    };
}
