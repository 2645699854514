import { Country } from '../../../../../../../../models/Country';
import SellerContactDetails from './components/SellerContactDetails';
import SellerMainInfo from './components/SellerMainInfo';
import SellerOtherPayeeDetails from './components/SellerOtherPayeeDetails';
import SellerPostalAddress from './components/SellerPostalAddress';
import SellerTaxRepresentativeInfo from './components/SellerTaxRepresentativeInfo';
import { SenderData } from '../../../../../../../../models/SenderData';

export interface ISellerSummary {
    senderData: SenderData;
    countriesList: Country[];
}

export default function SellerSummary(props: ISellerSummary) {
    let sellerTaxRepGroupIsNotEmpty: boolean =
        props.senderData.sellerTaxRepName ||
        props.senderData.sellerTaxRepVatId ||
        props.senderData.sellerTaxRepStreet ||
        props.senderData.sellerTaxRepName2 ||
        props.senderData.sellerTaxRepZip ||
        props.senderData.sellerTaxRepCity ||
        props.senderData.sellerTaxRepLocality ||
        props.senderData.sellerTaxRepCountry
            ? true
            : false;

    return (
        <>
            <SellerMainInfo senderData={props.senderData} />

            {sellerTaxRepGroupIsNotEmpty && (
                <SellerTaxRepresentativeInfo
                    senderData={props.senderData}
                    countriesList={props.countriesList}
                />
            )}

            <SellerPostalAddress
                senderData={props.senderData}
                countriesList={props.countriesList}
            />

            {(props.senderData.contactContactPerson ||
                props.senderData.contactContactEmail ||
                props.senderData.contactContactPhoneNo) && (
                <SellerContactDetails senderData={props.senderData} />
            )}

            {(props.senderData.payeeAddressName ||
                props.senderData.payeeIdRef ||
                props.senderData.payeeLegalEntityIdRef) && (
                <SellerOtherPayeeDetails senderData={props.senderData} />
            )}
        </>
    );
}
