export enum ValidationResultMessageStepSeverity {
    None = 0,
    Warning = 1,
    Error = 2,
}

export interface ValidationResultMessageStep {
    id: number;
    endTime: Date;
    severity: ValidationResultMessageStepSeverity;
    details: string;
    text?: any;
    state: string;
}
