import './UserAdministration.scss';

import {
    AnimationTools,
    Button,
    Column,
    ConsolePlaceTag,
    ConsoleTools,
    GridTable,
    IHeadingTitle,
    RequestTools,
    Row,
    Toast,
    findAndFocusModalCallerElement,
    useDebounce,
} from '@b4valuenet/ui-react';
import {
    ChangeEvent,
    MouseEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { UserState, UsersListItem } from '../../../../models/UsersListItem';

import AddItemModal from './modal/AddItemModal';
import DeleteItemsModal from './modal/DeleteItemsModal';
import EditItemModal from './modal/EditItemModal';
import UserAdministrationService from '../../../../services/UserAdministrationService';
import { UserModel } from '../../../../models/UserModel';
import { useTranslation } from 'react-i18next';

let consolePlaceTag = new ConsolePlaceTag({
    text: 'UserAdministration',
});

export function UserAdministration() {
    //#region 📁 Hooks
    // --------------------------------

    const { t } = useTranslation();

    const [modalCallerElement, setModalCallerElement] = useState<
        HTMLButtonElement | HTMLInputElement | HTMLAnchorElement | null
    >(null);

    // --------------------------------
    //#endregion

    //#region 📁 States and variables
    // --------------------------------

    //#region    ⌛ Content loading states
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingText] = useState<string | undefined>(undefined);
    const [loadingError, setLoadingError] = useState<string | null>(null);

    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);
    //#endregion

    //#region    📃 Content
    const [tableHeadingTitles, setTableHeadingTitles] = useState<
        IHeadingTitle[]
    >([]);

    const [data, setData] = useState<UsersListItem[] | null>(null);
    const [selectedIds, setSelectedIds] = useState<(string | number)[]>([]);
    const [debouncedSelectedIds, setDebouncedSelectedIds] = useState<
        (number | string)[]
    >([]);

    const [deleteId, setDeleteId] = useState<number | null>(null);

    useDebounce(() => {
        setDebouncedSelectedIds(selectedIds);
    }, [selectedIds]);

    //#endregion

    //#region    📁 Modal states
    //           -----------

    const [isItemAddModalActive, setIsItemAddModalActive] =
        useState<boolean>(false);

    const [isItemEditModalActive, setIsItemEditModalActive] =
        useState<boolean>(false);

    const [isItemsDeleteModalActive, setIsItemsDeleteModalActive] =
        useState<boolean>(false);

    //#region       ⚙️ Generic modal states
    const [modalItem, setModalItem] = useState<UserModel | null>(null);

    //#endregion

    // --------------------------------
    //#endregion

    // --------------------------------
    //#endregion

    //#region 📁 Event handlers
    // --------------------------------

    //#region    🔷 Table top bar events
    function handleItemAddButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
        setModalCallerElement(e.target as any);

        setModalItem({
            firstName: '',
            lastName: '',
            email: '',
            hasAdminAccess: false,
        } as UserModel);

        setIsItemAddModalActive(true);
    }

    function handleItemsDeleteButtonClick(
        e: React.MouseEvent<HTMLButtonElement>,
        id?: number
    ) {
        if (debouncedSelectedIds.length === 0 && !id) return;

        if (id) {
            setDeleteId(id);
        }

        setModalCallerElement(e.target as any);

        setIsItemsDeleteModalActive(true);
    }

    async function handleItemsLockButtonClick(
        e: React.MouseEvent<HTMLButtonElement>,
        id?: number
    ) {
        if (debouncedSelectedIds.length === 0 && !id) return;

        setModalCallerElement(e.target as any);

        setIsLoading(true);

        try {
            let response = await UserAdministrationService.lock(
                id ? [id] : debouncedSelectedIds
            );
            if (response !== null) {
                Toast.success(response.message, 350);
            }

            refresh(false);
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        setIsLoading(false);
    }

    async function handleItemsUnlockButtonClick(
        e: React.MouseEvent<HTMLButtonElement>,
        id?: number
    ) {
        if (debouncedSelectedIds.length === 0 && !id) return;

        setModalCallerElement(e.target as any);

        setIsLoading(true);

        try {
            let response = await UserAdministrationService.unlock(
                id ? [id] : debouncedSelectedIds
            );
            if (response !== null) {
                Toast.success(response.message, 350);
            }

            refresh(false);
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        setIsLoading(false);
    }
    //#endregion

    //#region    📃 Table row events
    async function handleItemEditButtonClick(
        user: UsersListItem,
        e: React.MouseEvent<HTMLButtonElement>
    ) {
        setIsLoading(true);

        let userFull = await UserAdministrationService.get(user.id);

        if (userFull !== null) {
            setModalCallerElement(e.target as any);
            setModalItem(userFull);
            AnimationTools.activateModal(setIsItemEditModalActive);
        }

        setIsLoading(false);
    }
    //#endregion

    //#region    📁 Modal events
    //           -----------

    //#region       ⚙️ Generic modal events
    function handleModalInputChange(
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        property: string,
        isCheckbox: boolean = false
    ) {
        let newValue =
            isCheckbox && e.target instanceof HTMLInputElement
                ? e.target.checked
                : e.target.value;

        if (modalItem !== null) {
            let newModalItem = {
                ...modalItem,
                [property]: newValue,
            } as UserModel;

            ConsoleTools.log(newModalItem, consolePlaceTag);
            setModalItem(newModalItem);
        }
    }

    //#endregion

    //#endregion

    // --------------------------------
    //#endregion

    //#region 📁 Functions
    // --------------------------------

    const refresh = useCallback(
        (showToast: boolean = true) => {
            setRefreshTrigger(!refreshTrigger);
            setSelectedIds([]);

            if (showToast) Toast.info(t('Common.ListUpdateSuccess.Text'));
        },
        [refreshTrigger, t]
    );

    // --------------------------------
    //#endregion

    useEffect(() => {
        setTableHeadingTitles([
            {
                title: t('UserAdministration.IdHeader.Text'),
            },
            {
                title: t('UserAdministration.StateHeader.Text'),
            },
            {
                title: t('UserAdministration.FirstNameHeader.Text'),
            },
            {
                title: t('UserAdministration.LastNameHeader.Text'),
            },
            { title: t('UserAdministration.Actions.Text') },
        ]);
    }, [t]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                let content = await UserAdministrationService.getAll();

                setData(content ?? null);
                setLoadingError(null);

                setIsLoading(false);
            } catch (error) {
                if (error instanceof Error) {
                    setLoadingError(RequestTools.getApiErrorMessage(error));
                }

                setIsLoading(false);
            }
        };

        fetchData();
    }, [refreshTrigger]);

    return (
        <>
            <GridTable
                isSubPage={true}
                enableSelection={true}
                disableCtrlA={
                    isItemAddModalActive ||
                    isItemEditModalActive ||
                    isItemsDeleteModalActive
                }
                selectedItems={debouncedSelectedIds}
                onSelectItems={(newSelectedIds: (string | number)[]) => {
                    setSelectedIds(newSelectedIds);
                }}
                className='user-administration'
                title={t('Common.UserAdministration.Text')}
                onEmptyMessage={t('UserAdministration.NoRecords.Text')}
                isLoading={isLoading}
                loadingError={loadingError}
                loadingText={loadingText ?? t('Common.LoadingMessage.Text')}
                tableHeadingTitles={tableHeadingTitles}
                tableData={data}
                checkboxAllTitle={t('Common.SelectAll.Text', {
                    items: t('UserAdministration.User.Text'),
                })}
                srOnlyCheckBoxTitle={true}
                rowRendering={(element: UsersListItem, index: number) => {
                    return (
                        <Row
                            key={index}
                            id={element.id}
                            checkboxTitle={t('Common.Select.Text', {
                                item: t('UserAdministration.User.Text'),
                            })}
                            srOnlyCheckBoxTitle={true}
                        >
                            <Column size='small'>{element.id}</Column>
                            <Column size='small'>
                                {t(
                                    'UserAdministration.UserState_' +
                                        UserState[element.state] +
                                        '.Text'
                                )}
                            </Column>
                            <Column size='medium'>{element.firstName}</Column>
                            <Column size='medium'>{element.lastName}</Column>
                            <Column className='actions'>
                                <Button
                                    className='edit'
                                    icon='pencil-square'
                                    primary={true}
                                    text={t(
                                        'UserAdministration.EditUserBtn.Text'
                                    )}
                                    onClick={(
                                        e: MouseEvent<HTMLButtonElement>
                                    ) => {
                                        handleItemEditButtonClick(element, e);
                                    }}
                                />

                                {(element.state === 1 ||
                                    element.state === 4) && (
                                    <Button
                                        onClick={(
                                            e: MouseEvent<HTMLButtonElement>
                                        ) =>
                                            handleItemsLockButtonClick(
                                                e,
                                                element.id
                                            )
                                        }
                                        icon='lock'
                                        aira-label={t(
                                            'UserAdministration.LockUserBtn.Tooltip'
                                        )}
                                        title={t(
                                            'UserAdministration.LockUserBtn.Tooltip'
                                        )}
                                        className='lock'
                                        primary={true}
                                    />
                                )}
                                {(element.state === 2 ||
                                    element.state === 5) && (
                                    <Button
                                        onClick={(
                                            e: MouseEvent<HTMLButtonElement>
                                        ) =>
                                            handleItemsUnlockButtonClick(
                                                e,
                                                element.id
                                            )
                                        }
                                        icon='unlock'
                                        aria-label={t(
                                            'UserAdministration.UnlockUserBtn.Tooltip'
                                        )}
                                        title={t(
                                            'UserAdministration.UnlockUserBtn.Tooltip'
                                        )}
                                        className='unlock'
                                        primary={true}
                                    />
                                )}
                                <Button
                                    aria-label={t(
                                        'UserAdministration.DeleteUserBtn.Tooltip'
                                    )}
                                    onClick={(
                                        e: MouseEvent<HTMLButtonElement>
                                    ) =>
                                        handleItemsDeleteButtonClick(
                                            e,
                                            element.id
                                        )
                                    }
                                    icon='trash'
                                    title={t(
                                        'UserAdministration.DeleteUserBtn.Tooltip'
                                    )}
                                    className='delete'
                                    primary={true}
                                    danger={true}
                                />
                            </Column>
                        </Row>
                    );
                }}
                actionButtons={
                    <>
                        <Button
                            onClick={(e: MouseEvent<HTMLButtonElement>) =>
                                handleItemAddButtonClick(e)
                            }
                            icon='plus-lg'
                            text={t('UserAdministration.AddUserBtn.Tooltip')}
                            className='add'
                            primary={true}
                        />

                        <Button
                            onClick={(e: MouseEvent<HTMLButtonElement>) =>
                                handleItemsDeleteButtonClick(e)
                            }
                            icon='trash'
                            text={t('UserAdministration.DeleteUserBtn.Tooltip')}
                            className='delete'
                            primary={debouncedSelectedIds.length > 0}
                        />

                        <Button
                            onClick={(e: MouseEvent<HTMLButtonElement>) =>
                                handleItemsLockButtonClick(e)
                            }
                            icon='lock'
                            text={t('UserAdministration.LockUserBtn.Tooltip')}
                            className='lock'
                            primary={debouncedSelectedIds.length > 0}
                        />

                        <Button
                            onClick={(e: MouseEvent<HTMLButtonElement>) =>
                                handleItemsUnlockButtonClick(e)
                            }
                            icon='unlock'
                            text={t('UserAdministration.UnlockUserBtn.Tooltip')}
                            className='unlock'
                            primary={debouncedSelectedIds.length > 0}
                        />

                        <Button
                            onClick={() => refresh()}
                            icon='arrow-clockwise'
                            text={t('Messages.RefreshBtn.Text')}
                            className='refresh'
                        />
                    </>
                }
            />

            <AddItemModal
                onClose={(actionConfirmed: boolean) => {
                    AnimationTools.deactivateModal(
                        setIsItemAddModalActive,
                        () => {
                            setModalItem(null);
                            setDebouncedSelectedIds([]);
                            setSelectedIds([]);

                            if (actionConfirmed) refresh(false);

                            findAndFocusModalCallerElement(
                                modalCallerElement,
                                () => {
                                    setModalCallerElement(null);
                                }
                            );
                        }
                    );
                }}
                onInputValueChange={(
                    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
                    property: string,
                    isCheckbox: boolean
                ) => handleModalInputChange(e, property, isCheckbox)}
                active={isItemAddModalActive}
                item={modalItem}
            />
            <EditItemModal
                onClose={(actionConfirmed: boolean) => {
                    AnimationTools.deactivateModal(
                        setIsItemEditModalActive,
                        () => {
                            if (actionConfirmed) refresh(false);

                            findAndFocusModalCallerElement(
                                modalCallerElement,
                                () => {
                                    setModalCallerElement(null);
                                }
                            );

                            setModalItem(null);
                        }
                    );
                }}
                onInputValueChange={(
                    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
                    property: string,
                    isCheckbox: boolean
                ) => handleModalInputChange(e, property, isCheckbox)}
                active={isItemEditModalActive}
                item={modalItem}
            />
            <DeleteItemsModal
                onClose={(actionConfirmed: boolean) => {
                    setIsItemsDeleteModalActive(false);

                    if (actionConfirmed) {
                        setDebouncedSelectedIds([]);
                        setSelectedIds([]);
                        setDeleteId(null);
                        refresh(false);
                    }

                    findAndFocusModalCallerElement(modalCallerElement, () => {
                        setModalCallerElement(null);
                    });
                }}
                selectedIds={debouncedSelectedIds}
                deleteId={deleteId}
                active={isItemsDeleteModalActive}
            />
        </>
    );
}
