import { RebateGroup } from '../../../../../../../../../models/RebateGroup';
import { useTranslation } from 'react-i18next';

interface IRebateGroupsSummary {
    rebateGroups: RebateGroup[];
}

export default function RebateGroupsSummary(props: IRebateGroupsSummary) {
    const { t } = useTranslation();

    return (
        <div className='pseudo-table align-center cw-25'>
            <table>
                <thead>
                    <tr>
                        <th>{t('XRechnung.RebateGroupName.Text')}</th>
                        <th>{t('XRechnung.RebateGroupBaseAmount.Text')}</th>
                        <th>{t('XRechnung.RebateGroupPercent.Text')}</th>
                        <th>{t('XRechnung.RebateGroupAmount.Text')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.rebateGroups.map((rebateGroup, index) => {
                        return (
                            <tr key={index}>
                                <td>{rebateGroup.name}</td>
                                <td>{rebateGroup.baseAmount}</td>
                                <td>{rebateGroup.percent}</td>
                                <td>{rebateGroup.amount}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
