import './UploadReportDialog.scss';

import {
    BootstrapIcon,
    Button,
    Dialog
} from '@b4valuenet/ui-react';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../../redux/hooks';

interface IUploadReportFile {
    filename: string;
}
interface IUploadReportFileSize extends IUploadReportFile {
    size: string;
    maxUploadLimit: string;
}
interface IUploadReportFileUpload extends IUploadReportFile {
    reason: string;
}
interface IUploadReportFileType extends IUploadReportFile {
    type: string;
    allowedTypes: string;
}

export interface IUploadReportDialogData {
    size: IUploadReportFileSize[];
    type: IUploadReportFileType[];
    upload: IUploadReportFileUpload[];
    success: IUploadReportFile[];
}

export interface IUploadReportDialog {
    report: IUploadReportDialogData;
    onClose: CallableFunction;
    active: boolean;
}

export function UploadReportDialog(props: IUploadReportDialog) {
    const { t } = useTranslation();

    const appSettings = useAppSelector((state) => state.app.settings);

    return (
        <Dialog
            icon='upload'
            className='upload-report-dialog'
            title={t('Messages.UploadReport.Text')}
            closeButtonTitle={t('Common.Close.Text')}
            active={props.active}
            aria-labelledby='upload-report-modal-title'
            onClose={() => {
                props.onClose();
            }}
        >
            <div className='reports'>
                {props.report.size.length > 0 && (
                    <table>
                        <caption>
                            {t('XRechnung.UploadReportFileTooLarge.Text')}
                        </caption>
                        <thead>
                            <tr>
                                <th>{t('Common.Filename.Text')}</th>
                                <th>{t('Common.FileSize.Text')}</th>
                                <th>{t('Common.UploadSizeLimit.Text')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.report.size.map((file, index) => (
                                <tr key={index}>
                                    <td>{file.filename}</td>
                                    <td>{file.size}</td>
                                    <td>{file.maxUploadLimit}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {props.report.type.length > 0 && (
                    <table>
                        <caption>
                            {t(
                                'XRechnung.UploadReportNotAllowedAttachmentType.Text'
                            )}
                        </caption>
                        <thead>
                            <tr>
                                <th>{t('Common.Filename.Text')}</th>
                                <th>{t('Common.FileType.Text')}</th>
                                <th>{t('Common.SupportedFileTypes.Text')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.report.type.map((file, index) => (
                                <tr key={index}>
                                    <td>{file.filename}</td>
                                    <td>{file.type}</td>
                                    <td>{file.allowedTypes}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {props.report.upload.length > 0 && (
                    <table>
                        <caption>
                            {t('XRechnung.UploadReportUploadError.Text')}
                        </caption>
                        <thead>
                            <tr>
                                <th>{t('Common.Filename.Text')}</th>
                                <th>{t('Common.Reason.Text')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.report.upload.map((file, index) => (
                                <tr key={index}>
                                    <td>{file.filename}</td>
                                    <td>{file.reason}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {props.report.success.length > 0 && (
                    <table>
                        <caption>
                            {t('XRechnung.UploadReportSuccess.Text')}
                        </caption>
                        <thead>
                            <tr>
                                <th>{t('Common.Filename.Text')}</th>
                                <th>{t('Common.Result.Text')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.report.success.map((file, index) => (
                                <tr key={index}>
                                    <td>{file.filename}</td>
                                    <td>
                                        <BootstrapIcon
                                            icon='check-circle-fill'
                                            className='success'
                                        />
                                        <span className='sr-only'>
                                            {t('Common.Success.Text')}
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>

            <div className='buttons-group'>
                {(props.report.type.length > 0 ||
                    props.report.size.length > 0 ||
                    props.report.upload.length > 0) && (
                    <Button
                        {...{
                            icon: 'headset',
                            text: t('Common.ContactSupportButton.Text'),
                            isLink: true,
                            linkTo: `mailto:${appSettings?.supportMail}`,
                            linkExternal: true,
                        }}
                    />
                )}
                <Button
                    icon='x-lg'
                    onClick={() => props.onClose()}
                    text={t('Common.Close.Text')}
                />
            </div>
        </Dialog>
    );
}
