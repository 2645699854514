import { Button, Dialog } from '@b4valuenet/ui-react';

import { useTranslation } from 'react-i18next';

export interface IDownloadInvoiceModal {
    active: boolean;
    onClose: CallableFunction;
}

export enum DownloadInvoiceModelResponse {
    Cancel = 0,
    WithoutAttachments = 1,
    WithAttachments = 2,
}

export default function DownloadInvoiceModal(props: IDownloadInvoiceModal) {
    const { t } = useTranslation();

    function onClose(actionConfirmed: DownloadInvoiceModelResponse) {
        props.onClose(actionConfirmed);
    }

    return (
        <Dialog
            aria-labelledby='xrechnung-download-modal-title'
            closeButtonTitle={t('Common.Close.Text')}
            active={props.active}
            onClose={() => onClose(DownloadInvoiceModelResponse.Cancel)}
        >
            <h2 id='xrechnung-download-modal-title'>
                {t('Messages.DownloadBtn.Tooltip')}
            </h2>
            <div className='modal-main'>{t('XRechnung.ExportDialog.Text')}</div>
            <div className='footer'>
                <Button
                    onClick={() =>
                        onClose(DownloadInvoiceModelResponse.WithAttachments)
                    }
                    primary={true}
                    text={t('XRechnung.ExportWithAttachmentsBtn.Text')}
                />
                <Button
                    onClick={() =>
                        onClose(DownloadInvoiceModelResponse.WithoutAttachments)
                    }
                    primary={true}
                    text={t('XRechnung.ExportWithoutAttachmentsBtn.Text')}
                />
                <Button
                    onClick={() => onClose(DownloadInvoiceModelResponse.Cancel)}
                    text={t('Common.CancelBtn.Text')}
                />
            </div>
        </Dialog>
    );
}
