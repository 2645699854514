import {
    AnimationTools,
    AsyncForm,
    BootstrapIcon,
    Button,
    Card,
    LoadingOverlay,
    RequiredFieldsNotice,
    TextInput,
    Toast,
    ValidationTools,
    findAndFocusModalCallerElement,
} from '@b4valuenet/ui-react';
import { ChangeEvent, createRef, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { PeppolStatus } from '../../../../../models/PeppolActivate';
import { PeppolVerificationState } from '../../../../../models/PeppolVerificationState';
import ClientService from '../../../../../services/ClientService';
import PeppolUnlinkDialog from './PeppolUnlinkDialog';
import PeppolVerificationDialog from './PeppolVerificationDialog';

export default function PeppolVerificationCard() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isUnlinkDialogActive, setIsUnlinkDialogActive] =
        useState<boolean>(false);

    const [modalCallerElement, setModalCallerElement] = useState<
        HTMLButtonElement | HTMLInputElement | HTMLAnchorElement | null
    >(null);

    const [peppolStatus, setPeppolStatus] = useState<PeppolStatus | null>(null);
    const [formSended, setFormSended] = useState<boolean>(false);
    const [attachmentDialogActive, setAttachmentDialogActive] =
        useState<boolean>(false);

    const newProcessButtonRef = createRef<HTMLButtonElement>();

    const [isFormValid, setIsFormValid] = useState<boolean>();

    useEffect(() => {
        if (peppolStatus !== null && peppolStatus.peppolId) {
            setIsFormValid(true);
            return;
        }

        setIsFormValid(false);
    }, [peppolStatus, peppolStatus?.peppolId]);

    function handlePeppolIdChange(
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) {
        let newValue = e.target.value;

        if (peppolStatus !== null) {
            setPeppolStatus({ ...peppolStatus, peppolId: newValue });
        }
    }

    const getPeppolAttachmentState = (): JSX.Element => {
        if (!peppolStatus) {
            return <></>;
        }

        // TODO: respect locale from user
        var expiryDate = new Date(
            peppolStatus.tokenExpiryDate
        ).toLocaleTimeString('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        if (
            peppolStatus.verificationState ===
            PeppolVerificationState.VerificationPending
        ) {
            return (
                <>
                    {t(`ClientAdministration.PeppolAttachmentValidUntil.Text`)}
                    <strong> {expiryDate}</strong>
                </>
            );
        }

        if (
            peppolStatus.verificationState ===
            PeppolVerificationState.VerificationExpired
        ) {
            return (
                <>
                    {t(`ClientAdministration.PeppolAttachmentExpiredAt.Text`)}
                    <strong> {expiryDate}</strong>
                </>
            );
        }

        return <></>;
    };

    useEffect(() => {
        const fetchPeppolStatus = async () => {
            setIsLoading(true);

            try {
                const result = await ClientService.getCurrentPeppolState();

                if (result.data) {
                    setPeppolStatus(result.data);
                }

                setIsLoading(false);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);

                setIsLoading(false);
            }
        };

        fetchPeppolStatus();
    }, []);

    const cancelVerificationProcess = async () => {
        setIsLoading(true);
        try {
            const result =
                await ClientService.cancelPeppolVerificationProcess();

            if (result.data) {
                setPeppolStatus(result.data);
            }

            setIsLoading(false);
            setFormSended(false);
            Toast.success(result.message, 350);
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);

            setIsLoading(false);
        }
    };

    const startVerificationProcess = async (
        e?: React.MouseEvent<HTMLButtonElement>
    ) => {
        setFormSended(true);

        if (isFormValid === false || !peppolStatus?.peppolId) {
            return;
        }

        setIsLoading(true);

        try {
            const result =
                await ClientService.activatePeppolVerificationProcess({
                    peppolId: peppolStatus.peppolId,
                });

            if (result.data) {
                setPeppolStatus(result.data);
            }

            setIsLoading(false);

            Toast.success(result.message, 350);
            AnimationTools.activateModal(setAttachmentDialogActive);
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);

            setIsLoading(false);
        }
    };

    const openAttachmentDialog = async (
        renew: boolean,
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (e.target instanceof HTMLButtonElement) {
            setModalCallerElement(e.target);
        }

        if (renew) {
            await startVerificationProcess();
        }

        setAttachmentDialogActive(true);
    };

    let statusIcon = '';

    switch (peppolStatus?.verificationState) {
        case PeppolVerificationState.Active:
            statusIcon = 'check-circle-fill';
            break;

        case PeppolVerificationState.NotActive:
            statusIcon = 'exclamation-triangle-fill';
            break;

        case PeppolVerificationState.VerificationExpired:
            statusIcon = 'exclamation-triangle-fill';
            break;

        case PeppolVerificationState.VerificationPending:
            statusIcon = 'hourglass-split';
            break;
    }

    return (
        <div>
            <Card
                className='peppol-verification-card'
                title={t('ClientAdministration.ActivateClientHeader.Text')}
            >
                <AsyncForm
                    onSubmit={() => startVerificationProcess()}
                    className='card-content'
                >
                    <RequiredFieldsNotice
                        description={t(
                            'ValidationMessage.IndicatesARequiredField.Text'
                        )}
                    />
                    <p className='info'>
                        {t('ClientAdministration.PeppolId.Help')}
                        <ul>
                            <li>{t('ClientAdministration.PeppolId1.Help')}</li>
                            <li>{t('ClientAdministration.PeppolId2.Help')}</li>
                        </ul>
                    </p>
                    <p>
                        {t('ClientAdministration.ActivationStatus.Text')}:
                        <strong className='activation-status'>
                            {
                                <BootstrapIcon
                                    icon={statusIcon}
                                    className={
                                        'state-' +
                                        (peppolStatus?.verificationState ?? 0)
                                    }
                                />
                            }
                            {peppolStatus
                                ? '' +
                                  t(
                                      `ClientAdministration.Peppol${
                                          PeppolVerificationState[
                                              peppolStatus?.verificationState
                                          ]
                                      }.Text`
                                  )
                                : ''}
                        </strong>
                    </p>
                    <div className='spaced-fields'>
                        <TextInput
                            type='text'
                            required
                            icon='building'
                            value={
                                peppolStatus?.peppolId
                                    ? peppolStatus.peppolId
                                    : ''
                            }
                            readOnly={
                                peppolStatus?.verificationState !==
                                PeppolVerificationState.NotActive
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handlePeppolIdChange(e)
                            }
                            isValid={ValidationTools.isRequiredFieldValueValid(
                                peppolStatus?.peppolId ?? '',
                                formSended
                            )}
                            validationError={t(
                                'ValidationMessage.Required.Text'
                            )}
                            placeholder={t(
                                'ClientAdministration.PeppolId.Text'
                            )}
                            label={t('ClientAdministration.PeppolId.Text')}
                        />
                        {(peppolStatus?.verificationState ===
                            PeppolVerificationState.VerificationPending ||
                            peppolStatus?.verificationState ===
                                PeppolVerificationState.VerificationExpired) && (
                            <p>{getPeppolAttachmentState()}</p>
                        )}
                    </div>
                    <div className='flex-row'>
                        {peppolStatus?.verificationState ===
                            PeppolVerificationState.NotActive && (
                            <Button
                                icon='chevron-right'
                                iconPosition='right'
                                text={t(
                                    'ClientAdministration.PeppolStartVerification.Text'
                                )}
                                type='submit'
                                primary={true}
                            />
                        )}
                        {(peppolStatus?.verificationState ===
                            PeppolVerificationState.VerificationExpired ||
                            peppolStatus?.verificationState ===
                                PeppolVerificationState.VerificationPending) && (
                            <Button
                                buttonRef={newProcessButtonRef}
                                text={t(
                                    'ClientAdministration.PeppolRenewVerification.Text'
                                )}
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    openAttachmentDialog(true, e);
                                }}
                                icon={'arrow-repeat'}
                                primary={true}
                            />
                        )}
                        {peppolStatus?.verificationState ===
                            PeppolVerificationState.VerificationPending && (
                            <Button
                                text={t(
                                    'ClientAdministration.PeppolRedownloadAttachment.Text'
                                )}
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>
                                ) => {
                                    openAttachmentDialog(false, e);
                                }}
                                icon={'download'}
                            />
                        )}
                        {peppolStatus?.verificationState !==
                            PeppolVerificationState.NotActive && (
                            <Button
                                danger={true}
                                text={
                                    peppolStatus?.verificationState ===
                                    PeppolVerificationState.Active
                                        ? t(
                                              'ClientAdministration.PeppolCancelPeppolConnection.Text'
                                          )
                                        : t(
                                              'ClientAdministration.PeppolCancelVerification.Text'
                                          )
                                }
                                onClick={() =>
                                    AnimationTools.activateModal(
                                        setIsUnlinkDialogActive
                                    )
                                }
                                icon={'x-lg'}
                            />
                        )}
                    </div>
                </AsyncForm>
                <LoadingOverlay
                    loadingMessage={t('Common.LoadingMessage.Text')}
                    active={isLoading}
                    isGlobal={false}
                />
            </Card>
            <PeppolVerificationDialog
                onClose={() => {
                    AnimationTools.deactivateModal(
                        setAttachmentDialogActive,
                        () => {
                            findAndFocusModalCallerElement(
                                modalCallerElement,
                                () => {
                                    setModalCallerElement(null);
                                }
                            );
                        }
                    );

                    //TODO: this doesnt focus the button...
                    findAndFocusModalCallerElement(
                        newProcessButtonRef.current,
                        () => {
                            setModalCallerElement(null);
                        }
                    );
                }}
                isActive={attachmentDialogActive}
                expiryDate={peppolStatus ? peppolStatus.tokenExpiryDate : null}
            />
            <PeppolUnlinkDialog
                onClose={(actionConfirmed: boolean) => {
                    if (actionConfirmed) {
                        cancelVerificationProcess();
                    }

                    AnimationTools.deactivateModal(
                        setIsUnlinkDialogActive,
                        () => {
                            findAndFocusModalCallerElement(
                                modalCallerElement,
                                () => {
                                    setModalCallerElement(null);
                                }
                            );
                        }
                    );

                    //TODO: this doesnt focus the button...
                    findAndFocusModalCallerElement(
                        newProcessButtonRef.current,
                        () => {
                            setModalCallerElement(null);
                        }
                    );
                }}
                isActive={isUnlinkDialogActive}
            />
        </div>
    );
}
