import {
    RequiredFieldsNotice,
    TextInput,
    ValidationTools,
} from '@b4valuenet/ui-react';

import { ChangeEvent } from 'react';
import { UserModel } from '../../../../../../models/UserModel';
import { useTranslation } from 'react-i18next';

export interface IUserListModalFields {
    formSended: boolean;
    onInputValueChange: CallableFunction;
    item: UserModel | null;
}

export default function UserListModalFields(props: IUserListModalFields) {
    const { t } = useTranslation();

    //TODO: Required field miss aligend. Must be always on top of the form - BitV
    return (
        <>
            <RequiredFieldsNotice
                description={t(
                    'ValidationMessage.IndicatesARequiredField.Text'
                )}
            />
            <TextInput
                required={true}
                icon='building'
                type='text'
                autoComplete='off'
                label={t('UserAdministration.FirstName.Text')}
                placeholder={t('UserAdministration.FirstName.Text')}
                value={props.item?.firstName ?? ''}
                isValid={
                    ValidationTools.isRequiredFieldValueValid(
                        props.item?.firstName,
                        props.formSended
                    ) &&
                    ValidationTools.hasValidMaxLength(
                        props.item?.firstName,
                        props.formSended
                    )
                }
                validationError={
                    (props.item?.firstName.length ?? 0) > 200
                        ? t('ValidationMessage.MaxLength.Text', {
                              maxLength: 200,
                          })
                        : t('ValidationMessage.Required.Text')
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    props.onInputValueChange(e, 'firstName');
                }}
            />

            <TextInput
                required={true}
                icon='building'
                type='text'
                autoComplete='off'
                label={t('UserAdministration.LastName.Text')}
                placeholder={t('UserAdministration.LastName.Text')}
                value={props.item?.lastName ?? ''}
                isValid={
                    ValidationTools.isRequiredFieldValueValid(
                        props.item?.lastName,
                        props.formSended
                    ) &&
                    ValidationTools.hasValidMaxLength(
                        props.item?.lastName,
                        props.formSended
                    )
                }
                validationError={
                    (props.item?.lastName.length ?? 0) > 200
                        ? t('ValidationMessage.MaxLength.Text', {
                              maxLength: 200,
                          })
                        : t('ValidationMessage.Required.Text')
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    props.onInputValueChange(e, 'lastName');
                }}
            />

            <TextInput
                required={true}
                icon='building'
                type='text'
                autoComplete='off'
                label={t('UserAdministration.Email.Text')}
                placeholder={t('UserAdministration.Email.Text')}
                value={props.item?.email ?? ''}
                isValid={
                    ValidationTools.isEmailFieldValueValid(
                        props.item?.email ?? '',
                        props.formSended
                    ) &&
                    ValidationTools.hasValidMaxLength(
                        props.item?.email ?? '',
                        props.formSended
                    )
                }
                validationError={
                    (props.item?.email.length ?? 0) === 0
                        ? t('ValidationMessage.Required.Text')
                        : (props.item?.email.length ?? 0) > 200
                        ? t('ValidationMessage.MaxLength.Text', {
                              maxLength: 200,
                          })
                        : t('ValidationMessage.EmailInvalid.Text')
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    props.onInputValueChange(e, 'email');
                }}
            />
        </>
    );
}
