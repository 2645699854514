import { SurchargeGroup } from '../../../../../../../../../models/SurchargeGroup';
import { useTranslation } from 'react-i18next';

interface ISurchargeGroupsSummary {
    surchargeGroups: SurchargeGroup[];
}

export default function SurchargeGroupsSummary(props: ISurchargeGroupsSummary) {
    const { t } = useTranslation();

    return (
        <div className='pseudo-table align-center cw-25'>
            <table>
                <thead>
                    <tr>
                        <th>{t('XRechnung.SurchargeGroupName.Text')}</th>
                        <th>{t('XRechnung.SurchargeGroupBaseAmount.Text')}</th>
                        <th>{t('XRechnung.SurchargeGroupPercent.Text')}</th>
                        <th>{t('XRechnung.SurchargeGroupAmount.Text')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.surchargeGroups.map((surchargeGroup, index) => {
                        return (
                            <tr key={index}>
                                <td>{surchargeGroup.name}</td>
                                <td>{surchargeGroup.baseAmount}</td>
                                <td>{surchargeGroup.percent}</td>
                                <td>{surchargeGroup.amount}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
