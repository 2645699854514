import { NumberInput, ValidationTools } from '@b4valuenet/ui-react';

import { useTranslation } from 'react-i18next';
import { AmountGroupData } from '../../../../../../../models/AmountGroupData';
import { IXRechnungSubPage } from '../../../XRechnungPage';

export interface IInvoiceAmountGroup {
    group: AmountGroupData;
    isFormSubmitted: boolean;
    onChange: (updatedGroup: AmountGroupData) => void;
}

export default function InvoiceAmountGroup(
    props: IInvoiceAmountGroup & IXRechnungSubPage
) {
    const { t } = useTranslation();

    const getTranslationByVatRate = (vatRate: number | undefined): string => {
        if (vatRate === -1) {
            return t('Common.TaxCategoryExemptFromTax.Text');
        } else if (vatRate === -2) {
            return t('Common.TaxCategoryVatReverseCharge.Text');
        } else if (vatRate === -3) {
            return t('Common.TaxCategoryVatExemptEea.Text');
        } else if (vatRate === -4) {
            return t('Common.TaxCategoryFreeExportItem.Text');
        } else if (vatRate === -5) {
            return t('Common.TaxCategoryServicesOutsideScope.Text');
        } else {
            return vatRate + '%';
        }
    };

    let vatTranslation = getTranslationByVatRate(props.group.vatRate);

    return (
        <div className='flex-row invoice-amount-group'>
            <NumberInput
                min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                placeholder={t('XRechnung.TaxableAmount.Text')}
                label={t('XRechnung.TaxableAmount.Text')}
                value={
                    props.group.netAmount === undefined ||
                    props.group.netAmount === null
                        ? undefined
                        : props.group.netAmount
                }
                onChange={(_e, rawValue) => {
                    props.onChange({
                        ...props.group,
                        netAmount: rawValue || undefined,
                        vatAmount:
                            Math.round(
                                rawValue * 100 * (props.group.vatRate || 0)
                            ) /
                            100 /
                            100,
                    });
                }}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.group.netAmount?.toString(),
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                infoButtonEnabled={true}
                infoButtonText={t('XRechnung.TaxableAmountHelpTitle.ShortText')}
                infoButtonTooltipTitle={t(
                    'XRechnung.TaxableAmountHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.TaxableAmount.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />
            <p className='vat-rate'>
                <strong>{vatTranslation}</strong>
            </p>
            <NumberInput
                min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                placeholder={t('XRechnung.AmountsVatAmount.Text')}
                label={t('XRechnung.AmountsVatAmount.Text')}
                value={props.group.vatAmount}
                onChange={(_e, rawValue) => {
                    props.onChange({
                        ...props.group,
                        vatAmount: rawValue || undefined,
                    });
                }}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.group.vatAmount?.toString(),
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.AmountsVatAmount1HelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.AmountsVatAmount1HelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.AmountsNetAmount1.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />
        </div>
    );
}
