import {
    Button,
    Card,
    NumberInput,
    TextInput,
    Toast,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { createRef, useEffect } from 'react';

import { SurchargeGroup } from '../../../../../../../models/SurchargeGroup';
import { useTranslation } from 'react-i18next';

export interface IInvoiceSurchargeGroup {
    group: SurchargeGroup;
    isFormSubmitted: boolean;
    onChange: (updatedGroup: SurchargeGroup) => void;
    onRemove: () => void;
}

export default function InvoiceSurchargeGroup(props: IInvoiceSurchargeGroup) {
    const { t } = useTranslation();

    const firstInputRef = createRef<HTMLInputElement>();

    useEffect(() => {
        if (firstInputRef) {
            firstInputRef.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let updatedGroup = {
            ...props.group,
        } as SurchargeGroup;

        if (updatedGroup.baseAmount && updatedGroup.percent) {
            updatedGroup.amount =
                (updatedGroup.baseAmount * updatedGroup.percent) / 100;

            updatedGroup.amount = Math.round(updatedGroup.amount * 100) / 100;
            props.onChange(updatedGroup);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.group.baseAmount, props.group.percent]);

    useEffect(() => {
        let updatedGroup = {
            ...props.group,
        } as SurchargeGroup;

        if (props.group.amount && updatedGroup.baseAmount) {
            props.group.percent =
                (props.group.amount / updatedGroup.baseAmount) * 100;

            props.group.percent = Math.round(props.group.percent * 100) / 100;

            props.onChange(updatedGroup);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.group.amount]);

    return (
        <Card className='fade-in invoice-surcharge-group spaced-fields'>
            <TextInput
                inputRef={firstInputRef}
                type={'text'}
                placeholder={t('XRechnung.SurchargeGroupName.Text')}
                label={t('XRechnung.SurchargeGroupName.Text')}
                value={props.group.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let updatedGroup = {
                        ...props.group,
                    } as SurchargeGroup;
                    updatedGroup.name = e.target.value;
                    props.onChange(updatedGroup);
                }}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.group.name,
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.SurchargeGroupNameHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.SurchargeGroupNameHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.SurchargeGroupName.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />
            <div className='flex-row'>
                <NumberInput
                    placeholder={t('XRechnung.SurchargeGroupBaseAmount.Text')}
                    label={t('XRechnung.SurchargeGroupBaseAmount.Text')}
                    value={props.group.baseAmount}
                    required={true}
                    isValid={ValidationTools.isRequiredFieldValueValid(
                        props.group.baseAmount?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t(
                        'ValidationMessage.BaseAmountMissing.Text'
                    )}
                    maxPrecision={2}
                    onChange={(_e, rawValue) => {
                        let updatedGroup = {
                            ...props.group,
                        } as SurchargeGroup;
                        updatedGroup.baseAmount = rawValue || undefined;
                        props.onChange(updatedGroup);
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.SurchargeGroupBaseAmountHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.SurchargeGroupBaseAmountHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.SurchargeGroupBaseAmount.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />

                <NumberInput
                    min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    placeholder={t('XRechnung.SurchargeGroupPercent.Text')}
                    label={t('XRechnung.SurchargeGroupPercent.Text')}
                    value={props.group.percent}
                    required={true}
                    isValid={ValidationTools.isRequiredFieldValueValid(
                        props.group.percent?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t('ValidationMessage.PercentMissing.Text')}
                    maxPrecision={2}
                    onChange={(_e, rawValue) => {
                        let updatedGroup = {
                            ...props.group,
                        } as SurchargeGroup;
                        updatedGroup.percent = rawValue || undefined;
                        props.onChange(updatedGroup);
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.SurchargeGroupPercentHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.SurchargeGroupPercentHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.SurchargeGroupPercent.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />

                <NumberInput
                    min={-+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    max={+(process.env.REACT_APP_MAX_NUM ?? 999999999999999)}
                    placeholder={t('XRechnung.SurchargeGroupAmount.Text')}
                    label={t('XRechnung.SurchargeGroupAmount.Text')}
                    value={props.group.amount}
                    maxPrecision={2}
                    onChange={(_e, rawValue) => {
                        let updatedGroup = {
                            ...props.group,
                        } as SurchargeGroup;
                        updatedGroup.amount = rawValue || undefined;
                        props.onChange(updatedGroup);
                    }}
                    required={true}
                    isValid={ValidationTools.isRequiredFieldValueValid(
                        props.group.amount?.toString(),
                        props.isFormSubmitted
                    )}
                    validationError={t('ValidationMessage.Required.Text')}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.SurchargeGroupAmountHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.SurchargeGroupAmountHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.SurchargeGroupAmount.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
            </div>
            <Button
                icon='trash'
                danger={true}
                text={t('XRechnung.RemoveSurchargeGroup.Text')}
                className='action-button'
                onClick={() => {
                    props.onRemove();

                    Toast.success(
                        t('UserFeedback.SuccessfullyRemoved.Text', {
                            field: t('XRechnung.Surcharge.Text'),
                        })
                    );
                }}
            />
        </Card>
    );
}
