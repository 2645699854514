import './SignLanguagePage.scss';

import { AccessibleNavLink, VideoPlayer } from '@b4valuenet/ui-react';

import { Button } from '@b4valuenet/ui-react';
import { UILibraryTranslations } from '../../shared/UILibraryTranslations';
import { useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';

export interface ISignLanguagePage {
    title?: string;
    i18title?: string;
}

interface Video {
    title: string;
    link: string;
}

export default function SignLanguagePage(props: ISignLanguagePage) {
    const { t } = useTranslation();

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    const videos: Video[] = [
        {
            title: t('Common.BitV_Video_Content.Text'),
            link: 'assets/videos/inhalte-final.mp4',
        },
        {
            title: t('Common.BitV_Video_Navigation.Text'),
            link: 'assets/videos/navigation-final.mp4',
        },
        {
            title: t('Common.BitV_Video_SignLanguage.Text'),
            link: 'assets/videos/barrierefreiheit-final.mp4',
        },
    ];

    return (
        <div className='sub-page sign-language-page'>
            <h1>
                {props.title ??
                    (props.i18title !== undefined
                        ? t(props.i18title)
                        : '[no-title]')}
            </h1>
            <hr />
            {!isLoggedIn && (
                <>
                    <Button
                        linkTo={'/'}
                        isLink={true}
                        icon='chevron-left'
                        text={t('PasswordForgotten.CancelButton.Text')}
                        primary={true}
                    />
                </>
            )}
            <div className='videos'>
                {videos?.map((video, index) => {
                    return (
                        <div className='video' key={index}>
                            <h3>{t(video.title)}</h3>
                            <VideoPlayer
                                disablePlaybackIcon={true}
                                disableScrollingToChangeVolume={true}
                                alwaysShowControls={true}
                                source={video.link}
                                translation={UILibraryTranslations.getVideoPlayerTranslation(
                                    t
                                )}
                            />
                        </div>
                    );
                })}
            </div>

            {!isLoggedIn && (
                <>
                    <hr className='auth-separator' />
                    <AccessibleNavLink to={'/'}>
                        <Button
                            icon='chevron-left'
                            text={t('PasswordForgotten.CancelButton.Text')}
                            primary={true}
                        />
                    </AccessibleNavLink>
                </>
            )}
        </div>
    );
}
