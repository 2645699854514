import { Card } from '@b4valuenet/ui-react';
import { Country } from '../../../../../../../../../models/Country';
import { SenderData } from '../../../../../../../../../models/SenderData';
import { useTranslation } from 'react-i18next';

export interface ISellerPostalAddress {
    senderData: SenderData;
    countriesList: Country[];
}
export default function SellerPostalAddress(props: ISellerPostalAddress) {
    const { t } = useTranslation();

    let localizedSenderCountry =
        props.countriesList?.find(
            (country) => country.code === props.senderData.senderCountry
        )?.name ?? '';

    return (
        <Card title={t('XRechnung.SenderAddress.Text')}>
            <div className='pseudo-table align-left cw-50'>
                <table>
                    <tbody>
                        <tr>
                            <th>{t('XRechnung.Street.Text')}</th>
                            <td>{props.senderData.senderStreet}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.SenderSubLocality.Text')}</th>
                            <td>{props.senderData.senderSubLocality}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.ZipCity.Text')}</th>
                            <td>
                                {props.senderData.senderZip}{' '}
                                {props.senderData.senderCity}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.Locality.Text')}</th>
                            <td>{props.senderData.senderLocality}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.Country.Text')}</th>
                            <td>{localizedSenderCountry}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
