import './Dashboard.scss';

import { KeyboardEvent, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { headerMenuHidden, routeChanged } from '../../redux/slices/AppSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import AppFooter from '../../shared/components/global/AppFooter';
import { ConsolePlaceTag } from '@b4valuenet/ui-react';
import { ConsoleTools } from '@b4valuenet/ui-react';
import DashboardBreadcrumb from './components/DashboardBreadcrumb';
import DashbordHeader from './components/DashboardHeader';
import { useCookies } from 'react-cookie';
import { useWindowSize } from '@b4valuenet/ui-react';

let consolePlaceTag = new ConsolePlaceTag({
    text: 'Dashboard routing',
    background: '#9932CC',
    color: 'black',
});

export default function Dashboard() {
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    const isHeaderMenuExpanded = useAppSelector(
        (state) => state.app.isHeaderMenuExpanded
    );

    const navigate = useNavigate();
    const location = useLocation();
    const windowSize = useWindowSize();
    const dispatch = useAppDispatch();
    const [, setCookie] = useCookies(['dashboard-route']);

    // Subscribes to route change and do redux event
    useEffect(() => {
        setCookie(
            'dashboard-route',
            location.pathname + window.location.search,
            { path: '/' }
        );

        dispatch(routeChanged(location.pathname + window.location.search));

        ConsoleTools.log(
            'Route changed to: ' + location.pathname + window.location.search,
            consolePlaceTag
        );

        if (isHeaderMenuExpanded) dispatch(headerMenuHidden());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, dispatch, setCookie]);

    // Auto checks auth and redirects to login page when unauthorized
    useEffect(() => {
        if (!isLoggedIn) navigate('/auth');
    }, [isLoggedIn, navigate]);

    function handleDashboardClick() {
        if (isHeaderMenuExpanded) {
            dispatch(headerMenuHidden());
        }
    }

    function handleKeyDown(e: KeyboardEvent<HTMLElement>) {
        if (e.key === 'Escape') {
            if (isHeaderMenuExpanded) {
                dispatch(headerMenuHidden());
            }
        }
    }

    if (isLoggedIn) {
        return (
            <div className='page dashboard' onKeyDown={(e) => handleKeyDown(e)}>
                <DashbordHeader />

                {windowSize !== null && windowSize.width >= 600 && (
                    <DashboardBreadcrumb />
                )}

                <main
                    onClick={() => handleDashboardClick()}
                    aria-hidden={isHeaderMenuExpanded ? true : undefined}
                    className={
                        'dashboard-layout' +
                        (isHeaderMenuExpanded ? ' inactive' : '')
                    }
                >
                    <Outlet />
                </main>

                <AppFooter />
            </div>
        );
    }

    return null;
}
