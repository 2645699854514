import { TextInput, ValidationTools } from '@b4valuenet/ui-react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { XRechnungAccordionItem } from '../../../components/XRechnungAccordionItem';
import XRechnungSellerValidators from '../../../validators/SellerValidators';

export interface ISellerOtherPayeeDetailsData {
    payeeAddressName: string | undefined;
    payeeIdRef: string | undefined;
    payeeLegalEntityIdRef: string | undefined;
}

export interface ISellerOtherPayeeDetails extends ISellerOtherPayeeDetailsData {
    isFormSubmitted: boolean;
    onChange: (key: keyof ISellerOtherPayeeDetailsData, value: string) => void;
}

export function SellerOtherPayeeDetails(props: ISellerOtherPayeeDetails) {
    const { t } = useTranslation();

    const [payeeAddressNameRequired, setPayeeAddressNameRequired] =
        useState<boolean>(false);

    useEffect(() => {
        if (props.payeeIdRef || props.payeeLegalEntityIdRef) {
            setPayeeAddressNameRequired(true);
        } else {
            setPayeeAddressNameRequired(false);
        }
    }, [props.payeeAddressName, props.payeeIdRef, props.payeeLegalEntityIdRef]);

    let pageHaveErrors = !XRechnungSellerValidators.OtherPayeeDetailsValid({
        otherPayeeDetailsData: props,
        isFormSubmitted: props.isFormSubmitted,
    });

    return (
        <XRechnungAccordionItem
            icon='arrow-left-right'
            title={t('XRechnung.OtherPayeeDetails.Text')}
            expanded={pageHaveErrors}
            textWhenErrorsArePresent={t('ValidationMessage.ErrorsPresent.Text')}
            body={
                <div className='spaced-fields'>
                    <TextInput
                        type='text'
                        icon='tags'
                        placeholder={t('XRechnung.Name.Text')}
                        label={t('XRechnung.Name.Text')}
                        value={props.payeeAddressName}
                        required={payeeAddressNameRequired}
                        isValid={
                            !payeeAddressNameRequired ||
                            ValidationTools.isRequiredFieldValueValid(
                                props.payeeAddressName,
                                props.isFormSubmitted
                            )
                        }
                        validationError={t('ValidationMessage.Required.Text')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            props.onChange('payeeAddressName', e.target.value);
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.PayeeAddressNameNoHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.PayeeAddressNameNoHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.PayeeAddressName.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                    <TextInput
                        type='text'
                        icon='hash'
                        placeholder={t('XRechnung.PayeeIdRef.Text')}
                        label={t('XRechnung.PayeeIdRef.Text')}
                        value={props.payeeIdRef}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            props.onChange('payeeIdRef', e.target.value);
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.PayeeIdRefHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.PayeeIdRefHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t('XRechnung.PayeeIdRef.Help')}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                    <TextInput
                        type='text'
                        icon='hash'
                        placeholder={t('XRechnung.PayeeLegalEntityIdRef.Text')}
                        label={t('XRechnung.PayeeLegalEntityIdRef.Text')}
                        value={props.payeeLegalEntityIdRef}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            props.onChange(
                                'payeeLegalEntityIdRef',
                                e.target.value
                            );
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.PayeeLegalEntityIdRefHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.PayeeLegalEntityIdRefHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.PayeeLegalEntityIdRef.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                </div>
            }
        />
    );
}
