export interface ITestPage {
    title?: string;
    i18title?: string;
    component: JSX.Element;
}

export const TestPage = (props: ITestPage) => {
    let title = props.title ?? (props.i18title ? props.i18title : '');

    return <>Test page {title}</>;
};
