import {
    Card,
    Mask,
    MaskedInput,
    TextInput,
    ValidationTools,
} from '@b4valuenet/ui-react';
import React, { createRef, useEffect, useState } from 'react';

import { Debit } from '../../../../../../../models/Debit';
import { IBANValidationPattern } from '../../../../../../../models/IBANValidationPattern';
import { useTranslation } from 'react-i18next';

export interface IInvoiceSepaDirectDebit {
    debit: Debit | null;
    isFormSubmitted: boolean;
    IBANValidationPatterns: IBANValidationPattern[];
    onChange: (updatedDebit: Debit | null) => void;
}

export default function InvoiceSepaDirectDebit(props: IInvoiceSepaDirectDebit) {
    const { t } = useTranslation();
    const IBANInputRef = createRef<HTMLInputElement>();

    const defaultIBANMask =
        'LL99 9999999999999999999999999999999999999999999999999';

    const [IBAN, setIBAN] = useState<string | null>(
        props.debit?.buyerBankAccountIBAN ?? null
    );
    const [isIBANFull, setIsIBANFull] = useState<boolean>(true);

    let isCountryCodeValid = false;
    let IBANInputCurrentPattern: IBANValidationPattern | null = null;
    let IBANInputMask: string | null = null;

    let countryCode =
        IBAN?.slice(0, 2)?.replaceAll('_', '').toUpperCase() ?? '';

    if (countryCode.length >= 2) {
        IBANInputCurrentPattern =
            props.IBANValidationPatterns.find(
                (pattern) => pattern.countryCode === countryCode
            ) ?? null;

        IBANInputMask = IBANInputCurrentPattern?.maskPattern ?? null;
        isCountryCodeValid = IBANInputMask !== null;
    }

    let sepaChecked = IBANInputCurrentPattern?.partOfSepaSpace;

    let isIBANValid =
        (!props.isFormSubmitted &&
            !isIBANFull &&
            (sepaChecked || countryCode.length < 2 || IBAN?.length === 0)) ||
        (isIBANFull && sepaChecked && ValidationTools.isIBANValid(IBAN ?? ''));
    let IBANValidationError = '';

    if (IBANInputCurrentPattern) {
        if (sepaChecked) {
            if (isIBANFull) {
                if (!isIBANValid) {
                    IBANValidationError = t(
                        'ValidationMessage.IbanInvalid.Text'
                    );
                }
            } else {
                if (props.isFormSubmitted) {
                    IBANValidationError = t(
                        'ValidationMessage.IbanEntryNotCompleted.Text'
                    );
                }
            }
        } else {
            IBANValidationError = t(
                'ValidationMessage.IbanPatternUnknown.Text'
            );
        }
    } else {
        if (IBAN && IBAN.replace(/(\s|_)/g, '').length > 0) {
            IBANValidationError = t(
                'ValidationMessage.IbanCountryInvalid.Text'
            );
        } else {
            if (props.isFormSubmitted) {
                IBANValidationError = t('ValidationMessage.Required.Text');
            }
        }
    }

    useEffect(() => {
        props.onChange({
            ...props.debit,
            sepaCreditorReference: props.debit?.sepaCreditorReference
                ? props.debit.sepaCreditorReference
                : '',
            sepaMandateReference: props.debit?.sepaMandateReference
                ? props.debit.sepaMandateReference
                : '',
            buyerBankAccountIBAN: IBAN ?? '',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIBANFull, IBAN]);

    useEffect(() => {
        let IBANInputElement = IBANInputRef.current;
        setTimeout(() => {
            let IBANCleanValue = IBAN?.replaceAll(/(_|\s)/g, '') ?? '';

            if (IBANInputElement) {
                if (isCountryCodeValid && IBANCleanValue.length <= 2) {
                    IBANInputElement.selectionStart =
                        IBANInputElement.selectionEnd = 2;
                } else {
                    let mask = new Mask(IBANInputMask ?? defaultIBANMask);

                    IBANInputElement.selectionStart =
                        IBANInputElement.selectionEnd =
                            mask.replaceInputValueWithMask(
                                IBAN ?? '',
                                IBAN ?? '',
                                IBAN?.length ?? 0
                            ).cursorPosition;
                }
            }
        }, 2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCountryCodeValid]);

    const onChangeDirectDebit = (
        e: React.ChangeEvent<HTMLInputElement>,
        key: string
    ) => {
        props.onChange({
            ...props.debit,
            sepaCreditorReference: props.debit?.sepaCreditorReference
                ? props.debit.sepaCreditorReference
                : '',
            sepaMandateReference: props.debit?.sepaMandateReference
                ? props.debit.sepaMandateReference
                : '',
            buyerBankAccountIBAN: props.debit?.buyerBankAccountIBAN
                ? props.debit.buyerBankAccountIBAN
                : '',
            [key]: e.target.value,
        });
    };

    return (
        <Card className='fade-in spaced-fields'>
            <TextInput
                type='text'
                placeholder={t('XRechnung.PayeeIDRefGroupValue.Text')}
                label={t('XRechnung.PayeeIDRefGroupValue.Text')}
                value={props.debit?.sepaMandateReference?.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeDirectDebit(e, 'sepaMandateReference');
                }}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.debit?.sepaMandateReference,
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.PayeeIDRefGroupValueHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.PayeeIDRefGroupValueHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.PayeeIDRefGroupValue.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />
            <TextInput
                type='text'
                placeholder={t('XRechnung.IDRefGroupValue.Text')}
                label={t('XRechnung.IDRefGroupValue.Text')}
                value={props.debit?.sepaCreditorReference?.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeDirectDebit(e, 'sepaCreditorReference');
                }}
                required={true}
                isValid={ValidationTools.isRequiredFieldValueValid(
                    props.debit?.sepaCreditorReference,
                    props.isFormSubmitted
                )}
                validationError={t('ValidationMessage.Required.Text')}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.IDRefGroupValueHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.IDRefGroupValueHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.IDRefGroupValue.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <MaskedInput
                required={true}
                inputRef={IBANInputRef}
                icon={'bank'}
                mask={IBANInputMask ?? defaultIBANMask}
                placeholder={t('XRechnung.PayeeBankAccountIBAN.Text')}
                label={t('XRechnung.PayeeBankAccountIBAN.Text')}
                isValid={isIBANValid}
                validationError={IBANValidationError}
                value={IBAN ?? ''}
                onSimpleChange={(newValue: string, isFull: boolean) => {
                    setIBAN(newValue);
                    setIsIBANFull(isFull);
                }}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.BuyerBankAccountIBANHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.BuyerBankAccountIBANHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.BuyerBankAccountIBAN.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />
        </Card>
    );
}
