import { Button, Toast } from '@b4valuenet/ui-react';

import MessagesService from '../../../../../services/MessagesService';
import { ModalWindow } from '@b4valuenet/ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface IDeleteModal {
    active: boolean;
    onClose: CallableFunction;
    selectedIds: (number | string)[];
}

export default function DeleteModal(props: IDeleteModal) {
    const { t } = useTranslation();

    function onClose(actionConfirmed: boolean) {
        props.onClose(actionConfirmed);
    }

    function handleCancelButtonClick() {
        onClose(false);
    }

    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function handleFormSubmit() {
        setIsLoading(true);

        if (props.selectedIds.length > 0) {
            try {
                let response = await MessagesService.deleteMessages(
                    props.selectedIds
                );
                if (response !== null) {
                    Toast.success(response.message, 350);
                }
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        }

        setIsLoading(false);

        onClose(true);
    }

    return (
        <ModalWindow
            isLoading={isLoading}
            aria-labelledby='messages-modal-delete-title'
            active={props.active}
            onSubmit={() => handleFormSubmit()}
            onEscapeKeyPressed={() => {
                if (!isLoading) onClose(false);
            }}
        >
            <h2 id='messages-modal-delete-title'>
                {t('Messages.DeleteTitle.Text')}
            </h2>
            <div className='modal-main'>
                <p>
                    {t(
                        props.selectedIds.length > 1
                            ? 'Messages.DeleteQuestionMultiple.Text'
                            : 'Messages.DeleteQuestion.Text',
                        {
                            messageCount: props.selectedIds.length,
                        }
                    )}
                </p>
            </div>
            <div className='footer'>
                <Button
                    onClick={() => handleCancelButtonClick()}
                    text={t('Common.No.Text')}
                />
                <Button
                    type='submit'
                    primary={true}
                    text={t('Common.Yes.Text')}
                />
            </div>
        </ModalWindow>
    );
}
