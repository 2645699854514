import { Button, Toast } from '@b4valuenet/ui-react';

import BigAttachmentsService from '../../../../../services/BigAttachmentsService';
import { ModalWindow } from '@b4valuenet/ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface IDeleteModal {
    active: boolean;
    onClose: CallableFunction;
    id: string | null;
}

export default function DeleteModal(props: IDeleteModal) {
    const { t } = useTranslation();

    function onClose(actionConfirmed: boolean) {
        props.onClose(actionConfirmed);
    }

    function handleCancelButtonClick() {
        onClose(false);
    }

    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function handleFormSubmit() {
        if (!props.id) return;

        setIsLoading(true);

        try {
            let response = await BigAttachmentsService.delete(props.id);
            if (response !== null) {
                Toast.success(response.message, 350);
            }
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        setIsLoading(false);

        onClose(true);
    }

    return (
        <ModalWindow
            isLoading={isLoading}
            aria-labelledby='big-attachments-modal-delete-title'
            active={props.active}
            onSubmit={() => handleFormSubmit()}
            onEscapeKeyPressed={() => {
                if (!isLoading) onClose(false);
            }}
        >
            <h2 id='big-attachments-modal-delete-title'>
                {t('Messages.BigAttachmentsDeleteQuestion.Text')}
            </h2>
            <div className='modal-main'>
                {t('Messages.BigAttachmentsDeleteQuestion.Tooltip')}
            </div>
            <div className='footer'>
                <Button
                    onClick={() => handleCancelButtonClick()}
                    text={t('Common.No.Text')}
                />
                <Button
                    type='submit'
                    primary={true}
                    text={t('Common.Yes.Text')}
                />
            </div>
        </ModalWindow>
    );
}
