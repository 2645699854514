import { Transfer } from '../../../../../../../../../models/Transfer';
import { useTranslation } from 'react-i18next';

export interface IPaymentTransferSummary {
    transfer: Transfer;
    isSepaTransfer: boolean;
    index: number;
}

export default function PaymentTransferSummary(props: IPaymentTransferSummary) {
    const { t } = useTranslation();

    return (
        <>
            <h3>
                {props.index +
                    1 +
                    '. ' +
                    t(
                        props.isSepaTransfer
                            ? 'XRechnung.PaymentMeansSepaTransfer.Text'
                            : 'XRechnung.PaymentMeansTransfer.Text'
                    )}
            </h3>

            <div className='pseudo-table align-left cw-50'>
                <table>
                    <tbody>
                        <tr>
                            <th>
                                {t('XRechnung.PayeeBankAccountBankName.Text')}
                            </th>
                            <td>{props.transfer.payeeBankAccountBankName}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.PayeeBankAccountIBAN.Text')}</th>
                            <td>
                                {props.transfer.payeeBankAccountIBAN?.replaceAll(
                                    '_',
                                    ''
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.PayeeBankAccountBIC.Text')}</th>
                            <td>
                                {props.transfer.payeeBankAccountBIC?.replaceAll(
                                    '_',
                                    ''
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}
