import { Card } from '@b4valuenet/ui-react';
import { Country } from '../../../../../../../../../models/Country';
import { ReceiverData } from '../../../../../../../../../models/ReceiverData';
import { useTranslation } from 'react-i18next';

export interface IBuyerAddressInfo {
    receiverData: ReceiverData;
    countriesList: Country[];
}
export default function BuyerAddressInfo(props: IBuyerAddressInfo) {
    const { t } = useTranslation();

    let localizedBuyerCountry =
        props.countriesList.find(
            (country) =>
                country.code === props.receiverData.receiverAddressCountry
        )?.name ?? '';

    return (
        <Card title={t('XRechnung.ReceiverAddress.Text')}>
            <div className='pseudo-table align-left cw-50'>
                <table>
                    <tbody>
                        <tr>
                            <th>{t('XRechnung.Street.Text')}</th>
                            <td>{props.receiverData.receiverAddressStreet}</td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.PoBox.Text')}</th>
                            <td>{props.receiverData.receiverAddressPoBox}</td>
                        </tr>
                        <tr>
                            <th>
                                {t('XRechnung.DeliveryAddressSubLocality.Text')}
                            </th>
                            <td>
                                {props.receiverData.receiverAddressSubLocality}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {t('XRechnung.DeliveryAddressZipCity.Text')}
                            </th>
                            <td>
                                {props.receiverData.receiverAddressZip}{' '}
                                {props.receiverData.receiverAddressCity}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {t('XRechnung.DeliveryAddressLocality.Text')}
                            </th>
                            <td>
                                {props.receiverData.receiverAddressLocality}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('XRechnung.Country.Text')}</th>
                            <td>{localizedBuyerCountry}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
