import './ValidationResult.scss';

import { BootstrapIcon, TabbedView, TableTools } from '@b4valuenet/ui-react';
import {
    SchematronReportItem,
    SchematronReportStep,
} from '../../../../models/SchematronReport';
import {
    ValidationResultMessageStep,
    ValidationResultMessageStepSeverity,
} from '../../../../models/ValidationResultMessageStep';

import { InvoiceValidationResult } from '../../../../models/InvoiceValidationResult';
import { useTranslation } from 'react-i18next';

export interface IValidationResult {
    result: InvoiceValidationResult;
}

export function ValidationResult(props: IValidationResult) {
    const { t } = useTranslation();

    const items = [
        {
            title: t('Messages.ProcessingProtocol.Text'),
            content: (
                <table>
                    <thead>
                        <tr>
                            <th>{t('Messages.ProtocolStep.Text')}</th>
                            <th>{t('Messages.StepSeverity.Text')}</th>
                            <th>{t('Messages.StepCreated.Text')}</th>
                            <th>{t('Messages.StepDetails.Text')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(props.result?.messageSteps ?? []).map(
                            (
                                step: ValidationResultMessageStep,
                                index: number
                            ) => (
                                <tr key={index}>
                                    <td>
                                        {t(
                                            'Messages.Step' +
                                                step.state +
                                                '.Text'
                                        )}
                                    </td>
                                    <td className='icon'>
                                        {step.severity ===
                                            ValidationResultMessageStepSeverity.None && (
                                            <>
                                                <BootstrapIcon
                                                    icon='check-circle-fill'
                                                    className='success'
                                                />
                                                <span className='sr-only'>
                                                    {t('Common.Success.Text')}
                                                </span>
                                            </>
                                        )}
                                        {step.severity ===
                                            ValidationResultMessageStepSeverity.Warning && (
                                            <>
                                                <BootstrapIcon
                                                    icon='exclamation-triangle-fill'
                                                    className='warning'
                                                />
                                                <span className='sr-only'>
                                                    {t('Common.Warning.Text')}
                                                </span>
                                            </>
                                        )}
                                        {step.severity ===
                                            ValidationResultMessageStepSeverity.Error && (
                                            <>
                                                <BootstrapIcon
                                                    icon='exclamation-circle-fill'
                                                    className='error'
                                                />
                                                <span className='sr-only'>
                                                    {t('Common.Error.Text')}
                                                </span>
                                            </>
                                        )}
                                    </td>
                                    <td className='align-center'>
                                        {TableTools.toLocaleDateString(
                                            step.endTime
                                        )}
                                    </td>
                                    <td>{step.details}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            ),
        },
    ];

    if (props.result.schematronReport) {
        items.push({
            title: t('Messages.SchematronProtocolDialog.Text'),
            content: (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('Messages.ValidationStep.Text')}</th>
                                <th>{t('Common.Errors.Text')}</th>
                                <th>{t('Common.Warnings.Text')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.result.schematronReport.steps.map(
                                (step: SchematronReportStep, index: number) => (
                                    <tr key={index}>
                                        <td>{step.name}</td>
                                        <td>{step.errorCount}</td>
                                        <td>{step.warningCount}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                    <br />
                    <table>
                        <thead>
                            <tr>
                                <th>{t('Common.Position.Text')}</th>
                                <th>{t('Common.Code.Text')}</th>
                                <th>{t('Common.Severity.Text')}</th>
                                <th>{t('Common.Text.Text')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.result.schematronReport.items.map(
                                (step: SchematronReportItem, index: number) => (
                                    <tr key={index}>
                                        <td>{step.position}</td>
                                        <td>{step.code}</td>
                                        <td lang='en'>{step.severity}</td>
                                        <td>{step.text}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </>
            ),
        });
    }

    return (
        <div className='invoice-validation-result'>
            <TabbedView headlineLevel='h3' items={items} />
        </div>
    );
}
