import { GenericActionResponse } from '../models/GenericActionResponse';
import { RequestTools } from '@b4valuenet/ui-react';

export default class HelloService {
    static ApiRoot = 'api/hello/';

    static sendSupportMessage = async (
        email: string,
        subject: string,
        phone: string | null,
        message: string,
        lastVisitedPage: string,
        lang: string
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.post(
            this.ApiRoot + 'send-support-message/?lang=' + lang,
            {
                email: email,
                subject: subject,
                phone: phone,
                message: message,
                lastVisitedPage: lastVisitedPage,
            }
        );

        let json = await response.data;
        return (json as GenericActionResponse) || null;
    };
}
