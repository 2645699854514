import axios, { AxiosRequestConfig } from 'axios';

import { ConsolePlaceTag } from '@b4valuenet/ui-react';
import { ConsoleTools } from '@b4valuenet/ui-react';
import { RequestTools } from '@b4valuenet/ui-react';
import { Store } from '@reduxjs/toolkit';
import { isUnauthenticated } from '../redux/slices/AuthSlice';

let consolePlaceTagInfo = new ConsolePlaceTag({
    text: 'NetworkService',
    color: '#778899',
});

let consolePlaceTagSuccess = new ConsolePlaceTag({
    text: 'NetworkService',
    background: '#34D058',
    color: 'black',
});

let consolePlaceTagWarning = new ConsolePlaceTag({
    text: 'NetworkService',
    background: 'yellow',
    color: '#2F4F4F',
});

let consolePlaceTagError = new ConsolePlaceTag({
    text: 'NetworkService',
    background: 'red',
    color: 'black',
});

export default class NetworkService {
    static getAxiosFullUrl(config: AxiosRequestConfig<any>): string {
        return (
            window.location.origin +
            '/' +
            ((config.baseURL ?? '') + ' ' + (config.url ?? '')).trim()
        );
    }

    static setupInterceptors = (store: Store) => {
        axios.interceptors.request.use(
            function (config) {
                ConsoleTools.log(
                    'Created ' +
                        config.method?.toLocaleUpperCase() +
                        ' request to ' +
                        NetworkService.getAxiosFullUrl(config),
                    consolePlaceTagInfo
                );
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            function (response) {
                ConsoleTools.log(
                    response.config.method?.toLocaleUpperCase() +
                        ' request to ' +
                        NetworkService.getAxiosFullUrl(response.config) +
                        ' finished',
                    consolePlaceTagSuccess
                );
                return response;
            },
            function (error) {
                let errorCode = RequestTools.getApiErrorCode(error);

                ConsoleTools.error(
                    error.config.method?.toLocaleUpperCase() +
                        ' request to ' +
                        NetworkService.getAxiosFullUrl(error.config) +
                        ' failed with code ' +
                        errorCode,
                    consolePlaceTagError
                );

                if (errorCode === 401) {
                    let storeState = store.getState();

                    if (storeState && storeState.auth.isLoggedIn) {
                        ConsoleTools.warn(
                            'SESSION EXPIRED!',
                            consolePlaceTagWarning
                        );

                        store.dispatch(isUnauthenticated(true));
                    }
                }

                return Promise.reject(error);
            }
        );
    };
}
