import { Card } from '@b4valuenet/ui-react';
import { SenderData } from '../../../../../../../../../models/SenderData';
import { useTranslation } from 'react-i18next';

export interface ISellerOtherPayeeDetails {
    senderData: SenderData;
}
export default function SellerOtherPayeeDetails(
    props: ISellerOtherPayeeDetails
) {
    const { t } = useTranslation();

    return (
        <Card title={t('XRechnung.OtherPayeeDetails.Text')}>
            <div className='pseudo-table align-left cw-50'>
                <table>
                    <tbody>
                        {props.senderData.payeeAddressName && (
                            <tr>
                                <th>{t('XRechnung.Name.Text')}</th>
                                <td>{props?.senderData.payeeAddressName}</td>
                            </tr>
                        )}
                        {props.senderData.payeeAddressName && (
                            <tr>
                                <th>{t('XRechnung.PayeeIdRef.Text')}</th>
                                <td>{props?.senderData.payeeIdRef}</td>
                            </tr>
                        )}
                        {props.senderData.payeeAddressName && (
                            <tr>
                                <th>
                                    {t('XRechnung.PayeeLegalEntityIdRef.Text')}
                                </th>
                                <td>
                                    {props?.senderData.payeeLegalEntityIdRef}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
