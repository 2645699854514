import { AmountGroupData } from '../../../../../../models/AmountGroupData';
import { IXRechnungSubPage } from '../../XRechnungPage';
import AttachmentSummary from './components/AttachmentSummary/AttachmentSummary';
import BuyerSummary from './components/BuyerSummary/BuyerSummary';
import ExternalReferencesSummary from './components/ExternalReferencesSummary/ExternalReferencesSummary';
import InvoiceDataSummary from './components/InvoiceDataSummary/InvoiceDataSummary';
import PaymentAmountGroupsSummary from './components/PaymentAmountGroupsSummary/PaymentAmountGroupsSummary';
import PaymentInfoSummary from './components/PaymentInfoSummary/PaymentInfoSummary';
import PaymentTermsSummary from './components/PaymentTermsSummary/PaymentTermsSummary';
import PaymentTotalsSummary from './components/PaymentTotalsSummary/PaymentTotalsSummary';
import PositionLinesSummary from './components/PositionLinesSummary/PositionLinesSummary';
import SellerSummary from './components/SellerSummary/SellerSummary';

export function XRechnungSummary(props: IXRechnungSubPage) {
    let allAmountsNotShown = props.data?.documentTotalsData.amountGroups.every(
        (group: AmountGroupData) => {
            return group.vatRate === null;
        }
    );

    return (
        <div className='xrechnung-summary spaced-fields'>
            {props.data && (
                <>
                    <div className='card-grid spaced-fields'>
                        <InvoiceDataSummary
                            invoiceData={props.data.invoiceData}
                            messageTypes={props.messageTypes}
                        />

                        <PaymentInfoSummary
                            paymentInfo={props.data.paymentInfo}
                        />

                        <SellerSummary
                            countriesList={props.countriesList ?? []}
                            senderData={props.data.senderData}
                        />

                        {props.data.invoiceData.discounts.length > 0 && (
                            <PaymentTermsSummary
                                invoiceData={props.data.invoiceData}
                            />
                        )}

                        <BuyerSummary
                            countriesList={props.countriesList ?? []}
                            receiverData={props.data.receiverData}
                        />
                    </div>
                    {props.data.attachments.length > 0 && (
                        <AttachmentSummary
                            attachments={props.data.attachments}
                        />
                    )}

                    {props.data.references.length > 0 && (
                        <ExternalReferencesSummary
                            references={props.data.references}
                        />
                    )}
                    {props.data.positions.length > 0 && (
                        <PositionLinesSummary
                            lines={props.data.positions}
                            unitOptions={props.unitOptions}
                            taxCategories={props.taxCategories}
                        />
                    )}
                    {!allAmountsNotShown && props.data.positions.length > 0 && (
                        <PaymentAmountGroupsSummary
                            amountGroups={
                                props.data.documentTotalsData.amountGroups
                            }
                            taxCategories={props.taxCategories}
                        />
                    )}

                    <PaymentTotalsSummary
                        documentTotals={props.data.documentTotalsData}
                    />
                </>
            )}
        </div>
    );
}
