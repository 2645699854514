import {
    AnimationTools,
    Button,
    Dialog,
    DragAndDropArea,
    DragAndDropAreaTranslation,
    LoadingOverlay,
    RequiredFieldsNotice,
    StringTools,
    Toast,
} from '@b4valuenet/ui-react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Attachment } from '../../../../../../models/Attachment';
import { AttachmentRestrictions } from '../../../../../../models/AttachmentRestrictions';
import { useAppSelector } from '../../../../../../redux/hooks';
import UploadService from '../../../../../../services/UploadService';
import XRechnungService from '../../../../../../services/XRechnungService';
import { UILibraryTranslations } from '../../../../../../shared/UILibraryTranslations';
import { IXRechnungSubPage } from '../../XRechnungPage';
import InvoiceAttachment from './components/InvoiceAttachment';

export function XRechnungAttachments(props: IXRechnungSubPage) {
    const { t } = useTranslation();

    const appSettings = useAppSelector((state) => state.app.settings);

    let params = useParams();
    let id = params.id !== undefined ? +params.id : null;

    let dndAreaTranslation: DragAndDropAreaTranslation =
        UILibraryTranslations.getDragAndDropTranslation(t);

    let messageId: number = props.data?.messageId ?? id ?? 0;

    const [restrictions, setRestrictions] =
        useState<AttachmentRestrictions | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [errorDialogActive, setErrorDialogActive] = useState<boolean>(false);
    const [errorDialogBodyText, setErrorDialogBodyText] = useState<string>('');

    useEffect(() => {
        async function fetchRestrictions() {
            try {
                let newRestrictions =
                    await XRechnungService.getAttachmentRestrictions(messageId);

                setRestrictions(newRestrictions);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        }

        fetchRestrictions();
    }, [messageId]);

    let currentAttachmentsCount = props.data?.attachments.length ?? 0;
    let maxAttachmentsCount = restrictions?.maximumAttachmentCount ?? 30;

    const handleOnFileSelected = async (files: File[]) => {
        setIsLoading(true);

        let uploadedAttachments: Attachment[] = [];

        for (const file of files) {
            try {
                if (!restrictions || !appSettings) {
                    Toast.error(t('Common.UploadTooLarge.Text'));
                    setIsLoading(false);
                    return;
                }

                if (file.size > appSettings?.maximumUploadSize) {
                    if (appSettings?.bigAttachmentsEnabled) {
                        setErrorDialogBodyText(
                            t('XRechnung.BigAttachmentsUploadErrorNotice.Text')
                        );
                        AnimationTools.activateModal(setErrorDialogActive);
                    } else {
                        Toast.error(t('Common.UploadTooLarge.Text'));
                    }
                    setIsLoading(false);
                    return;
                }

                if (currentAttachmentsCount >= maxAttachmentsCount) {
                    Toast.error(
                        t('UserFeedback.MaximumAllowedAttachmentsReached.Text')
                    );
                    setIsLoading(false);
                    return;
                }

                let attachment = await UploadService.uploadAttachment(
                    file,
                    messageId,
                    new AbortController(),
                    () => {}
                );

                if (attachment) uploadedAttachments.push(attachment);
            } catch (error) {
                Toast.showErrorWhenAuthorized(error);
            }
        }

        if (props.data && uploadedAttachments.length > 0) {
            if (uploadedAttachments.length === 1) {
                Toast.success(t('Common.FileStatusUploaded.Text'));
            } else {
                Toast.success(t('Common.FilesBatchStatusUploaded.Text'));
            }

            props.updateData((prevData) => ({
                ...prevData,
                attachments: [
                    ...(prevData.attachments ?? []),
                    ...uploadedAttachments,
                ],
            }));
        }

        setIsLoading(false);
    };

    return (
        <div className='spaced-fields xrechnung-attachments'>
            <RequiredFieldsNotice
                description={t(
                    'ValidationMessage.IndicatesARequiredField.Text'
                )}
            />
            {currentAttachmentsCount < maxAttachmentsCount && (
                <>
                    <DragAndDropArea
                        translation={dndAreaTranslation}
                        allowedExtensions={
                            restrictions?.allowedExtensions ?? []
                        }
                        onFilesSelected={(files: File[]) =>
                            handleOnFileSelected(files)
                        }
                    />

                    <div className='file-requirements'>
                        <p>
                            <strong>
                                {t('XRechnung.MaxFileSizePerFile.Text')}:
                            </strong>{' '}
                            {StringTools.humanSize(
                                appSettings?.maximumUploadSize ?? 0
                            )}
                        </p>
                        <p>
                            <strong>
                                {t('XRechnung.AllowedExtensions.Text')}:
                            </strong>{' '}
                            {restrictions?.allowedExtensions.join(', ')}
                        </p>
                    </div>

                    <p className='help-text'>
                        {t('XRechnung.AttachmentsHelpText.Text')}
                    </p>
                </>
            )}

            <div className='spaced-fields'>
                {props.data?.attachments.map(
                    (attachment: Attachment, index: number) => {
                        return (
                            <InvoiceAttachment
                                key={index}
                                isFormSubmitted={props.isFormSubmitted}
                                attachment={attachment}
                                onChange={(updatedAttachment: Attachment) => {
                                    if (props.data) {
                                        let newAttachments = [
                                            ...(props.data.attachments ?? []),
                                        ];

                                        newAttachments[index] =
                                            updatedAttachment;

                                        props.updateData((prevData) => ({
                                            ...prevData,
                                            attachments: newAttachments,
                                        }));
                                    }
                                }}
                                onRemove={async () => {
                                    if (props.data) {
                                        setIsLoading(true);

                                        try {
                                            await XRechnungService.deleteAttachment(
                                                attachment.id
                                            );

                                            props.updateData((prevData) => {
                                                let newAttachments = [
                                                    ...prevData.attachments,
                                                ];

                                                newAttachments.splice(index, 1);

                                                return {
                                                    ...prevData,
                                                    attachments: newAttachments,
                                                };
                                            });

                                            Toast.success(
                                                t(
                                                    'UserFeedback.SuccessfullyRemoved.Text',
                                                    {
                                                        field: t(
                                                            'Common.Attachment.Text'
                                                        ),
                                                    }
                                                )
                                            );
                                        } catch (error) {
                                            Toast.showErrorWhenAuthorized(
                                                error
                                            );
                                        }

                                        setIsLoading(false);
                                    }
                                }}
                            />
                        );
                    }
                )}
            </div>

            <LoadingOverlay
                active={isLoading}
                loadingMessage={t('Common.LoadingMessage.Text')}
                isGlobal={true}
            />

            <Dialog
                title={t('Common.Error.Text')}
                closeButtonTitle={t('Common.Close.Text')}
                onClose={() => {
                    AnimationTools.deactivateModal(setErrorDialogActive);
                }}
                active={errorDialogActive}
            >
                <div className='modal-main'>
                    <p>{errorDialogBodyText}</p>
                </div>
                <div className='footer'>
                    <Button
                        onClick={() => {
                            AnimationTools.deactivateModal(
                                setErrorDialogActive
                            );
                        }}
                        text={t('Common.Close.Text')}
                    />
                </div>
            </Dialog>
        </div>
    );
}
