import { useTranslation } from 'react-i18next';
import { AmountGroupData } from '../../../../../../../../../models/AmountGroupData';
import { KeyValuePair } from '../../../../../../../../../models/KeyValuePair';

interface IPaymentAmountGroupSummary {
    amountGroup: AmountGroupData;
    taxCategories: KeyValuePair[];
}

export default function PaymentAmountGroupSummary(
    props: IPaymentAmountGroupSummary
) {
    const { t } = useTranslation();

    const getTranslationByVatRate = (vatRate: number | undefined): string => {
        if (vatRate === -1) {
            return t('Common.TaxCategoryExemptFromTax.Text');
        } else if (vatRate === -2) {
            return t('Common.TaxCategoryVatReverseCharge.Text');
        } else if (vatRate === -3) {
            return t('Common.TaxCategoryVatExemptEea.Text');
        } else if (vatRate === -4) {
            return t('Common.TaxCategoryFreeExportItem.Text');
        } else if (vatRate === -5) {
            return t('Common.TaxCategoryServicesOutsideScope.Text');
        } else {
            return vatRate + '%';
        }
    };

    let vatTranslation = getTranslationByVatRate(props.amountGroup.vatRate);

    return (
        <tr>
            <td style={{ width: '28%' }}>{props.amountGroup.netAmount}</td>
            <td>
                <strong>{vatTranslation}</strong>
            </td>
            <td style={{ width: '30%' }}>{props.amountGroup.vatAmount}</td>
        </tr>
    );
}
