import { createSlice } from '@reduxjs/toolkit';

const storageLang = localStorage.getItem('lang');

interface UserSettingsState {
    lang: string | null;
}

const initialState: UserSettingsState = { lang: storageLang };

const UserSlice = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        changedLanguage(state, action) {
            return {
                ...state,
                lang: action.payload,
            };
        },
    },
});

export const { changedLanguage } = UserSlice.actions;
export default UserSlice.reducer;
