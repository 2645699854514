import { Debit } from './Debit';
import { Transfer } from './Transfer';

// BT-81 Enum
export enum PaymentMeansCode {
    CreditTransfer = 30, // Payment by credit movement of funds from one account to another.
    DebitTransfer = 31, // Payment by debit movement of funds from one account to another. - No fruther specs found in XRechnung
    SepaCreditTransfer = 58, // Credit transfer inside the Single Euro Payment Area (SEPA) system.
    SepaDirectDebit = 59, // Direct debit inside the Single Euro Payment Area (SEPA) system.
}

export interface PaymentInfo {
    transfers: Transfer[];
    debit: Debit | null;
    paymentMeans: PaymentMeansCode | 0; //BT-82
    paymentReference: string; //BT-83
}
