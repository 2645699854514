import './Feedback.scss';

import {
    AccessibleNavLink,
    AsyncForm,
    BootstrapIcon,
    Button,
    Card,
    LoadingOverlay,
    RequestTools,
    RequiredFieldsNotice,
    TextArea,
    TextInput,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

import HelloService from '../../../../services/HelloService';
import LocaleService from '../../../../services/LocaleService';
import { RootState } from '../../../../redux/store';
import { supportContactPageOpened } from '../../../../redux/slices/AppSlice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export interface IFeedback {
    subject?: string;
}

export default function Feedback(props: IFeedback) {
    const { t } = useTranslation();

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    const supportLastVisitedPage = useAppSelector(
        (state) => state.app.supportLastVisitedPage
    );

    const dispatch = useAppDispatch();

    const [lastVisitedPage] = useState(supportLastVisitedPage);

    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formSended, setFormSended] = useState<boolean>(false);
    const [formErrorText, setFormErrorText] = useState<string | null>(null);
    const [formSuccessText, setFormSuccessText] = useState<string | null>();

    const reduxLang = useSelector(
        (state: RootState) => state.userSettings.lang
    );
    const authenticatedUserLang = useSelector(
        (state: RootState) => state.auth?.user?.language
    );

    let browserLang = LocaleService.getBrowserLanguage();
    let browserLangCountryCode = browserLang && browserLang.slice(0, 2);

    let lang = authenticatedUserLang || reduxLang || browserLangCountryCode;

    useEffect(() => {
        dispatch(supportContactPageOpened());
    }, [dispatch]);

    async function handleSubmit() {
        setFormSended(true);

        if (props.subject && isFormValid()) {
            setIsLoading(true);

            try {
                let response = await HelloService.sendSupportMessage(
                    email,
                    props.subject,
                    phone.length > 0 ? phone : null,
                    message,
                    lastVisitedPage ?? '/',
                    lang ?? 'en'
                );
                if (response !== null) {
                    setFormSuccessText(response.message);
                }
            } catch (error) {
                let message = RequestTools.getApiErrorMessage(error);
                setFormErrorText(message);
            }

            setIsLoading(false);
        }

        return;
    }

    function isFormValid(): boolean {
        return ValidationTools.isEmailValid(email) && message.length > 0;
    }

    return (
        <div className='sub-page feedback'>
            <Card>
                {(!formSuccessText && (
                    <>
                        <AsyncForm
                            onSubmit={() => handleSubmit()}
                            noValidate={true}
                        >
                            <h1>{t('Common.ReportBarrier.Text')}</h1>
                            <hr />

                            <RequiredFieldsNotice
                                description={t(
                                    'ValidationMessage.IndicatesARequiredField.Text'
                                )}
                            />

                            <TextInput
                                required={true}
                                icon={'envelope'}
                                label={t('UserFeedback.Email.Text')}
                                placeholder={t('UserFeedback.Email.Text')}
                                type={'email'}
                                isValid={ValidationTools.isEmailFieldValueValid(
                                    email,
                                    formSended
                                )}
                                validationError={t(
                                    'ValidationMessage.EmailInvalid.Text'
                                )}
                                value={email}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setEmail(e.target.value)
                                }
                                autoComplete='email'
                            />

                            <TextInput
                                required={true}
                                readOnly={true}
                                icon={'folder'}
                                label={t('UserFeedback.Subject.Text')}
                                placeholder={t('UserFeedback.Subject.Text')}
                                value={props.subject}
                                type={'text'}
                            />

                            <TextInput
                                icon={'telephone'}
                                label={t('UserFeedback.Phone.Text')}
                                placeholder={t('UserFeedback.Phone.Text')}
                                type={'tel'}
                                value={phone}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setPhone(e.target.value)
                                }
                                autoComplete='tel'
                            />

                            <TextArea
                                required={true}
                                icon={'chat-left-dots'}
                                label={t('UserFeedback.Message.Text')}
                                placeholder={t('UserFeedback.Message.Help')}
                                rows={6}
                                resize='none'
                                isValid={ValidationTools.isRequiredFieldValueValid(
                                    message,
                                    formSended
                                )}
                                validationError={t(
                                    'ValidationMessage.Required.Text'
                                )}
                                value={message}
                                onChange={(
                                    e: ChangeEvent<HTMLTextAreaElement>
                                ) => setMessage(e.target.value)}
                            />

                            <Button
                                icon='send'
                                text={t('UserFeedback.SendButton.Text')}
                                type={'submit'}
                                primary={true}
                                disabled={!isFormValid()}
                            />

                            {isLoggedIn && (
                                <Button
                                    isLink={true}
                                    linkTo={lastVisitedPage ?? '/dashboard'}
                                    icon='chevron-left'
                                    text={t(
                                        lastVisitedPage
                                            ? 'UserFeedback.GoToLastVisitedPageButton.Text'
                                            : 'XRechnung.GoHomeButton.Text'
                                    )}
                                    primary={false}
                                />
                            )}

                            {formErrorText && (
                                <p className='error'>{formErrorText}</p>
                            )}
                        </AsyncForm>
                    </>
                )) || (
                    <div className='success-container'>
                        <p className='success'>
                            <BootstrapIcon icon='check-circle-fill' />
                            {formSuccessText}
                        </p>

                        <AccessibleNavLink to={lastVisitedPage ?? '/dashboard'}>
                            <Button
                                icon='chevron-left'
                                text={t(
                                    lastVisitedPage
                                        ? 'UserFeedback.GoToLastVisitedPageButton.Text'
                                        : 'XRechnung.GoHomeButton.Text'
                                )}
                                primary={true}
                            />
                        </AccessibleNavLink>
                    </div>
                )}

                {!isLoggedIn && (
                    <>
                        <AccessibleNavLink to={'/'}>
                            <Button
                                icon='chevron-left'
                                text={t('PasswordForgotten.CancelButton.Text')}
                                primary={true}
                            />
                        </AccessibleNavLink>
                    </>
                )}

                <LoadingOverlay
                    loadingMessage={t('Common.LoadingMessage.Text')}
                    active={isLoading}
                />
            </Card>
        </div>
    );
}
