import { RequestTools } from '@b4valuenet/ui-react';
import {
    ActionResponse,
    GenericActionResponse,
} from '../models/GenericActionResponse';
import { GetNotificationOptionsResponse } from '../models/GetNotificationOptionsResponse';
import { GetPersonalSettingsResponse } from '../models/GetPersonalSettingsResponse';
import { KeyValuePair } from '../models/KeyValuePair';
import { PasswordChangeResultType } from '../models/PasswordChangeResultType';
import { PersonalSettings } from '../models/PersonalSettings';

export default class PersonalSettingsService {
    static ApiRoot = 'api/';

    static updatePassword = async (
        currentPassword: string,
        newPassword: string,
        confirmPassword: string
    ): Promise<ActionResponse<PasswordChangeResultType> | null> => {
        let response = await RequestTools.put(
            this.ApiRoot + 'personalsettings/changepassword',
            {
                currentPassword: currentPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword,
            }
        );

        let json = await response.data;
        let result = (json as ActionResponse<PasswordChangeResultType>) || null;

        return result;
    };

    static get = async (): Promise<PersonalSettings | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'personalsettings/usersettings'
        );

        let json = await response.data;
        let result = (json as GetPersonalSettingsResponse) || null;

        return result.data ?? null;
    };

    static save = async (
        language: string,
        selectedNotificationOption: number
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.put(
            this.ApiRoot + 'personalsettings/save',
            {
                language: language,
                messageStateNotification: selectedNotificationOption,
            }
        );

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result;
    };

    static getNotificationOptions = async (): Promise<
        KeyValuePair[] | null
    > => {
        let response = await RequestTools.get(
            this.ApiRoot + 'selectableoptions/getnotificationoptions'
        );

        let json = await response.data;
        let result = (json as GetNotificationOptionsResponse) || null;

        return result.data ?? null;
    };
}
