import './Help.scss';

import {
    BootstrapIcon,
    Button,
    ItemsSwitch,
    StringTools,
    useWindowSize,
} from '@b4valuenet/ui-react';
import { createRef, useEffect, useState } from 'react';

import LocaleService from '../../services/LocaleService';
import { RootState } from '../../redux/store';
import { useAppSelector } from '../../redux/hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function Help() {
    const containerRef = createRef<HTMLDivElement>();

    const { t } = useTranslation();

    const reduxLang = useSelector(
        (state: RootState) => state.userSettings.lang
    );
    const authenticatedUserLang = useSelector(
        (state: RootState) => state.auth?.user?.language
    );

    let browserLang = LocaleService.getBrowserLanguage();
    let browserLangCountryCode = browserLang && browserLang.slice(0, 2);

    let lang = authenticatedUserLang || reduxLang || browserLangCountryCode;

    console.log(lang);

    const sectionsRefs = Array(31)
        .fill(null)
        .map(() => createRef<HTMLDivElement>());

    function convertLinksToAnchorTags(text: string): JSX.Element[] {
        const urlRegex = /https?:\/\/[^\s)]+/g;
        const parts = text.split(urlRegex);
        const links = text.match(urlRegex);

        let elements: JSX.Element[] = [];
        parts.forEach((part, index) => {
            elements.push(
                <span key={index} style={{ display: 'inline-block' }}>
                    {part}
                </span>
            );
            if (links && links[index]) {
                elements.push(
                    <>
                        &nbsp;
                        <a
                            href={links[index]}
                            target='_blank'
                            rel='noopener noreferrer'
                            key={'link' + index}
                        >
                            {links[index]}
                        </a>
                        &nbsp;
                    </>
                );
            }
        });

        return elements;
    }
    const sectionsData = [
        <>
            <h2>{t('HelpPage.Block2.Text')}</h2>
            <p>{t('HelpPage.Block3.Text')}</p>
            <p>{t('HelpPage.Block4.Text')}</p>
            <ul>
                <li>
                    <p>{t('HelpPage.Block5.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block6.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block7.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block8.Text')}</p>
                </li>
            </ul>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/33.png'
                        : 'assets/guide/old/en/33.png'
                }
                alt={t('HelpPage.Block9.Text')}
            />
            <p>{t('HelpPage.Block9.Text')}</p>
        </>,
        <>
            <h2>{t('HelpPage.Block10.Text')}</h2>
            <p>
                {t('HelpPage.Block11.Text')} {t('HelpPage.Block12.Text')}
            </p>
            <p>
                <strong style={{ marginTop: '40px', display: 'block' }}>
                    {t('HelpPage.Block13.Text')}
                </strong>
            </p>
            <p>{t('HelpPage.Block14.Text')}</p>
        </>,
        <>
            <h2>{t('HelpPage.Block15.Text')}</h2>
            <p>{t('HelpPage.Block16.Text')}</p>
            <p>{t('HelpPage.Block17.Text')}</p>
            <ol>
                <li>
                    <p>
                        <span style={{ whiteSpace: 'pre-wrap' }}>
                            {t('HelpPage.Block18.Text')}
                        </span>
                        <a href='https://test.xrechnung-bdr.de/'>
                            {t('HelpPage.Block19.Text')}
                        </a>
                    </p>
                    <p>
                        <span style={{ whiteSpace: 'pre-wrap' }}>
                            {t('HelpPage.Block20.Text')}
                        </span>
                        <a href='https://xrechnung-bdr.de/'>
                            {t('HelpPage.Block21.Text')}
                        </a>
                    </p>
                </li>
                <li>
                    <p>{t('HelpPage.Block22.Text')}</p>
                    <br />
                    <img
                        style={{ maxWidth: 800 }}
                        src={
                            lang === 'de'
                                ? 'assets/guide/old/de/36.png'
                                : 'assets/guide/old/en/36.png'
                        }
                        alt='Abbildung 2: Anmeldung OZG-RE'
                    />
                    <p>{t('HelpPage.Block23.Text')}</p>
                    <p>
                        <strong style={{ marginTop: '20px', display: 'block' }}>
                            {t('HelpPage.Block24.Text')}{' '}
                            {t('HelpPage.Block25.Text')}
                        </strong>
                    </p>
                    <p>
                        <strong>{t('HelpPage.Block26.Text')}</strong>
                    </p>
                    <br />
                </li>
                <li>
                    <p>{t('HelpPage.Block27.Text')}</p>
                    <img
                        style={{ maxWidth: 800 }}
                        src={
                            lang === 'de'
                                ? 'assets/guide/old/de/37.png'
                                : 'assets/guide/old/en/37.png'
                        }
                        alt={t('HelpPage.Block28.Text')}
                    />
                    <p>{t('HelpPage.Block28.Text')}</p>
                    <p>
                        <strong style={{ marginTop: '20px', display: 'block' }}>
                            {t('HelpPage.Block29.Text') + ' '}
                            {t('HelpPage.Block30.Text')}
                        </strong>
                    </p>
                </li>
                <li>
                    <p>{t('HelpPage.Block31.Text')}</p>
                    <p>{t('HelpPage.Block32.Text')}</p>
                    <p>{t('HelpPage.Block33.Text')}</p>
                    <ul>
                        <li>
                            <p>{t('HelpPage.Block34.Text')}</p>
                        </li>
                        <li>
                            <p>{t('HelpPage.Block35.Text')}</p>
                        </li>
                        <li>
                            <p>{t('HelpPage.Block36.Text')}</p>
                        </li>
                        <li>
                            <p>{t('HelpPage.Block37.Text')}</p>
                            <p>
                                <strong>{t('HelpPage.Block38.Text')}</strong>
                            </p>
                            <p>{t('HelpPage.Block39.Text')}</p>
                            <p>{t('HelpPage.Block40.Text')}</p>
                            <img
                                style={{ maxWidth: 800 }}
                                src={
                                    lang === 'de'
                                        ? 'assets/guide/old/de/39.png'
                                        : 'assets/guide/old/en/39.png'
                                }
                                alt={t('HelpPage.Block41.Text')}
                            />
                            <p>{t('HelpPage.Block41.Text')}</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        {t('HelpPage.Block42.Text')}{' '}
                        {StringTools.linkifyEmails(t('HelpPage.Block43.Text'))}
                    </p>
                </li>
                <li>
                    <p>{t('HelpPage.Block44.Text')}</p>
                </li>
            </ol>
        </>,
        <>
            <h2>{t('HelpPage.Block45.Text')}</h2>
            <p>{convertLinksToAnchorTags(t('HelpPage.Block46.Text'))}</p>
            <p>{t('HelpPage.Block50.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/41.png'
                        : 'assets/guide/old/en/41.png'
                }
                alt={t('HelpPage.Block51.Text')}
            />
            <p>{t('HelpPage.Block51.Text')}</p>
        </>,
        <>
            <h2>{t('HelpPage.Block52.Text')}</h2>
            <p>{t('HelpPage.Block53.Text')}</p>
            <ul>
                <li>
                    <p>
                        {t('HelpPage.Block54.Text')}
                        <img
                            style={{ maxWidth: 30 }}
                            src={'assets/guide/old/de/46.png'}
                            alt={t('HelpPage.Block55.Text')}
                        />{' '}
                        {t('HelpPage.Block55.Text')}
                    </p>
                    <p>{t('HelpPage.Block56.Text')}</p>
                    <img
                        style={{ maxWidth: 800 }}
                        src={
                            lang === 'de'
                                ? 'assets/guide/old/de/47.png'
                                : 'assets/guide/old/en/45.png'
                        }
                        alt={t('HelpPage.Block57.Text')}
                    />
                    <p>{t('HelpPage.Block57.Text')}</p>
                </li>
                <li>
                    <p>
                        {t('HelpPage.Block58.Text') + ' '}
                        <BootstrapIcon className='im' icon='house' />{' '}
                        {t('HelpPage.Block59.Text')}
                    </p>
                </li>
                <li>
                    <p>
                        {t('HelpPage.Block60.Text')}{' '}
                        <BootstrapIcon className='im' icon='box-arrow-left' />{' '}
                        {t('HelpPage.Block61.Text')}
                    </p>
                </li>
                <li>
                    <p>
                        {t('HelpPage.Block60.Text')}{' '}
                        <BootstrapIcon className='im' icon='question-circle' />{' '}
                        {t('HelpPage.Block63.Text')}
                    </p>
                </li>
                <li>
                    <p>
                        {t('HelpPage.Block64.Text')}
                        <img
                            style={{ maxWidth: 800 }}
                            src={'assets/guide/old/de/45.png'}
                            alt={t('HelpPage.Block65.Text')}
                        />{' '}
                        {t('HelpPage.Block65.Text')}
                    </p>
                </li>
            </ul>
        </>,
        <>
            <h2>{t('HelpPage.Block66.Text')}</h2>
            <p>{t('HelpPage.Block67.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/50.png'
                        : 'assets/guide/old/en/50.png'
                }
                alt={t('HelpPage.Block68.Text')}
            />
            <p>{t('HelpPage.Block68.Text')}</p>
            <p>
                <strong style={{ marginTop: '20px', display: 'block' }}>
                    {t('HelpPage.Block69.Text')}
                </strong>
            </p>
            <p>{t('HelpPage.Block70.Text')}</p>
            <h3>{t('HelpPage.Block71.Text')}</h3>
            <p>{t('HelpPage.Block72.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/52.png'
                        : 'assets/guide/old/en/51.png'
                }
                alt={t('HelpPage.Block73.Text')}
            />
            <p>{t('HelpPage.Block73.Text')}</p>
            <p>{t('HelpPage.Block74.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/53.png'
                        : 'assets/guide/old/en/53.png'
                }
                alt={t('HelpPage.Block75.Text')}
            />
            <p>{t('HelpPage.Block75.Text')}</p>
            <p>
                <strong style={{ marginTop: '20px', display: 'block' }}>
                    {t('HelpPage.Block76.Text')}
                </strong>
            </p>
            <h3>{t('HelpPage.Block77.Text')}</h3>
            <p>{t('HelpPage.Block78.Text')}</p>
            <p>{t('HelpPage.Block79.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/55.png'
                        : 'assets/guide/old/en/54.png'
                }
                alt={t('HelpPage.Block80.Text')}
            />
            <p>{t('HelpPage.Block80.Text')}</p>
            <p>{t('HelpPage.Block81.Text')}</p>
            <ul>
                <li>
                    <p>{t('HelpPage.Block82.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block83.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block84.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block85.Text')}</p>
                </li>
            </ul>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/56.png'
                        : 'assets/guide/old/en/55.png'
                }
                alt={t('HelpPage.Block86.Text')}
            />
            <p>{t('HelpPage.Block86.Text')}</p>
            <p>{t('HelpPage.Block87.Text')}</p>
            <p>{t('HelpPage.Block88.Text')}</p>
            <h3>{t('HelpPage.Block89.Text')}</h3>
            <p>{t('HelpPage.Block90.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/59.png'
                        : 'assets/guide/old/en/57.png'
                }
                alt={t('HelpPage.Block91.Text')}
            />
            <p>{t('HelpPage.Block91.Text')}</p>
            <h3>{t('HelpPage.Block92.Text')}</h3>
            <p>{t('HelpPage.Block93.Text')}</p>
            <p>{t('HelpPage.Block94.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/60.png'
                        : 'assets/guide/old/en/59.png'
                }
                alt={t('HelpPage.Block95.Text')}
            />
            <p>{t('HelpPage.Block95.Text')}</p>
            <p>{t('HelpPage.Block96.Text')}</p>
            <p>{t('HelpPage.Block97.Text')}</p>
            <p>{t('HelpPage.Block98.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/61.png'
                        : 'assets/guide/old/en/60.png'
                }
                alt={t('HelpPage.Block99.Text')}
            />
            <p>{t('HelpPage.Block99.Text')}</p>
        </>,
        <>
            <h2>{t('HelpPage.Block100.Text')}</h2>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/64.png'
                        : 'assets/guide/old/en/62.png'
                }
                alt={t('HelpPage.Block101.Text')}
            />
            <p>{t('HelpPage.Block101.Text')}</p>
            <p>{t('HelpPage.Block102.Text')}</p>
            <p>{t('HelpPage.Block103.Text')}</p>
            <h3>{t('HelpPage.Block104.Text')}</h3>
            <p>{t('HelpPage.Block105.Text')}</p>
            <p>{t('HelpPage.Block106.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/65.png'
                        : 'assets/guide/old/en/64.png'
                }
                alt={t('HelpPage.Block107.Text')}
            />
            <p>{t('HelpPage.Block107.Text')}</p>
            <p>
                <strong style={{ marginTop: '20px', display: 'block' }}>
                    {t('HelpPage.Block108.Text')}
                </strong>
            </p>
            <h3>{t('HelpPage.Block109.Text')}</h3>
            <p>{t('HelpPage.Block110.Text')}</p>
            <p>{t('HelpPage.Block111.Text')}</p>
            <p>
                <strong style={{ marginTop: '20px', display: 'block' }}>
                    {t('HelpPage.Block112.Text')}
                </strong>
            </p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/67.png'
                        : 'assets/guide/old/en/66.png'
                }
                alt={t('HelpPage.Block113.Text')}
            />
            <p>{t('HelpPage.Block113.Text')}</p>
        </>,
        <>
            <h2>{t('HelpPage.Block114.Text')}</h2>
            <p>{t('HelpPage.Block115.Text')}</p>
            <br />
            <h2>{t('HelpPage.Block116.Text')}</h2>
            <p>
                <p>
                    {t('HelpPage.Block117.Text') +
                        ' ' +
                        t('HelpPage.Block118.Text') +
                        t('HelpPage.Block119.Text')}
                </p>
            </p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/71.png'
                        : 'assets/guide/old/en/70.png'
                }
                alt={t('HelpPage.Block120.Text')}
            />
            <p>{t('HelpPage.Block120.Text')}</p>
            <h2>{t('HelpPage.Block121.Text')}</h2>
            <p>{t('HelpPage.Block122.Text')}</p>
            <h3>{t('HelpPage.Block123.Text')}</h3>
            <p>
                <>{t('HelpPage.Block124.Text')}&nbsp;</>
                <>{t('HelpPage.Block125.Text')}</>
            </p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/73.png'
                        : 'assets/guide/old/en/72.png'
                }
                alt={t('HelpPage.Block126.Text')}
            />
            <p>{t('HelpPage.Block126.Text')}</p>
            <p>{t('HelpPage.Block127.Text')}</p>
            <ol>
                <li>
                    <p>{t('HelpPage.Block128.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block129.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block130.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block131.Text')}</p>
                </li>
            </ol>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/74.png'
                        : 'assets/guide/old/en/73.png'
                }
                alt={t('HelpPage.Block132.Text')}
            />
            <p>{t('HelpPage.Block132.Text')}</p>
            <h3>{t('HelpPage.Block133.Text')}</h3>
            <p>{t('HelpPage.Block134.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/76.png'
                        : 'assets/guide/old/en/75.png'
                }
                alt={t('HelpPage.Block135.Text')}
            />
            <p>{t('HelpPage.Block135.Text')}</p>
            <p>
                <p>
                    {t('HelpPage.Block136.Text')}&nbsp;
                    {t('HelpPage.Block137.Text')}
                    {t('HelpPage.Block138.Text')}
                </p>
            </p>
        </>,
        <>
            <h2>{t('HelpPage.Block139.Text')}</h2>
            <p>{t('HelpPage.Block140.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/79.jpg'
                        : 'assets/guide/old/en/78.png'
                }
                alt={t('HelpPage.Block141.Text')}
            />
            <p>{t('HelpPage.Block141.Text')}</p>
            <h3>{t('HelpPage.Block142.Text')}</h3>
            <p>{t('HelpPage.Block143.Text')}</p>
            <p>{t('HelpPage.Block144.Text')}</p>
            <p>{t('HelpPage.Block145.Text')}</p>
            <p>{t('HelpPage.Block146.Text')}</p>
            <p>
                <strong style={{ marginTop: '20px', display: 'block' }}>
                    {t('HelpPage.Block147.Text')}
                </strong>
            </p>
            <p>{t('HelpPage.Block148.Text')}</p>
            <p>
                <strong style={{ marginTop: '20px', display: 'block' }}>
                    {t('HelpPage.Block149.Text')}
                </strong>
            </p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/81.png'
                        : 'assets/guide/old/en/80.png'
                }
                alt={t('HelpPage.Block150.Text')}
            />
            <p>{t('HelpPage.Block150.Text')}</p>
            <p>{t('HelpPage.Block151.Text')}</p>
            <p>{t('HelpPage.Block152.Text')}</p>
            <p>
                <strong style={{ marginTop: '20px', display: 'block' }}>
                    {t('HelpPage.Block153.Text')}
                </strong>
            </p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/83.png'
                        : 'assets/guide/old/en/81.png'
                }
                alt={t('HelpPage.Block154.Text')}
            />
            <p>{t('HelpPage.Block154.Text')}</p>
            <p>{t('HelpPage.Block155.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/84.png'
                        : 'assets/guide/old/en/84.png'
                }
                alt={t('HelpPage.Block156.Text')}
            />
            <p>{t('HelpPage.Block156.Text')}</p>
            <h3>{t('HelpPage.Block157.Text')}</h3>
            <p>{t('HelpPage.Block158.Text')}</p>
            <p>
                <strong style={{ marginTop: '20px', display: 'block' }}>
                    {t('HelpPage.Block159.Text')}
                </strong>
            </p>
            <p>
                <a href='https://xeinkauf.de/xrechnung/'>
                    {t('HelpPage.Block160.Text')}
                </a>
            </p>
            <h3>{t('HelpPage.Block161.Text')}</h3>
            <p>{t('HelpPage.Block162.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/86.png'
                        : 'assets/guide/old/en/85.png'
                }
                alt={t('HelpPage.Block163.Text')}
            />
            <p>{t('HelpPage.Block163.Text')}</p>
            <p>{t('HelpPage.Block164.Text')}</p>
            <p>{t('HelpPage.Block165.Text')}</p>
            <p>{t('HelpPage.Block166.Text')}</p>
            <p>{t('HelpPage.Block167.Text')}</p>
            <p>{t('HelpPage.Block168.Text')}</p>
            <p>{t('HelpPage.Block169.Text')}</p>
            <h3>{t('HelpPage.Block170.Text')}</h3>
            <p>{t('HelpPage.Block171.Text')}</p>
            <p>{t('HelpPage.Block172.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/88.png'
                        : 'assets/guide/old/en/87.png'
                }
                alt={t('HelpPage.Block173.Text')}
            />
            <p>{t('HelpPage.Block173.Text')}</p>
            <p>{t('HelpPage.Block174.Text')}</p>
            <p>{t('HelpPage.Block175.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/89.png'
                        : 'assets/guide/old/en/88.jpg'
                }
                alt={t('HelpPage.Block176.Text')}
            />
            <p>{t('HelpPage.Block176.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/90.png'
                        : 'assets/guide/old/en/89.png'
                }
                alt={t('HelpPage.Block177.Text')}
            />
            <p>{t('HelpPage.Block177.Text')}</p>
            <p>{t('HelpPage.Block178.Text')}</p>
            <p>{t('HelpPage.Block179.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/91.png'
                        : 'assets/guide/old/en/r-1.png'
                }
                alt={t('HelpPage.Block180.Text')}
            />
            <p>{t('HelpPage.Block180.Text')}</p>
            <p>{t('HelpPage.Block181.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/94.png'
                        : 'assets/guide/old/en/90.png'
                }
                alt={t('HelpPage.Block182.Text')}
            />
            <p>{t('HelpPage.Block182.Text')}</p>
            <h3>{t('HelpPage.Block183.Text')}</h3>
            <h3>{t('HelpPage.Block184.Text')}</h3>
            <p>{t('HelpPage.Block185.Text')}</p>
            <p>{t('HelpPage.Block186.Text')}</p>
            <p>{t('HelpPage.Block187.Text')}</p>
            <p>
                <strong style={{ marginTop: '20px', display: 'block' }}>
                    {t('HelpPage.Block188.Text')}
                </strong>
            </p>
            <h2>{t('HelpPage.Block189.Text')}</h2>
            <p>{t('HelpPage.Block190.Text')}</p>
            <p>{StringTools.linkifyEmails(t('HelpPage.Block191.Text'))}</p>
            <h3>{t('HelpPage.Block196.Text')}</h3>
            <p>{t('HelpPage.Block197.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/98.png'
                        : 'assets/guide/old/en/81.png'
                }
                alt={t('HelpPage.Block198.Text')}
            />
            <p>{t('HelpPage.Block198.Text')}</p>
            <p>{t('HelpPage.Block199.Text')}</p>
            <img
                style={{ maxWidth: 800 }}
                src={
                    lang === 'de'
                        ? 'assets/guide/old/de/99.png'
                        : 'assets/guide/old/en/95.jpg'
                }
                alt={t('HelpPage.Block200.Text')}
            />
            <p>{t('HelpPage.Block200.Text')}</p>
            <h3>{t('HelpPage.Block201.Text')}</h3>
            <p>{t('HelpPage.Block202.Text')}</p>
            <ul>
                <li>
                    <p>{t('HelpPage.Block203.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block204.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block205.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block206.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block207.Text')}</p>
                    <p>
                        <strong style={{ marginTop: '20px', display: 'block' }}>
                            {t('HelpPage.Block208.Text')}
                        </strong>
                    </p>
                </li>
                <li>
                    <p>{t('HelpPage.Block209.Text')}</p>
                </li>
                <li>
                    <p>{t('HelpPage.Block210.Text')}</p>
                    <p>
                        <strong style={{ marginTop: '20px', display: 'block' }}>
                            {t('HelpPage.Block211.Text')}
                        </strong>
                    </p>
                </li>
            </ul>
        </>,
        <>
            <h2>{t('HelpPage.Block212.Text')}</h2>
            <p>{t('HelpPage.Block213.Text')}</p>
            <ul>
                <li>
                    <p>
                        {t('HelpPage.Block214.Text')}&nbsp;
                        <BootstrapIcon icon='arrow-clockwise' />
                    </p>
                    <p>
                        <strong style={{ marginTop: '20px', display: 'block' }}>
                            {t('HelpPage.Block215.Text')}
                        </strong>
                    </p>
                </li>
                <li>
                    <p>{t('HelpPage.Block216.Text')}</p>
                    <Button
                        text={t('Messages.UploadBtn.Text')}
                        icon={'upload'}
                        primary={true}
                    />
                </li>
                <li>
                    <p>{t('HelpPage.Block217.Text')}</p>&nbsp;
                    <BootstrapIcon icon='eye' />
                    <p>
                        <strong style={{ marginTop: '20px', display: 'block' }}>
                            {t('HelpPage.Block218.Text')}
                        </strong>
                    </p>
                </li>
                <li>
                    <p>
                        {t('HelpPage.Block219.Text')}&nbsp;
                        {t('HelpPage.Block220.Text')}
                    </p>
                    &nbsp;
                    <BootstrapIcon icon='download' />
                    <p>
                        <strong style={{ marginTop: '20px', display: 'block' }}>
                            {t('HelpPage.Block218.Text')}
                        </strong>
                    </p>
                </li>
                <li>
                    <p>{t('HelpPage.Block222.Text')}</p>&nbsp;
                    <BootstrapIcon icon='search' />
                    <br />
                    <br />
                    <img
                        style={{ maxWidth: 800 }}
                        src={
                            lang === 'de'
                                ? 'assets/guide/old/de/106.png'
                                : 'assets/guide/old/en/103.png'
                        }
                        alt={t('HelpPage.Block223.Text')}
                    />
                    <p>{t('HelpPage.Block223.Text')}</p>
                    <p>
                        <strong style={{ marginTop: '20px', display: 'block' }}>
                            {t('HelpPage.Block218.Text')}
                        </strong>
                    </p>
                </li>
            </ul>
        </>,
        <>
            <h2>{t('HelpPage.Block225.Text')}</h2>
            <p>{t('HelpPage.Block226.Text')}</p>
            <ul>
                <li>
                    <p>
                        <strong>{t('HelpPage.Block227.Text')}</strong>
                        {t('HelpPage.Block228.Text')}
                        {t('HelpPage.Block229.Text')}
                    </p>
                </li>
                <li>
                    <p>
                        <strong>{t('HelpPage.Block230.Text')}</strong>
                        {t('HelpPage.Block231.Text')}
                    </p>
                </li>
                <li>
                    <p>
                        <strong>{t('HelpPage.Block232.Text')}</strong>
                        {t('HelpPage.Block233.Text')}
                    </p>
                </li>
                <li>
                    <p>
                        <strong>{t('HelpPage.Block234.Text')}</strong>
                        {t('HelpPage.Block235.Text')}
                    </p>
                    <p>{t('HelpPage.Block236.Text')}</p>
                </li>
                <li>
                    <p>
                        <strong>{t('HelpPage.Block237.Text')}</strong>
                        <p>{t('HelpPage.Block238.Text')}</p>
                        <a href='https://xeinkauf.de/xrechnung/'>
                            {t('HelpPage.Block160.Text')}
                        </a>
                        <a href='https://www.xoev.de/xrechnung-16828#BestandteileXRechnung'>
                            {t('HelpPage.Block195.Text')}
                        </a>
                    </p>
                    <p>
                        {t('HelpPage.Block241.Text')}
                        <a href='#bookmark63'>{t('HelpPage.Block242.Text')}</a>
                    </p>
                </li>
            </ul>
        </>,
        <>
            <h2>{t('HelpPage.Block243.Text')}</h2>
            <p>{t('HelpPage.Block244.Text')}</p>
            <p>{t('HelpPage.Block245.Text')}</p>
            <p>{t('HelpPage.Block246.Text')}</p>
            <p>
                <p>{t('HelpPage.Block247.Text')}&nbsp;</p>
                <a href='mailto:sendersupport-xrechnung@bdr.de'>
                    {t('HelpPage.Block248.Text')}
                </a>
            </p>
        </>,
    ];

    const [mostVisible, setMostVisible] = useState<number>(1);
    const [scrollItemDetectionLock, setScrollItemDetectionLock] =
        useState<boolean>(false);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollItemDetectionLock) return;
            const container = containerRef.current;
            if (container) {
                const { top: containerTop, bottom: containerBottom } =
                    container.getBoundingClientRect();

                let maxVisibleIndex = -1;
                let maxVisibleArea = 0;

                for (let i = 0; i < sectionsData.length; i++) {
                    const element = sectionsRefs[i].current;
                    if (element) {
                        const { top, bottom } = element.getBoundingClientRect();
                        const visibleTop = Math.max(top, containerTop);
                        const visibleBottom = Math.min(bottom, containerBottom);
                        const visibleArea = Math.max(
                            0,
                            visibleBottom - visibleTop
                        );

                        if (visibleArea > maxVisibleArea) {
                            maxVisibleArea = visibleArea;
                            maxVisibleIndex = i;
                        }
                    }
                }

                setMostVisible(maxVisibleIndex + 1);
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [
        scrollItemDetectionLock,
        containerRef,
        sectionsData.length,
        sectionsRefs,
    ]);

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    const windowSize = useWindowSize();

    return (
        <div className='help-page'>
            <div className='help-sidebar'>
                <ItemsSwitch
                    mode='nav'
                    ariaLabel='Help navigation'
                    vertical={(windowSize?.width ?? 0) >= 850}
                    value={mostVisible}
                    onChange={(value) => {
                        if (value) {
                            let element = sectionsRefs[+value - 1].current;
                            setScrollItemDetectionLock(true);
                            containerRef.current?.scrollTo({
                                top: element?.offsetTop,
                                behavior: 'smooth',
                            });

                            setTimeout(() => {
                                setScrollItemDetectionLock(false);
                            }, 500);
                        }
                    }}
                    items={[
                        {
                            title: t('HelpPage.Block2.Text'),
                            value: 1,
                            icon: 'gear',
                        },
                        {
                            title: t('HelpPage.Block10.Text'),
                            value: 2,
                            icon: 'hash',
                        },
                        {
                            title: t('HelpPage.Block15.Text'),
                            value: 3,
                            icon: 'pencil-square',
                        },
                        {
                            title: t('HelpPage.Block45.Text'),
                            value: 4,
                            icon: 'arrow-right-circle',
                        },
                        {
                            title: t('HelpPage.Block52.Text'),
                            value: 5,
                            icon: 'menu-app',
                        },
                        {
                            title: t('HelpPage.Block66.Text'),
                            value: 6,
                            icon: 'person',
                        },
                        {
                            title: t('HelpPage.Block100.Text'),
                            value: 7,
                            icon: 'building',
                        },
                        {
                            title: t('HelpPage.Block114.Text'),
                            value: 8,
                            icon: 'file-plus',
                        },
                        {
                            title: t('HelpPage.Block139.Text'),
                            value: 9,
                            icon: 'send',
                        },
                        {
                            title: t('HelpPage.Block212.Text'),
                            value: 10,
                            icon: 'gear-wide',
                        },
                        {
                            title: t('HelpPage.Block225.Text'),
                            value: 11,
                            icon: 'file-earmark-check',
                        },
                        {
                            title: t('HelpPage.Block243.Text'),
                            value: 12,
                            icon: 'life-preserver',
                        },
                    ]}
                />
            </div>

            <div ref={containerRef} className='help-content'>
                <div>
                    <h1>{t('HelpPage.Block1.Text')}</h1>

                    {!isLoggedIn && (
                        <div>
                            <Button
                                linkTo={'/'}
                                isLink={true}
                                icon='chevron-left'
                                text={t('PasswordForgotten.CancelButton.Text')}
                                primary={true}
                            />
                        </div>
                    )}
                </div>

                {sectionsData.map((data, index) => {
                    return (
                        <section key={index} ref={sectionsRefs[index]}>
                            {data}
                        </section>
                    );
                })}
            </div>
        </div>
    );
}
