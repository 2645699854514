import { Accordion } from '@b4valuenet/ui-react';
import { KeyValuePair } from '../../../../../../../../models/KeyValuePair';
import { PositionLine } from '../../../../../../../../models/PositionLine';
import PositionLineSummary from './components/PositionLineSummary';

interface IPositionLinesSummary {
    lines: PositionLine[];
    unitOptions: KeyValuePair[];
    taxCategories: KeyValuePair[];
}

export default function PositionLinesSummary(props: IPositionLinesSummary) {
    return (
        <div className='spaced-fields position-lines-summary'>
            <Accordion>
                {props.lines.map((line, index) => {
                    return (
                        <PositionLineSummary
                            line={line}
                            index={index}
                            totalCount={props.lines.length}
                            key={index}
                            unitOptions={props.unitOptions}
                            taxCategories={props.taxCategories}
                        />
                    );
                })}
            </Accordion>
        </div>
    );
}
