import {
    Checkbox,
    TextArea,
    TextInput,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { useTranslation } from 'react-i18next';
import { XRechnung } from '../../../../../../../models/XRechnung';
import { XRechnungAccordionItem } from '../../../components/XRechnungAccordionItem';
import XRechnungSellerValidators from '../../../validators/SellerValidators';

export interface ISellerInformationData {
    data: XRechnung | null;
}

export interface ISellerInformation extends ISellerInformationData {
    isFormSubmitted: boolean;
    updateData: (callable: (prevState: XRechnung) => XRechnung | null) => void;
}

export function SellerInformation(props: ISellerInformation) {
    const { t } = useTranslation();

    let senderMicroenterpreneuer =
        props.data?.senderData.senderMicroenterpreneuer;

    return (
        <XRechnungAccordionItem
            icon='info-circle'
            title={t('XRechnung.SenderInformation.Text')}
            expanded={true}
            textWhenErrorsArePresent={t('ValidationMessage.ErrorsPresent.Text')}
            body={
                <div className='spaced-fields'>
                    <Checkbox
                        title={t('XRechnung.SenderMicroenterpreneur.Text')}
                        checked={senderMicroenterpreneuer}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    senderData: {
                                        ...prevData.senderData,
                                        senderMicroenterpreneuer:
                                            e.target.checked,
                                    },
                                }));
                            }
                        }}
                    />
                    <TextInput
                        required={true}
                        type='text'
                        icon='building'
                        placeholder={t('XRechnung.LegalEntityName.Text')}
                        label={t('XRechnung.LegalEntityName.Text')}
                        value={
                            props.data?.senderData.senderLegalEntityName || ''
                        }
                        isValid={ValidationTools.isRequiredFieldValueValid(
                            props.data?.senderData.senderLegalEntityName,
                            props.isFormSubmitted
                        )}
                        validationError={t('ValidationMessage.Required.Text')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    senderData: {
                                        ...prevData.senderData,
                                        senderLegalEntityName: e.target.value,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.SenderLegalEntityNameHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.SenderLegalEntityNameHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.SenderLegalEntityName.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />

                    <TextInput
                        type='text'
                        icon='tags'
                        placeholder={t('XRechnung.ClientName.Text')}
                        label={t('XRechnung.ClientName.Text')}
                        value={props.data?.senderData.senderName || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    senderData: {
                                        ...prevData.senderData,
                                        senderName: e.target.value,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.SenderNameHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.SenderNameHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t('XRechnung.SenderName.Help')}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />

                    <TextInput
                        type='text'
                        icon='hash'
                        placeholder={t('XRechnung.SenderIDRefGroupValue.Text')}
                        label={t('XRechnung.SenderIDRefGroupValue.Text')}
                        value={
                            props.data?.senderData.senderIDRefGroupValue || ''
                        }
                        required={
                            (props.data?.senderData.senderVatId ?? '')
                                .length === 0
                        }
                        isValid={
                            (props.data?.senderData.senderVatId ?? '').length >
                                0 ||
                            ValidationTools.isRequiredFieldValueValid(
                                props.data?.senderData.senderIDRefGroupValue,
                                props.isFormSubmitted
                            )
                        }
                        validationError={t(
                            'ValidationMessage.SenderSupplierNumberOrVatId.Text'
                        )}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    senderData: {
                                        ...prevData.senderData,
                                        senderIDRefGroupValue: e.target.value,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.SenderIDRefGroupValueHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.SenderIDRefGroupValueHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.SenderIDRefGroupValue.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />

                    <TextInput
                        type='text'
                        icon='bank'
                        required={XRechnungSellerValidators.IsSenderVatIdRequired(
                            {
                                data: props.data,
                                isFormSubmitted: props.isFormSubmitted,
                            }
                        )}
                        isValid={
                            !XRechnungSellerValidators.IsSenderVatIdRequired({
                                data: props.data,
                                isFormSubmitted: props.isFormSubmitted,
                            }) ||
                            ValidationTools.isRequiredFieldValueValid(
                                props.data?.senderData.senderVatId,
                                props.isFormSubmitted
                            )
                        }
                        validationError={t(
                            senderMicroenterpreneuer
                                ? 'ValidationMessage.Required.Text'
                                : 'ValidationMessage.SenderVatIdOrTaxIdRequired.Text'
                        )}
                        placeholder={t('XRechnung.VatId.Text')}
                        label={t('XRechnung.VatId.Text')}
                        value={props.data?.senderData.senderVatId || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    senderData: {
                                        ...prevData.senderData,
                                        senderVatId: e.target.value,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.SenderVatIdHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.SenderVatIdHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t('XRechnung.SenderVatId.Help')}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />

                    <TextInput
                        type='text'
                        icon='bank'
                        required={XRechnungSellerValidators.IsSenderTaxNumberRequired(
                            {
                                data: props.data,
                                isFormSubmitted: props.isFormSubmitted,
                            }
                        )}
                        isValid={
                            !XRechnungSellerValidators.IsSenderTaxNumberRequired(
                                {
                                    data: props.data,
                                    isFormSubmitted: props.isFormSubmitted,
                                }
                            ) ||
                            ValidationTools.isRequiredFieldValueValid(
                                props.data?.senderData.senderTaxNo,
                                props.isFormSubmitted
                            )
                        }
                        validationError={t(
                            senderMicroenterpreneuer
                                ? 'ValidationMessage.Required.Text'
                                : 'ValidationMessage.SenderVatIdOrTaxIdRequired.Text'
                        )}
                        placeholder={t('XRechnung.TaxNo.Text')}
                        label={t('XRechnung.TaxNo.Text')}
                        value={props.data?.senderData.senderTaxNo || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    senderData: {
                                        ...prevData.senderData,
                                        senderTaxNo: e.target.value,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.SenderTaxNoHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.SenderTaxNoHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t('XRechnung.SenderTaxNo.Help')}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />

                    <TextArea
                        icon='card-list'
                        placeholder={t(
                            'XRechnung.SenderLegalEntityLegalInformation.Text'
                        )}
                        label={t(
                            'XRechnung.SenderLegalEntityLegalInformation.Text'
                        )}
                        value={
                            props.data?.senderData
                                .senderLegalEntityLegalInformation ?? ''
                        }
                        onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                            if (props.data) {
                                props.updateData((prevData) => ({
                                    ...prevData,
                                    senderData: {
                                        ...prevData.senderData,
                                        senderLegalEntityLegalInformation:
                                            e.target.value,
                                    },
                                }));
                            }
                        }}
                        infoButtonEnabled={true}
                        infoButtonText={t(
                            'XRechnung.SenderLegalEntityLegalInformationHelpTitle.ShortText'
                        )}
                        infoButtonTooltipTitle={t(
                            'XRechnung.SenderLegalEntityLegalInformationHelpTitle.Text'
                        )}
                        infoButtonTooltipText={t(
                            'XRechnung.SenderLegalEntityLegalInformation.Help'
                        )}
                        infoButtonTitle={t('Common.InputHelp.Text')}
                        infoButtonAriaLabel={t('Common.InputHelp.Text')}
                        combineInfoButtonTextWithTitle={true}
                    />
                </div>
            }
        />
    );
}
