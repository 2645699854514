import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

import {
    AsyncForm,
    Button,
    Card,
    ErrorOverlay,
    LoadingOverlay,
    RequestTools,
    Select,
    Toast,
} from '@b4valuenet/ui-react';
import { useTranslation } from 'react-i18next';
import { KeyValuePair } from '../../../../../models/KeyValuePair';
import { Language } from '../../../../../models/Language';
import { changeUserLanguage } from '../../../../../redux/actions/userSettings';
import { languageInSettingsChanged } from '../../../../../redux/slices/AuthSlice';
import LocaleService from '../../../../../services/LocaleService';
import PersonalSettingsService from '../../../../../services/PersonalSettingsService';

export default function GeneralSettings() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const authenticatedUser = useAppSelector((state) => state.auth.user);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingError, setLoadingError] = useState<string | null>(null);

    const [notificationOptions, setNotificationOptions] = useState<
        KeyValuePair[] | null
    >(null);

    const [availableLanguagesList, setAvailableLanguagesList] = useState<
        Language[] | null
    >();

    const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
        null
    );
    const [selectedNotificationOption, setSelectedNotificationOption] =
        useState<number | null>(null);

    useEffect(() => {
        setSelectedLanguage(authenticatedUser?.language ?? 'de');
    }, [authenticatedUser]);

    const handleOnSubmit = useCallback(async () => {
        setIsLoading(true);

        try {
            let response = await PersonalSettingsService.save(
                selectedLanguage ?? 'de',
                selectedNotificationOption ?? 0
            );

            if (response?.message) {
                if (
                    selectedLanguage &&
                    selectedLanguage !== authenticatedUser?.language
                ) {
                    dispatch(languageInSettingsChanged(selectedLanguage));
                    dispatch(changeUserLanguage(selectedLanguage));
                }
                Toast.success(response.message, 350);
            }
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        setIsLoading(false);
    }, [
        selectedLanguage,
        authenticatedUser?.language,
        dispatch,
        selectedNotificationOption,
    ]);

    useEffect(() => {
        const fetchMainData = async () => {
            setIsLoading(true);

            const fetchAvailableLanguages = async () => {
                try {
                    let languages = await LocaleService.getLanguageList();
                    setAvailableLanguagesList(languages);
                } catch (error) {
                    let message = RequestTools.getApiErrorMessage(error);

                    setLoadingError(message);
                }
            };

            const fetchNotificationOptions = async () => {
                try {
                    let options =
                        await PersonalSettingsService.getNotificationOptions();
                    setNotificationOptions(options);
                } catch (error) {
                    let message = RequestTools.getApiErrorMessage(error);

                    setLoadingError(message);
                }
            };

            const fetchCurrentSettings = async () => {
                try {
                    let settings = await PersonalSettingsService.get();
                    setSelectedNotificationOption(
                        settings?.messageStateNotification ?? 0
                    );
                } catch (error) {
                    let message = RequestTools.getApiErrorMessage(error);

                    setLoadingError(message);
                }
            };

            await fetchAvailableLanguages();
            await fetchNotificationOptions();
            await fetchCurrentSettings();

            setIsLoading(false);
        };

        fetchMainData();
    }, [authenticatedUser?.language]);

    let notificationIcon = 'hourglass-split';

    switch (selectedNotificationOption) {
        case 0:
            notificationIcon = 'bell-slash';
            break;
        default:
            notificationIcon = 'bell';
            break;
    }

    return (
        <Card
            className='general-settings'
            title={t('PersonalSettings.General.Text')}
        >
            <AsyncForm className='card-content' onSubmit={handleOnSubmit}>
                <div className='pseudo-table'>
                    <div className='pseudo-row'>
                        <div className='pseudo-column'>
                            <strong>
                                {t('PersonalSettings.FirstName.Text')}
                            </strong>
                        </div>
                        <div className='pseudo-column'>
                            {authenticatedUser?.firstName}
                        </div>
                    </div>
                    <div className='pseudo-row'>
                        <div className='pseudo-column'>
                            <strong>
                                {t('PersonalSettings.LastName.Text')}
                            </strong>
                        </div>
                        <div className='pseudo-column'>
                            {authenticatedUser?.lastName}
                        </div>
                    </div>
                    <div className='pseudo-row'>
                        <div className='pseudo-column'>
                            <strong>{t('PersonalSettings.Email.Text')}</strong>
                        </div>
                        <div className='pseudo-column'>
                            {authenticatedUser?.email}
                        </div>
                    </div>
                    <div className='pseudo-row'>
                        <div className='pseudo-column'>
                            <label htmlFor={'user-settings-language'}>
                                <strong>
                                    {t('PersonalSettings.Language.Text')}
                                </strong>
                            </label>
                        </div>
                        <div className='pseudo-column'>
                            <Select
                                id={'user-settings-language'}
                                icon='globe'
                                value={selectedLanguage ?? undefined}
                                autoComplete='language'
                                onChange={(
                                    e: ChangeEvent<HTMLSelectElement>
                                ) => {
                                    if (e.target instanceof HTMLSelectElement) {
                                        setSelectedLanguage(e.target.value);
                                    }
                                }}
                            >
                                {availableLanguagesList?.map(
                                    (language, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={language.code}
                                            >
                                                {language.text}
                                            </option>
                                        );
                                    }
                                )}
                            </Select>
                        </div>
                    </div>
                    <div className='pseudo-row'>
                        <div className='pseudo-column'>
                            <label htmlFor={'user-settings-notifications'}>
                                <strong>
                                    {t('PersonalSettings.Notification.Text')}
                                </strong>
                            </label>
                        </div>
                        <div className='pseudo-column'>
                            <Select
                                id={'user-settings-notifications'}
                                icon={notificationIcon}
                                value={
                                    selectedNotificationOption?.toString() ??
                                    undefined
                                }
                                onChange={(
                                    e: ChangeEvent<HTMLSelectElement>
                                ) => {
                                    if (e.target instanceof HTMLSelectElement) {
                                        setSelectedNotificationOption(
                                            +e.target.value
                                        );
                                    }
                                }}
                            >
                                {notificationOptions?.map((option, index) => {
                                    return (
                                        <option key={index} value={option.key}>
                                            {option.value}
                                        </option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </div>

                <hr className='big-separator' />

                <Button
                    type='submit'
                    text={t('PersonalSettings.SaveBtn.Text')}
                    primary={true}
                />
            </AsyncForm>

            <LoadingOverlay
                loadingMessage={t('Common.LoadingMessage.Text')}
                active={isLoading}
                isGlobal={false}
            />

            <ErrorOverlay
                active={loadingError !== null}
                isGlobal={false}
                errorText={loadingError ?? ''}
            />
        </Card>
    );
}
