import { Card } from '@b4valuenet/ui-react';
import { ExternalReference } from '../../../../../../../../models/ExternalReference';
import { useAppSelector } from '../../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

interface IExternalReferencesSummary {
    references: ExternalReference[];
}

export default function ExternalReferencesSummary(
    props: IExternalReferencesSummary
) {
    const { t } = useTranslation();

    const appSettings = useAppSelector((state) => state.app.settings);

    return (
        <Card
            className='grid-big-item'
            title={t(
                appSettings?.bigAttachmentsEnabled
                    ? 'XRechnung.ExternalReferencesTabBigAttachments.Text'
                    : 'XRechnung.ExternalReferencesTab.Text'
            )}
        >
            <div className='pseudo-table'>
                <table>
                    <thead>
                        <tr>
                            <th>{t('XRechnung.ExternalReferenceName.Text')}</th>
                            <th>
                                {t(
                                    'XRechnung.ExternalReferenceIdentifier.Text'
                                )}
                            </th>
                            <th>
                                {t(
                                    'XRechnung.ExternalReferenceDescription.Text'
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.references.map((reference, index) => {
                            return (
                                <tr key={index}>
                                    <td>{reference.name}</td>
                                    <td>{reference.identifier}</td>
                                    <td>{reference.description}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
