import './SubmitExternallyCreatedInvoiceDialog.scss';

import {
    AnimatedErrorIcon,
    AnimatedSuccessIcon,
    Button,
    Dialog,
    IStep,
    LoadingOverlay,
    RequestTools,
    StepsProgress,
    StringTools
} from '@b4valuenet/ui-react';
import { createRef, useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { InvoiceValidationResult } from '../../../models/InvoiceValidationResult';
import { useAppSelector } from '../../../redux/hooks';
import UploadService from '../../../services/UploadService';
import { ValidationResult } from './components/ValidationResult';

interface ISubmitExternallyCreatedInvoiceDialog {
    active: boolean;
    onClose: CallableFunction;
    selectedFile?: File | null;
}

export enum ExternalInvoiceUploadStep {
    Select = 1,
    Process = 2,
    Validate = 3,
    Submit = 4,
}

export default function SubmitExternallyCreatedInvoiceDialog(
    props: ISubmitExternallyCreatedInvoiceDialog
) {
    const { t } = useTranslation();
    const appSettings = useAppSelector((state) => state.app.settings);

    const [currentStep, setCurrentStep] = useState<ExternalInvoiceUploadStep>(
        ExternalInvoiceUploadStep.Select
    );

    const [selectedFile, setSelectedFile] = useState<File | null>(
        props.selectedFile ?? null
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadPercent, setUploadPercent] = useState<number>(0);
    const [error, setError] = useState<string>('');
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [submitResultText, setSubmitResultText] = useState<string | null>(
        null
    );
    const [cancelationTrigger, setCancelationTrigger] =
        useState<AbortController>(new AbortController());

    const [isSelectedFileValid, setIsSelectedFileValid] = useState<
        boolean | null
    >(null);

    const [isProcessedFileValid, setIsProcessedFileValid] = useState<
        boolean | null
    >(null);

    const [fileValidationResult, setFileValidationResult] =
        useState<InvoiceValidationResult | null>(null);

    useEffect(() => {
        setSelectedFile(props.selectedFile ?? null);

        if (
            props.selectedFile &&
            currentStep === ExternalInvoiceUploadStep.Select
        ) {
            setCurrentStep(ExternalInvoiceUploadStep.Process);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedFile]);

    const selectFileButtonRef = createRef<HTMLInputElement>();

    let steps: IStep[] = [
        {
            icon: 'file-earmark-plus',
            title: t('Home.StepTitleSelectInvoiceFile.Text'),
            ariaTitle:
                t('Common.Step.Text') +
                ' 1: ' +
                t('Home.StepTitleSelectInvoiceFile.Text'),
            done: currentStep >= ExternalInvoiceUploadStep.Select,
            showStatusIcon: currentStep > ExternalInvoiceUploadStep.Select,
            current: currentStep === ExternalInvoiceUploadStep.Select,
        },
        {
            icon: 'gear',
            title: t('Home.StepTitleProceedInvoice.Text'),
            ariaTitle:
                t('Common.Step.Text') +
                ' 2: ' +
                t('Home.StepTitleProceedInvoice.Text'),
            done:
                currentStep >= ExternalInvoiceUploadStep.Process ||
                isProcessedFileValid === true,
            showStatusIcon:
                currentStep > ExternalInvoiceUploadStep.Process ||
                (currentStep === ExternalInvoiceUploadStep.Process &&
                    error.length > 0),
            error:
                currentStep === ExternalInvoiceUploadStep.Process &&
                error.length > 0,
            current: currentStep === ExternalInvoiceUploadStep.Process,
        },
        {
            icon: 'journal-check',
            title: t('Home.StepTitleValidationResult.Text'),
            ariaTitle:
                t('Common.Step.Text') +
                ' 3: ' +
                t('Home.StepTitleValidationResult.Text'),
            done:
                currentStep >= ExternalInvoiceUploadStep.Validate ||
                isSelectedFileValid === true,
            showStatusIcon:
                currentStep > ExternalInvoiceUploadStep.Validate ||
                isSelectedFileValid === true ||
                (currentStep === ExternalInvoiceUploadStep.Validate &&
                    error.length > 0),
            error:
                currentStep === ExternalInvoiceUploadStep.Validate &&
                error.length > 0,
            current: currentStep === ExternalInvoiceUploadStep.Validate,
        },
        {
            icon: 'upload',
            title: t('Home.StepTitleSubmitInvoice.Text'),
            ariaTitle:
                t('Common.Step.Text') +
                ' 4: ' +
                t('Home.StepTitleSubmitInvoice.Text'),
            done:
                currentStep >= ExternalInvoiceUploadStep.Submit || isSubmitted,
            showStatusIcon:
                currentStep > ExternalInvoiceUploadStep.Submit ||
                isSubmitted ||
                (currentStep === ExternalInvoiceUploadStep.Submit &&
                    error.length > 0),
            error:
                currentStep === ExternalInvoiceUploadStep.Submit &&
                error.length > 0,
            current: currentStep === ExternalInvoiceUploadStep.Submit,
        },
    ];

    function onClose() {
        cancelationTrigger.abort();
        setCancelationTrigger(new AbortController());
        setTimeout(() => {
            setCurrentStep(ExternalInvoiceUploadStep.Select);
            setIsLoading(false);
            setIsUploading(false);
            setUploadPercent(0);
            setError('');
            setIsProcessedFileValid(null);
            setIsSelectedFileValid(null);
            setFileValidationResult(null);
            setIsSubmitted(false);
            setSelectedFile(null);
        }, 300);
        if (props.onClose) props.onClose();
    }

    let fileUploadButton = (
        <Button
            onFileChange={(files: File[]) => {
                setSelectedFile(files ? files[0] : null);
                setCurrentStep(ExternalInvoiceUploadStep.Process);
            }}
            text={t('Home.SelectInvoiceFile.Text')}
            icon={'chevron-right'}
            iconPosition={'right'}
            isFileUpload={true}
            fileAccept={appSettings?.uploadFileExtensions}
            primary={true}
            inputRef={selectFileButtonRef}
        />
    );

    function checkFile(file: File) {
        const defaultSizeLimit =
            appSettings?.maximumUploadSize || 15 * 1024 * 1024; // 15 MB

        if (file.size > defaultSizeLimit) {
            setIsProcessedFileValid(false);
            setError(
                t('Common.InvalidMaxFileSize.Text', {
                    fileSize: StringTools.humanSize(
                        appSettings?.maximumUploadSize ?? 0
                    ),
                    fileSizeCurrent: StringTools.humanSize(file.size ?? 0),
                })
            );
            return;
        }

        setIsProcessedFileValid(true);
    }

    async function validateFile(file: File) {
        setIsLoading(true);
        setIsUploading(true);

        try {
            let validationResult = await UploadService.validate(
                file,
                cancelationTrigger,
                (progressEvent: ProgressEvent) => {
                    let percent = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadPercent(percent);

                    if (percent === 100) {
                        setIsUploading(false);
                    }
                }
            );

            setIsLoading(false);
            setIsSelectedFileValid(true);
            setFileValidationResult(validationResult?.data ?? null);

            return validationResult;
        } catch (error) {
            let message = RequestTools.getApiErrorMessage(error);

            if (error instanceof AxiosError && error.response?.data) {
                setFileValidationResult(error.response.data?.data ?? null);
            }

            setError(message);
            setIsLoading(false);
        }
    }

    async function submitFile(file: File) {
        setIsLoading(true);
        setIsUploading(true);

        try {
            let submissionResult = await UploadService.submit(
                file,
                cancelationTrigger,
                (progressEvent: ProgressEvent) => {
                    let percent = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadPercent(percent);

                    if (percent === 100) {
                        setIsUploading(false);
                    }
                }
            );

            setIsLoading(false);
            setIsSubmitted(true);
            setSubmitResultText(submissionResult?.message ?? null);
        } catch (error) {
            let message = RequestTools.getApiErrorMessage(error);

            setError(message);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (currentStep === ExternalInvoiceUploadStep.Process) {
            if (selectedFile) {
                checkFile(selectedFile);
            }
        }

        if (currentStep === ExternalInvoiceUploadStep.Validate) {
            if (selectedFile) {
                validateFile(selectedFile);
            }
        }

        if (currentStep === ExternalInvoiceUploadStep.Submit) {
            if (selectedFile) {
                submitFile(selectedFile);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, selectedFile]);

    return (
        <Dialog
            aria-busy={isLoading}
            className='submit-external-invoice'
            title={t('Home.SubmitExternalInvoiceTitle.Text')}
            closeButtonTitle={t('Common.Close.Text')}
            onClose={() => onClose()}
            active={props.active}
        >
            <StepsProgress type={'simple-small'} steps={steps} />

            {currentStep === ExternalInvoiceUploadStep.Select && (
                <p>{t('Home.SelectInvoiceFile.Help')}</p>
            )}

            {currentStep === ExternalInvoiceUploadStep.Process && (
                <>
                    {isProcessedFileValid === false && (
                        <div className='dialog-result'>
                            <AnimatedErrorIcon />
                            <p className='error'>{error}</p>
                        </div>
                    )}
                    {isProcessedFileValid === true && (
                        <ReactMarkdown>
                            {t('Home.UploadInvoiceConfirmation.Text', {
                                filename: selectedFile?.name,
                            })}
                        </ReactMarkdown>
                    )}
                </>
            )}

            {currentStep === ExternalInvoiceUploadStep.Validate && (
                <>
                    {isSelectedFileValid && (
                        <p>{t('Home.InvoiceValidationSuccessfully.Text')}</p>
                    )}
                    <LoadingOverlay
                        active={isLoading}
                        loadingMessage={
                            isUploading
                                ? t('Home.UploadInvoiceInProgress.Text') +
                                  ' - ' +
                                  uploadPercent +
                                  ' %'
                                : t('Home.UploadValidationInProgress.Text')
                        }
                    />

                    {error && (
                        <div className='dialog-result'>
                            <AnimatedErrorIcon />
                            <h4>{error}</h4>
                        </div>
                    )}

                    {fileValidationResult &&
                        fileValidationResult?.messageSteps && (
                            <ValidationResult result={fileValidationResult} />
                        )}
                </>
            )}

            {currentStep === ExternalInvoiceUploadStep.Submit && (
                <>
                    <LoadingOverlay
                        active={isLoading}
                        loadingMessage={
                            isUploading
                                ? t('Home.UploadInvoiceInProgress.Text') +
                                  ' - ' +
                                  uploadPercent +
                                  ' %'
                                : t('Home.UploadInvoiceInProgress.Text')
                        }
                    />

                    {error && (
                        <div aria-live='polite' className='dialog-result'>
                            <AnimatedErrorIcon />
                            <h4>{error}</h4>
                        </div>
                    )}

                    {isSubmitted && (
                        <div aria-live='polite' className='dialog-result'>
                            <AnimatedSuccessIcon />
                            <p>
                                <strong>{submitResultText}</strong>
                            </p>
                        </div>
                    )}
                </>
            )}

            <div className='buttons-group'>
                {currentStep === ExternalInvoiceUploadStep.Select && (
                    <>
                        <Button
                            text={t('Home.CancelInvoice.Text')}
                            onClick={() => onClose()}
                            danger={true}
                            icon={'x-lg'}
                        />
                        {fileUploadButton}
                    </>
                )}

                {currentStep === ExternalInvoiceUploadStep.Process && (
                    <>
                        <Button
                            text={t('Home.BackSelectNewInvoiceFile.Text')}
                            icon={'chevron-left'}
                            onClick={() => {
                                setIsProcessedFileValid(true);
                                setError('');
                                setCurrentStep(
                                    ExternalInvoiceUploadStep.Select
                                );
                            }}
                        />
                        <Button
                            text={t('Home.RemoveInvoiceFile.Text')}
                            danger={true}
                            icon={'x-lg'}
                            onClick={() => onClose()}
                        />
                        {isProcessedFileValid === true && (
                            <Button
                                text={t('Home.ValidateInvoice.Text')}
                                icon={'chevron-right'}
                                iconPosition={'right'}
                                primary={true}
                                onClick={() =>
                                    setCurrentStep(
                                        ExternalInvoiceUploadStep.Validate
                                    )
                                }
                            />
                        )}
                    </>
                )}

                {currentStep === ExternalInvoiceUploadStep.Validate && (
                    <>
                        <Button
                            text={t('Home.CancelInvoice.Text')}
                            onClick={() => onClose()}
                            danger={true}
                            icon={'x-lg'}
                        />
                        {isSelectedFileValid && (
                            <Button
                                text={t('Home.SubmitInvoice.Text')}
                                icon={'chevron-right'}
                                iconPosition={'right'}
                                primary={true}
                                onClick={() =>
                                    setCurrentStep(
                                        ExternalInvoiceUploadStep.Submit
                                    )
                                }
                            />
                        )}
                    </>
                )}
                {currentStep === ExternalInvoiceUploadStep.Submit && (
                    <>
                        {isSubmitted && (
                            <Button
                                text={t('Home.CloseInvoiceSubmit.Text')}
                                icon={'check-lg'}
                                primary={true}
                                onClick={() => onClose()}
                            />
                        )}
                    </>
                )}
            </div>
        </Dialog>
    );
}
