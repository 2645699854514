import {
    Button,
    Checkbox,
    ModalWindow,
    Toast,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { ChangeEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { UserModel } from '../../../../../models/UserModel';
import UserAdministrationService from '../../../../../services/UserAdministrationService';
import UserListModalFields from './generic/UserListModalFields';

export interface IAddItemModal {
    active: boolean;
    onClose: CallableFunction;
    onInputValueChange: CallableFunction;
    item: UserModel | null;
}

export default function AddItemModal(props: IAddItemModal) {
    const { t } = useTranslation();

    function onClose(actionConfirmed: boolean) {
        setFormSended(false);
        props.onClose(actionConfirmed);
    }

    function handleCancelButtonClick() {
        onClose(false);
    }

    function isModalItemValid(): boolean {
        return (
            props.item !== null &&
            props.item.firstName.length > 0 &&
            props.item.firstName.length <= 200 &&
            props.item.lastName.length > 0 &&
            props.item.lastName.length <= 200 &&
            ValidationTools.isEmailValid(props.item.email) &&
            props.item.email.length <= 200 &&
            props.item.hasAdminAccess !== null &&
            props.item.hasAdminAccess !== undefined
        );
    }

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formSended, setFormSended] = useState<boolean>(false);

    async function handleFormSubmit() {
        setFormSended(true);
        if (props.item === null || !isModalItemValid()) return;

        setIsLoading(true);

        try {
            let result = await UserAdministrationService.create(props.item);

            if (result?.message) {
                Toast.success(result.message, 350);
            }

            props.onClose(true);
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        setIsLoading(false);
    }

    return (
        <ModalWindow
            isLoading={isLoading}
            aria-labelledby='user-administration-modal-add-title'
            active={props.active}
            onSubmit={() => handleFormSubmit()}
            onEscapeKeyPressed={() => {
                if (!isLoading) onClose(false);
            }}
        >
            <h2 id='user-administration-modal-add-title'>
                {t('UserAdministration.AddDialog.Text')}
            </h2>
            <div className='modal-main'>
                <UserListModalFields
                    formSended={formSended}
                    item={props.item}
                    onInputValueChange={(
                        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
                        property: string
                    ) => {
                        props.onInputValueChange(e, property);
                    }}
                />
                <Checkbox
                    checked={props.item?.hasAdminAccess}
                    onChange={(
                        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
                    ) => {
                        props.onInputValueChange(e, 'hasAdminAccess', true);
                    }}
                    title={t('UserAdministration.HasAdminAccess.Text')}
                />
            </div>
            <div className='footer'>
                <Button
                    onClick={() => handleCancelButtonClick()}
                    text={t('UserAdministration.UserDialogCancelBtn.Text')}
                />
                <Button
                    type='submit'
                    disabled={!isModalItemValid()}
                    primary={true}
                    text={t('UserAdministration.AddUserSaveBtn.Text')}
                />
            </div>
        </ModalWindow>
    );
}
