import './TechnicalInformation.scss';

import { Button } from '@b4valuenet/ui-react';

import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAppSelector } from '../../redux/hooks';

const TechnicalInformation = () => {
    const { t } = useTranslation();

    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

    return (
        <div className='sub-page technical-information'>
            <h1>{t('Common.TechnicalInformation.Text')}</h1>
            <p>{t('TechnicalInformation.Block2.Text')}</p>
            <p>{t('TechnicalInformation.Block3.Text')}</p>
            {!isLoggedIn && (
                <Button
                    linkTo={'/'}
                    isLink={true}
                    icon='chevron-left'
                    text={t('PasswordForgotten.CancelButton.Text')}
                    primary={true}
                />
            )}
            <hr />
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className={'text-justify'}
            >
                {t('TechnicalInformation.Content.Text')}
            </ReactMarkdown>
        </div>
    );
};

export default TechnicalInformation;
