import {
    Button,
    Card,
    Mask,
    MaskedInput,
    TextInput,
    Toast,
    ValidationTools,
} from '@b4valuenet/ui-react';
import { createRef, useEffect, useState } from 'react';

import { IBANValidationPattern } from '../../../../../../../models/IBANValidationPattern';
import { Transfer } from '../../../../../../../models/Transfer';
import { useTranslation } from 'react-i18next';

export interface IInvoiceTransfer {
    transfer: Transfer;
    requireBic: boolean;
    isFormSubmitted: boolean;
    disableIBANValidation?: boolean;
    IBANValidationPatterns: IBANValidationPattern[];
    onChange: (updatedTransfer: Transfer) => void;
    onRemove: () => void;
}

export default function InvoiceTransfer(props: IInvoiceTransfer) {
    const { t } = useTranslation();

    const defaultIBANMask =
        'LL** ************************************************';
    const IBANInputRef = createRef<HTMLInputElement>();

    const firstInputRef = createRef<HTMLInputElement>();

    const [IBAN, setIBAN] = useState<string | null>(
        props.transfer.payeeBankAccountIBAN ?? null
    );
    const [isIBANFull, setIsIBANFull] = useState<boolean>(
        props.disableIBANValidation ?? true
    );

    const [BIC, setBIC] = useState<string | null>(
        props.transfer.payeeBankAccountBIC ?? null
    );
    const [isBICFull, setIsBICFull] = useState<boolean>(true);

    useEffect(() => {
        setIBAN((IBAN ? IBAN.replaceAll('_', '') : '').trim());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.disableIBANValidation]);

    useEffect(() => {
        if (firstInputRef) {
            firstInputRef.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let isCountryCodeValid = false;
    let IBANInputCurrentPattern: IBANValidationPattern | null = null;
    let IBANInputMask: string | null = null;
    let BicRequired: boolean = false;

    let countryCode =
        IBAN?.slice(0, 2)?.replaceAll('_', '').toUpperCase() ?? '';

    if (countryCode.length >= 2) {
        IBANInputCurrentPattern =
            props.IBANValidationPatterns.find(
                (pattern) => pattern.countryCode === countryCode
            ) ?? null;

        IBANInputMask = IBANInputCurrentPattern?.maskPattern ?? null;
        BicRequired = IBANInputCurrentPattern?.requireBic ?? false;
        isCountryCodeValid = IBANInputMask !== null;
    }

    let sepaChecked =
        IBANInputCurrentPattern?.partOfSepaSpace || props.disableIBANValidation;

    let isIBANValid =
        (!props.isFormSubmitted &&
            !isIBANFull &&
            (sepaChecked || countryCode.length < 2 || IBAN?.length === 0)) ||
        (isIBANFull && sepaChecked && ValidationTools.isIBANValid(IBAN ?? ''));
    let IBANValidationError = '';

    if (IBANInputCurrentPattern) {
        if (sepaChecked) {
            if (isIBANFull) {
                if (!isIBANValid) {
                    IBANValidationError = t(
                        'ValidationMessage.IbanInvalid.Text'
                    );
                }
            } else {
                if (props.isFormSubmitted) {
                    IBANValidationError = t(
                        'ValidationMessage.IbanEntryNotCompleted.Text'
                    );
                }
            }
        } else {
            IBANValidationError = t(
                'ValidationMessage.IbanPatternUnknown.Text'
            );
        }
    } else {
        if (IBAN && IBAN.replace(/(\s|_)/g, '').length > 0) {
            IBANValidationError = t(
                'ValidationMessage.IbanCountryInvalid.Text'
            );
        } else {
            if (props.isFormSubmitted) {
                IBANValidationError = t('ValidationMessage.Required.Text');
            }
        }
    }

    let isBICValid = !props.isFormSubmitted || isBICFull;
    let BICValidationError = '';

    if (BIC && BIC.length > 0) {
        BICValidationError = t('ValidationMessage.BicEntryNotCompleted.Text');
    } else {
        BICValidationError = t('ValidationMessage.Required.Text');
    }

    useEffect(() => {
        let updatedTransfer = {
            ...props.transfer,
        } as Transfer;
        updatedTransfer.payeeBankAccountIBAN = IBAN ?? '';
        props.onChange(updatedTransfer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIBANFull, IBAN]);

    useEffect(() => {
        let updatedTransfer = {
            ...props.transfer,
        } as Transfer;

        updatedTransfer.payeeBankAccountBIC = BIC ?? '';

        props.onChange(updatedTransfer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBICFull, BIC]);

    useEffect(() => {
        if (props.disableIBANValidation) return;

        let IBANInputElement = IBANInputRef.current;
        setTimeout(() => {
            let IBANCleanValue = IBAN?.replaceAll(/(_|\s)/g, '') ?? '';

            if (IBANInputElement) {
                if (isCountryCodeValid && IBANCleanValue.length <= 2) {
                    IBANInputElement.selectionStart =
                        IBANInputElement.selectionEnd = 2;
                } else {
                    let mask = new Mask(IBANInputMask ?? defaultIBANMask);
                    IBANInputElement.selectionStart =
                        IBANInputElement.selectionEnd =
                            mask.replaceInputValueWithMask(
                                IBAN ?? '',
                                IBAN ?? '',
                                IBAN?.length ?? 0
                            ).cursorPosition;
                }
            }
        }, 2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCountryCodeValid]);

    let bicNeeded = BicRequired || props.requireBic;

    return (
        <Card className={'fade-in spaced-fields'}>
            <TextInput
                inputRef={firstInputRef}
                type={'text'}
                icon={'person'}
                placeholder={t('XRechnung.PayeeBankAccountBankName.Text')}
                label={t('XRechnung.PayeeBankAccountBankName.Text')}
                value={props.transfer.payeeBankAccountBankName?.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let updatedTransfer = {
                        ...props.transfer,
                    } as Transfer;
                    updatedTransfer.payeeBankAccountBankName = e.target.value;
                    props.onChange(updatedTransfer);
                }}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.PayeeBankAccountBankNameHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.PayeeBankAccountBankNameHelpTitle.Text'
                )}
                infoButtonTooltipText={t(
                    'XRechnung.PayeeBankAccountBankName.Help'
                )}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />
            {!props.disableIBANValidation && (
                <MaskedInput
                    required={true}
                    inputRef={IBANInputRef}
                    icon={'bank'}
                    mask={IBANInputMask ?? defaultIBANMask}
                    placeholder={t('XRechnung.PayeeBankAccountIBAN.Text')}
                    label={t('XRechnung.PayeeBankAccountIBAN.Text')}
                    isValid={isIBANValid}
                    validationError={IBANValidationError}
                    value={IBAN ?? ''}
                    onSimpleChange={(newValue: string, isFull: boolean) => {
                        setIBAN(newValue);
                        setIsIBANFull(isFull);
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.PayeeBankAccountIBANHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.PayeeBankAccountIBANHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.PayeeBankAccountIBAN.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
            )}

            {props.disableIBANValidation && (
                <TextInput
                    required={true}
                    inputRef={IBANInputRef}
                    icon={'bank'}
                    type={'text'}
                    placeholder={t('XRechnung.PayeeBankAccountIBAN.Text')}
                    label={t('XRechnung.PayeeBankAccountIBAN.Text')}
                    isValid={ValidationTools.isRequiredFieldValueValid(
                        IBAN,
                        props.isFormSubmitted
                    )}
                    validationError={t('ValidationMessage.Required.Text')}
                    value={IBAN ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setIBAN(e.target.value.toUpperCase());

                        if (!isIBANFull) {
                            setIsIBANFull(true);
                        }
                    }}
                    infoButtonEnabled={true}
                    infoButtonText={t(
                        'XRechnung.PayeeBankAccountIBANHelpTitle.ShortText'
                    )}
                    infoButtonTooltipTitle={t(
                        'XRechnung.PayeeBankAccountIBANHelpTitle.Text'
                    )}
                    infoButtonTooltipText={t(
                        'XRechnung.PayeeBankAccountIBAN.Help'
                    )}
                    infoButtonTitle={t('Common.InputHelp.Text')}
                    infoButtonAriaLabel={t('Common.InputHelp.Text')}
                    combineInfoButtonTextWithTitle={true}
                />
            )}

            <MaskedInput
                icon={'bank'}
                required={bicNeeded}
                mask={'AAAA LL AA AAA'}
                placeholder={t('XRechnung.PayeeBankAccountBIC.Text')}
                label={t('XRechnung.PayeeBankAccountBIC.Text')}
                isValid={!bicNeeded || isBICValid}
                validationError={BICValidationError}
                value={BIC ?? ''}
                onSimpleChange={(newValue: string, isFull: boolean) => {
                    setBIC(newValue);
                    setIsBICFull(isFull);
                }}
                infoButtonEnabled={true}
                infoButtonText={t(
                    'XRechnung.PayeeBankAccountBICHelpTitle.ShortText'
                )}
                infoButtonTooltipTitle={t(
                    'XRechnung.PayeeBankAccountBICHelpTitle.Text'
                )}
                infoButtonTooltipText={t('XRechnung.PayeeBankAccountBIC.Help')}
                infoButtonTitle={t('Common.InputHelp.Text')}
                infoButtonAriaLabel={t('Common.InputHelp.Text')}
                combineInfoButtonTextWithTitle={true}
            />

            <Button
                className='action-button'
                icon='trash'
                danger={true}
                text={t('XRechnung.RemoveTransfer.Text')}
                onClick={() => {
                    props.onRemove();

                    Toast.success(
                        t('UserFeedback.SuccessfullyRemoved.Text', {
                            field: t('XRechnung.Bankaccount.Text'),
                        })
                    );
                }}
            />
        </Card>
    );
}
