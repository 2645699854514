import './Attachments.scss';

import {
    AnimationTools,
    BootstrapIcon,
    Button,
    Column,
    CommonTools,
    ConsolePlaceTag,
    ConsoleTools,
    FileTools,
    FilesUploadDialog,
    FilesUploadDialogTools,
    FilteringOdataFunction,
    FilteringType,
    GridTable,
    IActiveFilter,
    IHeadingTitle,
    ISmartUploadingFile,
    IUploadingFile,
    Pagination,
    ReadableScreenReaderItem,
    RequestTools,
    Row,
    SortState,
    TableTools,
    Toast,
    findAndFocusModalCallerElement,
} from '@b4valuenet/ui-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BigAttachment } from '../../../../models/BigAttachment';
import BigAttachmentsService from '../../../../services/BigAttachmentsService';
import DeleteModal from './modal/DeleteModal';
import { FileUploadDialogTranslation } from '@b4valuenet/ui-react';
import { UILibraryTranslations } from '../../../../shared/UILibraryTranslations';
import { useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

let consolePlaceTag = new ConsolePlaceTag({
    text: 'Attachments',
});

export function Attachments() {
    const { t } = useTranslation();

    const dashboardRoute = useAppSelector((state) => state.app.dashboardRoute);

    let itemsProPage = 25;

    const [searchParams, setSearchParams] = useSearchParams();

    let currentPage: number = TableTools.parsePageFromQuery(searchParams);
    let queryFiltering = TableTools.parseQueryFiltering(searchParams);

    let tableHeadingTitles: IHeadingTitle[] = [
        {
            title: t('Messages.BigAttachmentsFileName.Text'),
            propertyName: 'metaData/fileName',
            sortable: true,
            filtering: FilteringType.String,
            filteringOdataFunction: FilteringOdataFunction.Contains,
        },
        {
            title: t('Messages.BigAttachmentsFileFormat.Text'),
            propertyName: 'metaData/mimeType',
            sortable: true,
            filtering: FilteringType.StringList,
            filteringOdataFunction: FilteringOdataFunction.Equal,
            filteringOptionsList: [
                {
                    key: '',
                    value: t('Common.NoSelection.Text'),
                },
                {
                    key: 'image/jpeg',
                    value: 'JPG/JPEG',
                },
                {
                    key: 'image/png',
                    value:
                        FileTools.getFileExtension('png')?.toUpperCase() || '',
                },
                {
                    key: 'application/pdf',
                    value:
                        FileTools.getFileExtension('pdf')?.toUpperCase() || '',
                },
                {
                    key: 'text/csv',
                    value:
                        FileTools.getFileExtension('csv')?.toUpperCase() || '',
                },
                {
                    key: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    value:
                        FileTools.getFileExtension('xlsx')?.toUpperCase() || '',
                },
                {
                    key: 'application/vnd.oasis.opendocument.spreadsheet',
                    value:
                        FileTools.getFileExtension('ods')?.toUpperCase() || '',
                },
                {
                    key: 'application/xml',
                    value:
                        FileTools.getFileExtension('xml')?.toUpperCase() || '',
                },
            ],
        },
        {
            title: t('Messages.BigAttachmentsCreated.Text'),
            propertyName: 'metaData/created',
            sortable: true,
            filtering: FilteringType.Date,
            filteringOdataFunction: FilteringOdataFunction.DateDefault,
        },
        {
            title: t('Messages.BigAttachmentsExpires.Text'),
            propertyName: 'metaData/expires',
            sortable: true,
            filtering: FilteringType.Date,
            filteringOdataFunction: FilteringOdataFunction.DateDefault,
        },
        {
            title: t('Messages.BigAttachmentsDownloadCount.Text'),
            propertyName: 'metaData/downloadCount',
            sortable: true,
            filtering: FilteringType.Number,
            filteringOdataFunction: FilteringOdataFunction.Equal,
        },
        { title: t('Messages.BigAttachmentsActions.Text') },
    ];

    const appSettings = useAppSelector((state) => state.app.settings);
    const navigate = useNavigate();

    if (appSettings && !appSettings.bigAttachmentsEnabled) {
        navigate('/dashboard/home');
    }

    const [pageCount, setPageCount] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingText, setLoadingText] = useState<string>();
    const [loadingError, setLoadingError] = useState<string | null>(null);

    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(true);

    const [data, setData] = useState<BigAttachment[] | null>(null);
    const [totalCount, setTotalCount] = useState<number>(0);

    const [activeFiltering, setActiveFiltering] = useState<IActiveFilter[]>(
        TableTools.queryFilteringToGridTableFormat(
            queryFiltering,
            tableHeadingTitles
        )
    );
    const [filteringString, setFilteringString] = useState<string>(
        TableTools.buildOdataFilterString(activeFiltering)
    );
    const [activeSort, setActiveSort] = useState<SortState>(
        TableTools.sortStateFromString(
            searchParams.get('sort-direction') ?? 'none'
        )
    );
    const [activeSortProperty, setActiveSortProperty] = useState<string | null>(
        TableTools.getValidSortProperty(
            searchParams.get('sort'),
            tableHeadingTitles
        )
    );

    const refresh = useCallback(
        (showToast: boolean = true) => {
            setRefreshTrigger(!refreshTrigger);

            if (showToast) Toast.info(t('Common.ListUpdateSuccess.Text'));
        },
        [refreshTrigger, t]
    );

    const [linkCopied, setLinkCopied] = useState<boolean>(false);
    const [linkCopiedId, setLinkCopiedId] = useState<string | null>(null);
    const [linkCopiedResetTimeout, setLinkCopiedResetTimeout] =
        useState<NodeJS.Timeout | null>(null);

    const [expExtended, setExpExtended] = useState<boolean>(false);
    const [expExtendedId, setExpExtendedId] = useState<string | null>(null);
    const [expExtendedResetTimeout, setExpExtendedResetTimeout] =
        useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (data !== null) {
            if (currentPage < 1) {
                searchParams.set('page', '1');
                setSearchParams(searchParams);
            }
            if (pageCount > 0 && currentPage > pageCount) {
                searchParams.set('page', pageCount.toString());
                setSearchParams(searchParams);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, pageCount, data]);

    useEffect(() => {
        TableTools.updateActiveSortOnQueryParamsChange(
            setActiveSort,
            setActiveSortProperty,
            searchParams,
            tableHeadingTitles
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    useEffect(() => {
        setActiveFiltering(
            TableTools.queryFilteringToGridTableFormat(
                queryFiltering,
                tableHeadingTitles
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        ConsoleTools.log({ activeSort, activeSortProperty }, consolePlaceTag);
    }, [activeSort, activeSortProperty]);

    useEffect(() => {
        if ((filteringString?.length ?? 0) === 0) refresh(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteringString]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                let content = await BigAttachmentsService.getBigAttachments(
                    currentPage,
                    itemsProPage,
                    activeSort,
                    activeSortProperty,
                    filteringString
                );

                setTotalCount(content?.['@odata.count'] ?? 0);
                setData(content?.value ?? null);
                setLoadingError(null);

                let allItemsCount =
                    content !== null ? content['@odata.count'] : 0;
                let newPageCount = Math.ceil(allItemsCount / itemsProPage);

                setPageCount(newPageCount);

                setIsLoading(false);
            } catch (error) {
                if (error instanceof Error) {
                    setLoadingError(RequestTools.getApiErrorMessage(error));
                }

                setIsLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentPage,
        itemsProPage,
        activeSort,
        activeSortProperty,
        refreshTrigger,
    ]);

    const [uploadingFiles, setUploadingFiles] = useState<ISmartUploadingFile[]>(
        []
    );
    const [uploadDialogActive, setUploadDialogActive] =
        useState<boolean>(false);

    const [isDeleteModalActive, setIsDeleteModalActive] =
        useState<boolean>(false);

    const [modalCallerElement, setModalCallerElement] =
        useState<HTMLButtonElement | null>(null);
    const [modalId, setModalId] = useState<string | null>(null);

    const handleOnFilesSelected = useCallback((selectedFiles: File[]) => {
        FilesUploadDialogTools.defaultOnFilesSelected(
            setUploadingFiles,
            selectedFiles,
            async (
                file: File,
                onUploadProgress: CallableFunction,
                abortController: AbortController
            ) => {
                let result = await BigAttachmentsService.upload(
                    file,
                    abortController,
                    onUploadProgress
                );

                if (result) {
                    return result.message;
                }
            }
        );
    }, []);

    const handleOnClearButtonPressed = useCallback(
        (staticFiles: IUploadingFile[]) => {
            FilesUploadDialogTools.defaultOnClearAction(
                setUploadingFiles,
                staticFiles
            );
        },
        []
    );

    async function handleDownloadButtonOnClick(
        attachment: BigAttachment,
        e: React.MouseEvent<HTMLButtonElement>
    ) {
        let button = e.target as HTMLButtonElement;

        setLoadingText(t('Messages.DownloadPrepared.Text'));
        setIsLoading(true);

        if (e.target instanceof HTMLButtonElement)
            setModalCallerElement(e.target);

        button.disabled = true;

        try {
            await BigAttachmentsService.download(attachment);
            Toast.info(t('Messages.DownloadStarted.Text'));
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        button.disabled = false;

        setTimeout(() => {
            findAndFocusModalCallerElement(modalCallerElement, () => {
                setModalCallerElement(null);
            });
        }, 300);

        setLoadingText(undefined);
        refresh(false);
    }

    async function handleCopyLinkButtonOnClick(attachment: BigAttachment) {
        if (linkCopiedResetTimeout) clearTimeout(linkCopiedResetTimeout);

        let copied = await CommonTools.copyTextToClipboard(
            attachment.metaData.link
        );

        setLinkCopied(copied);
        setLinkCopiedId(attachment.id);

        Toast.success(t('Messages.CopiedToClipboard.Text'));

        let timeout = setTimeout(() => {
            setLinkCopied(false);
            setLinkCopiedId(null);
        }, 2000);

        setLinkCopiedResetTimeout(timeout);
    }

    async function handleExtendExpirationButtonOnClick(
        attachment: BigAttachment
    ) {
        if (expExtendedResetTimeout) clearTimeout(expExtendedResetTimeout);

        setIsLoading(true);

        try {
            let result = await BigAttachmentsService.extendExpiration(
                attachment.id
            );

            if (result) {
                Toast.success(result.message, 350);
            }

            setExpExtended(true);
            setExpExtendedId(attachment.id);

            refresh(false);
        } catch (error) {
            Toast.showErrorWhenAuthorized(error);
        }

        let timeout = setTimeout(() => {
            setExpExtended(false);
            setExpExtendedId(null);
        }, 2000);

        setExpExtendedResetTimeout(timeout);

        setIsLoading(false);
    }

    const AttachmentsUploadDialogTranslation = {
        dragAndDropTranslation: {
            title: t('Common.FileUpload_DragAndDrop_Title.Text'),
            or: t('Common.FileUpload_DragAndDrop_Or.Text'),
            actions: {
                browseFiles: t(
                    'Common.FileUpload_DragAndDrop_BrowseFiles.Text'
                ),
            },
        },
        requirements: {
            maxFileSize: t('Common.FileUpload_Requirements_MaxFileSize.Text'),
            allowedExtensions: t(
                'Common.FileUpload_Requirements_AllowedExtension.Text'
            ),
        },
        fileStates: {
            pending: t('Common.FileUpload_FileStates_Pending.Text'),
            uploaded: t('Common.FileUpload_FileStates_Uploaded.Text'),
            canceled: t('Common.FileUpload_FileStates_Canceled.Text'),
        },
        fileActions: {
            delete: t('Common.FileUpload_FileActions_Delete.Text'),
            stopUpload: t('Common.FileUpload_FileActions_StopUpload.Text'),
        },
        footerActions: {
            clear: t('Common.FileUpload_FooterActions_Clear.Text'),
            stopAllUploads: t(
                'Common.FileUpload_FooterActions_StopAllUploads.Text'
            ),
            startFileUpload: t(
                'Common.FileUpload_FooterActions_StartFileUpload.Text'
            ),
            done: t('Common.FileUpload_FooterActions_Done.Text'),
        },
        remainingTime: t('Common.FileUpload_RemainingTime.Text'),
        errors: {
            fileTooBig: t('Common.FileUpload_Errors_FileTooBig.Text'),
            extensionNotAllowed: t(
                'Common.FileUpload_Errors_ExtensionNotAllowed.Text'
            ),
            exitOnActiveUploads: t(
                'Common.FileUpload_Errors_ExitOnActiveUploads.Text'
            ),
        },
    } as FileUploadDialogTranslation;

    return (
        <div className='big-attachments-wrapper'>
            <GridTable
                isSubPage={true}
                subTitle={t('XRechnung.BigAttachmentsGridHelpText.Text')}
                className='big-attachments'
                title={t('Home.BigAttachmentGridTitle.Text')}
                onEmptyMessage={t('Messages.NoAttachmentsAvailable.Text')}
                enableFiltering={true}
                onFilter={(filtering, newFilteringString) => {
                    TableTools.updateQueryParamsOnFilter(
                        filtering,
                        searchParams,
                        setSearchParams
                    );

                    setFilteringString(newFilteringString);
                }}
                isLoading={isLoading}
                loadingText={loadingText ?? t('Common.LoadingMessage.Text')}
                loadingError={loadingError}
                tableHeadingTitles={tableHeadingTitles}
                tableData={data}
                pagination={
                    pageCount > 1 ? (
                        <Pagination
                            routePath={dashboardRoute}
                            maxItemsCount={5}
                            currentPage={currentPage}
                            pageCount={pageCount}
                            labels={{
                                goToStartPage: t(
                                    'Common.PaginationGoToStartPageLabel.Text'
                                ),
                                goToPage: t(
                                    'Common.PaginationGoToPageLabel.Text'
                                ),
                                goToEndPage: t(
                                    'Common.PaginationGoToEndPageLabel.Text'
                                ),
                            }}
                        />
                    ) : null
                }
                filteringTranslation={{
                    toggleButtonTitle: t(
                        'Common.Filtering_ToggleButtonTitle.Text'
                    ),
                    resetButtonTitle: t(
                        'Common.Filtering_ResetButtonTitle.Text'
                    ),
                    calendarTranslation:
                        UILibraryTranslations.getCalendarTranslation(t),
                }}
                filteringActionButtons={
                    <>
                        <Button
                            onClick={() => refresh(false)}
                            primary={true}
                            icon={'search'}
                            disabled={(filteringString?.length ?? 0) === 0}
                            text={t('Setup.SearchBtn.Text')}
                        />
                    </>
                }
                showCount={true}
                totalCount={totalCount}
                countPerPage={itemsProPage}
                currentPage={currentPage}
                countTranslation={{
                    of: t('Common.PaginatorOf.Text'),
                    items: t('XRechnung.Attachment.Text'),
                }}
                activeFiltering={activeFiltering ?? []}
                activeSort={activeSort}
                activeSortProperty={activeSortProperty}
                onSort={(
                    newActiveSort: SortState,
                    newActiveSortProperty: string | null
                ) => {
                    TableTools.updateQueryParamsOnSort(
                        newActiveSort,
                        newActiveSortProperty,
                        searchParams,
                        setSearchParams
                    );
                }}
                rowRendering={(element: BigAttachment, index: number) => {
                    return (
                        <Row id={element.id} key={index}>
                            <Column>{element.metaData.fileName}</Column>
                            <Column size='small'>
                                {
                                    <div className='file-format'>
                                        <BootstrapIcon
                                            className='file-type'
                                            icon={FileTools.getIconByMime(
                                                element.metaData.mimeType
                                            )}
                                        />
                                        {FileTools.getFileExtension(
                                            element.metaData.fileName
                                        )?.toUpperCase()}
                                    </div>
                                }
                            </Column>
                            <Column>
                                {TableTools.toLocaleDateString(
                                    element.metaData.created
                                )}
                            </Column>
                            <Column>
                                {TableTools.toLocaleDateString(
                                    element.metaData.expires
                                )}
                            </Column>
                            <Column size='small'>
                                {element.metaData.downloadCount}
                            </Column>
                            <Column className='actions'>
                                <Button
                                    onClick={() =>
                                        handleCopyLinkButtonOnClick(element)
                                    }
                                    icon={
                                        linkCopied &&
                                        linkCopiedId === element.id
                                            ? 'check-lg'
                                            : 'clipboard'
                                    }
                                    primary={
                                        linkCopied &&
                                        linkCopiedId === element.id
                                    }
                                    title={t(
                                        'Messages.BigAttachmentsCopyLink.Text'
                                    )}
                                    aria-label={t(
                                        'Messages.BigAttachmentsCopyLink.Text'
                                    )}
                                />
                                <Button
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) =>
                                        handleDownloadButtonOnClick(element, e)
                                    }
                                    icon='download'
                                    primary={false}
                                    title={t(
                                        'Messages.BigAttachmentsDownloadFile.Text'
                                    )}
                                    aria-label={t(
                                        'Messages.BigAttachmentsDownloadFile.Text'
                                    )}
                                />
                                <Button
                                    onClick={() =>
                                        handleExtendExpirationButtonOnClick(
                                            element
                                        )
                                    }
                                    icon={
                                        expExtended &&
                                        expExtendedId === element.id
                                            ? 'check-lg'
                                            : 'clock'
                                    }
                                    primary={
                                        expExtended &&
                                        expExtendedId === element.id
                                    }
                                    title={t(
                                        'Messages.BigAttachmentsExtendFileExpiration.Text'
                                    )}
                                    aria-label={t(
                                        'Messages.BigAttachmentsExtendFileExpiration.Text'
                                    )}
                                />
                                <Button
                                    onClick={(
                                        e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        setModalId(element.id);

                                        if (
                                            e.target instanceof
                                            HTMLButtonElement
                                        )
                                            setModalCallerElement(e.target);

                                        AnimationTools.activateModal(
                                            setIsDeleteModalActive
                                        );
                                    }}
                                    icon='trash'
                                    danger={true}
                                    title={t(
                                        'Messages.BigAttachmentsDeleteFile.Text'
                                    )}
                                    aria-label={t(
                                        'Messages.BigAttachmentsDeleteFile.Text'
                                    )}
                                />
                            </Column>
                        </Row>
                    );
                }}
                actionButtons={
                    <>
                        <Button
                            onClick={() => refresh()}
                            icon='arrow-clockwise'
                            text={t('Messages.RefreshBtn.Text')}
                            className='refresh'
                            primary={true}
                        />
                        <Button
                            primary={true}
                            icon={'cloud-upload'}
                            text={t('Messages.UploadBigAttachment.Text')}
                            onClick={() => setUploadDialogActive(true)}
                        />
                    </>
                }
            />
            <FilesUploadDialog
                title={t('Messages.UploadBigAttachment.Text')}
                translation={AttachmentsUploadDialogTranslation}
                // TODO: Check if this value can be get from backend
                maxFileSize={200 * 1024 * 1024}
                // TODO: check is case senstive
                allowedExtensions={[
                    'jpg',
                    'jpeg',
                    'png',
                    'pdf',
                    'csv',
                    'xlsx',
                    'ods',
                    'xml',
                ]}
                supportButton={{
                    icon: 'headset',
                    text: t('Common.ContactSupportButton.Text'),
                    isLink: true,
                    linkTo: `mailto:${appSettings?.supportMail}`,
                    linkExternal: true,
                }}
                active={uploadDialogActive}
                files={uploadingFiles}
                onFilesSelected={handleOnFilesSelected}
                onClearButtonPressed={handleOnClearButtonPressed}
                onFileRemove={(file: IUploadingFile) => {
                    FilesUploadDialogTools.defaultOnFileRemove(
                        setUploadingFiles,
                        file
                    );
                }}
                onClose={() => {
                    setUploadDialogActive(false);
                    refresh(false);
                    setTimeout(() => {
                        setUploadingFiles([]);
                    }, 1000);
                }}
            ></FilesUploadDialog>

            {data && activeFiltering && (
                <div aria-live={'polite'}>
                    {data.length > 0 && (
                        <ReadableScreenReaderItem
                            text={
                                t('Messages.FindedInvoices.Text') +
                                ': ' +
                                data.length
                            }
                        />
                    )}
                    {data.length === 0 && (
                        <ReadableScreenReaderItem
                            text={t('Messages.NoAttachmentsAvailable.Text')}
                        />
                    )}
                </div>
            )}

            <DeleteModal
                id={modalId}
                active={isDeleteModalActive}
                onClose={(actionConfirmed: boolean) => {
                    AnimationTools.deactivateModal(
                        setIsDeleteModalActive,
                        () => {
                            if (actionConfirmed) {
                                refresh(false);
                                setModalId(null);
                            }

                            findAndFocusModalCallerElement(
                                modalCallerElement,
                                () => {
                                    setModalCallerElement(null);
                                }
                            );
                        }
                    );
                }}
            />
        </div>
    );
}
