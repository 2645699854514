import {
    CalendarTranslation,
    DragAndDropAreaTranslation,
    VideoPlayerTranslation,
} from '@b4valuenet/ui-react';

export class UILibraryTranslations {
    static getCalendarTranslation(t: CallableFunction): CalendarTranslation {
        return {
            units: {
                months: [
                    t('Unit.MonthOfYear_January.Text'),
                    t('Unit.MonthOfYear_February.Text'),
                    t('Unit.MonthOfYear_March.Text'),
                    t('Unit.MonthOfYear_April.Text'),
                    t('Unit.MonthOfYear_May.Text'),
                    t('Unit.MonthOfYear_June.Text'),
                    t('Unit.MonthOfYear_July.Text'),
                    t('Unit.MonthOfYear_August.Text'),
                    t('Unit.MonthOfYear_September.Text'),
                    t('Unit.MonthOfYear_October.Text'),
                    t('Unit.MonthOfYear_November.Text'),
                    t('Unit.MonthOfYear_December.Text'),
                ],
                weekDays: [
                    t('Unit.DayOfWeek_Monday.Text'),
                    t('Unit.DayOfWeek_Tuesday.Text'),
                    t('Unit.DayOfWeek_Wednesday.Text'),
                    t('Unit.DayOfWeek_Thursday.Text'),
                    t('Unit.DayOfWeek_Friday.Text'),
                    t('Unit.DayOfWeek_Saturday.Text'),
                    t('Unit.DayOfWeek_Sunday.Text'),
                ],
            },
            datePicker: {
                fromDate: t('Common.Calendar_DatePicker_FromDate.Text'),
                toDate: t('Common.Calendar_DatePicker_ToDate.Text'),
                open: t('Common.Calendar_DatePicker_Open.Text'),
                close: t('Common.Calendar_DatePicker_Close.Text'),
                format: t('Common.Calendar_DatePicker_Format.Text'),
            },
            suggestions: {
                thisMonth: t('Common.Calendar_Suggestions_ThisMonth.Text'),
                thisWeek: t('Common.Calendar_Suggestions_ThisWeek.Text'),
                lastSevenDays: t(
                    'Common.Calendar_Suggestions_LastSevenDays.Text'
                ),
                lastThirtyDays: t(
                    'Common.Calendar_Suggestions_LastThirtyDays.Text'
                ),
                yesterday: t('Common.Calendar_Suggestions_Yesterday.Text'),
                today: t('Common.Calendar_Suggestions_Today.Text'),
                tomorrow: t('Common.Calendar_Suggestions_Tomorrow.Text'),
            },
            headerActions: {
                prevYear: t('Common.Calendar_HeaderActions_PrevYear.Text'),
                prevMonth: t('Common.Calendar_HeaderActions_PrevMonth.Text'),
                nextMonth: t('Common.Calendar_HeaderActions_NextMonth.Text'),
                nextYear: t('Common.Calendar_HeaderActions_NextYear.Text'),
            },

            footerActions: {
                navigate: {
                    selectYear: t(
                        'Common.Calendar_FooterActions_Navigate_SelectYear.Text'
                    ),
                    selectMonth: t(
                        'Common.Calendar_FooterActions_Navigate_SelectMonth.Text'
                    ),
                    selectDay: t(
                        'Common.Calendar_FooterActions_Navigate_SelectDay.Text'
                    ),
                },
                confirm: t('Common.Calendar_FooterActions_Confirm.Text'),
            },
        } as CalendarTranslation;
    }

    static getDragAndDropTranslation(
        t: CallableFunction
    ): DragAndDropAreaTranslation {
        return {
            title: t('Common.FileUpload_DragAndDrop_Title.Text'),
            or: t('Common.FileUpload_DragAndDrop_Or.Text'),
            actions: {
                browseFiles: t(
                    'Common.FileUpload_DragAndDrop_BrowseFiles.Text'
                ),
            },
        };
    }

    static getVideoPlayerTranslation(
        t: CallableFunction
    ): VideoPlayerTranslation {
        return {
            actions: {
                play: t('Common.VideoPlayer_Actions_Play.Text'),
                pause: t('Common.VideoPlayer_Actions_Pause.Text'),
                mute: t('Common.VideoPlayer_Actions_Mute.Text'),
                unmute: t('Common.VideoPlayer_Actions_Unmute.Text'),
                goFullscreen: t('Common.VideoPlayer_Actions_GoFullscreen.Text'),
                exitFullscreen: t(
                    'Common.VideoPlayer_Actions_ExitFullscreen.Text'
                ),
            },
            seek: t('Common.VideoPlayer_Seek.Text'),
            controlPanel: t('Common.VideoPlayer_ControlPanel.Text'),
            defaultErrorText: t('Common.VideoPlayer_DefaultErrorText.Text'),
        };
    }
}
