import { Card } from '@b4valuenet/ui-react';
import { useTranslation } from 'react-i18next';
import { Discount } from '../../../../../../../../../models/Discount';

export interface IPaymentTermsDiscounts {
    discounts: Discount[];
}
export default function PaymentTermsDiscounts(props: IPaymentTermsDiscounts) {
    const { t } = useTranslation();

    return (
        <Card title={t('XRechnung.Discounts.Text')}>
            <div className='pseudo-table'>
                <table>
                    <thead>
                        <tr>
                            <th>{t('XRechnung.DueDays.Text')}</th>
                            <th>{t('XRechnung.Percentage.Text')}</th>
                            <th>{t('XRechnung.BaseAmount.Text')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.discounts.map((discount, index) => {
                            return (
                                <tr key={index}>
                                    <td>{discount.dueDays}</td>
                                    <td>{discount.percentage}</td>
                                    <td>{discount.baseAmount}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
