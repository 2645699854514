import { RequestTools, SortState } from '@b4valuenet/ui-react';

import { GenericActionResponse } from '../models/GenericActionResponse';
import { GetMessagesResponse } from '../models/GetMessagesResponse';
import { MessageStatusStep } from '../models/MessageStatusStep';
import { MessageVisualizeData } from '../models/MessageVisualizeData';
import { SchematronReport } from '../models/SchematronReport';
import { ValidationResultMessageStep } from '../models/ValidationResultMessageStep';
import { XRechnungMessageType } from '../models/XRechnungMessageType';

export default class MessagesService {
    static ApiRoot = 'api/';
    static OdataRoot = 'api/';

    static getMessages = async (
        currentPage: number,
        itemsProPage: number,
        activeSort: SortState,
        activeSortProperty: string | null,
        filteringString: string | null
    ): Promise<GetMessagesResponse | null> => {
        let skip = (currentPage - 1) * itemsProPage;

        filteringString = filteringString ?? '';

        let response = await RequestTools.get(this.OdataRoot + 'MessageView', {
            params: {
                $skip: skip,
                $top: itemsProPage,
                $orderby:
                    activeSort !== SortState.None && activeSortProperty !== null
                        ? activeSortProperty +
                          ' ' +
                          (activeSort === SortState.Asc ? 'asc' : 'desc')
                        : 'created desc',
                $count: true,
                $filter:
                    filteringString.length > 0 ? filteringString : undefined,
            },
        });

        let json = await response.data;
        let result = (json as GetMessagesResponse) || null;

        return result;
    };

    static getXRechnungMessageTypes = async (): Promise<
        XRechnungMessageType[] | null
    > => {
        let response = await RequestTools.get(
            this.ApiRoot + 'selectableoptions/getxrechnungmessagetypes'
        );

        let json = await response.data;
        let result = (json.data as XRechnungMessageType[]) || null;

        return result;
    };

    static deleteMessages = async (
        ids: (number | string)[]
    ): Promise<GenericActionResponse | null> => {
        var params = new URLSearchParams();
        params.append('ids', ids.join(','));

        let response = await RequestTools.delete(this.ApiRoot + 'message', {
            params: params,
        });

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result;
    };

    static downloadFile = async (id: number) => {
        await RequestTools.get(this.ApiRoot + 'message/' + id + '/download', {
            responseType: 'blob', // important
        }).then((response) => {
            let headerLine = response.headers['content-disposition'] ?? null;

            if (headerLine !== null) {
                let filename = headerLine.split('filename=')[1].split(';')[0];

                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        });
    };

    static downloadPdfFile = async (id: number) => {
        await RequestTools.get(this.ApiRoot + 'message/' + id + '/view', {
            responseType: 'blob', // important
        }).then((response) => {
            let headerLine = response.headers['content-disposition'] ?? null;

            if (headerLine !== null) {
                let filename = headerLine.split('filename=')[1].split(';')[0];

                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        });
    };

    static exportCSV = async () => {
        await RequestTools.get(this.ApiRoot + 'message/excel-export', {
            responseType: 'blob', // important
        }).then((response) => {
            let headerLine = response.headers['content-disposition'] ?? null;

            if (headerLine !== null) {
                let filename = headerLine.split('filename=')[1].split(';')[0];

                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        });
    };

    static visualize = async (
        id: number
    ): Promise<MessageVisualizeData | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'message/visualize/' + id
        );

        let json = await response.data;
        let result = (json.data as MessageVisualizeData) || null;

        return result;
    };

    static getMessageProtocol = async (
        id: number
    ): Promise<ValidationResultMessageStep[]> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'message/getprotocol/' + id
        );

        let json = await response.data;
        let result = (json.data as ValidationResultMessageStep[]) || null;

        return result;
    };

    static getMessageStatus = async (
        id: number
    ): Promise<MessageStatusStep[] | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'message/' + id + '/status'
        );

        let json = await response.data;
        let result = (json.data as MessageStatusStep[]) || null;

        return result;
    };

    static getSchematronProtocol = async (
        id: number
    ): Promise<SchematronReport | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'message/getschematronprotocol/' + id
        );

        let json = await response.data;
        let result = (json.data as SchematronReport) || null;
        return result;
    };
}
