import { AttachmentRestrictions } from '../models/AttachmentRestrictions';
import { GenericActionResponse } from '../models/GenericActionResponse';
import { IBANValidationPattern } from '../models/IBANValidationPattern';
import { KeyValuePair } from '../models/KeyValuePair';
import { PaymentMeansCode } from '../models/PaymentInfo';
import { RequestTools } from '@b4valuenet/ui-react';
import { XRechnung } from '../models/XRechnung';

export default class XRechnungService {
    static ApiRoot = 'api/';

    static getCurrencyOptions = async (): Promise<KeyValuePair[] | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'selectableoptions/getcurrencyoptions'
        );

        let json = await response.data;
        let result = (json.data as KeyValuePair[]) || null;

        return result ?? null;
    };

    static getUnitOptions = async (): Promise<KeyValuePair[] | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'selectableoptions/getUnitOptions'
        );

        let json = await response.data;
        let result = (json.data as KeyValuePair[]) || null;

        return result ?? null;
    };

    static getTaxCategories = async (): Promise<KeyValuePair[] | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'selectableoptions/getTaxCategories'
        );

        let json = await response.data;
        let result = (json.data as KeyValuePair[]) || null;

        return result ?? null;
    };

    static getIBANValidationPatterns = async (): Promise<
        IBANValidationPattern[] | null
    > => {
        let response = await RequestTools.get(
            this.ApiRoot + 'xrechnung/iban-validation-patterns'
        );

        let json = await response.data;
        let result = (json.data as IBANValidationPattern[]) || null;

        return result ?? null;
    };

    static get = async (id: number): Promise<XRechnung | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'xrechnung/' + id + '/get'
        );

        let json = await response.data;
        let result = (json.data as XRechnung) || null;

        return result ?? null;
    };

    static getAttachmentRestrictions = async (
        id: number
    ): Promise<AttachmentRestrictions | null> => {
        let response = await RequestTools.get(
            this.ApiRoot + 'xrechnung/get/' + id + '/attachmentrestrictions'
        );

        let json = await response.data;
        let result = (json.data as AttachmentRestrictions) || null;

        if (result !== null) {
            result.allowedExtensions = result.allowedExtensions
                .join(', ')
                .replaceAll('.', '')
                .split(', ');
        }

        return result ?? null;
    };

    static deleteAttachment = async (
        id: number
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.delete(
            this.ApiRoot + 'xrechnung/delete/attachment?attachmentId=' + id
        );

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result ?? null;
    };

    static prepareData = (data: XRechnung) => {
        let newData = { ...data };

        if (
            newData.paymentInfo.paymentMeans ===
            PaymentMeansCode.SepaDirectDebit
        ) {
            newData.paymentInfo.transfers = [];
        }

        newData.paymentInfo.transfers = data.paymentInfo.transfers.map(
            (transfer) => {
                let newTransfer = { ...transfer };

                newTransfer.payeeBankAccountIBAN = transfer.payeeBankAccountIBAN
                    ?.toString()
                    .replaceAll('_', '')
                    .replaceAll(' ', '')
                    .trim();

                newTransfer.payeeBankAccountBIC = transfer.payeeBankAccountBIC
                    ?.toString()
                    .replaceAll('_', '')
                    .replaceAll(' ', '')
                    .trim();

                return newTransfer;
            }
        );

        if (newData.paymentInfo.debit) {
            newData.paymentInfo.debit.buyerBankAccountIBAN =
                newData.paymentInfo.debit.buyerBankAccountIBAN
                    .toString()
                    .replaceAll('_', '')
                    .replaceAll(' ', '');
        }

        return newData;
    };

    static save = async (data: XRechnung) => {
        let newData = this.prepareData(data);

        let response = await RequestTools.put(
            this.ApiRoot + 'xrechnung/save',
            newData
        );

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result ?? null;
    };

    static updateAndValidate = async (data: XRechnung) => {
        let newData = this.prepareData(data);

        let response = await RequestTools.put(
            this.ApiRoot + 'xrechnung/updateAndValidate',
            newData
        );

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result ?? null;
    };

    static submit = async (data: XRechnung) => {
        let newData = this.prepareData(data);

        let response = await RequestTools.put(
            this.ApiRoot + 'xrechnung/submit',
            newData
        );

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result ?? null;
    };

    static export = async (data: XRechnung, ignoreAttachments: boolean) => {
        let newData = this.prepareData(data);

        await RequestTools.post(
            this.ApiRoot +
                `xrechnung/export?ignoreAttachments=${ignoreAttachments}`,
            newData,
            {
                responseType: 'blob', // important
            }
        ).then((response) => {
            let headerLine = response.headers['content-disposition'] ?? null;

            if (headerLine !== null) {
                let filename = headerLine.split('filename=')[1].split(';')[0];

                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        });
    };
}
