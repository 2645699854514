import { InvoiceData } from '../../../../../../../../models/InvoiceData';
import PaymentTermsDiscounts from './components/PaymentTermsDiscounts';

export interface IPaymentTermsSummary {
    invoiceData: InvoiceData;
}

export default function PaymentTermsSummary(props: IPaymentTermsSummary) {
    return (
        <>
            {(props.invoiceData.discounts ?? []).length > 0 && (
                <PaymentTermsDiscounts
                    discounts={props.invoiceData.discounts ?? []}
                />
            )}
        </>
    );
}
