import {
    appSettingsLoaded,
    appVisiabilityUpdated,
    isLoadingUpdated,
} from '../slices/AppSlice';
import { isAuthenticated, isUnauthenticated } from '../slices/AuthSlice';

import { RequestTools } from '@b4valuenet/ui-react';
import { Dispatch } from '@reduxjs/toolkit';
import { AppSettings } from '../../models/AppSettings';
import { User } from '../../models/User';
import AppSettingsService from '../../services/AppSettingsService';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';

export const checkAuth = () => async (dispatch: Dispatch) => {
    dispatch(isLoadingUpdated({ isLoading: true }));

    try {
        let isLoggedIn: boolean = await AuthService.isAuthenticated();

        if (isLoggedIn) {
            let user: User | null = await UserService.getCurrent();
            dispatch(isAuthenticated({ user: user }));
        } else {
            dispatch(isUnauthenticated());
        }

        dispatch(appVisiabilityUpdated({ isVisible: true }));
        setTimeout(() => {
            dispatch(isLoadingUpdated({ isLoading: false }));
        }, 10);
    } catch (error) {
        let errorText = RequestTools.getApiErrorMessage(error);

        dispatch(
            isLoadingUpdated({
                isLoading: false,
                loadingError: errorText + ' on isAuthenticated',
            })
        );
    }
};

export const getAppSettings =
    (locale: string) => async (dispatch: Dispatch) => {
        dispatch(isLoadingUpdated({ isLoading: true }));

        try {
            let appSettings: AppSettings | null = await AppSettingsService.get(
                locale
            );

            dispatch(appSettingsLoaded({ appSettings: appSettings }));

            dispatch(appVisiabilityUpdated({ isVisible: true }));
            setTimeout(() => {
                dispatch(isLoadingUpdated({ isLoading: false }));
            }, 10);
        } catch (error) {
            let errorText = RequestTools.getApiErrorMessage(error);

            dispatch(
                isLoadingUpdated({
                    isLoading: false,
                    loadingError: errorText + ' on getAppSettings',
                })
            );
        }
    };
