import { RequestTools } from '@b4valuenet/ui-react';
import { ClientAdministrationData } from '../models/ClientAdministrationData';
import {
    ActionResponse,
    GenericActionResponse,
} from '../models/GenericActionResponse';
import { PeppolStatus } from '../models/PeppolActivate';

export default class ClientService {
    static ApiRoot = 'api/clientadministration/';

    static getClientAdministrationData =
        async (): Promise<ClientAdministrationData | null> => {
            let response = await RequestTools.get(this.ApiRoot + 'get');

            return (response.data.data as ClientAdministrationData) || null;
        };

    static updateClientAdministrationData = async (
        data: ClientAdministrationData
    ) => {
        let response = await RequestTools.put(this.ApiRoot + 'update', data);

        let result = await response.data;

        return result;
    };

    static deleteClient = async () => {
        let response = await RequestTools.delete(this.ApiRoot + 'deactivate');

        return response.data as GenericActionResponse;
    };

    static getCurrentPeppolState = async (): Promise<
        ActionResponse<PeppolStatus>
    > => {
        let response = await RequestTools.get(this.ApiRoot + 'peppol');

        return (response.data as ActionResponse<PeppolStatus>) || null;
    };

    static activatePeppolVerificationProcess = async (data: {
        peppolId: string;
    }): Promise<ActionResponse<PeppolStatus>> => {
        let response = await RequestTools.post(this.ApiRoot + 'peppol', data);

        return (response.data as ActionResponse<PeppolStatus>) || null;
    };

    static cancelPeppolVerificationProcess = async (): Promise<
        ActionResponse<PeppolStatus>
    > => {
        let response = await RequestTools.delete(this.ApiRoot + 'peppol');

        return (response.data as ActionResponse<PeppolStatus>) || null;
    };

    static downloadPeppolVerificationAttachment = async () => {
        await RequestTools.get(this.ApiRoot + 'peppol/attachment', {
            responseType: 'blob', // important
        }).then((response) => {
            let headerLine = response.headers['content-disposition'] ?? null;

            if (headerLine !== null) {
                let filename = '';

                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(headerLine);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }

                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        });
    };
}
