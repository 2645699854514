import { Card } from '@b4valuenet/ui-react';
import { ReceiverData } from '../../../../../../../../../models/ReceiverData';
import { useTranslation } from 'react-i18next';

export interface IBuyerContactDetails {
    receiverData: ReceiverData;
}
export default function BuyerContactDetails(props: IBuyerContactDetails) {
    const { t } = useTranslation();

    return (
        <Card title={t('XRechnung.BuyerDetails.Text')}>
            <div className='pseudo-table align-left cw-50'>
                <table>
                    <tbody>
                        {props.receiverData.buyerContactContactPerson && (
                            <tr>
                                <th>
                                    {t('XRechnung.BuyerContactPerson.Text')}
                                </th>
                                <td>
                                    {
                                        props.receiverData
                                            .buyerContactContactPerson
                                    }
                                </td>
                            </tr>
                        )}
                        {props.receiverData.buyerContactContactEmail && (
                            <tr>
                                <th>{t('XRechnung.ContactEmail.Text')}</th>
                                <td>
                                    {
                                        props.receiverData
                                            .buyerContactContactEmail
                                    }
                                </td>
                            </tr>
                        )}
                        {props.receiverData.buyerContactContactPhoneNo && (
                            <tr>
                                <th>{t('XRechnung.ContactPhoneNo.Text')}</th>
                                <td>
                                    {
                                        props.receiverData
                                            .buyerContactContactPhoneNo
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
