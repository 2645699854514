import { AmountGroupData } from '../../../../../../../../models/AmountGroupData';
import { Card } from '@b4valuenet/ui-react';
import { KeyValuePair } from '../../../../../../../../models/KeyValuePair';
import PaymentAmountGroupSummary from './components/PaymentAmountGroupSummary';
import { useTranslation } from 'react-i18next';

interface IPaymentAmountGroupsSummary {
    amountGroups: AmountGroupData[];
    taxCategories: KeyValuePair[];
}

export default function PaymentAmountGroupsSummary(
    props: IPaymentAmountGroupsSummary
) {
    const { t } = useTranslation();

    return (
        <Card title={t('XRechnung.AmountGroupsHeader.Text')}>
            <div className='pseudo-table cw-50'>
                <table>
                    <thead>
                        <tr>
                            <th>{t('XRechnung.TaxableAmount.Text')}</th>
                            <th>{t('XRechnung.PositionTaxCategory.Text')}</th>
                            <th>{t('XRechnung.AmountsVatAmount.Text')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.amountGroups.map((amountGroup, index) => (
                            <PaymentAmountGroupSummary
                                key={index}
                                amountGroup={amountGroup}
                                taxCategories={props.taxCategories}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </Card>
    );
}
